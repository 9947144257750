import {
  useCherreState,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import {
  Button,
  Grid,
  Link as MuiLink,
  Typography,
  styled,
} from '@cherre-frontend/ui';
import React from 'react';
import ReminderList from './ReminderList';
import ReminderListSkeleton from './ReminderListSkeleton';
import {
  getResolvedReminders,
  getUnresolvedReminders,
  reminderDrawerShowCompletedState,
} from './recoil';

const ActiveRemindersCount = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

const ShowCompletedButton = styled(Button)`
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey[500] : theme.palette.primary.main};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export type FilterOptions = {
  'Entity ID': Record<string, string>;
  Batch: Record<string, string>;
};

const ReminderDrawerContent: React.FC = () => {
  const isSuspeneded = useIsSuspended();

  const resolvedReminders = useCherreValue(getResolvedReminders());
  const unresolvedReminders = useCherreValue(getUnresolvedReminders());

  const [showCompleted, setShowCompleted] = useCherreState(
    reminderDrawerShowCompletedState
  );

  if (isSuspeneded || !resolvedReminders || !unresolvedReminders) {
    return <ReminderListSkeleton />;
  }

  const activeReminderLabel = React.useMemo(() => {
    if (showCompleted) {
      const totalReminders =
        resolvedReminders?.length + unresolvedReminders?.length;
      return `${totalReminders} Reminder${totalReminders === 1 ? '' : 's'}`;
    }

    return `${
      unresolvedReminders.length === 0 ? 'No' : unresolvedReminders.length
    } Active Reminder${unresolvedReminders.length === 1 ? '' : 's'}`;
  }, [unresolvedReminders, resolvedReminders, showCompleted]);

  return (
    <>
      <Grid display='flex' alignItems='center' justifyContent='space-between'>
        <ActiveRemindersCount>{activeReminderLabel}</ActiveRemindersCount>
        {resolvedReminders && resolvedReminders.length > 0 ? (
          <ShowCompletedButton
            as={MuiLink}
            disabled={!resolvedReminders.length}
            onClick={() => {
              if (resolvedReminders.length) {
                setShowCompleted(!showCompleted);
              }
            }}
          >
            {showCompleted ? 'Hide' : 'Show all'}
          </ShowCompletedButton>
        ) : null}
      </Grid>

      <ReminderList
        resolvedReminders={resolvedReminders}
        unresolvedReminders={unresolvedReminders}
        showCompleted={showCompleted || false}
        setShowCompleted={setShowCompleted}
      />
    </>
  );
};

export default ReminderDrawerContent;
