import React from 'react';
import CheckboxLabelExpansionIcon from './CheckboxLabelExpansionIconListItem';
import ExpansionIconLabel from './ExpansionIconLabelListItem';
import LabelListItem from './LabelListItem';

const ListItem = (props) => {
  const {
    listItemType,
    node = {
      id: 'default',
      parent: '',
    },
    idData,
  } = props;

  if (listItemType === 'checkboxLabel' || idData[node].relationship === true) {
    return <ExpansionIconLabel {...props} node={node} />;
  } else if (listItemType === 'labelOnly') {
    return <LabelListItem {...props} node={node} />;
  }

  return <CheckboxLabelExpansionIcon {...props} node={node} />;
};

export default ListItem;
