import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'src/components/ButtonProgress';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { disableApiKey } from 'src/products/admin/redux/organization/actions';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

const useStyles = makeStyles({
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
});

const DisableApiKey = ({
  open,
  onRequestClose,
  data: { refresh, row: apiKey },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle>Disable API Key</DialogTitle>
      <DialogContent className={classes.modalContent}>
        Are you sure you want to disable API key {apiKey.id}?
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color='primary'>
          close
        </Button>
        <Button
          onClick={async () => {
            try {
              await dispatch(disableApiKey(apiKey.id));
              await refresh();

              showSuccessPopupNotification(`API key ${apiKey.id} was disabled`);
              onRequestClose();
            } catch (error) {
              console.log(error);

              showErrorPopupNotification(
                `Failed to disable API key ${apiKey.id}`
              );
            }
          }}
          color='primary'
          variant='contained'
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DisableApiKey.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  data: PropTypes.object,
  disableApiKey: PropTypes.func,
};

export default DisableApiKey;
