import { Box, Skeleton, styled } from '@cherre-frontend/ui';
import React from 'react';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

const ReminderToggleRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReminderListSkeleton: React.FC = () => {
  return (
    <Container>
      <ReminderToggleRow>
        <Skeleton variant='rectangular' width={'160px'} height={16} />
        <Skeleton variant='rectangular' width={'80px'} height={16} />
      </ReminderToggleRow>
      {Array(3)
        .fill(null)
        .map((_, idx) => {
          return (
            <div key={idx} style={{ display: 'grid', gap: 4 }}>
              <Skeleton variant='rectangular' width={'100%'} height={36} />
              <Skeleton variant='rectangular' width={'60%'} height={16} />
            </div>
          );
        })}
    </Container>
  );
};

export default ReminderListSkeleton;
