import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Column } from 'react-base-table';
import SelectionCell from './SelectionCell';

export const formatColumns = (columns, stickyLeft, stickyRight, theme) =>
  columns.map((col, index) => {
    let frozen;

    if (index < stickyLeft) {
      frozen = Column.FrozenDirection.LEFT;
    }

    if (columns.length - 1 - index < stickyRight) {
      frozen = Column.FrozenDirection.RIGHT;
    }

    return {
      dataKey: col.dataKey,
      title: col.label,
      key: col.id || col.dataKey,
      width: col.width,
      align: col.align,
      frozen,
      resizable: col.isResizable,
      sortable: col.isSortable,
      flexGrow: col.flexGrow,
      flexShrink: col.flexShrink,
      cellRenderer: ({ cellData, rowData, columnIndex, rowIndex }) => {
        return col.cellRenderer
          ? col.cellRenderer({
              columnIndex,
              rowData,
              cellData,
              theme,
              rowIndex,
            })
          : cellData;
      },
    };
  });

export const addSelection = ({
  columns,
  onSelect,
  onSelectAll,
  isSelectAllChecked,
  selectedRowKeys,
  rowKey,
}) => {
  return [
    {
      headerRenderer: () => (
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              disableRipple
              checked={isSelectAllChecked}
              onChange={(e) => onSelectAll(e.target.checked)}
            />
          }
        />
      ),
      isSelectAllChecked,
      width: 50,
      flexShrink: 0,
      resizable: true,
      frozen: Column.FrozenDirection.LEFT,
      cellRenderer: SelectionCell,
      key: '__selection__',
      selectedRowKeys,
      onChange: onSelect,
      rowKey,
    },
    ...columns,
  ];
};
