import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import numberWithCommas from 'src/utils/numberWithCommas';
import capitalize from 'src/utils/capitalize';
import moment from 'moment';

export const getMaskedValue = (
  inputValue: string | number | null | undefined,
  mask: string,
  options: { isTemplate?: boolean; dateFormat?: string } = {}
) => {
  let value =
    typeof inputValue === 'number' ? inputValue.toString() : inputValue;

  if (options?.isTemplate && [null, undefined, '', 'N/A'].includes(value)) {
    value = '-';
  }

  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === 'N/A'
  ) {
    return value;
  }

  if (!mask) {
    return value;
  }

  const { dateFormat = 'MMM D, YYYY' } = options;

  let maskedValue: string | null = value;

  switch (mask) {
    case 'percent':
      maskedValue = `${value}%`;
      break;

    case 'dollars':
      maskedValue = `$${numberWithCommas(value)}`;
      break;
    case 'dollarsPerSqft':
      maskedValue = `$${numberWithCommas(value)}/PSF`;
      break;
    case 'ft':
      maskedValue = `${numberWithCommas(value)} ft`;
      break;
    case 'months':
      maskedValue = `${numberWithCommas(value)} mo`;
      break;
    case 'sqft':
      maskedValue = `${numberWithCommas(value)} sqft`;
      break;

    case 'capitalize':
      maskedValue = capitalize(value);
      break;

    case 'number':
      maskedValue = numberWithCommas(value);
      break;

    case 'boolean':
      maskedValue = value ? 'yes' : 'no';
      break;

    case 'reverseBoolean':
      maskedValue = value ? 'no' : 'yes';
      break;

    case 'date':
      if (!value || value === '-') {
        break;
      }

      maskedValue = value ? moment(value).format(dateFormat) : null;
      break;

    case 'timeago':
      if (!value) {
        break;
      }

      maskedValue = value ? moment(new Date(value)).fromNow() : null;
      break;
    case 'phone': {
      const areaCode = value.slice(0, 3);
      const firstThree = value.slice(3, 6);
      const lastFour = value.slice(-4);

      return `${areaCode}.${firstThree}.${lastFour}`;
    }
    default:
  }

  return maskedValue;
};

export const getMaskedInput = (
  mask:
    | 'dollars'
    | 'sqft'
    | 'percent'
    | 'ft'
    | 'digitsNoCommas'
    | 'bbl'
    | 'borough'
    | 'block'
    | 'lot'
    | 'bin'
    | 'year_built'
) => {
  let prefix: string | undefined,
    suffix: string | undefined,
    format: string | undefined,
    thousandSeparator: string | boolean | undefined;
  const decimalSeparator = '.';

  switch (mask) {
    case 'dollars':
      thousandSeparator = true;
      prefix = '$';
      break;
    case 'sqft':
      thousandSeparator = true;
      suffix = ' sqft';
      break;
    case 'percent':
      thousandSeparator = true;
      suffix = '%';
      break;
    case 'ft':
      thousandSeparator = true;
      suffix = ' ft';
      break;
    case 'digitsNoCommas':
      thousandSeparator = '';
      break;
    case 'bbl':
      format = '#-#####-####';
      break;
    case 'borough':
      format = '#';
      break;
    case 'block':
      format = '####';
      break;
    case 'lot':
      format = '####';
      break;
    case 'bin':
      format = '#######';
      break;
    case 'year_built':
      format = '####';
      break;
    default:
  }

  const props: NumberFormatProps = {
    prefix,
    suffix,
    thousandSeparator,
    decimalSeparator,
    format,
  };

  return (userProps: NumberFormatProps) => (
    <NumberFormat {...props} {...userProps} />
  );
};
