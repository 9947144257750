import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import {
  CustomCheckbox,
  CustomListItem,
  ListItemTextStyles,
} from '../TreeCustomComponents';
import IndeterminateIcon from '../IndeterminateIcon';
import ExpansionIcon from '../ExpansionIcon';

const CheckboxLabelExpansionIconListItem = (props) => {
  const {
    disabled,
    checked,
    indeterminate,
    handleCheckboxClick,
    showExpansionIcon,
    idData,
    changePanelExpansion,
    node,
    depth,
  } = props;

  if (idData[node] === undefined) {
    return null;
  }

  return (
    <div
      title={idData[node].tooltip}
      id='js-checkbox-label-expansion-list-item'
    >
      <CustomListItem key={node} component='div'>
        <CustomCheckbox
          disableRipple
          color='primary'
          disabled={disabled[node] === true}
          checked={checked[node] === true}
          indeterminate={indeterminate[node] === true}
          indeterminateIcon={<IndeterminateIcon />}
          onChange={() => handleCheckboxClick(idData[node])}
        />
        <ListItemText
          primary={`${idData[node].label}`}
          onClick={() => {
            if (!showExpansionIcon(idData[node])) {
              handleCheckboxClick(idData[node]);
            } else {
              changePanelExpansion(idData[node]);
            }
          }}
          style={ListItemTextStyles(depth).normal}
        />
        <ExpansionIcon
          {...props}
          node={node}
          handleExpansionIconClick={() => {
            changePanelExpansion(idData[node]);
          }}
        />
      </CustomListItem>
    </div>
  );
};

export default CheckboxLabelExpansionIconListItem;
