/**
 * @generated SignedSource<<e56221b63f77606cb26b2ea3644cc90d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type runScheduledMutation$variables = {
  owner: string;
};
export type runScheduledMutation$data = {
  readonly run_scheduled_scan: {
    readonly end_time: string;
  } | null;
};
export type runScheduledMutation = {
  response: runScheduledMutation$data;
  variables: runScheduledMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "owner"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "owner",
            "variableName": "owner"
          }
        ],
        "kind": "ObjectValue",
        "name": "params"
      }
    ],
    "concreteType": "run_scheduled_scan_output",
    "kind": "LinkedField",
    "name": "run_scheduled_scan",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end_time",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "runScheduledMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "runScheduledMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "19278575ccab8cf97eeb30329f89b6d8",
    "id": null,
    "metadata": {},
    "name": "runScheduledMutation",
    "operationKind": "mutation",
    "text": "mutation runScheduledMutation(\n  $owner: String!\n) {\n  run_scheduled_scan(params: {owner: $owner}) {\n    end_time\n  }\n}\n"
  }
};
})();

(node as any).hash = "b37e2353ec80c2f192e5f8fce4701864";

export default node;
