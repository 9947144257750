import { Snapshot } from 'recoil';
import {
  dealBrokersSelector,
  dealILOsSelector,
  dealLeadersSelector,
  dealSellersSelector,
  dealStagesSelector,
  dealTeamsSelector,
  dealInvestmentTypesSelector,
} from './recoil';

export interface LotParams {
  cherreParcelId: string;
  taxAssessorId: number;
}

export const getCommonData = async (snapshot: Snapshot) => {
  //first get the loadables
  const brokersLoadable = snapshot.getLoadable(dealBrokersSelector);
  const sellersLoadable = snapshot.getLoadable(dealSellersSelector);
  const ilosLoadable = snapshot.getLoadable(dealILOsSelector);
  const stagesLoadable = snapshot.getLoadable(dealStagesSelector);
  const leadsLoadable = snapshot.getLoadable(dealLeadersSelector);
  const teamsLoadable = snapshot.getLoadable(dealTeamsSelector);
  const investmentTypesLoadable = snapshot.getLoadable(
    dealInvestmentTypesSelector
  );

  // await for all promises at the same time
  const [stages, leads, brokers, sellers, ilos, teams, investmentTypes] =
    await Promise.all([
      stagesLoadable.toPromise(),
      leadsLoadable.toPromise(),
      brokersLoadable.toPromise(),
      sellersLoadable.toPromise(),
      ilosLoadable.toPromise(),
      teamsLoadable.toPromise(),
      investmentTypesLoadable.toPromise(),
    ]);

  return {
    stages,
    leads,
    brokers,
    sellers,
    ilos,
    teams,
    investmentTypes,
  };
};
