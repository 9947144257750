import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

const reverseOrder = (order) => {
  if (order === 'asc') {
    return 'desc';
  }

  if (order === 'desc') {
    return 'asc';
  }
};

const TableSortLabelStyled = withStyles({
  root: {
    fontSize: '12px',
  },
  active: {
    fontSize: '12px',
  },
})(TableSortLabel);

const TableCellStyles = withStyles({
  root: {
    padding: '4px 24px',
  },
})(TableCell);

function EnhancedTableHead(props) {
  const { order, orderBy, columns, onRequestSort, actions } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          return (
            <TableCellStyles
              key={`${column.field_name}-${index}`}
              numeric={column.numeric}
              padding={column.disablePadding ? 'none' : 'default'}
            >
              {!column.sortable ? (
                column.column_label
              ) : (
                <Tooltip
                  title='Sort'
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabelStyled
                    active={orderBy === column.field_name}
                    direction={
                      column.mask === 'timeago' ? reverseOrder(order) : order
                    }
                    onClick={() => onRequestSort(column.field_name)}
                  >
                    {column.column_label}
                  </TableSortLabelStyled>
                </Tooltip>
              )}
            </TableCellStyles>
          );
        })}
        {actions && (
          <TableCell style={{ textAlign: 'right' }}>Actions</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  actions: PropTypes.bool,
};

export default EnhancedTableHead;
