import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import pluralize from 'pluralize';

export function Title({ classes = {} }, { schema = {} }) {
  return (
    <div id='table-page-toolbar-title' className={classes.root}>
      {pluralize(schema.entityName || '')}
    </div>
  );
}

Title.contextTypes = {
  schema: PropTypes.object,
};

Title.propTypes = {
  classes: PropTypes.object,
};

const styles = {
  root: {
    color: '#212121',
    fontSize: 36,
    marginRight: 15,
    textTransform: 'capitalize',
  },
};

export default withStyles(styles)(Title);
