import { ConnectorStatus } from 'src/products/connector/recoil';
import { StandardizedConnector } from 'src/products/connector/types/connectorTypes';

export const connectors: StandardizedConnector[] = [
  {
    name: 'chatham-warehouse',
    displayName: 'Chatham to Warehouse',
    sources: [
      {
        provider: 'chatham',
        name: 'Chatham',
        logo: '/assets/connector-icons/rounded-logos/chatham-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Snowflake',
            logo: '/assets/connector-icons/rounded-logos/snowflake-rounded.svg',
            provider: 'snowflake',
          },
        ],
      },
    ],

    status: ConnectorStatus.SUCCESS,
    statusMessage: 'Success Apr 26 9:00 PM',
    lastRun: 'Apr 12 2024 9:00 PM',
    nextRun: 'May 26 2024 9:00 PM',
    disabled: false,
    schemaName: 'cherre-demo.prd-2-25-openmetadata-demo-1.publish',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
  {
    displayName: 'VTS Leasing to Warehouse',
    name: 'vts-leasing-warehouse',
    sources: [
      {
        provider: 'VTS',
        name: 'VTS',
        logo: '/assets/connector-icons/rounded-logos/vts-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Snowflake',
            logo: '/assets/connector-icons/rounded-logos/snowflake-rounded.svg',
            provider: 'snowflake',
          },
        ],
      },
    ],

    status: ConnectorStatus.SUCCESS,
    statusMessage: 'Success Apr 10 9:30 PM',
    lastRun: 'Apr 12 2024 9:00 PM',
    nextRun: 'Sep 10 2025 9:00 PM',
    disabled: false,
    schemaName: 'cherre-demo.prd-2-25-openmetadata-demo-1.publish',
    connectorRunningObject: {
      isRunning: true,
      runningMessage: 'Source Running',
    },
  },
  {
    displayName: 'Dwellsy Test Feed',
    name: 'dwellsy-test-feed',
    sources: [
      {
        provider: 'dwellsy',
        name: 'Dwellsy',
        logo: '/assets/connector-icons/rounded-logos/dwellsy-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Snowflake',
            logo: '/assets/connector-icons/rounded-logos/snowflake-rounded.svg',
            provider: 'snowflake',
          },
        ],
      },
    ],

    status: ConnectorStatus.FAILED,
    statusMessage: 'Source Failed Apr 10 9:30 PM',
    lastRun: 'Mar 10 2024 9:00 PM',
    disabled: false,
    schemaName: 'prd-2-25-dwellsy.prd-2-25-dwellsy-1.publish',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
  {
    displayName: 'Cash Flows to Anaplan',
    name: 'cash-flows-anaplan',
    sources: [
      {
        provider: 'excel',
        name: 'Excel',
        logo: '/assets/connector-icons/rounded-logos/excel-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
      {
        provider: 'argus',
        name: 'Argus',
        logo: '/assets/connector-icons/rounded-logos/argus-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Anaplan',
            logo: '/assets/connector-icons/rounded-logos/anaplan-rounded.svg',
            provider: 'anaplan',
          },
        ],
      },
    ],

    status: ConnectorStatus.SCHEDULED,
    statusMessage: 'Scheduled Mar 10 9:00 PM',
    lastRun: 'Apr 10 2024 9:00 PM',
    disabled: true,
    schemaName: 'prd-2-25-demo.prd-2-25-demo.cardinal',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
  {
    displayName: 'Actuals to Anaplan',
    name: 'actuals-anaplan',
    sources: [
      {
        provider: 'excel',
        name: 'Excel',
        logo: '/assets/connector-icons/rounded-logos/excel-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
      {
        provider: 'mri',
        name: 'MRI',
        logo: '/assets/connector-icons/rounded-logos/mri-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Anaplan',
            logo: '/assets/connector-icons/rounded-logos/anaplan-rounded.svg',
            provider: 'anaplan',
          },
        ],
      },
    ],

    status: ConnectorStatus.DRAFT,
    statusMessage: 'Draft',
    lastRun: 'Apr 12 2024 9:00 PM',
    disabled: true,
    schemaName: 'prd-2-25-demo.prd-2-25-demo.cardinal',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
  {
    displayName: 'MRI to Snowflake',
    name: 'mri-snowflake',
    sources: [
      {
        provider: 'mri',
        name: 'MRI',
        logo: '/assets/connector-icons/rounded-logos/mri-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Snowflake',
            logo: '/assets/connector-icons/rounded-logos/snowflake-rounded.svg',
            provider: 'snowflake',
          },
        ],
      },
    ],

    status: ConnectorStatus.DEACTIVATED,
    statusMessage: 'Deactivated',
    lastRun: 'May 26 2023 9:00 PM',
    disabled: true,
    schemaName: 'prd-2-25-demo.prd-2-25-demo.cardinal',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
  {
    displayName: 'Cherre Foundation Data',
    name: 'cherre-foundation-data',
    sources: [
      {
        provider: 'cherre',
        name: 'Cherre',
        logo: '/assets/connector-icons/rounded-logos/cherre-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Snowflake',
            logo: '/assets/connector-icons/rounded-logos/snowflake-rounded.svg',
            provider: 'snowflake',
          },
        ],
      },
    ],

    status: ConnectorStatus.DEACTIVATED,
    statusMessage: 'Deactivated',
    lastRun: 'May 26 2023 9:00 PM',
    disabled: true,
    schemaName: 'prd-2-25-demo.prd-2-25-demo.cardinal',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
  {
    displayName: 'Lease Comps',
    name: 'lease-comps',
    sources: [
      {
        provider: 'compstack',
        name: 'Compstack',
        logo: '/assets/connector-icons/rounded-logos/compstack-rounded.svg',
        createdAt: '2024-05-02T17:17:49+00:00',
        schedule: '',
      },
    ],
    models: [
      {
        provider: 'cherre',
        name: 'cherre',
        createdAt: '2024-05-02T17:17:49+00:00',
        publishedTables: [],
        destinations: [
          {
            name: 'Snowflake',
            logo: '/assets/connector-icons/rounded-logos/snowflake-rounded.svg',
            provider: 'snowflake',
          },
        ],
      },
    ],

    status: ConnectorStatus.DEACTIVATED,
    schemaName: 'prd-2-25-demo.prd-2-25-demo.cardinal',
    statusMessage: 'Deactivated',
    lastRun: 'May 26 2023 9:00 PM',
    disabled: true,
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
];
