import React from 'react';
import { makeStyles, createStyles, InputLabel } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Avatar from '@material-ui/core/Avatar';

import compressImage from 'src/utils/compressImage';

type Props = {
  input: {
    value: string;
    name: string;
    onChange: (val: string) => void;
  };
  label: string;
  shape: 'square' | 'round';
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: 15,
    },
    image: {
      margin: 15,
      width: (props: Props) => (props.shape === 'round' ? 100 : 200),
      height: 100,
      position: 'relative',
      overflow: 'hidden',
      borderRadius: (props: Props) => (props.shape === 'round' ? '100%' : '0%'),
    },
    file: {
      cursor: 'pointer',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      opacity: 0,
      width: (props: Props) => (props.shape === 'round' ? 100 : 200),
      height: 100,
    },
  })
);

const UploadImage = (props: Props): JSX.Element => {
  const { input, label } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {label && <InputLabel>{label}</InputLabel>}
      <div className={classes.image}>
        <Avatar
          alt='Logo Image'
          src={input.value}
          style={{
            width: props.shape === 'round' ? 100 : 200,
            height: 100,
            padding: 10,
            borderRadius: props.shape === 'round' ? '100%' : '10px',
          }}
        >
          <PhotoCameraIcon style={{ fontSize: 48 }} />
        </Avatar>
        <br />
        <input
          {...input}
          accept='image/*'
          value=''
          onChange={(event) => {
            const selectedFile = event?.target?.files?.[0];

            if (!selectedFile) {
              return;
            }

            const reader = new FileReader();

            reader.onload = async function (event) {
              const src =
                selectedFile.type === 'image/svg+xml'
                  ? event?.target?.result
                  : await compressImage(
                      event?.target?.result as string,
                      200,
                      200
                    );

              input.onChange(src as string);
            };

            reader.readAsDataURL(selectedFile);
          }}
          type='file'
          className={classes.file}
        />
      </div>
    </div>
  );
};

export default UploadImage;
