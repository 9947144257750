import * as FILTER_TYPES from 'src/components/filters/const/filter-types';

const filters = {
  [FILTER_TYPES.RANGE_SELECTION]: require('./RangeSelection').default,
  [FILTER_TYPES.RANGE_INPUT]: require('./RangeInput').default,
  [FILTER_TYPES.RANGE_DATE]: require('./RangeDate').default,
  [FILTER_TYPES.TEXT_INPUT]: require('./TextInput').default,
  [FILTER_TYPES.MULTIPLE_SELECTION]: require('./MultipleSelection').default,
  [FILTER_TYPES.BOOLEAN_SELECTION]: require('./BooleanSelection').default,
  [FILTER_TYPES.HIERARCHICAL_SELECTION]: require('./HierarchicalSelection')
    .default,
  [FILTER_TYPES.SELECT_FILTER]: require('./Select').default,
};

export function getSearchParameterOperator(filterType) {
  switch (filterType) {
    case FILTER_TYPES.RANGE_SELECTION:
    case FILTER_TYPES.RANGE_INPUT:
    case FILTER_TYPES.RANGE_DATE:
      return 'range';

    case FILTER_TYPES.MULTIPLE_SELECTION:
    case FILTER_TYPES.HIERARCHICAL_SELECTION:
    case FILTER_TYPES.SECTORS_SELECTION:
      return 'part_of_a_list';

    case FILTER_TYPES.TEXT_INPUT:
      return 'match';

    default:
      return 'equals';
  }
}

export function getFilterComponent(filter) {
  const filterType = Object.prototype.hasOwnProperty.call(filter, 'filter_type')
    ? filter.filter_type
    : filter;
  return filters[filterType].component;
}

export function getFilterValue(filter) {
  const store =
    require('src/products/shell/redux/store/configureStore').default;

  const state = store.getState();
  return state.common.search.filters[filter.id];
}

export function getFilterQuery(filter, value) {
  return filters[filter.filter_type].serializer.getQuery(filter, value);
}

export function getFilterDisplayValue(filter, value, data) {
  return filters[filter.filter_type].serializer.getFilterDisplayValue(
    filter,
    value,
    data
  );
}

export function getFilterSetDisplayValue(filter, value, data) {
  return filters[filter.filter_type].serializer.getFilterSetDisplayValue(
    filter,
    value,
    data
  );
}
