import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import grey from '@material-ui/core/colors/grey';

const checkBoxStyles = () => ({
  root: {
    height: 24,
    width: 24,
    fontSize: 20,
  },
  disabled: {
    color: '#bdbdbd',
  },
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const CustomListItem = withStyles({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 0,
    paddingLeft: 0,
    height: 'auto',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: grey[200],
      cursor: 'pointer',
    },
  },
  disabled: {
    backgroundColor: 'rgba(255, 80, 81, 0.10)',
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 0,
    paddingLeft: 0,
    borderRadius: 3,
    borderWidth: 2,
    borderColor: 'rgba(255, 80, 81, .4)',
    opacity: 1,
    borderStyle: 'solid',
  },
})(ListItem);

const CustomCollapse = withStyles({
  container: {
    marginTop: 4,
    marginBottom: 4,
  },
})(Collapse);

const ListItemTextStyles = (depth, bold, disabled) => {
  return {
    normal: {
      display: 'inline',
      fontSize: '1em',
      fontWeight: depth === 1 || bold ? 900 : 'normal',
      color: disabled ? 'rgba(0, 0, 0, 0.26)' : '',
      paddingRight: 0,
      paddingLeft: 1,
      whiteSpace: 'normal',
      overflow: 'hidden',
      alignItems: 'flex-start',
      paddingBottom: 1,
      marginTop: 0,
      marginBottom: 0,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: 'transparent',
      borderStyle: 'solid',
    },
    selected: {
      display: 'inline',
      fontSize: '1em',
      fontWeight: depth === 1 ? 'bold' : 'normal',
      paddingRight: 0,
      paddingLeft: 1,
      backgroundColor: 'rgba(255, 80, 81, 0.10)',
      borderRadius: 2,
      borderWidth: 2,
      borderColor: 'rgba(255, 80, 81, .4)',
      opacity: 1,
      borderStyle: 'solid',
      whiteSpace: 'normal',
      overflow: 'hidden',
      alignItems: 'flex-start',
      paddingBottom: 1,
      marginBottom: -4,
    },
  };
};

const ListStyles = (depth) => {
  return {
    paddingLeft: depth === 1 ? 0 : 22,
    marginBottom: 3,
    '&:lastChild': {
      marginTop: 100,
    },
  };
};

const ExpansionIconStyles = (disabled) => {
  return {
    color: disabled ? 'rgba(0, 0, 0, 0.26)' : '',
  };
};

export {
  CustomCheckbox,
  CustomListItem,
  CustomCollapse,
  ListItemTextStyles,
  ListStyles,
  ExpansionIconStyles,
};
