import { Permission as PermissionValues } from 'src/products/shell/redux/reducers/user';
import { PRODUCTS, PRODUCT_LINES } from '.';

export type PermissionType = {
  permission: PermissionValues;
  label?: string;
  product: (typeof PRODUCTS)[keyof typeof PRODUCTS];
  productLine: (typeof PRODUCT_LINES)[keyof typeof PRODUCT_LINES];
  description: string;
  children?: Pick<PermissionType, 'permission' | 'label' | 'description'>[];
};

const permissions: PermissionType[] = [
  {
    permission: 'CoreSchema',
    product: PRODUCTS.SCHEMA,
    productLine: PRODUCT_LINES.CORE_CONNECT,
    description:
      'Discover connected data objects, fields and relations between them.',
  },
  {
    permission: 'CoreAPI',
    product: PRODUCTS.API,
    productLine: PRODUCT_LINES.CORE_CONNECT,
    description:
      'Technical documentation containing instructions about how to effectively use and integrate with Cherre API',
  },
  {
    permission: 'AnalyticsAPI',
    product: PRODUCTS.ANALYTICS_API,
    productLine: PRODUCT_LINES.CORE_CONNECT,
    description:
      'Technical documentation, specifically for our BigQuery offering, providing an outline of how the API may be used',
  },
  {
    permission: 'Connectors',
    product: PRODUCTS.CONNECTORS,
    productLine: PRODUCT_LINES.CORE_CONNECT,
    description:
      'Create and review existing Connectors. Get visibility into Connector status, observability, usage, and create new Connectors on the fly!',
    children: [
      {
        permission: 'ConnectorsAdmin',
        label: 'Connectors Admin',
        description:
          'Includes the ability to create connectors, create and edit validation rule, and manage alerts.',
      },
      {
        permission: 'Connectors',
        label: 'Connectors Regular',
        description:
          'Includes the ability to view connectors, validation rules, and alerts.',
      },
    ],
  },
  {
    permission: 'CoreProspect',
    label: 'Search',
    product: PRODUCTS.EXPLORE,
    productLine: PRODUCT_LINES.CORE_EXPLORE,
    description:
      'Explore property characteristics, transaction history, taxes, ownership and more.',
  },
  {
    permission: 'CoreBI',
    product: PRODUCTS.BI,
    productLine: PRODUCT_LINES.CORE_EXPLORE,
    description:
      'Discover patterns, trends and business opportunities using the advanced visualization dashboards.',
  },
  {
    permission: 'PowerBI',
    product: PRODUCTS.BI,
    productLine: PRODUCT_LINES.CORE_EXPLORE,
    description:
      'Discover patterns, trends and business opportunities using the advanced visualization dashboards.',
    children: [
      {
        permission: 'PowerBIAdmin',
        label: 'PowerBI Admin',
        description:
          'Includes the ability to view and assign user access to all dashboards associated with an organization.',
      },
      {
        permission: 'PowerBI',
        label: 'PowerBI Regular',
        description:
          'Includes the ability to view all dashboards that have been assigned to this user.',
      },
    ],
  },
  {
    permission: 'DataConnections',
    product: PRODUCTS.EXPLORE,
    productLine: PRODUCT_LINES.CORE_EXPLORE,
    description: 'Lightning Connections',
  },
  {
    permission: 'UserManagement',
    product: PRODUCTS.USER_MANAGEMENT,
    productLine: PRODUCT_LINES.ADMINISTRATION,
    description: 'User Management',
  },
  {
    permission: 'ApiKeyManagement',
    product: PRODUCTS.API_KEY_MANAGEMENT,
    productLine: PRODUCT_LINES.ADMINISTRATION,
    description: 'Api Keys Management',
  },
  {
    permission: 'CherreAdmin',
    product: PRODUCTS.CHERRE_ADMIN,
    productLine: PRODUCT_LINES.ADMINISTRATION,
    description: 'For Cherre Only - Manage organizations, Users, Permissions',
  },
  {
    permission: 'EmulateUser',
    product: PRODUCTS.CHERRE_ADMIN,
    productLine: PRODUCT_LINES.ADMINISTRATION,
    description: 'Allow User Emulation',
  },
  {
    permission: 'CherreAdminReadOnly',
    product: PRODUCTS.CHERRE_ADMIN,
    productLine: PRODUCT_LINES.ADMINISTRATION,
    description: 'Allow Users to view Admin panels',
  },
  {
    permission: 'CherreDev',
    product: PRODUCTS.CHERRE_ADMIN,
    productLine: PRODUCT_LINES.DEVELOPMENT,
    description: 'For Cherre Only - Access to developer settings',
  },
  {
    permission: 'DSPAdmin',
    product: PRODUCTS.DATA_SUBMISION_PORTAL,
    productLine: PRODUCT_LINES.DATA_SUBMISSION_PORTAL,
    description: 'Administrator User',
  },
  {
    permission: 'DSPUserManagement',
    product: PRODUCTS.DATA_SUBMISION_PORTAL,
    productLine: PRODUCT_LINES.DATA_SUBMISSION_PORTAL,
    description: 'DSP User Management (Scope: DSP Users Only)',
  },
  {
    permission: 'DSPEmulateUser',
    product: PRODUCTS.DATA_SUBMISION_PORTAL,
    productLine: PRODUCT_LINES.DATA_SUBMISSION_PORTAL,
    description: 'Allow DSP User Emulation (Scope: DSP Users Only)',
  },
  {
    permission: 'DSPRegular',
    product: PRODUCTS.DATA_SUBMISION_PORTAL,
    productLine: PRODUCT_LINES.DATA_SUBMISSION_PORTAL,
    description: 'Regular User',
  },
];

export default permissions;
