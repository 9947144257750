import React from 'react';
import {
  atomFamily,
  selectorFamily,
  SerializableParam,
  RecoilValueReadOnly,
  RecoilState,
  useRecoilValue,
  useRecoilCallback,
  ReadWriteSelectorFamilyOptions,
  ReadOnlySelectorFamilyOptions,
  ReadWriteSelectorOptions,
  ReadOnlySelectorOptions,
  atom,
  selector,
  useRecoilState,
} from 'recoil';

type Refetchable<T> = T & { refetchAtom: RecoilState<number> };

export type RefetchableRecoilState<T> = Refetchable<RecoilState<T>>;
export type RefetchableRecoilValueReadOnly<T> = Refetchable<
  RecoilValueReadOnly<T>
>;
export type RefetchableRecoil<T> =
  | RefetchableRecoilValueReadOnly<T>
  | RefetchableRecoilState<T>;

export function refetchableFamily<T, P extends SerializableParam>(
  options: ReadWriteSelectorFamilyOptions<T, P>
): (param: P) => RefetchableRecoilState<T>;
export function refetchableFamily<T, P extends SerializableParam>(
  options: ReadOnlySelectorFamilyOptions<T, P>
): (param: P) => RefetchableRecoilValueReadOnly<T>;
export function refetchableFamily<T, P extends SerializableParam>(options) {
  const refetchAtomFamily = atomFamily<number, P>({
    key: options.key + '/REFETCH-ATOM-FAMILY',
    default: () => Math.random(),
  });
  const mainSelectorFamily = selectorFamily<T, P>({
    ...options,
    key: options.key + '/MAIN-SELECTOR-FAMILY',
    get: (params) => (opts) => {
      //call the refetchAtom to create a dependency
      opts.get(refetchAtomFamily(params));
      return options.get(params)(opts);
    },
  });
  return (param: P) =>
    //return the main selector with the refetchAtom prop
    Object.assign(mainSelectorFamily(param), {
      refetchAtom: refetchAtomFamily(param),
    });
}

export function refetchable<T>(
  options: ReadWriteSelectorOptions<T>
): RefetchableRecoilState<T>;
export function refetchable<T>(
  options: ReadOnlySelectorOptions<T>
): RefetchableRecoilValueReadOnly<T>;
export function refetchable<T>(options) {
  const refetchAtom = atom<number>({
    key: options.key + '/REFETCH-ATOM',
    default: Math.random(),
  });
  const mainSelector = selector<T>({
    ...options,
    key: options.key + '/MAIN-SELECTOR',
    get: (opts) => {
      opts.get(refetchAtom);
      return options.get(opts);
    },
  });
  return Object.assign(mainSelector, { refetchAtom });
}

const didRefetchAtom = atomFamily<boolean, string>({
  key: 'DID-REFETCH-ATOM',
  default: () => true,
});

export const useRefetchCallback = <T>(refetchable: RefetchableRecoil<T>) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(refetchable.refetchAtom, Math.random());
      },
    [refetchable.key, refetchable.refetchAtom.key]
  );

export const useRefetchOnMount = <T>(refetchable: RefetchableRecoil<T>) => {
  const refetch = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const updated = await snapshot.getPromise(
          didRefetchAtom(refetchable.key)
        );
        if (updated) {
          return;
        }
        set(refetchable.refetchAtom, Math.random());
        set(didRefetchAtom(refetchable.key), true);
      },
    [refetchable.key, refetchable.refetchAtom.key]
  );
  const resetDidRefetchAtom = useRecoilCallback(
    ({ set }) =>
      () => {
        set(didRefetchAtom(refetchable.key), false);
      },
    [refetchable.key, refetchable.refetchAtom.key]
  );
  React.useEffect(() => {
    refetch();
    return resetDidRefetchAtom;
  }, []);
};

export const useRefetchableValue = <T>(refetchable: RefetchableRecoil<T>) => {
  const value = useRecoilValue(refetchable);
  const refetch = useRefetchCallback(refetchable);
  return [value, refetch] as const;
};

export const useRefetchableValueOnMount = <T>(
  refetchable: RefetchableRecoil<T>
) => {
  useRefetchOnMount(refetchable);
  return useRefetchableValue(refetchable);
};

export const useRefetchableState = <T>(
  refetchable: RefetchableRecoilState<T>
) => {
  const [value, setValue] = useRecoilState(refetchable);
  const refetch = useRefetchCallback(refetchable);
  return [value, setValue, refetch] as const;
};

export const useRefetchableStateOnMount = <T>(
  refetchable: RefetchableRecoilState<T>
) => {
  useRefetchOnMount(refetchable);
  return useRefetchableState(refetchable);
};
