export function getQuery(filter, value) {
  if (!value) {
    return {};
  }

  if (!Array.isArray(value) || value.length === 0) {
    return {};
  }

  const where = { _in: value };

  return where;
}

export function getFilterSetDisplayValue(filter, value = []) {
  if (value.length > 0) {
    return `${filter.label}`;
  }
}

export function getFilterDisplayValue(filter, value = [], data = {}) {
  const options = (data.options || []).filter((option) => {
    return value.some((v) => {
      return v === option.value;
    });
  });

  return options.map((option) => option.name).join('\r\n');
}

export default {
  getQuery,
  getFilterDisplayValue,
  getFilterSetDisplayValue,
};
