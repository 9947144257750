import coreApiElements from '../products/api-doc/schema/coreapi-elements';
import analyticsApiElements from '../products/api-doc/schema/analyticsapi-elements';
import pythonSnippetsElements from '../products/api-doc/schema/pythonSnippets-elements';
/**
 * @todo move to correct scope (products/api-doc)
 * @deprecated
 */
export const useDocumentationElements = (
  documentationType: 'coreApi' | 'analyticsApi' | 'pythonSnippets'
) => {
  switch (documentationType) {
    case 'coreApi':
      return coreApiElements;
    case 'analyticsApi':
      return analyticsApiElements;
    case 'pythonSnippets':
      return pythonSnippetsElements;
    default:
      throw new Error('A valid `documentationType` was not set on the context');
  }
};
