import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';

export const GroupIcon = createSvgIcon(
  <svg viewBox='0 0 24 13' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4,11 C4,10.298 4.127,9.627 4.35,9 L3.334,9 C1.493,9 0,10.159 0,12 L0,13 L4,13 L4,11 Z M7.5918,5.5088 C7.5918,5.5058 7.5928,5.5028 7.5928,5.4998 C7.5928,4.1188 6.4728,2.9998 5.0918,2.9998 C3.7118,2.9998 2.5928,4.1188 2.5928,5.4998 C2.5928,6.7908 3.5738,7.8398 4.8278,7.9738 C5.4658,6.8878 6.4318,6.0198 7.5918,5.5088'
      id='Combined-Shape'
    />
    <path
      d='M19.0918,3 C17.7118,3 16.5928,4.119 16.5928,5.5 C16.5928,5.535 16.6018,5.568 16.6028,5.603 C17.6828,6.125 18.5808,6.959 19.1828,7.991 C20.5208,7.942 21.5928,6.85 21.5928,5.5 C21.5928,4.119 20.4728,3 19.0918,3 M20.666,9 L19.65,9 C19.873,9.627 20,10.298 20,11 L20,13 L24,13 L24,12 C24,10.159 22.507,9 20.666,9'
      id='Combined-Shape'
    />
    <path
      d='M6,13 L18,13 L18,11 C18,9.084 16.651,7.487 14.853,7.096 C14.043,7.662 13.061,8 12,8 C10.939,8 9.957,7.662 9.147,7.096 C7.349,7.487 6,9.084 6,11 L6,13 Z M15,3 C15,4.657 13.657,6 12,6 C10.343,6 9,4.657 9,3 C9,1.343 10.343,0 12,0 C13.657,0 15,1.343 15,3'
      id='Combined-Shape'
    />
  </svg>,
  'Group'
);
