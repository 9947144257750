// import React from 'react';
import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { ClassNameMap } from '@material-ui/styles';
import { ButtonProps } from '@material-ui/core';

type ButtonProgressProps = ButtonProps & {
  loading?: boolean;
  customClasses?: ClassNameMap;
  wrapperStyle?: Record<string, any>;
  wrapperProps?: Record<string, any>;
  spinnerStyle?: CircularProgressProps['style'];
  spinnerSize?: number;
};

const ButtonProgress = React.forwardRef<HTMLButtonElement, ButtonProgressProps>(
  (props, ref) => {
    const {
      customClasses,
      children,
      loading,
      wrapperStyle,
      onClick,
      wrapperProps,
      spinnerStyle,
      spinnerSize,
      ...otherProps
    } = props;

    return (
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          ...wrapperStyle,
        }}
        {...wrapperProps}
      >
        <Button
          ref={ref}
          onClick={(e) => {
            onClick && onClick(e);
          }}
          classes={customClasses}
          disabled={loading}
          {...otherProps}
        >
          <span style={{ opacity: loading ? 0 : 1 }}>{children}</span>
          {loading && (
            <CircularProgress
              className='js-button-spinner'
              style={{
                color: '#fff',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
                ...(spinnerStyle || {}),
              }}
              size={spinnerSize || 24}
            />
          )}
        </Button>
      </div>
    );
  }
);

export default ButtonProgress;
