import {
  Model,
  Source,
  StandardizedConnector,
} from 'src/products/connector/types/connectorTypes';
import { connectorDataSelector } from '../../connectorLanding/recoil';
import { getSourceRunDetails } from 'src/products/connector/pages/connector-inventory/utils/getSourceRunDetails';
import { getComponentRunningMessage } from 'src/products/connector/pages/connectors-landing/utils/getComponentRunningMessage';
import { createConnectorStatusMessage } from 'src/products/connector/utils/createConnectorStatusMessage';
import { formatLastRunDate } from 'src/products/connector/utils/formatLastRunDate';
import { selector } from '@cherre-frontend/data-fetching';
import { buildConnectorAllStatuses } from 'src/products/connector/utils/buildConnectorAllStatuses';

export const connectorInventory = selector<{ data: StandardizedConnector[] }>({
  key: 'DOM-CONNECTOR-INVENTORY',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<{ data: StandardizedConnector[] }> => {
      const connectors = get(connectorDataSelector());

      const standardizedConnectors = connectors.data.map((connector) => {
        const { displayName, name, sources, models, statusObject } = connector;
        //Because a connector can have multiple sources, we need to calculate when the most recent run is so we can display it
        const { nextRun } = getSourceRunDetails(sources);

        const formattedSources = sources.map((source) => {
          const {
            provider = '',
            name,
            createdAt,
            schedule,
            statusObject,
          } = source;
          return {
            provider,
            name,
            createdAt,
            schedule,
            logo: `/assets/connector-icons/rounded-logos/${provider}-rounded.svg`,
            status: statusObject?.status as Source['status'],
          };
        });

        const formattedModels = models.map((model) => {
          const {
            name,
            provider,
            createdAt,
            publishedTables,
            destinations,
            statusObject,
          } = model;

          const formattedDestinations = destinations.map((destination) => {
            const { name, provider } = destination;
            return {
              name,
              provider: provider ?? '',
              logo: `/assets/connector-icons/rounded-logos/${provider}-rounded.svg`,
            };
          });

          return {
            name,
            provider: provider ?? '',
            createdAt,
            publishedTables,
            destinations: formattedDestinations,
            status: statusObject?.status as Model['status'],
          };
        });

        const { failedConnectorComponent, connectorStatus } =
          buildConnectorAllStatuses({
            models,
            sources,
            status: statusObject?.status,
          });
        const connectorStatusMessage = createConnectorStatusMessage({
          connectorEndDateTime: statusObject?.endDatetime,
          connectorStatus,
          sources,
          failedConnectorComponent,
        });

        const runningComponentMessage = getComponentRunningMessage(connector);

        const lastRun = formatLastRunDate(connector.statusObject?.endDatetime);

        const standardizedConnector: StandardizedConnector = {
          displayName: displayName ?? name,
          name,
          status: connectorStatus,
          statusMessage: connectorStatusMessage,
          failedConnectorComponent,
          lastRun,
          nextRun: nextRun,
          disabled: false,
          schemaName: '',
          sources: formattedSources,
          models: formattedModels,
          connectorRunningObject: {
            isRunning: Boolean(connector.statusObject?.isRunning),
            runningMessage: runningComponentMessage ?? '',
          },
        };
        return standardizedConnector;
      });
      return { data: standardizedConnectors };
    },
});
