import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { styled, Box, BellIcon } from '@cherre-frontend/ui';
import { useCherreValue } from '@cherre-frontend/data-fetching';
import { getUnreadRemindersCount } from 'src/components/RemindersDrawer/recoil';
import { Typography } from '@material-ui/core';
import { useClaims } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';

const MenuItem = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 16px;
  .MuiSvgIcon-root {
    width: 18px !important;
    height: 18px;
  }
`;

const ReminderButton = styled(BellIcon)`
  position: relative;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  path {
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`;

const UnreadRemindersBadge = styled(Box)`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  position: absolute;
  top: -3px;
  right: -6px;
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 10px;
  border-radius: 50%;
`;

interface RemindersProps {
  onClick: () => void;
}

const RemindersView: React.FC<RemindersProps> = ({ onClick }) => {
  const unreadRemindersCount = useCherreValue(getUnreadRemindersCount());

  const badgeCount = useMemo(() => {
    if (!unreadRemindersCount) {
      return 0;
    }
    return unreadRemindersCount > 99 ? '99+' : unreadRemindersCount;
  }, [unreadRemindersCount]);

  return (
    <MenuItem onClick={onClick}>
      <Tooltip title='Reminders'>
        <ReminderButton color={'primary'} />
      </Tooltip>
      {unreadRemindersCount !== 0 && (
        <UnreadRemindersBadge>
          <Typography variant='caption'>{badgeCount}</Typography>
        </UnreadRemindersBadge>
      )}
    </MenuItem>
  );
};

const Reminders: React.FC<RemindersProps> = ({ onClick }) => {
  const isReviewer = useClaims('reviewer');
  const isPreparer = useClaims('preparer');
  const isDSPAdmin = useClaims('view_admin');

  return isDSPAdmin || isReviewer || isPreparer ? (
    <RemindersView onClick={onClick} />
  ) : null;
};

export default Reminders;
