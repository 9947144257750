import React from 'react';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Box, Popover, Tab, Tabs } from '@material-ui/core';
import { getPropertyLists } from 'src/products/core-prospect/redux/propertyLists/actions';
import { getViews } from 'src/products/core-prospect/redux/query/actions';
import { getViews as getViewsSelector } from 'src/products/core-prospect/search-pages/selectors';
import Icon from './Icon';
import List from './List';
import SearchFilterSummary from 'src/components/SearchFilterSummary';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import { InteractiveListItem } from 'src/components/InteractiveList/List';

const tabIndicatorProps = {
  style: {
    bottom: 'unset',
    top: 0,
    height: 4,
    width: '50%',
  },
};

export enum TabValue {
  propertyLists = 'propertyLists',
  savedSearches = 'savedSearches',
}

const PropertyListsIcon = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tab, setTab] = React.useState(TabValue.savedSearches);

  const dispatch = useDispatch();

  const propertyLists = useSelector(
    (state: any) => state.coreProspect.propertyLists.propertyLists.value
  );

  const views = useSelector(getViewsSelector);

  React.useEffect(() => {
    dispatch(getPropertyLists());
    dispatch(getViews());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const lists = (propertyLists || []).slice(0, 10).map(
    (item) =>
      ({
        label: item.name,
        value: item.id,
        secondary: pluralize('Property', item.entries_count, true),
        shareIconTooltip:
          item.usersCount + item.teamsCount > 0 ? 'Manage Sharing' : 'Share',
        shareIcon:
          item.usersCount + item.teamsCount > 0 ? (
            <SupervisedUserCircle />
          ) : (
            <img src='/assets/share.svg' width={25} />
          ),
      } as InteractiveListItem)
  );

  const savedSearches = (views || []).slice(0, 10).map(
    (item) =>
      ({
        label: item.view.name,
        value: item.view.id.toString(),
        secondary: <SearchFilterSummary view={item} />,
      } as InteractiveListItem)
  );

  const open = Boolean(anchorEl);

  return (
    <>
      <Icon onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <Tabs
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            TabIndicatorProps={tabIndicatorProps}
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab label='Saved Searches' value={TabValue.savedSearches} />
            <Tab label='Property Lists' value={TabValue.propertyLists} />
          </Tabs>
          <List
            tab={tab}
            lists={lists}
            savedSearches={savedSearches}
            onClose={(url) => {
              handleClose();
              if (url !== undefined) {
                dispatch(push(url));
              }
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

PropertyListsIcon.displayName = 'ShowPropertyListsMenu';

export default PropertyListsIcon;
