import findIndex from 'lodash/findIndex';

export const toggleLayer = (
  group,
  onChange,
  layerName,
  checked,
  isRadio,
  parentName
) => {
  const index = findIndex(
    group.layers,
    (layer) => layer.name === (parentName || layerName)
  );
  const layer = group.layers[index];

  if (isRadio) {
    return onChange({
      ...group,
      checkedLayerName: layerName,
    });
  }

  const newGroup = {
    ...group,
    layers: [
      ...group.layers.slice(0, index),
      {
        ...layer,
        checked: checked,
        checkedLayerName: layerName,
      },
      ...group.layers.slice(index + 1),
    ],
  };

  onChange(newGroup);

  return newGroup;
};
