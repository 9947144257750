import 'mapbox-gl/dist/mapbox-gl.css';

import { debounce, isEmpty } from 'lodash';
import { Map } from 'mapbox-gl';
import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import ReactMapGL, { MapEvent, MapRef } from 'react-map-gl';

import { MapboxGLDraw } from './components/DrawEditor';
import { NavigationControl } from './components/NavigationControl';

import { DrawingMode, OnDrawComplete } from './components/DrawEditor/types';
import { Sources } from './components/Sources';
import { CHERRE_BASE_MAP_STYLE } from 'src/const';
import { LayerProps, Sources as SourcesType, Viewport } from './types';

export type MapBounds = [[number, number], [number, number]];

type Props = {
  width: number | string;
  height: number | string;
  zoom: number;
  latitude: number;
  longitude: number;
  sources?: SourcesType;
  mapStyle?: string;
  scrollZoom?: boolean;
  interactiveLayerIds: string[];
  onLoad?: (map: Map) => void;
  onViewportChange?: (map: Map) => void;
  onHover: (evt: MapEvent, map: Map) => void;
  drawingMode?: DrawingMode;
  onDrawComplete?: OnDrawComplete;
};

export type RefProps = {
  map: Map;
};

const MapComponent: React.ForwardRefRenderFunction<
  RefProps,
  React.PropsWithChildren<Props>
> = (
  {
    width,
    height,
    zoom,
    latitude,
    longitude,
    sources = [],
    mapStyle,
    scrollZoom,
    onLoad,
    onViewportChange,
    onHover,
    onDrawComplete,
    drawingMode,
    children,
    interactiveLayerIds,
  },
  ref
) => {
  const mapRef = React.useRef<MapRef>(null);

  useImperativeHandle(ref, () => {
    return {
      map: mapRef.current?.getMap() as Map,
    };
  });

  const [viewport, setViewport] = React.useState<Viewport>({
    latitude,
    longitude,
    zoom,
  });

  const onLoadDebounce = useMemo(() => {
    return debounce(() => {
      const map = mapRef.current?.getMap() as Map;
      if (map) {
        if (onLoad) {
          onLoad(map);
        }
      }
    }, 500);
  }, [onLoad]);

  const onViewportChangeDebounce = useMemo(() => {
    return debounce(() => {
      const map = mapRef.current?.getMap() as Map;
      if (map) {
        if (onViewportChange) {
          onViewportChange(map);
        }
      }
    }, 500);
  }, [onViewportChange]);

  React.useEffect(() => {
    setViewport({
      latitude,
      longitude,
      zoom,
    });
  }, [zoom, latitude, longitude]);

  const layers = useMemo(
    () =>
      sources.reduce<LayerProps[]>((list, source) => {
        return [...list, ...source.layers];
      }, []),
    [sources]
  );

  const _mapStyle = mapStyle || CHERRE_BASE_MAP_STYLE;

  const mapOnLoad = useCallback(() => {
    onLoadDebounce();
  }, [onViewportChangeDebounce, setViewport]);

  const mapOnViewportChange = useCallback(
    (state, _, oldState) => {
      if (isEmpty(oldState)) {
        return;
      }
      setViewport({
        latitude: state.latitude,
        longitude: state.longitude,
        zoom: state.zoom,
      });
      onViewportChangeDebounce();
    },
    [onViewportChangeDebounce, setViewport]
  );

  const onHoverCallback = useCallback(
    (e) => {
      onHover?.(e, mapRef.current?.getMap());
    },
    [onHover]
  );

  const onClickCallback = useCallback(
    (e) => {
      layers
        .filter((layer) => {
          return (e.features || []).some(
            (feature) => feature.layer.id === layer.id
          );
        })
        .slice(0, 1)
        .forEach((layer) => layer.onClick && layer.onClick(e));
    },
    [layers]
  );

  const navigationOnViewportChange = useCallback(
    (viewport) => {
      setViewport(viewport);
      onViewportChangeDebounce();
    },
    [setViewport, onViewportChangeDebounce]
  );

  return (
    <ReactMapGL
      latitude={viewport.latitude}
      longitude={viewport.longitude}
      zoom={Math.min(viewport.zoom, 18)}
      transitionDuration={0}
      minZoom={2}
      onHover={onHoverCallback}
      ref={mapRef}
      scrollZoom={scrollZoom || false}
      width={width}
      height={height}
      interactiveLayerIds={interactiveLayerIds}
      onViewportChange={mapOnViewportChange}
      onLoad={mapOnLoad}
      mapboxApiAccessToken={__MAPBOX_TOKEN__}
      mapStyle={_mapStyle}
      onClick={onClickCallback}
    >
      <Sources sources={sources} />
      {children}
      <NavigationControl onViewportChange={navigationOnViewportChange} />
      {onDrawComplete && drawingMode && (
        <MapboxGLDraw
          drawingMode={drawingMode}
          onDrawComplete={onDrawComplete}
          drawingFeatures={[]}
        />
      )}
    </ReactMapGL>
  );
};

export default React.forwardRef<RefProps, React.PropsWithChildren<Props>>(
  MapComponent
);
