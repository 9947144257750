import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'src/components/ButtonProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { editApiKey } from 'src/products/admin/redux/organization/actions';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

const useStyles = makeStyles({
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
  textField: {
    width: '100%',
  },
});

const EditApiKey = ({
  open,
  onRequestClose,
  data: { refresh, row: apiKey, isCherreAdmin },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [nickname, setNickname] = useState(apiKey.nickname || '');
  const [apiHasuraRole, setApiHasuraRole] = useState(
    apiKey.apiHasuraRole || ''
  );
  const [hasuraOrgName, setHasuraOrgName] = useState(
    apiKey.hasuraOrgName || ''
  );

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle>Edit API Key</DialogTitle>
      <DialogContent className={classes.modalContent}>
        <List>
          <ListItem>
            <TextField
              id='nickname'
              label='Nickname'
              value={nickname}
              className={classes.textField}
              onChange={(e) => setNickname(e.target.value)}
            />
          </ListItem>
          {isCherreAdmin && (
            <ListItem>
              <TextField
                id='apiHasuraRole'
                label='API Hasura Role Override'
                value={apiHasuraRole}
                className={classes.textField}
                onChange={(e) => setApiHasuraRole(e.target.value)}
              />
            </ListItem>
          )}
          {isCherreAdmin && (
            <ListItem>
              <TextField
                id='hasuraOrgName'
                label='Hasura Org Name Override'
                value={hasuraOrgName}
                className={classes.textField}
                onChange={(e) => setHasuraOrgName(e.target.value)}
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              await dispatch(
                editApiKey(apiKey.id, {
                  apiHasuraRole,
                  hasuraOrgName,
                  nickname,
                })
              );
              await refresh();

              showSuccessPopupNotification(`API key ${apiKey.id} was updated`);
              onRequestClose();
            } catch (error) {
              console.log(error);

              showErrorPopupNotification(
                `Failed to update API key ${apiKey.id}`
              );
            }
          }}
          color='primary'
          variant='contained'
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditApiKey.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  data: PropTypes.object,
};

export default EditApiKey;
