import {
  Box,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

export type HeaderProps = {
  title: string;
  subtitle?: string | string[];
  backLink?: string;
  children?: React.ReactNode;
  'data-testid'?: string;
};

const getTemplateAreas = (subtitle: boolean, backLink: boolean) => {
  if (subtitle && backLink) {
    return `"back title actions" ". subtitle ."`;
  }

  if (subtitle && !backLink) {
    return `"title actions" "subtitle ."`;
  }

  if (!subtitle && backLink) {
    return `"back title actions"`;
  }

  return `"title actions"`;
};

const useStyles = makeStyles<Theme, { subtitle: boolean; backLink: boolean }>({
  container: {
    display: 'grid',
    gridGap: '0px 12px',
    gridTemplateColumns: ({ backLink }) =>
      backLink ? 'auto 1fr auto' : '1fr auto',
    gridTemplateAreas: ({ subtitle, backLink }) =>
      getTemplateAreas(subtitle, backLink),
    paddingBottom: '20px',
  },
  actions: {
    display: 'flex',
    gridGap: '14px',
  },
});

export const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  backLink,
  children,
  ['data-testid']: testid,
}) => {
  const classes = useStyles({ subtitle: !!subtitle, backLink: !!backLink });
  const onClickBackButton = useCallback(() => null, [backLink]);

  return (
    <Box data-testid={testid} className={classes.container}>
      {backLink && (
        <Box gridArea='back'>
          <IconButton
            onClick={onClickBackButton}
            style={{ color: '#000' }}
            size='small'
            component={Link}
            to={backLink}
          >
            <ArrowBack />
          </IconButton>
        </Box>
      )}
      <Box gridArea='title'>
        <Typography variant='h3'>{title}</Typography>
      </Box>
      {subtitle && (
        <Box gridArea='subtitle'>
          {Array.isArray(subtitle) ? (
            subtitle.map((text, idx) => (
              <Typography key={idx} variant='subtitle2'>
                {text}
              </Typography>
            ))
          ) : (
            <Typography variant='subtitle2'>{subtitle}</Typography>
          )}
        </Box>
      )}
      <Box gridArea='actions' className={classes.actions}>
        {children}
      </Box>
    </Box>
  );
};
