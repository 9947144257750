import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import constants from 'src/const';

const { NAV_BAR_Z_INDEX } = constants;

const styles = {
  root: {
    background: '#411023',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    padding: '4px',
    position: 'fixed',
    width: '100%',
    zIndex: NAV_BAR_Z_INDEX + 1,
  },
};

const stopEmulatedSession = (user) => {
  sessionStorage.removeItem(constants.IS_EMULATED_SESSION_KEY);

  window.location.href = `/api/v1/organizations/${user.organizationId}/users/${user.pgId}/end-emulated-session`;
};

const EmulatedSessionBar = ({ user, classes }) => {
  return (
    <div id='emulated-session-bar' className={classes.root}>
      <span className={classes.snackbarContent}>
        Logged in as: {user.email} ({user.organizationName})
      </span>
      &nbsp;
      <Button onClick={() => stopEmulatedSession(user)} color='primary'>
        Logout
      </Button>
    </div>
  );
};

EmulatedSessionBar.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    user: {
      profile: { value: user },
    },
  } = state;

  return { user };
};

export default connect(mapStateToProps)(withStyles(styles)(EmulatedSessionBar));
