import { Link, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Search from '@material-ui/icons/Search';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import InteractiveList, {
  InteractiveListItem,
} from 'src/components/InteractiveList/List';
import { useShareListEdit } from 'src/products/core-prospect/hooks/useShareListEdit';
import { TabValue } from '.';

type Props = {
  tab: string;
  lists: InteractiveListItem[];
  savedSearches: InteractiveListItem[];
  onClose: (string?: string) => void;
};

const useEmptyBodyStyle = makeStyles({
  root: {
    display: 'flex',
    width: 350,
    height: 200,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    color: grey[500],
  },
  icon: {
    color: grey[500],
    fontSize: 36,
    marginBottom: 10,
  },
});

const useStyle = makeStyles({
  viewAll: {
    margin: '0px 0px 14px 26px',
  },
});

const EmptyList = ({ text = '' }) => {
  const classes = useEmptyBodyStyle();

  return (
    <div className={classes.root}>
      <Search className={classes.icon} />
      <Typography align='center'>{text}</Typography>
    </div>
  );
};

const List: React.FC<Props> = ({ onClose, tab, lists, savedSearches }) => {
  const classes = useStyle();
  const shareListEdit = useShareListEdit();

  if (tab === TabValue.propertyLists) {
    if (lists.length === 0) {
      return (
        <EmptyList text='Looks like you don’t have any property lists yet.' />
      );
    }

    return (
      <>
        <InteractiveList
          placeholder='Search'
          items={lists}
          selected={[]}
          onItemDeselect={undefined}
          onItemCreate={undefined}
          onItemSelect={(item) => {
            window.analytics?.track('Property List Opened');
            onClose(`/properties?sidebar=property-list&list_id=${item.value}`);
          }}
          onItemShare={(item) => {
            onClose();
            shareListEdit(Number(item.value));
          }}
        />
        <div className={classes.viewAll}>
          <RouterLink component={Link} to='/lists-and-searches'>
            <Typography>
              <b>View All</b>
            </Typography>
          </RouterLink>
        </div>
      </>
    );
  }

  if (tab === TabValue.savedSearches) {
    if (savedSearches.length === 0) {
      return (
        <EmptyList text='Looks like you don’t have any saved searches yet.' />
      );
    }
    return (
      <>
        <InteractiveList
          placeholder='Search'
          items={savedSearches}
          selected={[]}
          onItemDeselect={undefined}
          onItemCreate={undefined}
          onItemSelect={(item) => {
            window.analytics?.track('Saved Search Opened - From Pop-Up');
            onClose(`/properties/${item.value}`);
          }}
        />
        <div className={classes.viewAll}>
          <RouterLink component={Link} to='/lists-and-searches'>
            <Typography>
              <b>View All</b>
            </Typography>
          </RouterLink>
        </div>
      </>
    );
  }
  return null;
};

export default List;
