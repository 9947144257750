import React from 'react';
import { NavigationControl as MapNavigationControl } from 'react-map-gl';
import { Viewport } from '../../types';

type Props = {
  onViewportChange: (viewport: Viewport) => void;
};

export const NavigationControl: React.FC<Props> = ({ onViewportChange }) => (
  <div style={{ position: 'absolute', right: 60, bottom: 180 }}>
    <MapNavigationControl
      onViewportChange={(viewport: Viewport) => {
        onViewportChange(viewport);
      }}
      showZoom
    />
  </div>
);
