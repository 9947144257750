import React from 'react';
import Button from 'src/components/ButtonProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { reEnableOrganization } from 'src/products/admin/redux/organization/actions';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
}));

const ReEnableOrganizationDialog: React.FC<{
  open: boolean;
  onRequestClose: () => void;
  data: {
    row: any;
    schema: any;
    refresh: () => void;
  };
}> = (props) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { open, onRequestClose } = props;
  const organization = props.data.row;

  const removeHandler = async () => {
    try {
      await dispatch(reEnableOrganization(organization.id));

      showSuccessPopupNotification(`${organization.name} was re-activated.`);
      onRequestClose();
      props.data.refresh();
    } catch (e: any) {
      showErrorPopupNotification(`${e?.message} ${organization.name}`);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle>Re-Enable User</DialogTitle>
      <DialogContent>
        Are you sure you want to Reactivate <b>{organization.name}</b> <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color='primary'>
          close
        </Button>
        <Button onClick={removeHandler} color='primary' variant='contained'>
          Reactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReEnableOrganizationDialog;
