import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'src/components/ButtonProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { resendEmail } from 'src/products/admin/redux/organization/actions';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useAppContext } from '@cherre-frontend/core';

const useStyles = makeStyles({
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: 20,
    padding: 20,
  },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ResendEmail: React.FC<any> = (props: any) => {
  const {
    open,
    onRequestClose,
    data: { refresh, row: user },
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const context = useAppContext();

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        Send Welcome Email
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to send welcome / registration email to{' '}
          <b>
            {user.firstName} {user.lastName}
          </b>{' '}
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color='primary'>
          Close
        </Button>
        <Button
          onClick={async () => {
            (dispatch(resendEmail({ id: user.id })) as unknown as Promise<void>)
              .then(refresh)
              .then(() => {
                console.log('RESEND EMAIL');
                context?.showSnackbar({
                  type: 'success',
                  message: 'Invitation has been sent',
                });
              })
              .then(onRequestClose)
              .catch(() => {
                console.log('RESEND EMAIL', user.id);
                context?.showSnackbar({
                  type: 'error',
                  message: 'Email failed to send',
                });
              });
          }}
          color='primary'
          variant='contained'
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResendEmail;
