import React from 'react';
import NoContentMessage from './NoContentMessage';
import Reminder from './Reminder';
import { recoilGetUnresolvedRemindersQuery$data } from './__generated__/recoilGetUnresolvedRemindersQuery.graphql';
import { ReminderListContainer } from './styles';

type ReminderListProps = {
  unresolvedReminders: recoilGetUnresolvedRemindersQuery$data['sys_reminders'];
  resolvedReminders: recoilGetUnresolvedRemindersQuery$data['sys_reminders'];
  showCompleted: boolean;
  setShowCompleted: (value: boolean) => void;
};

const ReminderList: React.FC<ReminderListProps> = ({
  unresolvedReminders,
  resolvedReminders,
  showCompleted,
  setShowCompleted,
}) => {
  const closeReminder = () => {
    if (resolvedReminders.length - 1 === 0) {
      setShowCompleted(false);
    }
  };

  return (
    <ReminderListContainer>
      {unresolvedReminders?.length || resolvedReminders?.length ? (
        <>
          {unresolvedReminders?.map((reminder, idx) => {
            return (
              <Reminder
                key={reminder.reminder_id}
                reminder={reminder}
                closeReminder={closeReminder}
                showBottomBorder={
                  idx !== unresolvedReminders.length - 1 || showCompleted
                }
              />
            );
          })}
          {showCompleted && resolvedReminders?.length
            ? resolvedReminders?.map((reminder, idx) => {
                return (
                  <Reminder
                    key={reminder.reminder_id}
                    reminder={reminder}
                    closeReminder={closeReminder}
                    showBottomBorder={idx !== resolvedReminders.length - 1}
                  />
                );
              })
            : null}
        </>
      ) : (
        <NoContentMessage body='No reminders yet' />
      )}
    </ReminderListContainer>
  );
};

export default ReminderList;
