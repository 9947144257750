import { graphql } from 'relay-runtime';

export const UpdateModelMutation = graphql`
  mutation updateModelMutation(
    $modelName: String!
    $schedule: String
    $displayName: String
    $egressedTables: [EgressedTablesInput]
  ) {
    updateModel(
      modelName: $modelName
      schedule: $schedule
      displayName: $displayName
      egressedTables: $egressedTables
    ) {
      name
    }
  }
`.default;
