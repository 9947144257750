import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMe } from 'src/products/shell/redux/actions/user';
import { Permission } from 'src/products/shell/redux/reducers/user';
import { isAllowed } from './isAllowed';
import AccessDenied from 'src/products/shell/pages/AccessDenied/AccessDenied';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export const EnsurePermissions: React.FC<{
  permissions: (Permission | 'Global')[];
  permissionHook?: () => boolean;
  children: React.ReactNode;
  renderError?: boolean;
}> = ({ permissions, children, permissionHook, renderError = true }) => {
  const dispatch = useDispatch();
  const { userPermissions, fetchStatus } = useSelector((state) => ({
    userPermissions: state.user.profile.value.permissions || [],
    fetchStatus: state.user.profile.fetchStatus.value,
  }));

  const postHogEnabled = useFeatureFlag('PostHogEnabled');
  const posthog = postHogEnabled ? usePostHog() : undefined;

  React.useEffect(() => {
    dispatch(loadMe(posthog));
  }, [posthog]);

  const shouldShow = permissionHook?.() ?? true;

  if (fetchStatus !== 'LOADED') {
    return null;
  }

  if (!shouldShow) {
    return <AccessDenied />;
  }

  if (isAllowed(permissions, [...userPermissions, 'Global'])) {
    return <>{children}</>;
  } else {
    return renderError ? <AccessDenied /> : null;
  }
};
