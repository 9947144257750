import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import fillTemplate from 'src/utils/fillTemplate';
import { primaryColor } from 'src/const/theme';
import { getMaskedValue } from 'src/utils/mask';
import Truncate from 'src/components/Truncate';
import { isAllowed } from 'src/utils/routing/isAllowed';

export function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

export function applyMask(value, mask) {
  if (mask === 'boolean') {
    return value ? 'yes' : 'no';
  }

  if (mask === 'reverseBoolean') {
    return value ? 'no' : 'yes';
  }

  if (mask === 'date') {
    return value ? moment(new Date(value)).format('lll') : null;
  }

  if (mask === 'timeago') {
    return value ? moment(new Date(value)).fromNow() : null;
  }

  return value;
}

function getFieldValue(row, field_name) {
  const fields = field_name.split('.');
  return fields.reduce(
    (acc, cur) =>
      typeof acc === 'object' && acc !== null ? acc[cur] : undefined,
    row
  );
}

export function renderCell(row, column, params) {
  const value = getMaskedValue(
    getFieldValue(row, column.field_name),
    column.mask
  );
  if (column.cellRenderer) {
    return column.cellRenderer({
      cellData: row[column.field_name],
      rowData: row,
    });
  }

  if (column.col_type === 'internalLink') {
    return (
      <Link
        style={{
          color: primaryColor.main,
        }}
        to={fillTemplate(column.additional_props.url_template_string, {
          row,
          params,
        })}
      >
        {value ?? column.additional_props.defaultValue}
      </Link>
    );
  }

  if (column.col_type === 'truncated') {
    return value ? (
      <div style={{ width: 100 }}>
        <Truncate>{value}</Truncate>
      </div>
    ) : (
      ''
    );
  }

  return value;
}

export function allowCustomerAdmin(schema) {
  return ['customer_users', 'customer_api_keys'].includes(schema.id);
}

export function allowCustomerAdminAndCherreAdmin(user, _, schema) {
  if (allowCustomerAdmin(schema)) {
    return true;
  }
  if (isAllowed(user.permissions, ['CherreAdmin'])) {
    return true;
  }
  return false;
}

export const customerUsersIsVisibleByPermissions = (
  user,
  row,
  schema,
  allowedPermissions = ['CherreAdmin', 'UserManagement', 'IDAdmin']
) => {
  if (schema.id !== 'customer_users') {
    return true;
  }

  return isAllowed(user.permissions, allowedPermissions);
};
