import React from 'react';
import { adminPages } from 'src/components/NavBar/pages';
import ContextMenu from 'src/components/NavBar/components/ContextMenu';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useStyles } from '../styles';

let adminSettingsMenu;

const AdminSettingsMenu = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.profile?.value);
  const buildOptions = (page) => {
    return {
      onClick: () => dispatch(push(page.url)),
      icon: <i className={`icon icon-${page.icon} ${classes.menuIcon}`} />,
      name: page.name,
      id: page.id,
    };
  };

  return (
    <React.Fragment>
      <div
        className={classes.menuItem}
        onClick={(e) => adminSettingsMenu.open(e.target)}
        id='admin-settings-icon'
      >
        <Tooltip title='Administration'>
          <i
            className={`icon icon-settings ${classes.icon}`}
            style={{
              fontSize: 18,
              position: 'relative',
              top: 1,
            }}
          />
        </Tooltip>
      </div>
      <ContextMenu
        ref={(node) => {
          adminSettingsMenu = node;
        }}
        options={adminPages
          .filter((option) => option.isVisible(user))
          .map(buildOptions)}
      />
    </React.Fragment>
  );
};

export default AdminSettingsMenu;
