import React from 'react';
import ExpansionIcon from '../ExpansionIcon';
import { CustomCheckbox } from '../TreeCustomComponents';
import IndeterminateIcon from '../IndeterminateIcon';

const ExpansionOrCheckbox = (props) => {
  const {
    node,
    showExpansionIcon,
    idData,
    changePanelExpansion,
    disabled,
    checked,
    indeterminate,
    handleCheckboxClick,
  } = props;

  if (node === 'root') {
    return null;
  }
  if (idData[node] === undefined && idData[node].relationship !== true) {
    return null;
  }

  return showExpansionIcon(idData[node]) ? (
    <ExpansionIcon
      handleExpansionIconClick={() => {
        changePanelExpansion(idData[node]);
      }}
      {...props}
      node={node}
    />
  ) : (
    <CustomCheckbox
      color='primary'
      disableRipple
      disabled={disabled[node] === true}
      checked={checked[node] === true}
      indeterminate={indeterminate[node] === true}
      indeterminateIcon={<IndeterminateIcon />}
      onClick={() => handleCheckboxClick(idData[node])}
    />
  );
};

export default ExpansionOrCheckbox;
