export function getStreetViewParams({
  latitude,
  longitude,
  pitch,
  heading,
  width,
  height,
}) {
  let params = `key=${__GOOGLE_API_KEY__}&source=outdoor&radius=75`;

  if (latitude != null && longitude != null) {
    params += `&location=${latitude},${longitude}`;
  }

  if (pitch != null) {
    params += `&pitch=${pitch}`;
  }

  if (width != null && height != null) {
    params += `&size=${width}x${height}`;
  }

  if (heading != null) {
    params += `&heading=${heading}`;
  }

  return params;
}

export function getMapParams({ latitude, longitude, width, height }) {
  let params = `key=${__GOOGLE_API_KEY__}&maptype=satellite&zoom=18`;

  if (latitude != null && longitude != null) {
    params += `&q=${latitude},${longitude}`;
  }

  if (width != null && height != null) {
    params += `&size=${width}x${height}`;
  }

  return params;
}
