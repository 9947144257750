import React from 'react';
import cx from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import grey from '@material-ui/core/colors/grey';

const TabIcon = styled.i`
  font-size: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  opacity: 0.3;
  margin-right: 16px;
`;

const styles = () => ({
  nested: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  text: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontColor: grey[700],
  },
  textRoot: {
    margin: 0,
  },
});

const NavigationItem = ({
  clickHandler,
  className,
  pageName,
  pageIcon,
  classes,
  permissionHook,
}) => {
  const Icon = pageIcon;

  const shouldShow = permissionHook ? permissionHook() : true;

  if (!shouldShow) {
    return null;
  }

  return (
    <ListItem
      button
      className={cx(classes.nested, className, `item-${pageName}`)}
      onClick={clickHandler}
    >
      {pageIcon && typeof pageIcon === 'string' ? (
        <TabIcon className={`icon icon-${pageIcon}`} />
      ) : (
        <Icon
          style={{ color: '#000', width: 20, opacity: 0.3, marginRight: 16 }}
        />
      )}
      <ListItemText
        primary={pageName}
        classes={{
          root: classes.textRoot,
          primary: classes.text,
        }}
      />
    </ListItem>
  );
};

export default withStyles(styles)(NavigationItem);
