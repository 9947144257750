import { makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Search from '@material-ui/icons/Search';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const useStyles = makeStyles({
  root: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    border: `1px solid ${grey[400]}`,
    padding: 25,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    justifyContent: 'center',
  },
  icon: {
    fontSize: 60,
    color: grey[400],
  },
  title1: {
    marginBottom: 10,
    marginTop: 10,
  },
  title2: {
    color: grey[700],
  },
});

export type EmptyTableProps = {
  title1?: string;
  title2?: string;
  Icon?: typeof SvgIcon;
};

const EmptyTable: React.FC<EmptyTableProps> = ({
  title1 = 'No results found',
  title2 = 'Try another search or use filters to adjust your search criteria.',
  Icon = Search,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Icon className={classes.icon} />
        <Typography className={classes.title1} variant='h5'>
          {title1}
        </Typography>
        <Typography className={classes.title2}>{title2}</Typography>
      </div>
    </div>
  );
};

EmptyTable.displayName = 'EmptyTable';

export default EmptyTable;
