import React from 'react';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

const TreppLoanDataModalFooterTenants = ({
  tenantName,
  tenantSquareFootage,
  tenantPercent,
  dotColor,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 7,
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: dotColor,
          }}
        />
      </div>
      <Typography variant='body1' style={{ marginRight: 8, height: 21 }}>
        {`${tenantName} `}{' '}
      </Typography>
      <Typography
        variant='body2'
        style={{
          color: grey[700],
          height: 21,
          lineHeight: 1.5,
          paddingTop: 2,
        }}
      >
        {`${tenantSquareFootage} SQFT (${tenantPercent}%)`}
      </Typography>
    </div>
  );
};

export default TreppLoanDataModalFooterTenants;
