import { useEffect, useRef } from 'react';

/**
 * @deprecated use react-use hooks
 */
export default function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
