import React from 'react';
import moment from 'moment';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DateTimePicker } from '@material-ui/pickers';

const styles = {
  minHeight: '49px',
};

const formatDate = (date) => {
  return date.format('YYYY-MM-DDThh:mm');
};

const dynamicDate = function (props) {
  const {
    input,
    label,
    fullWidth,
    inputClassName,
    formControlClassName,
    meta: { touched, error },
    disabled,
  } = props;

  return (
    <FormControl
      className={formControlClassName}
      fullWidth={fullWidth}
      style={{ ...props.attr.style, ...styles }}
      error={touched && !!error}
      required={props.required}
    >
      <DateTimePicker
        {...input}
        id={name}
        label={label}
        disabled={disabled}
        className={inputClassName}
        clearable
        value={input.value ? formatDate(moment(input.value)) : null}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default dynamicDate;
