import {
  ReadOnlySelectorFamilyOptions,
  ReadOnlySelectorOptions,
  ReadWriteSelectorFamilyOptions,
  ReadWriteSelectorOptions,
  RecoilValue,
  RecoilValueReadOnly,
  selector,
  selectorFamily,
  SerializableParam,
} from 'recoil';

export function selectorWithDefaultValue<T>(
  params: ReadOnlySelectorOptions<T> & { defaultValue: T }
): RecoilValueReadOnly<T>;
export function selectorWithDefaultValue<T>(
  params: ReadWriteSelectorOptions<T> & { defaultValue: T }
): RecoilValue<T>;
export function selectorWithDefaultValue(params: any) {
  return selector({
    ...params,
    get: async (opts) => {
      try {
        const a = params.get(opts);
        if (a instanceof Promise) {
          const r = await a;
          return r;
        } else {
          return a;
        }
      } catch (e) {
        if (e instanceof Promise) {
          throw e;
        }
        return params.defaultValue;
      }
    },
  });
}

export function selectorFamilyWithDefaultValue<T, P extends SerializableParam>(
  params: ReadOnlySelectorFamilyOptions<T, P> & { defaultValue: T }
): (params: P) => RecoilValueReadOnly<T>;
export function selectorFamilyWithDefaultValue<T, P extends SerializableParam>(
  params: ReadWriteSelectorFamilyOptions<T, P> & { defaultValue: T }
): (params: P) => RecoilValue<T>;
export function selectorFamilyWithDefaultValue(params: any) {
  return selectorFamily({
    ...params,
    get: (p) => async (o) => {
      try {
        const a = params.get(p)(o);
        if (a instanceof Promise) {
          const r = await a;
          return r;
        } else {
          return a;
        }
      } catch (e) {
        if (e instanceof Promise) {
          throw e;
        }
        return params.defaultValue;
      }
    },
  });
}
