import React, { ReactElement, useCallback } from 'react';
import { Button, styled, LockIcon, ExpandIcon } from '@cherre-frontend/ui';
import SettingsIcon from '@mui/icons-material/Settings';

const Container = styled('div')<{ selected: boolean; disabled: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  border-radius: 10px;
  background: ${({ theme, selected, disabled }) =>
    selected
      ? `${theme.palette.primary.main}1a`
      : disabled
      ? theme.palette.grey[200]
      : 'white'};
  border: ${({ theme, selected, disabled }) =>
    selected
      ? `1px solid ${theme.palette.primary.main}`
      : disabled
      ? `1px solid ${theme.palette.grey[300]}`
      : `1px solid ${theme.palette.grey[100]}`};
  padding: 10px;
  align-items: center;
  justify-items: center;
  width: 173px;
  height: 83px;
  cursor: ${({ disabled, selected }) =>
    !selected && disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    border: 1px solid
      ${({ theme, disabled, selected }) =>
        !selected && disabled
          ? theme.palette.grey[300]
          : theme.palette.primary.main};
    background: ${({ theme, disabled, selected }) =>
      !selected && disabled
        ? theme.palette.grey[200]
        : `${theme.palette.primary.main}1a`};
  }

  &:active {
    background: ${({ theme, disabled, selected }) =>
      !selected && disabled
        ? theme.palette.grey[200]
        : `${theme.palette.primary.main}3a`};
  }
`;

const Image = styled('img')`
  width: 123px;
  height: 30px;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const EmptyIconContainer = styled('div')`
  height: 16px;
  width: 16px;
`;

const IconButton = styled(Button)`
  padding: 0px;
  min-width: 0px;
  height: 16px;
  width: 16px;
  line-height: unset;

  & span {
    height: 100%;
  }
`;

const CustomExpandIcon = styled(ExpandIcon)`
  width: 16px;
  height: 16px;
`;

const CustomLockIcon = styled(LockIcon)`
  width: 16px;
  height: 16px;
`;

const CustomSettingsIcon = styled(SettingsIcon)`
  width: 16px;
  height: 16px;
`;

export type DestinationCardProps = {
  image: string;
  selected?: boolean;
  locked?: boolean;
  settingsEnabled?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

/**
 * Component representing a destination card.
 *
 * @component
 * @param {DestinationCardProps} props - The properties for the DestinationCard component.
 * @param {string} props.image - The image URL for the destination card.
 * @param {boolean} [props.selected=false] - Indicates if the destination card is selected.
 * @param {boolean} [props.locked=false] - Indicates the locker icon will be rendered and destination is not available.
 * @param {boolean} [props.settingsEnabled=false] - Indicates the settings icon will be rendered and user can go to destination settings.
 * @param {boolean} [props.disabled=false] - Indicates if the destination card is disabled.
 * @param {() => void} [props.onClick] - The function to call when the card is clicked.
 *
 * @returns {ReactElement} The rendered DestinationCard component.
 */
const DestinationCard: React.FC<DestinationCardProps> = ({
  image,
  selected = false,
  locked = false,
  settingsEnabled = false,
  disabled = false,
  onClick,
}): ReactElement => {
  const handleExternalLink = useCallback(() => {}, []);
  const handleSettingRedirtect = useCallback(() => {}, []);

  return (
    <Container
      selected={selected}
      disabled={disabled}
      onClick={() => !disabled && onClick?.()}
      aria-disabled={disabled}
      aria-selected={selected}
      data-testid='destination-card'
    >
      <HeaderContainer>
        {!settingsEnabled && !locked && <EmptyIconContainer />}
        {settingsEnabled && (
          <IconButton onClick={handleSettingRedirtect}>
            <CustomSettingsIcon color='primary' />
          </IconButton>
        )}
        {locked && (
          <IconButton>
            <CustomLockIcon color='disabled' />
          </IconButton>
        )}
        <IconButton onClick={handleExternalLink}>
          <CustomExpandIcon />
        </IconButton>
      </HeaderContainer>
      <Image src={image} />
    </Container>
  );
};

export default DestinationCard;
