import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styled from 'styled-components';

const HeaderContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
`;
const Header = withStyles({
  root: {
    padding: 16,
    paddingBottom: 5,
    fontSize: 16,
    fontWeight: 500,
    color: '#9e9e9e',
  },
})(Typography);

const SuggestionHeader = ({ content }) => (
  <HeaderContainer>
    <Header>{content}</Header>
  </HeaderContainer>
);

export default SuggestionHeader;
