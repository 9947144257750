const fillTemplate = function (templateString, templateVars) {
  try {
    const fillTemplateFunc = new Function(
      'return `' + templateString + '`;'
    ).call(templateVars);
    return fillTemplateFunc;
  } catch (e) {
    console.log('templateString: ', templateString);
    console.log('templateVars: ', templateVars);
    console.log('e: ', e);
  }
};

export default fillTemplate;
