import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

export function Title({ text, classes = {} }) {
  return <div className={classes.root}>{text}</div>;
}

Title.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.object,
};

const styles = {
  root: {
    padding: '29px 0',
    color: '#212121',
    fontSize: 36,
    marginRight: 15,
  },
};

export default withStyles(styles)(Title);
