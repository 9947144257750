export default {
  root: {},
  inputs: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  textField: {
    flex: 0.48,
    background: '#fff',
  },
};
