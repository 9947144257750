import { makeStyles } from '@material-ui/core/styles';
import { ErrorBoundary } from '@sentry/react';
import React, { createElement as h } from 'react';
import * as T from './types';

const context = React.createContext(false);

export const useIsSuspended = () => React.useContext(context);

export const useSuspenseStyles = makeStyles(() => ({
  suspenseContainer: {
    '& * > .suspend': {
      display: 'block',
      background:
        'linear-gradient(to right,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0) 80%),lightgray',
      backgroundRepeat: 'repeat-y',
      backgroundSize: '100vh 100vw',
      backgroundPosition: '0 0',
      animation: '$shine 1s infinite',
      minHeight: '1.5em',
    },
  },
  '@keyframes shine': {
    to: {
      backgroundPosition: '100% 0',
    },
  },
}));

export const createSuspenseComponent = <
  A extends T.Obj,
  S extends T.Obj,
  P extends T.Obj,
  C extends T.AC<A, S, P>
>(
  params: T.CreateSuspenseComponentParams<A, S, P, C>
): T.CreateSuspenseComponentReturn<A, S, P, C> => {
  const displayName = params.component.displayName || params.component.name;

  /**
   * Fallback component
   */
  const Fallback: React.FC<any> = ({ children, ...props }) => {
    const classes = useSuspenseStyles();
    return h(
      context.Provider,
      { value: true },
      h(
        'div',
        { className: classes.suspenseContainer, id: 'suspense-container' },
        h(params.component, props, children)
      )
    );
  };
  Fallback.displayName = `Suspense::Fallback(${displayName})`;

  /**
   * Main component
   */
  const Main: React.FC<any> = ({ children, ...props }) => {
    const async = params.hook(props);
    const newProps = Object.assign({}, props, async);
    return h(
      context.Provider,
      { value: false },
      h(params.component, newProps, children)
    );
  };
  Main.displayName = `Suspense::Main(${displayName})`;

  /**
   * Wrapper component
   */
  const Wrapper: React.FC<any> = ({ children, ...props }) => {
    const isSuspended = useIsSuspended();
    const fallback = h(Fallback, props, children);
    if (!props.concurrent && isSuspended) {
      return fallback;
    }
    return h(React.Suspense, { fallback }, h(Main, props, children));
  };
  Wrapper.displayName = `Suspense::Wrapper(${displayName})`;

  /**
   * Error component
   */
  const Error: React.FC<any> = ({ children, ...props }) => {
    return h(
      ErrorBoundary,
      { fallback: h('span') },
      h(Wrapper, props, children)
    );
  };
  Error.displayName = `Suspense::Error(${displayName})`;

  return Error as T.CreateSuspenseComponentReturn<A, S, P, C>;
};
