import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { get } from 'lodash';
import { Link } from '@material-ui/core';
import muiThemeSettings, { pageTitleColor } from 'src/const/theme';
import { usePage } from 'src/utils/routing/CherreRoute';
import { routeWithoutParams } from 'src/utils/routing';
import { useSelector } from 'react-redux';

const NavBarPageTitle = () => {
  const [page, active] = usePage();

  const coreProspectStyles = get(muiThemeSettings, 'typography.h5', {});

  const userTheme = useSelector(
    (state) => state.user?.profile?.value?.themeName
  );

  const title = active?.path ? active?.customHeader : page?.title;

  if (userTheme === 'colliers' && title === 'CoreExplore') {
    return null;
  }

  const baseStyles = {
    display: 'inline-block',
    marginRight: 12,
    marginLeft: 12,
    color: pageTitleColor,
  };

  const styles = coreProspectStyles
    ? Object.assign({}, baseStyles, coreProspectStyles)
    : baseStyles;

  return (
    <Hidden smDown={false}>
      {active?.path ? (
        <Link component={RouterLink} to={routeWithoutParams(active?.path)}>
          <span style={styles}>{title}</span>
        </Link>
      ) : (
        <Link component={RouterLink} to={page?.path}>
          <span style={styles}>{title}</span>
        </Link>
      )}
    </Hidden>
  );
};

export default NavBarPageTitle;
