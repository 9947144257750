import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';

export const DropboxIcon = createSvgIcon(
  <svg viewBox='0 0 20 17' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.99979 0L0 3.14063L4.99979 6.28125L10.0004 3.14063L15.0002 6.28125L20 3.14063L15.0002 0L10.0004 3.14063L4.99979 0Z' />
    <path d='M4.99979 12.5625L0 9.42192L4.99979 6.28125L10.0004 9.42192L4.99979 12.5625Z' />
    <path d='M10.0005 9.42192L15.0003 6.28125L20.0001 9.42192L15.0003 12.5625L10.0005 9.42192Z' />
    <path d='M10.0007 16.75L5 13.6094L10.0007 10.4688L15.0004 13.6094L10.0007 16.75Z' />
  </svg>,
  'Dropbox'
);
