import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import grey from '@material-ui/core/colors/grey';
import { getMaskedValue } from 'src/utils/mask';
import TreppLoanDataModalTableRow from './TreppLoanDataModalTableRow';
import TreppLoanDataModalTableHeader from './TreppLoanDataModalTableHeader';
import TreppLoanDataModalFooter from './TreppLoanDataModalFooter';
import TreppLoanDataModalHeader from './TreppLoanDataModalHeader';
import CustomDialogTitle from './CustomDialogTitle';
import moment from 'moment';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const TreppLoanDataModal = ({ item, open, handleClose }) => {
  return (
    <Dialog
      onClose={handleClose}
      id='trepp-loan-data-dialog'
      open={open}
      maxWidth='xl'
    >
      <CustomDialogTitle
        id='trepp-loan-data-dialog-title'
        onClose={handleClose}
      >
        Loan information
      </CustomDialogTitle>
      <DialogContent>
        <TreppLoanDataModalHeader item={item} />
        <div
          style={{
            margin: '9px 7px 20px 0',
            padding: '8px 10px 8px 10px',
            borderRadius: 7,
            borderWidth: 1,
            borderStyle: `solid`,
            borderColor: grey[400],
            width: 660,
          }}
        >
          <TreppLoanDataModalTableHeader item={item} />
          <div style={{ marginBottom: 23 }}>
            <TreppLoanDataModalTableRow
              label='Appraised Value'
              item1={getMaskedValue(
                Math.round(item.securitization_appraised_value),
                'dollars'
              )}
              item2={getMaskedValue(
                Math.round(item.most_recent_value),
                'dollars'
              )}
            />
            <TreppLoanDataModalTableRow
              label='Occupancy'
              item1={`${item.securitization_occupancy}%`}
              item2={`${item.preceding_fiscal_year_physical_occupancy}%`}
            />
          </div>
          <div style={{ marginBottom: 23 }}>
            <TreppLoanDataModalTableRow
              label='Allocated Balance'
              item1={getMaskedValue(Math.round(item.cutoff_balance), 'dollars')}
              item2={getMaskedValue(
                Math.round(item.current_beginning_scheduled_balance),
                'dollars'
              )}
            />
            <TreppLoanDataModalTableRow
              label='Loan Interest'
              item1={`${item.note_rate_at_securitization}%`}
              item2={`${item.current_note_rate}%`}
            />
            <TreppLoanDataModalTableRow
              label='LTV'
              item1={`${item.securitization_ltv}%`}
              item2={`${item.derived_ltv}%`}
            />
            <TreppLoanDataModalTableRow
              label='DCSR'
              item1={Math.round(item.securitzation_dscr_noi * 100) / 100}
              item2={
                Math.round(item.preceding_fiscal_year_dscr_noi * 100) / 100
              }
            />
            <TreppLoanDataModalTableRow
              label='NOI'
              item1={getMaskedValue(
                Math.round(item.securitization_noi),
                'dollars'
              )}
              item2={getMaskedValue(
                Math.round(item.preceding_fiscal_year_noi),
                'dollars'
              )}
            />
          </div>
          <TreppLoanDataModalTableRow
            label='Term'
            item1={`${item.original_term_of_loan} mo`}
            item2={`${item.remaining_term} mo`}
          />
          <TreppLoanDataModalTableRow
            label='Maturity Date'
            item1={
              item.maturity_date
                ? moment(new Date(item.maturity_date)).format('ll')
                : ''
            }
          />
          <TreppLoanDataModalTableRow
            label='Balance'
            item1={getMaskedValue(Math.round(item.original_balance), 'dollars')}
            item2={getMaskedValue(Math.round(item.actual_balance), 'dollars')}
          />
        </div>
        <TreppLoanDataModalFooter item={item} />
      </DialogContent>
    </Dialog>
  );
};

export default TreppLoanDataModal;
