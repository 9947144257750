import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const SelectionCell = ({ rowData, rowIndex, column }) => {
  const { selectedRowKeys, rowKey, onChange } = column;

  const checked = selectedRowKeys.includes(rowData[rowKey]);

  return (
    <div style={{ marginLeft: 5 }}>
      <FormControlLabel
        control={
          <Checkbox
            color='primary'
            disableRipple
            checked={checked}
            onChange={(e) => {
              onChange({ selected: e.target.checked, rowData, rowIndex });
            }}
          />
        }
      />
    </div>
  );
};

export default SelectionCell;
