import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { useDispatch, useSelector } from 'react-redux';

export const FormSelect = (props) => {
  const {
    disabled,
    formControlClassName,
    fullWidth,
    label,
    name,
    meta: { touched, error },
    errorText,
    input,
    options: getOptions,
    helperText,
  } = props;

  const [options, setOptions] = React.useState(null);
  const reduxState = useSelector((s) => s);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      if (typeof getOptions === 'function') {
        setOptions(await getOptions(reduxState, dispatch));
      } else {
        setOptions(getOptions);
      }
    })();
  }, []);

  return (
    <FormControl
      style={{ width: '100%', marginTop: '15px', ...props.attr.style }}
      className={formControlClassName}
      fullWidth={fullWidth}
      error={touched && error}
      required={props.required}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={options ? input.value : 'Loading...'}
        onChange={input.onChange}
        name={name}
        input={<Input />}
        disabled={disabled}
      >
        {options ? (
          options.map((option, index) => {
            return (
              <MenuItem
                disabled={option.disabled}
                key={index + option.name}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value='Loading...' disabled>
            Loading...
          </MenuItem>
        )}
      </Select>
      {touched && error && (
        <FormHelperText>{errorText || error}</FormHelperText>
      )}
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
