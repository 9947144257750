import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SelectWithSearch from 'src/components/SelectWithSearch';

import styles from './styles';

function MultipleSelection({
  filterValue = [],
  setFilterValue,
  filter,
  data: { options },
  autoFocus,
  multiple = true,
}) {
  return (
    <SelectWithSearch
      optionLabelKey='name'
      options={options}
      value={filterValue}
      label={filter.label}
      onChange={setFilterValue}
      multiple={multiple}
      autoFocus={autoFocus}
    />
  );
}

MultipleSelection.propTypes = {
  filterValue: PropTypes.array,
  setFilterValue: PropTypes.func,
  filter: PropTypes.object,
  classes: PropTypes.object,
  data: PropTypes.object,
  autoFocus: PropTypes.bool,
};

MultipleSelection.defaultProps = {
  data: {
    options: [
      {
        value: 'none',
        name: 'Lookup is missing',
      },
    ],
  },
};

export default withStyles(styles)(MultipleSelection);
