import React, { createElement } from 'react';
import { memoize } from 'lodash';
import { Route, RouteProps, matchPath } from 'react-router';
import { routesListFlat } from 'src/products/shell/apps/cherre/Routes';
import { Permission } from 'src/products/shell/redux/reducers/user';
import { EnsurePermissions } from './EnsurePermissions';
import AppLayout from 'src/products/shell/AppLayout';

const findActiveRoute = memoize((path) => {
  const menuRoutes = routesListFlat.filter((r) => r.menu);
  return menuRoutes.find((r) => matchPath(path || '', { path: r.path }));
});

const CherrePageContext = React.createContext<{
  title: string;
  path: string;
} | null>(null);

export const usePage = () => {
  const page = React.useContext(CherrePageContext);
  const active = findActiveRoute(page?.path);
  return [page, active] as const;
};

export const CherreRoute: React.FC<
  RouteProps & {
    permissions: (Permission | 'Global')[];
    customHeader: string;
    component: React.ComponentType<any>;
    permissionHook?: () => boolean;
  }
> = ({
  permissions,
  customHeader,
  component,
  permissionHook,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      render={(renderRouteProps) => (
        <CherrePageContext.Provider
          value={{
            title: customHeader,
            path: renderRouteProps.location.pathname,
          }}
        >
          <EnsurePermissions
            permissions={permissions}
            permissionHook={permissionHook}
          >
            <AppLayout>
              {createElement(component, {
                ...renderRouteProps,
              })}
            </AppLayout>
          </EnsurePermissions>
        </CherrePageContext.Provider>
      )}
    />
  );
};
