function valueToList(value = {}) {
  return Object.keys(value).filter((key) => {
    return value[key];
  });
}

export function getQuery(filter, value) {
  if (!value) {
    return;
  }

  value = valueToList(value.checked);

  if (!value || Array.isArray(value) === false) {
    return;
  }

  if (value.length === 0) {
    return;
  }

  const where = { _in: value };

  return where;
}

function unflatten(arr) {
  const tree = [];
  const mappedArr = {};
  let arrElem;
  let mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id]['children'] = [];
  }

  for (const id in mappedArr) {
    if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.parent && mappedArr[mappedElem['parent']]) {
        mappedArr[mappedElem['parent']]['children'].push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }

  return tree;
}

function getNodeString(node, result) {
  if (!node) {
    return;
  }

  result.push(`${node.label}`);

  if (node.children.length > 0) {
    node.children.forEach((child) => {
      result = [...result, ...getNodeString(child, [])];
    });
  }

  return result;
}

export function getFilterSetDisplayValue(filter, value, data = {}) {
  if (!value) {
    return;
  }

  value = valueToList(value.checked);

  let nodes = (data.nodes || []).filter((node) => {
    return value.some((id) => {
      return id === node.id;
    });
  });

  nodes = nodes
    .filter((l) => l.parent !== 'null')
    .map((l) => (l.parent === 'root' ? { ...l, parent: null } : l));

  const tree = unflatten(nodes);

  // const result = getNodeString(tree && tree[0], []);

  const final = tree
    .map((node) => {
      const result = getNodeString(node, []);

      if (result.length === 1) {
        return result[0];
      }

      return `${result[0]}: (${result.length})`;
    })
    .join('\r\n');

  return final;
}

export function getFilterDisplayValue(filter, value, data) {
  if (!value) {
    return;
  }

  return getFilterSetDisplayValue(filter, value, data);
}

export default {
  getQuery,
  getFilterDisplayValue,
  getFilterSetDisplayValue,
};
