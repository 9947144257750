import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';

import EnhancedTableHead from './Head';
import Body from './Body';
import HeaderAlert from '../HeaderAlert/HeaderAlert';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

function EnhancedTable(props) {
  const {
    classes,
    columns,
    data,
    order,
    orderBy,
    page,
    actions,
    onDelete,
    onSort,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    refresh,
    isAddButtonDisabled,
    stores,
    total,
  } = props;

  const dispatch = useDispatch();

  const [alertMessage, setAlertMessage] = React.useState(null);

  const params = useParams();

  React.useEffect(() => {
    if (isAddButtonDisabled) {
      isAddButtonDisabled({
        stores,
        dispatch,
        callback: () => {},
        messageCallback: setAlertMessage,
        params,
      });
    }
  }, [stores]);

  return (
    <Paper className={classes.root}>
      <HeaderAlert message={alertMessage} />
      <div id='table-page-table' className={classes.tableWrapper}>
        <Table stickyHeader className={classes.table}>
          <EnhancedTableHead
            data={data}
            actions={actions}
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={onSort}
          />
          <Body
            refresh={refresh}
            params={params}
            actions={actions}
            data={data}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            onDelete={onDelete}
            local={total ? false : true}
          />
        </Table>
      </div>
      {onChangePage && (
        <TablePagination
          id='table-page-table-footer'
          component='div'
          style={{ overflow: 'hidden', minHeight: '52px' }}
          count={total ? total : data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          SelectProps={{
            id: 'table-page-table-footer-select',
          }}
        />
      )}
    </Paper>
  );
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  params: PropTypes.object,
  actions: PropTypes.bool,
  total: PropTypes.number,

  refresh: PropTypes.func,
  onDelete: PropTypes.func,
  onSort: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
  },
  table: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#fff',
    },
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '100%',
  },
});

export default withStyles(styles)(EnhancedTable);
