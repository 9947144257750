/**
 * Compares two semantic version strings and returns a number indicating their order.
 *
 * @param a - The first semantic version string (e.g., "1.2.3").
 * @param b - The second semantic version string (e.g., "1.2.4").
 * @returns A negative number if `a` is less than `b`, a positive number if `a` is greater than `b`, or 0 if they are equal.
 */
export const sortSemVer = (a: string, b: string) => {
  const [aMajor = 0, aMinor = 0, aPatch = 0] = a.split('.').map(Number);
  const [bMajor = 0, bMinor = 0, bPatch = 0] = b.split('.').map(Number);
  if (aMajor !== bMajor) {
    return aMajor - bMajor;
  }
  if (aMinor !== bMinor) {
    return aMinor - bMinor;
  }
  return aPatch - bPatch;
};
