import { isAllowed } from 'src/utils/routing/isAllowed';
import {
  ADMIN_ORGANIZATIONS,
  GLOBAL_SETTINGS,
  SIGNUP_REQUESTS,
} from './const/pages';

export const adminPages = [
  {
    page: ADMIN_ORGANIZATIONS,
    url: '/admin/organizations',
    name: 'Organizations',
    icon: 'ic_customers',
    isVisible: (user) => isAllowed(user.permissions, ['CherreAdminReadOnly']),
    id: 'admin-settings-organizations',
  },
  {
    page: SIGNUP_REQUESTS,
    url: '/admin/signups',
    name: 'Signup Requests',
    icon: 'ic_customers',
    isVisible: (user) => isAllowed(user.permissions, ['CherreAdmin']),
    id: 'admin-settings-signups',
  },
  {
    page: GLOBAL_SETTINGS,
    url: '/admin/global-settings',
    name: 'Global Settings',
    icon: 'ic_customers',
    isVisible: (user) => isAllowed(user.permissions, ['CherreAdmin']),
    id: 'admin-settings-global',
  },
];

export const filterByPermissions = (permissions, featureFlags) => (page) => {
  if (page.isVisible && !page.isVisible({ permissions, featureFlags })) {
    return;
  }
  return true;
};

export const filterByGroupName = (groupName) => (page) =>
  page.group === groupName;
