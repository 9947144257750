/**
 * @generated SignedSource<<5eefd22c0445c83e3f46203af0260caf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type create_reminders_reviewed_params = {
  reminder_ids?: ReadonlyArray<number> | null;
};
export type recoilUseMarkRemindersReviewedMutation$variables = {
  params: create_reminders_reviewed_params;
};
export type recoilUseMarkRemindersReviewedMutation$data = {
  readonly _sys_create_reminders_reviewed: {
    readonly reminders_reviewed_ids: ReadonlyArray<number> | null;
  };
};
export type recoilUseMarkRemindersReviewedMutation = {
  response: recoilUseMarkRemindersReviewedMutation$data;
  variables: recoilUseMarkRemindersReviewedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "create_reminders_reviewed_output",
    "kind": "LinkedField",
    "name": "_sys_create_reminders_reviewed",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reminders_reviewed_ids",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilUseMarkRemindersReviewedMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilUseMarkRemindersReviewedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ab558c829e1410a8c66484006992b7b3",
    "id": null,
    "metadata": {},
    "name": "recoilUseMarkRemindersReviewedMutation",
    "operationKind": "mutation",
    "text": "mutation recoilUseMarkRemindersReviewedMutation(\n  $params: create_reminders_reviewed_params!\n) {\n  _sys_create_reminders_reviewed(params: $params) {\n    reminders_reviewed_ids\n  }\n}\n"
  }
};
})();

(node as any).hash = "95c3f5fbbc5c3748fc6de059d6bc4119";

export default node;
