import { useLocation } from 'react-router';

/**
 * @deprecated use react-router standard hooks
 */
export default () => {
  const location = useLocation();
  const queryEntries = Array.from(
    new URLSearchParams(location.search).entries()
  )
    .filter(([, value]) => value !== 'undefined')
    .map(([key, value]) => {
      if (value === 'null') {
        return [key, null];
      }
      return [key, value];
    });
  const query = Object.fromEntries(queryEntries);
  return { ...location, query };
};
