import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from 'src/products/shell/redux/modals/actions';

import DeleteTableItem from './DeleteTableItem';
import DeployMigrations from './DeployMigrations';
import ClearSessions from './ClearSessions';
import ResolveMigration from './ResolveMigration';
import ChangeUserOrg from './ChangeUserOrg';
import ReEnableUser from './ReEnableUser';
import ReEnableOrganization from './ReEnableOrganization';
import ResendEmail from './ResendEmail';
import DisableApiKey from './DisableApiKey';
import EditApiKey from './EditApiKey';
import CoreProspectExportTable from './CoreProspectExportTable';
import NoTaxRecordFound from './NoTaxRecordFound';
import DeleteListOrSearch from './DeleteListOrSearch';
import Dealcloud from './DealCloud/Dealcloud';
import DealcloudPortfolio from './DealCloud/DealcloudPortfolio';
import ShareList from './ShareList';
import TeamAddMembers from './Team/TeamAddMembers';
import TeamCreate from './Team/TeamCreate';
import Confirm from './Confirm';
import DealCloudProperties from './DealCloud/DealcloudProperties';

const MODAL_COMPONENTS = {
  DELETE_TABLE_ITEM: DeleteTableItem,
  DEPLOY_MIGRATIONS: DeployMigrations,
  RESOLVE_MIGRATION: ResolveMigration,
  CHANGE_USER_ORG: ChangeUserOrg,
  REENABLE_USER: ReEnableUser,
  REENABLE_ORGANIZATION: ReEnableOrganization,
  RESEND_EMAIL: ResendEmail,
  DISABLE_API_KEY: DisableApiKey,
  EDIT_API_KEY: EditApiKey,
  CORE_PROSPECT_EXPORT_TABLE: CoreProspectExportTable,
  NO_TAX_RECORD_FOUND: NoTaxRecordFound,
  DELETE_LIST_OR_SEARCH: DeleteListOrSearch,
  DEALCLOUD: Dealcloud,
  DEALCLOUD_PORTFOLIO: DealcloudPortfolio,
  SHARE_LIST: ShareList,
  TEAMS_ADD_MEMBERS: TeamAddMembers,
  TEAMS_CREATE: TeamCreate,
  CONFIRM: Confirm,
  DEALCLOUD_PROPERTIES: DealCloudProperties,
  CLEAR_SESSIONS: ClearSessions,
} as const;

export type ModalComponentsMapType = typeof MODAL_COMPONENTS;
export type ModalComponentsKeys = keyof ModalComponentsMapType;
export type ModalComponent<T extends ModalComponentsKeys> =
  ModalComponentsMapType[T];
export type ModalComponentProps<T extends ModalComponentsKeys> =
  React.ComponentProps<ModalComponent<T>>;

export type ModalPropsType<T extends ModalComponentsKeys> =
  ModalComponentProps<T> extends { data: infer U } ? U : never;

type ModalRootProps<T extends ModalComponentsKeys> = {
  modalType: T;
  modalProps: ModalPropsType<T>;
  onClose: (data: T) => void;
  hideModal: () => void;
};

const ModalRoot: React.VFC<ModalRootProps<any>> = ({
  modalType,
  modalProps,
  onClose,
  hideModal,
}) => {
  const SpecificModal = MODAL_COMPONENTS[modalType];

  if (!SpecificModal) {
    return null;
  }

  return (
    <SpecificModal
      open
      onRequestClose={(data) => {
        hideModal();

        if (onClose) {
          onClose(data);
        }
      }}
      data={modalProps}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    modalProps: state.common.modals.modalProps,
    modalType: state.common.modals.modalType,
    onClose: state.common.modals.onClose,
  };
};

export default connect(mapStateToProps, { hideModal })(ModalRoot);
