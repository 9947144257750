import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const FormCheckbox = (props) => {
  const {
    input,
    label,
    templateString,
    fullWidth,
    formControlClassName,
    meta: { touched, error },
    id,
  } = props;
  const customLabelMode =
    typeof templateString === 'string' && templateString.length > 0;
  const labelContent = customLabelMode ? (
    <span dangerouslySetInnerHTML={{ __html: templateString }} />
  ) : (
    label
  );

  return (
    <FormControl
      style={{ ...props?.attr?.style, ...props?.style, marginLeft: 15 }}
      className={formControlClassName}
      fullWidth={fullWidth}
      error={touched && error}
    >
      <FormControlLabel
        control={
          <Checkbox
            indeterminate={[null, undefined, ''].includes(input.value)}
            name={input.name}
            color='primary'
            checked={Boolean(input.value)}
            onChange={(e) => input.onChange(e.target.checked, id)}
          />
        }
        label={labelContent}
      />
    </FormControl>
  );
};
