import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import validate from './validation';
import NumberFormat from 'react-number-format';
import { getNumberFormatProps } from '../utils/getNumberFormatProps';

function RangeInput(props) {
  const {
    filterValue,
    setFilterValue,
    filter: { number_format_props, filter_type_props = {} },
    classes,
    onSubmit,
    autoFocus,
    onBlur = () => {},
  } = props;

  const {
    minLabel = 'From',
    maxLabel = 'To',
    allow_negative_values,
  } = filter_type_props;

  const min = (filterValue && filterValue.min) || '';
  const max = (filterValue && filterValue.max) || '';

  const errors = validate(filterValue, allow_negative_values);

  const customInput = React.useCallback((props) => {
    return <TextField {...props} variant='outlined' size='small' />;
  }, []);

  const numberFormatProps = getNumberFormatProps(number_format_props);

  return (
    <div className={classes.root}>
      <div className={classes.inputs}>
        <FormControl className={classes.textField}>
          <NumberFormat
            {...numberFormatProps}
            autoFocus={autoFocus}
            error={!!errors.min}
            helperText={errors.min}
            placeholder={minLabel}
            value={min}
            onValueChange={(values) => {
              setFilterValue({ ...filterValue, min: values.value });
            }}
            customInput={customInput}
            onKeyDown={() => {
              if (event.key === 'Enter') {
                onSubmit();
              }
            }}
            onBlur={onBlur}
          />
        </FormControl>
        <FormControl className={classes.textField}>
          <NumberFormat
            {...numberFormatProps}
            error={!!errors.max}
            helperText={errors.max}
            placeholder={maxLabel}
            value={max}
            onValueChange={(values) =>
              setFilterValue({ ...filterValue, max: values.value })
            }
            customInput={customInput}
            onKeyDown={() => {
              if (event.key === 'Enter') {
                onSubmit();
              }
            }}
            onBlur={onBlur}
          />
        </FormControl>
      </div>
    </div>
  );
}

RangeInput.propTypes = {
  filterValue: PropTypes.object,
  setFilterValue: PropTypes.func,
  filter: PropTypes.object,
  classes: PropTypes.object,
  onBlur: PropTypes.func,
};

// default setFilterValue is needed so an error is not thrown when rendering filter in preview
// in filter editing and so that it will show the label when editing
RangeInput.defaultProps = {
  setFilterValue: (values) => {
    values;
  },
};

export default withStyles(styles)(RangeInput);
