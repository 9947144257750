import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import compose from 'src/utils/compose';

import { toggleSatelliteVisibility } from 'src/products/core-prospect/redux/map/actions';
import Closable from 'src/components/Closable';
import LayersListButton from './LayersListButton';
import LayerGroup from './LayerGroup';
import { primaryColor } from 'src/const/theme';

const MapButton = ({ title, image, selected, onClick }) => {
  return (
    <Tooltip title={title}>
      <div
        onClick={selected ? undefined : onClick}
        style={{
          backgroundImage: `url("${image}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          cursor: selected ? undefined : 'pointer',
          height: 40,
          width: 40,
          borderRadius: 10,
          border: `3px solid ${selected ? primaryColor.main : 'transparent'}`,
        }}
      />
    </Tooltip>
  );
};

class LayerSelector extends Closable {
  toggle = () => {
    if (!this.state.active) {
      this.props.onOpen && this.props.onOpen();
      window.analytics?.track('Map Layers Opened');
    }

    this.setState((prevState) => ({ active: !prevState.active }));
  };

  handleGroupChange(index, group) {
    const { onChange, layers } = this.props;

    const oldGroup = layers[index];

    const changedLayer = group.layers.find(
      (l, idx) => l?.checked !== oldGroup.layers?.[idx]?.checked
    );

    if (changedLayer?.checked) {
      window.analytics?.track('Map Layer Turned On', {
        name: changedLayer?.name,
      });
    }

    onChange([...layers.slice(0, index), group, ...layers.slice(index + 1)]);
  }

  render() {
    const {
      layers,
      classes,
      isSatelliteVisible,
      toggleSatelliteVisibility,
      isCompact,
      onLayerSelect,
    } = this.props;
    const { active } = this.state;

    if (!active) {
      return null;
    }

    return (
      <div
        data-testid='map-layer-drawer'
        id='map-layer-drawer'
        className='noselect'
        style={{
          width: isCompact ? '100%' : 350,
          background: '#fff',
          boxShadow: '-5px 0 5px -5px #00000033',
          padding: active ? 15 : 10,
          cursor: active ? '' : 'pointer',
          alignSelf: 'flex-start',
          position: 'fixed',
          top: 60,
          right: 0,
          height: '100%',
          zIndex: 1101,
          overflow: 'auto',
        }}
        onClick={() => (!active ? this.toggle() : null)}
      >
        <div className={classes.title}>
          <Typography
            component={'span'}
            variant={'h5'}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <i
              style={{ fontSize: 18, marginRight: 10 }}
              className='icon icon-Map-layers'
            />
            Map Layers
          </Typography>
          <LayersListButton expanded={active} toggle={() => this.toggle()} />
        </div>
        <div style={{ marginBottom: 30 }}>
          <div className={classes.sectionHeader}>
            <Typography component={'span'} variant={'h6'}>
              Base map
            </Typography>
          </div>
          <div className={classes.mapButtonSection}>
            <MapButton
              className={`js-base-style-button ${
                isSatelliteVisible ? 'active' : ''
              }`}
              title='Map'
              image='/assets/map-button.png'
              selected={!isSatelliteVisible}
              onClick={() => toggleSatelliteVisibility(false)}
            />
            <MapButton
              className={`js-satellite-style-button ${
                isSatelliteVisible ? 'active' : ''
              }`}
              title='Satellite'
              image='/assets/satellite-button.png'
              selected={isSatelliteVisible}
              onClick={() => toggleSatelliteVisibility(true)}
            />
          </div>
        </div>
        {this.state.active &&
          layers.map((group, index) => (
            <LayerGroup
              onChange={this.handleGroupChange.bind(this, index)}
              key={index}
              group={group}
              onLayerSelect={onLayerSelect}
            />
          ))}
      </div>
    );
  }
}

LayerSelector.propTypes = {
  top: PropTypes.number,
  layers: PropTypes.array,
};

const styles = {
  title: {
    margin: '0 0 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionHeader: {
    margin: '0 0 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mapButtonSection: {
    display: 'flex',
    '& :first-child': {
      marginRight: 15,
    },
  },
};

const mapStateToProps = (state) => {
  const {
    coreProspect: {
      map: { isSatelliteVisible },
    },
  } = state;

  return {
    isSatelliteVisible,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { toggleSatelliteVisibility }, null, {
    forwardRef: true,
  })
)(LayerSelector);
