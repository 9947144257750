import React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  padding: 16px;
  margin-top: 13px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  z-index: 9999;
  &:hover {
    text-decoration: none;
  }
  a {
    z-index: 999999;
  }
`;

const SuggestionFooter = ({ content }) => <Footer>{content}</Footer>;

export default SuggestionFooter;
