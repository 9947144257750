import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import constants from 'src/const';

const { NAV_BAR_HEIGHT, NAV_BAR_Z_INDEX } = constants;

const styles = () => ({
  drawer: {
    zIndex: NAV_BAR_Z_INDEX - 1,
  },
  drawerPaper: {
    height: `calc(100% - ${NAV_BAR_HEIGHT}px)`,
    top: NAV_BAR_HEIGHT,
  },
});

const UnderBarDrawer = ({ classes, ...props }) => {
  return (
    <Drawer
      className={classes.drawer}
      variant='temporary'
      classes={{
        paper: classes.drawerPaper,
      }}
      {...props}
    />
  );
};

export default withStyles(styles)(UnderBarDrawer);
