import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { isSearchParameterEmpty } from 'src/products/core-prospect/search-pages/selectors';
import { filtersConfig } from 'src/products/core-prospect/filtersConfig';
import { geoSearchFields } from 'src/products/core-prospect/constants';

const SearchFilterSummary = ({ classes, summary }) => {
  return (
    <span className={classes.summary}>
      {Object.keys(summary || {})
        .filter((groupName) => summary[groupName] !== 0)
        .map((groupName, index) => {
          return (
            <span key={index}>
              {index !== 0 && ', '}
              {groupName} ({summary[groupName]})
            </span>
          );
        })}
    </span>
  );
};

const styles = {
  summary: {
    fontWeight: 400,
    fontSize: 12,
    marginTop: 2,
  },
};

function mapStateToProps(state, { view }) {
  const groups = filtersConfig.filter_groups;
  const sets = filtersConfig.filter_sets;
  const filters = filtersConfig.filters;

  const search_parameters = view.search_parameters.filter(
    (search_parameter) => !isSearchParameterEmpty(search_parameter.attributes)
  );

  const geoSearchParameters = search_parameters.filter((searchParameter) => {
    return geoSearchFields.includes(searchParameter.field_name);
  });

  const summary = groups.reduce((memo, group) => {
    const groupSets = sets.filter((set) => set.filter_group_id === group.id);
    const groupFilters = filters.filter((filter) =>
      groupSets.some((set) => set.id === filter.filter_set_id)
    );

    const groupSearchParameters = search_parameters.filter((search_parameter) =>
      groupFilters.some(
        (filter) =>
          search_parameter.entity_name === filter.entity &&
          search_parameter.field_name === filter.field_name
      )
    );

    memo[group.label] = groupSearchParameters.length;

    return memo;
  }, {});

  summary['Location'] = geoSearchParameters.length;

  return {
    summary,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(SearchFilterSummary)
);
