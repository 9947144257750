import constants from 'src/const';
import redux from 'src/products/shell/redux/store/configureStore';

// Handle browsers that don't support BroadcastChannel.
class BroadcastChannelStub {
  onmessage() {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postMessage(msg: any) {}
}

const BroadcastChannel = window.BroadcastChannel || BroadcastChannelStub;

const isEmulatedSessionChannel = new BroadcastChannel(
  constants.IS_EMULATED_SESSION_KEY
);

isEmulatedSessionChannel.onmessage = (ev) => {
  const wasEmulatedSession = Boolean(
    redux.store.getState().user?.profile?.value?.isEmulatedSession
  );
  const isEmulatedSession = Boolean(ev.data);

  if (wasEmulatedSession !== isEmulatedSession) {
    window.location.reload();
  }
};

export default isEmulatedSessionChannel;
