/**
 * @generated SignedSource<<d654af6769c5d2f728d0168286b6d081>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type createConnectorMutation$variables = {
  displayName: string;
  modelNames?: ReadonlyArray<string> | null;
  sourceNames?: ReadonlyArray<string> | null;
};
export type createConnectorMutation$data = {
  readonly createConnector: {
    readonly displayName: string | null;
    readonly name: string;
  };
};
export type createConnectorMutation = {
  response: createConnectorMutation$data;
  variables: createConnectorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "modelNames"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceNames"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "displayName",
        "variableName": "displayName"
      },
      {
        "kind": "Variable",
        "name": "modelNames",
        "variableName": "modelNames"
      },
      {
        "kind": "Variable",
        "name": "sourceNames",
        "variableName": "sourceNames"
      }
    ],
    "concreteType": "Connector",
    "kind": "LinkedField",
    "name": "createConnector",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createConnectorMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "createConnectorMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9ed94d0a144bc3f40b007d395b0b1d50",
    "id": null,
    "metadata": {},
    "name": "createConnectorMutation",
    "operationKind": "mutation",
    "text": "mutation createConnectorMutation(\n  $displayName: String!\n  $sourceNames: [String!]\n  $modelNames: [String!]\n) {\n  createConnector(displayName: $displayName, sourceNames: $sourceNames, modelNames: $modelNames) {\n    displayName\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfbf4fc7f63964a430e74c8fb290c23d";

export default node;
