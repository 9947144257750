'use strict';

module.exports.cherre = {
  name: 'cherre',
  title: 'Cherre',
  favicon: '/assets/favicon.ico',
  expose: {
    theme: {
      mapClusterColor: 'hsl(315, 80%, 24%)',
      pageTitleColor: '#ffffff',
      palette: {
        primary: {
          main: '#df2467',
          light: '#ff6294',
          dark: '#a7003d',
        },
        secondary: {
          main: '#6e0c55',
          light: '#9f4182',
          dark: '#3f002c',
        },
      },
      appBar: {
        colorPrimary: {
          backgroundColor: '#6e0c55',
        },
      },
    },
  },
};

module.exports.coreexplore_starwood = {
  name: 'starwood',
  title: 'Starwood Capital Group',
  favicon: '/assets/favicon.ico',
  expose: {
    theme: {
      logo: '/assets/Starwood Capital_horiz_blue.svg',
      mapClusterColor: 'hsl(202, 100%, 16%)',
      pageTitleColor: '#003452',
      palette: {
        primary: {
          main: '#0079C1',
          light: '#59A8F4',
          dark: '#003452',
        },
        secondary: {
          main: '#675D49',
          light: '#BEB5A3',
          dark: '#3C3421',
        },
      },
      appBar: {
        colorPrimary: {
          backgroundColor: '#fff',
        },
      },
    },
  },
};

module.exports.maverick = {
  name: 'maverick',
  title: 'Maverick Real Estate Partners',
  favicon: '/assets/maverick.favicon.ico',
  expose: {
    theme: {
      palette: {
        primary: {
          main: '#455892',
          light: '#7584c3',
          dark: '#102f64',
        },
      },
      appBar: {
        colorPrimary: {
          backgroundColor: '#424242',
        },
      },
    },
  },
};

module.exports.colliers = {
  name: 'colliers',
  title: 'Colliers',
  favicon: '/assets/favicon.ico',
  expose: {
    theme: {
      logo: '/assets/Colliers_logo_60.svg',
      mapClusterColor: 'hsl(225, 59%, 35%)',
      pageTitleColor: '#ffffff',
      palette: {
        primary: {
          main: '#0079C1',
          light: '#59A8F4',
          dark: '#003452',
        },
        secondary: {
          main: '#675D49',
          light: '#BEB5A3',
          dark: '#3c3421',
        },
      },
      appBar: {
        colorPrimary: {
          backgroundColor: '#25408F',
        },
      },
    },
  },
};

module.exports.dalfen = {
  name: 'dalfen',
  title: 'Dalfen',
  favicon: '/assets/favicon.ico',
  expose: {
    theme: {
      logo: '/assets/white-dalfen-logo.png',
      mapClusterColor: '#12223b',
      pageTitleColor: '#ffffff',
      palette: {
        primary: {
          main: '#12223b',
          light: '#00B0F0',
          dark: '#00B0F0',
        },
        secondary: {
          main: '#BFBFBF',
          light: '#D9D9D9',
          dark: '#7F7F7F',
        },
      },
      appBar: {
        colorPrimary: {
          backgroundColor: '#12223b',
        },
      },
    },
  },
};

module.exports.cim = {
  name: 'cim',
  title: 'CIM',
  favicon: '/assets/favicon.ico',
  expose: {
    theme: {
      logo: '/assets/cim.png',
      mapClusterColor: 'hsl(29, 94%, 45%)',
      pageTitleColor: '#ffffff',
      palette: {
        primary: {
          main: '#FF8F1C',
          light: '#F2AF70',
          dark: '#DC6D07',
        },
        secondary: {
          main: '#54565A',
          light: '#797D82',
          dark: '#2A2B2E',
        },
      },
      appBar: {
        colorPrimary: {
          backgroundColor: '#54565A',
        },
      },
    },
  },
};

module.exports.howardhughes = {
  name: 'howardhughes',
  title: 'Howard Hughes',
  favicon: '/assets/favicon.ico',
  expose: {
    theme: {
      logo: '/assets/hh.svg',
      mapClusterColor: 'hsl(200, 99%, 35%)',
      pageTitleColor: '#000000',
      palette: {
        primary: {
          main: '#0076B4',
          light: '#80BAD9',
          dark: '#000000',
        },
        secondary: {
          main: '#4099C7',
          light: '#B1B3B3',
          dark: '#63666A',
        },
      },
      appBar: {
        colorPrimary: {
          backgroundColor: '#fff',
        },
      },
    },
  },
};
