import React from 'react';
import { useSelector } from 'react-redux';
import { ME_SUCCESS } from 'src/products/shell/redux/actions/user';
import { FeatureFlag } from 'src/products/shell/redux/reducers/user';
import redux from 'src/products/shell/redux/store/configureStore';

let __INTERNAL_ENABLED_FEATURE_FLAGS: Record<FeatureFlag, boolean> | undefined =
  undefined;

/**
 * @todo move user information retrieval to recoil
 */
export const useFeatureFlag = (flag: FeatureFlag) => {
  if (
    __INTERNAL_ENABLED_FEATURE_FLAGS &&
    !(flag in __INTERNAL_ENABLED_FEATURE_FLAGS)
  ) {
    throw new Error(`Feature flag ${flag} not found`);
  }

  return useSelector(
    (state) => state.user?.profile?.value?.featureFlags?.[flag] ?? false
  );
};

export const FeatureFlagGate: React.FC<{ flag: FeatureFlag }> = ({
  flag,
  children,
}) => {
  if (useFeatureFlag(flag)) {
    return <>{children}</>;
  }
  return null;
};

window.__INTERNAL_CHANGE_FEATURE_FLAGS = (
  flags: Record<FeatureFlag, boolean>
) => {
  const state = redux.store.getState();
  const profile = state.user.profile.value;
  profile.featureFlags = flags;
  redux.store.dispatch({ type: ME_SUCCESS, data: profile });
  __INTERNAL_ENABLED_FEATURE_FLAGS = flags;
};

window.__INTERNAL_CLEAR_FEATURE_FLAGS = () => {
  __INTERNAL_ENABLED_FEATURE_FLAGS = undefined;
};
