export type FailedSourceRecord = {
  connector: string;
  testName: string;
  testId: string;
  columnNames: string[];
  failingColumns: string[];
  records: { [key: string]: string | number }[];
};

export const failedSourceRecords: FailedSourceRecord[] = [
  {
    connector: 'Chatham',
    testName: 'amortization_start_date is not before closing_date',
    testId: '9ba7fa5b-080e-4c0a-975a-04ba50d9e845',
    columnNames: [
      'reference_number_chatham',
      'closing_date',
      'amortization_start_date',
    ],
    failingColumns: ['amortization_start_date'],
    records: [
      {
        reference_number_chatham: 123123,
        closing_date: '3/31/2024',
        amortization_start_date: '3/1/2024',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
      {
        reference_number_chatham: 456456,
        closing_date: '4/13/2024',
        amortization_start_date: '4/13/2023',
      },
    ],
  },
  {
    connector: 'VTS',
    testName: 'expiring_base_rent_amount is not less than 0',
    testId: 'e32f5c2e-dfcf-41d5-b454-3466c95c4d4f',
    columnNames: ['lease_terms_id', 'expiring_base_rent_amount'],
    failingColumns: ['expiring_base_rent_amount'],
    records: [
      { lease_terms_id: 123123, expiring_base_rent_amount: '-1250' },
      { lease_terms_id: 543543, expiring_base_rent_amount: '-1' },
      { lease_terms_id: 543543, expiring_base_rent_amount: '-10000' },
      { lease_terms_id: 543543, expiring_base_rent_amount: '-200' },
      { lease_terms_id: 543543, expiring_base_rent_amount: '-400' },
      { lease_terms_id: 543543, expiring_base_rent_amount: '-35' },
      { lease_terms_id: 543543, expiring_base_rent_amount: '-72' },
      { lease_terms_id: 543543, expiring_base_rent_amount: '-60' },
    ],
  },
  {
    connector: 'Dwellsy',
    testName: 'year_built is not before 1500',
    testId: '47355bf0-58d9-4421-a5d0-cf54ff0f38a4',
    columnNames: ['cherre_dwellsy_listings_pk', 'year_built'],
    failingColumns: ['year_built'],
    records: [
      { cherre_dwellsy_listings_pk: 789789, year_built: 1499 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
      { cherre_dwellsy_listings_pk: 678678, year_built: 150 },
    ],
  },
];
