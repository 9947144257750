import axios, { AxiosError } from 'axios';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

const options: OptionsObject = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const showRequestError = (
  e: AxiosError | Error | unknown,
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey
) => {
  if (axios.isAxiosError(e)) {
    const message = e.response?.data?.errors?.[0]?.message || e.message;
    enqueueSnackbar(message, options);
  } else if (e instanceof Error) {
    enqueueSnackbar(e.message, options);
  }
};

export default showRequestError;
