import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List, InfiniteLoader } from 'react-virtualized';

/**
 * Wrapper to reduce boilerplate for an auto-sized, infinite list.
 */
const InfiniteList = ({
  height,
  width,
  rowCount = Infinity,
  minimumBatchSize,
  threshold = minimumBatchSize / 2,
  isRowLoaded,
  loadMoreRows,
  deferredMeasurementCache,
  rowHeight,
  rowRenderer,
}) => {
  const hasHeight = height != null;
  const hasWidth = width != null;

  return (
    <AutoSizer disableHeight={hasHeight} disableWidth={hasWidth}>
      {({ height: autoHeight, width: autoWidth }) => (
        <InfiniteLoader
          rowCount={Infinity}
          minimumBatchSize={minimumBatchSize}
          threshold={threshold}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              ref={registerChild}
              height={hasHeight ? height : autoHeight}
              width={hasWidth ? width : autoWidth}
              onRowsRendered={onRowsRendered}
              deferredMeasurementCache={deferredMeasurementCache}
              rowHeight={rowHeight}
              rowCount={rowCount}
              rowRenderer={rowRenderer}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

InfiniteList.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  rowCount: PropTypes.number,
  minimumBatchSize: PropTypes.number.isRequired,
  threshold: PropTypes.number,
  isRowLoaded: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  deferredMeasurementCache: PropTypes.object,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  rowRenderer: PropTypes.func.isRequired,
};

export default InfiniteList;
