import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'src/components/ButtonProgress';

const NoTaxRecordFound: React.FC<any> = (props) => {
  const { open, onRequestClose, classes, data } = props;

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle>{`Delete ${data.type}`}</DialogTitle>
      <DialogContent className={classes.modalContent}>
        Are you sure you want to delete <i>{data.name}</i> ?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            data.onDelete();
            onRequestClose();
          }}
          color='primary'
          variant='contained'
        >
          Delete
        </Button>
        <Button onClick={onRequestClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
};

export default withStyles(styles)(NoTaxRecordFound);
