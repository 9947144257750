import { useSelector } from 'react-redux';
import { Permission } from 'src/products/shell/redux/reducers/user';

/**
 * @todo refactor to use recoil
 */
export const usePermission = (
  permission: Permission | Permission[] | 'Global'
) => {
  const permissions = useSelector((state) => {
    return state?.user?.profile?.value?.permissions;
  });

  if (permission === 'Global') {
    return true;
  }

  if (!permissions) {
    return false;
  }

  if (typeof permission === 'string') {
    return permissions.includes(permission);
  }

  return permission.some((p) => permissions.includes(p));
};
