import {
  Dialog,
  Typography,
  DialogContent,
  TextField,
  makeStyles,
  DialogActions,
  Button,
  Avatar,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Cancel from '@material-ui/icons/Cancel';
import Search from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useShareWithAutocompleteOptions } from 'src/products/core-prospect/hooks';
import { CreateShareListModalHook } from 'src/products/core-prospect/hooks/useShareListCreate';
import { EditShareListModalHook } from 'src/products/core-prospect/hooks/useShareListEdit';
import { ShareWithAutocompleteResponse } from 'src/products/core-prospect/recoil/types';
import { getNameInitials } from 'src/utils/getNameInitials';

const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: 520,
    overflow: 'visible',
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    padding: '20px 20px 0px',
    lineHeight: 'normal',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 14,
    padding: '3px 20px 0px',
  },
  content: {
    padding: '0px 20px',
  },
  key: {
    fontWeight: 500,
    fontSize: 14,
    paddingTop: 24,
    paddingBottom: 9,
  },
  value: {
    fontWeight: 700,
    fontSize: 14,
  },
  action: {
    padding: '12px 28px 31px',
  },
  done: {
    padding: '10px 20px 12px',
  },
  cancel: {
    padding: '10px 20px 12px',
  },
  sharedWithList: {
    paddingTop: '12px',
    paddingBottom: '1px',
    maxHeight: 245,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarGutter: 'stable both-edges',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.light,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.13,
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

type SharedWith = ShareWithAutocompleteResponse[number];

export const ListShareWithOption: React.VFC<{
  option: SharedWith;
  deleteAction?: (option: SharedWith) => void;
}> = ({ option, deleteAction }) => {
  const { title, subtitle, targetType, targetId } = option;
  const classes = useStyles();

  return (
    <ListItem dense style={{ padding: 0 }} component='div'>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{getNameInitials(title)}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subtitle} />
      {deleteAction && (
        <ListItemSecondaryAction>
          <Tooltip title='Remove sharing'>
            <IconButton
              onClick={() => deleteAction(option)}
              data-testid={`remove-${targetType}-${targetId}`}
            >
              <Cancel className={classes.icon} />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

type ShareWithAutocompleteProps = {
  selectedValues: SharedWith[];
  setSelectedValues: (values: SharedWith[]) => void;
  alreadySharedWith: SharedWith[] | undefined;
};

const ShareWithAutocomplete: React.VFC<ShareWithAutocompleteProps> = ({
  selectedValues,
  setSelectedValues,
  alreadySharedWith,
}) => {
  const { searchText, setSearchText, options } =
    useShareWithAutocompleteOptions();

  const alreadySharedWithMap = useMemo(() => {
    const m = new Map<string, boolean>();
    alreadySharedWith?.forEach((s) =>
      m.set(`${s.targetType}-${s.targetId}`, true)
    );
    return m;
  }, [alreadySharedWith]);

  return (
    <Autocomplete
      data-testid='share_list_autocomplete'
      value={selectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          size='small'
          placeholder='Add members or group'
          style={{ backgroundColor: 'white' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Search htmlColor={grey[400]} />,
          }}
        />
      )}
      onInputChange={(e, value) => {
        setSearchText(value);
      }}
      inputValue={searchText}
      getOptionLabel={(option) => option.title}
      getOptionSelected={(option, value) =>
        option.targetId === value.targetId &&
        option.targetType === value.targetType
      }
      renderOption={(option) => <ListShareWithOption option={option} />}
      filterOptions={(x) => {
        if (!alreadySharedWith) {
          return x;
        }

        return x.filter(
          (o) => !alreadySharedWithMap.has(`${o.targetType}-${o.targetId}`)
        );
      }}
      onChange={(_, option) => {
        setSelectedValues(
          option.filter((o): o is SharedWith => typeof o === 'object')
        );
      }}
      disableClearable
      options={options}
      multiple
      freeSolo
      autoHighlight
    />
  );
};

type ShareListProps = {
  data: {
    mode: 'create' | 'edit';
    listId: number;
    hook: CreateShareListModalHook | EditShareListModalHook;
  };
  open: boolean;
  onRequestClose: () => void;
};

const ShareList: React.VFC<ShareListProps> = ({
  open,
  data,
  onRequestClose,
}) => {
  const classes = useStyles();
  const { listId, mode, hook } = data;

  const [sharedWithList, setSharedWithList] = useState<SharedWith[]>([]);

  const [targets, setTargets] = useState<SharedWith[]>([]);

  const { propertyList, isSaving, onClickDone, sharedWith } = hook(
    listId,
    onRequestClose,
    targets,
    mode
  );

  const [alreadySharedWith, setAlreadySharedWith] = useState<SharedWith[]>(
    sharedWith || []
  );

  useEffect(() => {
    if (!sharedWith || sharedWith.length === 0) {
      return;
    }

    setAlreadySharedWith(sharedWith);
  }, [setAlreadySharedWith, sharedWith]);

  useEffect(() => {
    setTargets(alreadySharedWith.concat(sharedWithList));
  }, [setTargets, alreadySharedWith, sharedWithList]);

  if (!propertyList) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{ paper: classes.modal }}
      data-testid='share_list_modal'
    >
      <Typography className={classes.title}>
        Share "{propertyList.name}"
      </Typography>
      <Typography className={classes.subtitle}>
        {propertyList.entries_count} propert
        {propertyList.entries_count > 1 ? 'ies' : 'y'}
      </Typography>
      <DialogContent className={classes.content}>
        <div>
          <Typography className={classes.key}>Share with</Typography>
          <div className={classes.value}>
            <ShareWithAutocomplete
              selectedValues={sharedWithList}
              setSelectedValues={setSharedWithList}
              alreadySharedWith={alreadySharedWith}
            />
          </div>
        </div>
        {alreadySharedWith.length > 0 && (
          <List className={classes.sharedWithList}>
            {alreadySharedWith.map((shared) => (
              <ListShareWithOption
                key={`${shared.targetType}-${shared.targetId}`}
                option={shared}
                deleteAction={() =>
                  setAlreadySharedWith((s) =>
                    s.filter(
                      (a) =>
                        !(
                          a.targetId === shared.targetId &&
                          a.targetType === shared.targetType
                        )
                    )
                  )
                }
              />
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={onRequestClose}
          color='primary'
          className={classes.cancel}
        >
          Cancel
        </Button>
        <Button
          disabled={isSaving}
          onClick={onClickDone}
          variant='contained'
          color='primary'
          className={classes.done}
        >
          {mode === 'create' ? 'Share' : 'Done'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareList;
