import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const StyledSwitch = withStyles({
  default: {
    height: 35,
  },
})(Switch);

import styles from './styles';

function BooleanSelection(props) {
  const {
    filterValue,
    setFilterValue,
    filter: { label, filter_type_props = {} },
    classes,
  } = props;

  const { viewMode } = filter_type_props;

  return (
    <FormControlLabel
      className={cx(classes.label, {
        [classes.labelReversed]: viewMode === 'toggle',
      })}
      label={label || ''}
      control={
        viewMode === 'toggle' ? (
          <StyledSwitch
            style={{ height: 30 }}
            disableRipple
            checked={filterValue === true}
            onChange={(e) => {
              setFilterValue(e.target.checked ? true : undefined);
            }}
          />
        ) : (
          <Checkbox
            color='primary'
            disableRipple
            style={{ height: 30 }}
            checked={filterValue === true}
            onChange={(e) =>
              setFilterValue(e.target.checked ? true : undefined)
            }
          />
        )
      }
    />
  );
}

BooleanSelection.propTypes = {
  filterValue: PropTypes.bool,
  setFilterValue: PropTypes.func,
  filter: PropTypes.object,
  classes: PropTypes.object,
};

// default setFilterValue is needed so an error is not thrown when rendering filter in preview
// in filter editing and so that it will show the label when editing
BooleanSelection.defaultProps = {
  setFilterValue: (values) => {
    values;
  },
};

export default withStyles(styles)(BooleanSelection);
