import { useRecoilValueLoadable } from 'recoil';
import { useOrganizationPropsValue } from 'src/hooks';
import { dealPortfolioAllSelector } from '../recoil';
import { DealGetAllOptions } from '../types';

export const useAllDealsPortfolio = (opts: DealGetAllOptions) => {
  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  if (!isDealsIntegrationAvailable) {
    return;
  }

  return useRecoilValueLoadable(dealPortfolioAllSelector(opts));
};
