import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Title from './Title';

import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { createStyles } from '@material-ui/core/styles';

export function Toolbar({
  classes = {},
  backButtonClick,
  title,
}: {
  classes: { root?: string; title?: string };
  backButtonClick?: () => void;
  title: string;
}) {
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {backButtonClick && (
          <IconButton onClick={backButtonClick} style={{ color: '#000' }}>
            <ArrowBack />
          </IconButton>
        )}
        <Title text={title} />
      </div>
    </div>
  );
}

const styles = createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles)(Toolbar);
