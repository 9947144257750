import React, { ReactNode } from 'react';

export interface ConditionalProviderProps<T extends Record<string, unknown>> {
  Provider: React.FC<T>;
  providerProps: T;
  children: ReactNode;
  renderProvider: () => boolean;
}

// A Component to render a Provider based on a condition
export const ConditionalProvider = <T extends Record<string, unknown>>({
  Provider,
  providerProps,
  renderProvider,
  children,
}: ConditionalProviderProps<T>) =>
  renderProvider() ? (
    <Provider {...providerProps}>{children}</Provider>
  ) : (
    <>{children}</>
  );
