import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useRecoilCallback, useResetRecoilState } from 'recoil';
import {
  useLoadable,
  useSnackbarCherre,
} from 'src/products/core-prospect/hooks';
import {
  dealPortfolioAllSelector,
  dealPortfolioByTaxIdSelector,
  localDeal,
} from './recoil';
import * as T from './types';
import * as F from './formatters';
import * as U from './utils';
import { replace } from 'connected-react-router';
import { useDisableNextCoreExploreReset } from 'src/products/core-prospect/search-pages/hooks/useResetCoreExplore';
import { useState } from 'react';
import { getCommonData } from './getCommonData';
import { showModal } from 'src/products/shell/redux/modals/actions';

const useLocalDealModal =
  ({ deal, modalProps }: { deal: T.LocalDeal; modalProps: T.ModalProps }) =>
  (onRequestClose: () => void) => {
    const [name, setName] = useState(deal.dealName);
    const [description, setDescription] = useState(deal.description);
    const [stage, setStage] = useState<T.DealStageType>(
      deal.stage as T.DealStageType
    );
    const [lead, setLead] = useState<string | null>(deal.lead);
    const [broker, setBroker] = useState<string | null>(deal.broker);
    const [seller, setSeller] = useState<string | null>(deal.seller);
    const [ilo, setILO] = useState<string | null>(deal.ilo);
    const [lastActivityNote, setLastActivityNote] = useState(
      deal.lastActivityNote
    );

    const send = useRecoilCallback(
      ({ set }) =>
        async () => {
          set(localDeal(deal.dealId || 'new'), {
            ...deal,
            dealName: name,
            description,
            stage,
            lead: lead || '',
            broker: broker || '',
            seller: seller || '',
            ilo: ilo || '',
            lastActivityNote,
          });
          onRequestClose();
        },
      [
        deal,
        name,
        stage,
        lead,
        description,
        broker,
        seller,
        ilo,
        lastActivityNote,
      ]
    );

    return {
      ...modalProps,
      selectedStage: stage,
      setSelectedStage: setStage,
      selectedLead: lead,
      setSelectedLead: setLead,
      selectedBroker: broker,
      setSelectedBroker: setBroker,
      selectedSeller: seller,
      setSelectedSeller: setSeller,
      selectedILO: ilo,
      setSelectedILO: setILO,
      note: lastActivityNote,
      setNote: setLastActivityNote,
      send,
      name,
      setName,
      description,
      setDescription,
      loading: false,
      assets: deal.assets,
      dealId: undefined,
      showAssets: false,
    };
  };

export const useLocalDeal = (dealId: number | 'new') => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbarCherre();

  const [deal, dealState] = useLoadable(undefined, localDeal(dealId));
  const resetForm = useResetRecoilState(localDeal(dealId));
  const isDealLoading = dealState === 'loading';
  const { disableNextReset } = useDisableNextCoreExploreReset();

  const editDeal = useRecoilCallback(
    ({ refresh, snapshot, set }) =>
      async () => {
        try {
          const deal = await snapshot.getPromise(localDeal(dealId));

          if (!deal) {
            return;
          }

          const updatedDeal = await axios.post<T.PortfolioDeal>(
            '/api/v1/deals/edit',
            { dealId, ...deal }
          );

          set(localDeal(dealId), F.remoteToLocalDeal(updatedDeal.data));

          refresh(dealPortfolioAllSelector({}));
          for (const asset of deal.assets) {
            refresh(dealPortfolioByTaxIdSelector(asset.taxAssessorId));
          }

          enqueueSnackbar(
            'Portfolio Deal was updated.',
            U.getSnackbarProps('success')
          );
          disableNextReset();
          dispatch(replace(`/properties`));
        } catch (e) {
          const msg = U.getErrorMsg(e, 'Failed to update deal');
          enqueueSnackbar(msg, U.getSnackbarProps('error'));
        }
      },
    []
  );

  const createDeal = useRecoilCallback(
    ({ refresh, snapshot }) =>
      async () => {
        try {
          const deal = await snapshot.getPromise(localDeal(dealId));

          if (!deal) {
            return;
          }

          await axios.post<T.PortfolioDeal>('/api/v1/deals/create', deal);

          refresh(dealPortfolioAllSelector({}));
          for (const asset of deal.assets) {
            refresh(dealPortfolioByTaxIdSelector(asset.taxAssessorId));
          }

          disableNextReset();
          dispatch(replace(`/properties`));
          enqueueSnackbar('New Deal was added.', U.getSnackbarProps('success'));
        } catch (e) {
          const msg = U.getErrorMsg(e, 'Failed to create portfolio');
          enqueueSnackbar(msg, U.getSnackbarProps('error'));
        }
      },
    [dispatch]
  );

  const addAsset = useRecoilCallback(
    ({ set, snapshot }) =>
      async (asset: T.LocalDeal['assets'][number]) => {
        const deal = await snapshot.getPromise(localDeal(dealId));

        if (!deal) {
          return;
        }

        const assets = [...deal.assets, asset];

        set(localDeal(dealId), {
          ...deal,
          assets,
        });
      },
    []
  );

  const removeAsset = useRecoilCallback(
    ({ set, snapshot }) =>
      async (taxAssessorId: number) => {
        const deal = await snapshot.getPromise(localDeal(dealId));

        if (!deal) {
          return;
        }

        const assests = deal?.assets.filter(
          (asset) => asset.taxAssessorId !== taxAssessorId
        );

        set(localDeal(dealId), {
          ...deal,
          assets: assests.filter(
            (asset) => asset.taxAssessorId !== taxAssessorId
          ),
        });
      },
    []
  );

  const editDealModal = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const deal = await snapshot.getPromise(localDeal(dealId));

          const modalProps = await getCommonData(snapshot);

          if (!deal) {
            return;
          }

          const hook = useLocalDealModal({ deal, modalProps });
          dispatch(showModal('DEALCLOUD_PORTFOLIO', { hook, mode: 'edit' }));
        } catch (e) {
          console.log(e);
        } finally {
          release();
        }
      },
    []
  );

  return {
    deal,
    dealState,
    addAsset,
    removeAsset,
    createDeal,
    editDeal,
    isDealLoading,
    resetForm,
    editDealModal,
  };
};
