import React from 'react';

/**
 * @todo refactor and move to right scope (streetview component)
 */
const usePanoramaHeading = ({ latitude, longitude }) => {
  const [data, setData] = React.useState({ heading: null, loading: null });

  const streetViewMaxDistance = 50;
  const streetViewService = new google.maps.StreetViewService();

  React.useEffect(() => {
    const lookAt = new google.maps.LatLng(latitude, longitude);

    setData({ heading: null, loading: true });

    streetViewService.getPanoramaByLocation(
      lookAt,
      streetViewMaxDistance,
      (streetViewPanoramaData, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          const heading = google.maps.geometry.spherical.computeHeading(
            streetViewPanoramaData.location.latLng,
            lookAt
          );

          setData({ heading, loading: false });
        } else {
          setData({ heading: null, loading: false });
        }
      }
    );
  }, [latitude, longitude]);

  return data;
};

export default usePanoramaHeading;
