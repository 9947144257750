import React, { useState } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Button from 'src/components/ButtonProgress';

const CoreProspectExportTable = (props) => {
  const { open, onRequestClose, classes, data } = props;
  const [includeOwnerData, setIncludeOwnerData] = useState('false');

  return (
    <Dialog open={open} onClose={onRequestClose} id='js-download-csv'>
      <DialogTitle style={{ width: 500 }}>Export Data to CSV</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          onChange={(e) => setIncludeOwnerData(e.target.value)}
          value={includeOwnerData}
        >
          <FormControlLabel
            value='false'
            control={<Radio color='primary' name='details' />}
            label='Property Details'
          />
          <FormControlLabel
            value='true'
            control={<Radio color='primary' name='contacts' />}
            label='Ownership and Contacts'
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onRequestClose} color='primary'>
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (includeOwnerData === 'true') {
              window.analytics?.track('Contact Details CSV Downloaded');
            } else {
              window.analytics?.track('Property Details CSV Downloaded');
            }
            data.runExport(includeOwnerData === 'true');

            onRequestClose();
          }}
          id='js-download-csv-button'
        >
          Download CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  actions: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 16,
  },
  lineSpacing: {
    marginBottom: 20,
  },
};

export default withStyles(styles)(CoreProspectExportTable);
