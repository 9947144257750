import React from 'react';
import { Editor, EDIT_TYPE } from 'react-map-gl-draw';
import { getEditHandleStyle, getFeatureStyle } from './styles';
import { DrawingFeatures, DrawingMode, OnDrawComplete } from './types';

type Props = {
  onDrawComplete: OnDrawComplete;
  drawingMode: DrawingMode;
  drawingFeatures: DrawingFeatures;
};

const THROTTLE_MS = 200;
const DRAG_TO_DRAW = true;
const CLICK_RADIUS = 0;

export const MapboxGLDraw: React.FC<Props> = ({
  onDrawComplete,
  drawingMode,
  drawingFeatures,
}) => {
  return (
    <Editor
      modeConfig={{
        dragToDraw: DRAG_TO_DRAW,
        throttleMs: THROTTLE_MS,
      }}
      featureStyle={getFeatureStyle}
      editHandleStyle={getEditHandleStyle}
      clickRadius={CLICK_RADIUS}
      features={drawingFeatures}
      onUpdate={({ data: features, editType }) => {
        if (editType === EDIT_TYPE.ADD_FEATURE) {
          onDrawComplete(features[0]);
        }
      }}
      mode={drawingMode}
    />
  );
};
