import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { DealGetAllOptions } from '../types';
import { useAllDealsPortfolioAssets } from './useAllDealsPortfolioAssets';

export const useAllDealsPortfolioAssetsByTaxId = (opts: DealGetAllOptions) => {
  const assets = useAllDealsPortfolioAssets(opts);

  const assetsByTaxId = useMemo(() => {
    const assetsWithTaxAssessor = assets?.filter((a) =>
      Boolean(a.cherreAsset?.taxAssessorId)
    );
    return keyBy(assetsWithTaxAssessor, 'cherreAsset.taxAssessorId');
  }, [assets]);

  return assetsByTaxId;
};

export type DealsPortfolioAssetsByTaxId = ReturnType<
  typeof useAllDealsPortfolioAssetsByTaxId
>;
