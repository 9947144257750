/**
 * @generated SignedSource<<6b7e70ac69c259785cfd1e9345b29320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VariableInput = {
  name: string;
  value: string;
};
export type createModelMutation$variables = {
  connectorName?: string | null;
  displayName?: string | null;
  egressedTables?: ReadonlyArray<string | null> | null;
  envs?: ReadonlyArray<VariableInput | null> | null;
  preconfiguredModelName: string;
  schedule: string;
};
export type createModelMutation$data = {
  readonly createModel: {
    readonly name: string;
  };
};
export type createModelMutation = {
  response: createModelMutation$data;
  variables: createModelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectorName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "egressedTables"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envs"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "preconfiguredModelName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schedule"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "connectorName",
        "variableName": "connectorName"
      },
      {
        "kind": "Variable",
        "name": "displayName",
        "variableName": "displayName"
      },
      {
        "kind": "Variable",
        "name": "egressedTables",
        "variableName": "egressedTables"
      },
      {
        "kind": "Variable",
        "name": "envs",
        "variableName": "envs"
      },
      {
        "kind": "Variable",
        "name": "preconfiguredModelName",
        "variableName": "preconfiguredModelName"
      },
      {
        "kind": "Variable",
        "name": "schedule",
        "variableName": "schedule"
      }
    ],
    "concreteType": "Model",
    "kind": "LinkedField",
    "name": "createModel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createModelMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "createModelMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "03aa3840bd1f7f3888940f348e51e824",
    "id": null,
    "metadata": {},
    "name": "createModelMutation",
    "operationKind": "mutation",
    "text": "mutation createModelMutation(\n  $preconfiguredModelName: String!\n  $schedule: String!\n  $envs: [VariableInput]\n  $displayName: String\n  $connectorName: String\n  $egressedTables: [String]\n) {\n  createModel(preconfiguredModelName: $preconfiguredModelName, schedule: $schedule, envs: $envs, displayName: $displayName, connectorName: $connectorName, egressedTables: $egressedTables) {\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "35e69091549461e791d0cd3cc5b6c806";

export default node;
