import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function RangeSelection(props) {
  const {
    filterValue,
    setFilterValue,
    filter: { filter_type_props = {} },
    data: { options },
    classes,
  } = props;

  const min = (filterValue && filterValue.min) || '';
  const max = (filterValue && filterValue.max) || '';

  const { minLabel = 'From', maxLabel = 'To' } = filter_type_props;

  return (
    <div className={classes.root}>
      <div className={classes.inputs}>
        <FormControl className={classes.textField}>
          <InputLabel>{minLabel}</InputLabel>
          <Select
            variant='outlined'
            value={min}
            onChange={(e) =>
              setFilterValue({ ...filterValue, min: e.target.value })
            }
          >
            {options &&
              options.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl className={classes.textField}>
          <InputLabel>{maxLabel}</InputLabel>
          <Select
            variant='outlined'
            label={maxLabel}
            value={max}
            onChange={(e) =>
              setFilterValue({ ...filterValue, max: e.target.value })
            }
          >
            {options.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

RangeSelection.propTypes = {
  filterValue: PropTypes.object,
  data: PropTypes.object,
  setFilterValue: PropTypes.func,
  filter: PropTypes.object,
  classes: PropTypes.object,
};

// default setFilterValue is needed so an error is not thrown when rendering filter in preview
// in filter editing and so that it will show the label when editing
RangeSelection.defaultProps = {
  data: {
    options: [],
  },
  setFilterValue: (values) => {
    values;
  },
};

export default withStyles(styles)(RangeSelection);
