export const cherrePropertyUseHierarchical = [
  { lookup_code: 1, parent_id: 'root', lookup_value: 'Agriculture' },
  { lookup_code: 2, parent_id: 'root', lookup_value: 'Hotel' },
  { lookup_code: 3, parent_id: 'root', lookup_value: 'Industrial' },
  { lookup_code: 4, parent_id: 'root', lookup_value: 'Land' },
  { lookup_code: 5, parent_id: 'root', lookup_value: 'Multi-Family' },
  { lookup_code: 6, parent_id: 'root', lookup_value: 'Office' },
  { lookup_code: 7, parent_id: 'root', lookup_value: 'Other' },
  { lookup_code: 8, parent_id: 'root', lookup_value: 'Residential' },
  { lookup_code: 9, parent_id: 'root', lookup_value: 'Retail' },
  { lookup_code: 10, parent_id: '1', lookup_value: 'Agriculture' },
  { lookup_code: 11, parent_id: '1', lookup_value: 'Timberland' },
  { lookup_code: 12, parent_id: '2', lookup_value: 'Hotel' },
  { lookup_code: 13, parent_id: '2', lookup_value: 'Motels' },
  { lookup_code: 14, parent_id: '3', lookup_value: 'Industrial' },
  { lookup_code: 15, parent_id: '3', lookup_value: 'Manufacturing' },
  { lookup_code: 16, parent_id: '3', lookup_value: 'Storage' },
  { lookup_code: 17, parent_id: '4', lookup_value: 'Vacant' },
  { lookup_code: 18, parent_id: '4', lookup_value: 'Vacant - Agricultural' },
  { lookup_code: 19, parent_id: '4', lookup_value: 'Vacant - Commercial' },
  { lookup_code: 20, parent_id: '4', lookup_value: 'Vacant - Industrial' },
  { lookup_code: 21, parent_id: '4', lookup_value: 'Vacant - Residential' },
  { lookup_code: 22, parent_id: '5', lookup_value: 'Apartments' },
  { lookup_code: 23, parent_id: '5', lookup_value: 'Multi-Family' },
  { lookup_code: 24, parent_id: '6', lookup_value: 'Medical Services' },
  { lookup_code: 25, parent_id: '6', lookup_value: 'Mixed Use' },
  { lookup_code: 26, parent_id: '6', lookup_value: 'Office' },
  { lookup_code: 27, parent_id: '7', lookup_value: 'Commercial Condo' },
  { lookup_code: 28, parent_id: '7', lookup_value: 'Educational' },
  { lookup_code: 29, parent_id: '7', lookup_value: 'General Commercial' },
  { lookup_code: 30, parent_id: '7', lookup_value: 'Government' },
  { lookup_code: 31, parent_id: '7', lookup_value: 'Historical' },
  { lookup_code: 32, parent_id: '7', lookup_value: 'Hospital' },
  { lookup_code: 33, parent_id: '7', lookup_value: 'Misc' },
  { lookup_code: 34, parent_id: '7', lookup_value: 'Parking' },
  { lookup_code: 35, parent_id: '7', lookup_value: 'Services' },
  { lookup_code: 36, parent_id: '7', lookup_value: 'Transportation' },
  { lookup_code: 37, parent_id: '7', lookup_value: 'Unknown' },
  { lookup_code: 38, parent_id: '7', lookup_value: 'Utilites' },
  { lookup_code: 39, parent_id: '8', lookup_value: 'Cooperative' },
  { lookup_code: 40, parent_id: '8', lookup_value: 'Misc' },
  { lookup_code: 41, parent_id: '8', lookup_value: 'Mobile Homes' },
  {
    lookup_code: 42,
    parent_id: '8',
    lookup_value: 'Residential - Duplex/Triplex/Quadplex',
  },
  { lookup_code: 43, parent_id: '8', lookup_value: 'Residential Condo' },
  {
    lookup_code: 44,
    parent_id: '8',
    lookup_value: 'Single Family Residence',
  },
  { lookup_code: 45, parent_id: '9', lookup_value: 'Attractions' },
  { lookup_code: 46, parent_id: '9', lookup_value: 'Recreation' },
  { lookup_code: 47, parent_id: '9', lookup_value: 'Retail' },
];

export const cherrePropertyUseHierarchicalMap = Object.fromEntries(
  cherrePropertyUseHierarchical.map((i) => [i.lookup_code, i.lookup_value])
);
