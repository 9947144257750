export const RANGE_SELECTION = 'RangeSelectionFilter';
export const RANGE_INPUT = 'RangeInputFilter';
export const RANGE_DATE = 'RangeDateFilter';
export const TEXT_INPUT = 'TextInputFilter';
export const MULTIPLE_SELECTION = 'MultiSelectFilter';
export const BOOLEAN_SELECTION = 'BooleanFilter';
export const HIERARCHICAL_SELECTION = 'HierarchicalFilter';
export const SELECT_FILTER = 'SelectFilter';
export const RECORDED_OWNER_TYPES_SELECTION = 'RecordedOwnerTypesSelection';
export const UNMASKED_OWNER_TYPES_SELECTION = 'UnmaskedOwnerTypesSelection';
export const SECTORS_SELECTION = 'SectorsSelection';
export const DEAL_FILTER = 'DealFilter';

export default [
  RANGE_SELECTION,
  RANGE_INPUT,
  TEXT_INPUT,
  MULTIPLE_SELECTION,
  BOOLEAN_SELECTION,
  HIERARCHICAL_SELECTION,
  RANGE_DATE,
  SELECT_FILTER,
  SECTORS_SELECTION,
  DEAL_FILTER,
];
