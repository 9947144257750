import React, { forwardRef, useImperativeHandle } from 'react';
import { Popover, IconButton, Tooltip } from '@material-ui/core';
import Bookmark from '@material-ui/icons/Bookmark';

function ListIcon(
  {
    disabled,
    disableText,
    children,
    title,
    component,
    onOpen,
    onClose,
    ...otherProps
  },
  ref
) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  useImperativeHandle(ref, () => ({
    close: handleClose,
  }));

  const open = Boolean(anchorEl);

  return (
    <div {...otherProps}>
      {component ? (
        <span onClick={handleClick}>{component}</span>
      ) : disabled ? (
        <Tooltip title={disableText}>
          <div>
            <IconButton color='primary' size='small' disabled={disabled}>
              <Bookmark />
            </IconButton>
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={title}>
          <IconButton color='primary' size='small' onClick={handleClick}>
            <Bookmark />
          </IconButton>
        </Tooltip>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </div>
  );
}

export default forwardRef(ListIcon);
