import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import escapeRegExp from 'lodash/escapeRegExp';

import { getMaskedValue } from 'src/utils/mask';
import Page from './components/Page';
import PageContent from './components/PageContent';
import Toolbar from './components/Toolbar';
import Table from './components/Table';

function filterData(data, searchValue, filters) {
  let pattern;

  try {
    pattern = new RegExp(escapeRegExp(searchValue), 'gi');
  } catch (e) {
    return data;
  }

  const withSearchValue = data.filter((row) => {
    return values(row).some((value) => String(value || '').match(pattern));
  });

  if (filters) {
    return withSearchValue.filter((item) => {
      return Object.keys(filters).every((field) => {
        const value = filters[field].value;

        if (value === '' || value == null) {
          return true;
        }

        return item[field] === value;
      });
    });
  }

  return withSearchValue;
}

function TablePage({
  disableSearch,
  isAddButtonDisabled,
  onDelete,
  onAddButtonClick,
  onBackButtonClick,
  onSearch,
  onFilter,
  onSort,
  onChangePage,
  onChangeRowsPerPage,
  refresh,
  params,
  actions,
  stores,
  local = true,
  store: {
    data,
    columns,
    order,
    orderBy,
    page,
    rowsPerPage,
    searchValue = '',
    filters,
  },
  total = undefined,
}) {
  return (
    <Page>
      <PageContent>
        <Toolbar
          searchValue={searchValue}
          disableSearch={disableSearch}
          isAddButtonDisabled={isAddButtonDisabled}
          onSearch={onSearch}
          onAddButtonClick={onAddButtonClick}
          onBackButtonClick={onBackButtonClick}
          stores={stores}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {Object.keys(filters).map((field, i) => {
            const filter = filters[field];

            return (
              <FormControl key={i} style={{ width: 200 }}>
                <InputLabel>{filter.label}</InputLabel>
                <Select
                  value={filter.value}
                  name={field}
                  onChange={(event) => onFilter(field, event.target.value)}
                >
                  <MenuItem key={field} value={''}>
                    any
                  </MenuItem>
                  {filter.options.map((option, i) => {
                    const value = getMaskedValue(option, filter.mask);

                    return (
                      <MenuItem key={`${field}-${i}`} value={option}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          })}
        </div>
        <Table
          onDelete={onDelete}
          onSort={onSort}
          refresh={refresh}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          data={local ? filterData(data, searchValue, filters) : data}
          columns={columns}
          filters={local ? filters : {}}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          params={params}
          actions={actions}
          isAddButtonDisabled={isAddButtonDisabled}
          stores={stores}
          total={total}
        />
      </PageContent>
    </Page>
  );
}

TablePage.propTypes = {
  disableSearch: PropTypes.bool,
  isAddButtonDisabled: PropTypes.func,
  refresh: PropTypes.func,
  onDelete: PropTypes.func,
  onSearch: PropTypes.func,
  onAddButtonClick: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  onSort: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  params: PropTypes.object,
  stores: PropTypes.object,
  store: PropTypes.shape({
    data: PropTypes.array,
    columns: PropTypes.array,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }),
  local: PropTypes.bool,
  total: PropTypes.number,
};

export default TablePage;
