import { useCherrePartialState } from '@cherre-frontend/data-fetching';
import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import constants from 'src/const';
import RemindersDrawer from '../RemindersDrawer';
import { reminderDrawerState } from '../RemindersDrawer/recoil';
import NavigationDrawer from './components/Drawers/NavigationDrawer';
import EmulatedSessionBar from './components/EmulatedSessionBar';
import RegularNavBar from './components/NavBars/RegularNavBar';
import { FeatureFlagGate } from 'src/hooks/useFeatureFlag';

const { NAV_BAR_HEIGHT } = constants;
const EMULATED_SESSION_BAR_HEIGHT = 38;

interface NavBarProps {
  isEmulatedSession?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ isEmulatedSession }) => {
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] =
    React.useState(false);
  const [reminderDrawer, setReminderDrawer] =
    useCherrePartialState(reminderDrawerState);

  return (
    <div className='document_navbar'>
      {isEmulatedSession && <EmulatedSessionBar />}
      <AppBar
        position='fixed'
        style={{
          height: NAV_BAR_HEIGHT,
          top: isEmulatedSession ? EMULATED_SESSION_BAR_HEIGHT : 0,
        }}
        color='primary'
      >
        <RegularNavBar
          toggleNavigationDrawer={() =>
            setIsNavigationDrawerOpen(!isNavigationDrawerOpen)
          }
          toggleRemindersDrawer={() =>
            setReminderDrawer({
              isOpen: !reminderDrawer?.isOpen,
            })
          }
        />
        <NavigationDrawer
          isOpen={isNavigationDrawerOpen}
          onClose={() => setIsNavigationDrawerOpen(false)}
        />
        <FeatureFlagGate flag='DSPReminders'>
          <RemindersDrawer />
        </FeatureFlagGate>
      </AppBar>
      <div
        style={{
          height: isEmulatedSession
            ? NAV_BAR_HEIGHT + EMULATED_SESSION_BAR_HEIGHT
            : NAV_BAR_HEIGHT,
        }}
      />
    </div>
  );
};

export default NavBar;
