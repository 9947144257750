import { selector } from '@cherre-frontend/data-fetching';
import { connectorDataSelector } from '../../connectorLanding/recoil';
import { selectedConnectorName } from 'src/products/connector/pages/connector-details/recoil';
import { getTableNameFromFQN } from 'src/products/connector/pages/connector-details/components/DataValidation/utils/getTableNameFromFQN';

export const selectedConnectorPublishedTables = selector<string[]>({
  key: 'DOM-SELECTED-CONNECTOR-PUBLISHED-TABLES',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<string[]> => {
      const connectors = get(connectorDataSelector());
      const connectorName = get(selectedConnectorName);

      if (!connectorName) {
        return [];
      }

      const connector = connectors.data.find(
        (connector) => connector.name === connectorName
      );

      if (!connector) {
        return [];
      }

      const publishedTables = connector.models.reduce(
        (acc: string[], model) => {
          return acc.concat(model.publishedTables);
        },
        []
      );

      //We only want to show the table name so remove the prefixes provided by the DOM
      const publishedTableNames = publishedTables
        .map((fqn) => getTableNameFromFQN(fqn))
        .filter((tableName): tableName is string => tableName !== null);

      return publishedTableNames;
    },
});
export const aggregatedConnectorPublishedTables = selector<string[]>({
  key: 'DOM-CONNECTOR-AGGREGATED-PUBLISHED-TABLES',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<string[]> => {
      const connectors = get(connectorDataSelector());

      const allPublishedTables = connectors.data.reduce(
        (acc: string[], connector) => {
          const connectorTables = connector.models.reduce(
            (modelAcc: string[], model) => {
              return modelAcc.concat(model.publishedTables);
            },
            []
          );
          return acc.concat(connectorTables);
        },
        []
      );
      //We only want to show the table name so remove the prefixes provided by the DOM
      const publishedTableNames = allPublishedTables
        .map((fqn) => getTableNameFromFQN(fqn))
        .filter((tableName): tableName is string => tableName !== null);
      return publishedTableNames;
    },
});
