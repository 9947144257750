import { greaterThan } from 'src/products/shell/forms/validators';

export default function validate(value, allow_negative_values) {
  if (!value) {
    return {};
  }

  const { min, max } = value;
  const errors = {};

  if (Number.isFinite(+min)) {
    if (+min < 0 && !allow_negative_values) {
      errors.min = `${min} should be positive`;
    }
  }

  if (Number.isFinite(+max)) {
    if (+max < 0 && !allow_negative_values) {
      errors.max = `${max} should be positive`;
    }
  }

  if (min && max && [+min, +max].every(isFinite)) {
    errors.max = greaterThan(min, max);
  }

  return errors;
}
