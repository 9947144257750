import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SearchSelectOptions from './SearchSelectOptions';
import PropTypes from 'prop-types';

const styles = {
  root: {
    padding: '18px 20px',
    maxHeight: 400,
    overflow: 'auto',
  },
  input: {
    border: 'none',
    background: '#f5f5f5',
    padding: 5,
    outline: 'none',
    borderRadius: '3px',
    width: '100%',
    color: '#757575',
    fontSize: 14,
    marginBottom: 10,
  },
};

export class SelectSearchOptionsContainer extends Component {
  state = {
    options: [],
    filteredOptions: [],
    selectedItemIndex: 0,
    selectedValues: [],
    searchValue: '',
  };

  componentWillMount() {
    this.setState({
      options: this.props.options,
      filteredOptions: this.props.options,
      selectedValues: this.props.value || [],
    });
  }

  handleInputOnChange = (e) => {
    const { options } = this.state;
    const { optionLabelKey } = this.props;

    const newFilteredOptions = options.filter((option) =>
      option[optionLabelKey]
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    );
    this.setState({
      filteredOptions: newFilteredOptions,
      searchValue: e.target.value,
      selectedItemIndex: 0,
    });
  };

  handleOptionOnClick = (value) => {
    const { onChange, close, multiple } = this.props;

    if (!multiple) {
      onChange(value);
      close();
    }

    if (multiple) {
      const valueIndex = this.state.selectedValues.indexOf(value);
      const newValuesArray = [...this.state.selectedValues];

      if (valueIndex < 0) {
        newValuesArray.push(value);

        this.setState({
          selectedValues: newValuesArray,
        });

        onChange(newValuesArray);
      } else {
        newValuesArray.splice(valueIndex, 1);

        this.setState({
          selectedValues: newValuesArray,
        });

        onChange(newValuesArray);
      }
    }
  };

  handleKeyPress = (event) => {
    const { selectedItemIndex } = this.state;

    let nextSelectedItemIndex;

    if (event.key === 'Enter') {
      return this.handleEnterKeyPress();
    }

    if (event.key === 'ArrowUp') {
      nextSelectedItemIndex = Math.max(0, selectedItemIndex - 1);

      this.setState({
        selectedItemIndex: nextSelectedItemIndex,
      });
    } else if (event.key === 'ArrowDown') {
      nextSelectedItemIndex = Math.min(
        this.state.filteredOptions.length - 1,
        selectedItemIndex + 1
      );

      this.setState({
        selectedItemIndex: nextSelectedItemIndex,
      });
    }
  };

  handleEnterKeyPress = () => {
    const { selectedItemIndex } = this.state;

    const option = this.state.filteredOptions[selectedItemIndex];

    this.handleOptionOnClick(option.value);
  };

  render() {
    const { classes, close, optionLabelKey } = this.props;

    const { filteredOptions, selectedItemIndex, selectedValues, searchValue } =
      this.state;

    return (
      <div>
        <div className={classes.root}>
          <input
            autoFocus
            onKeyDown={this.handleKeyPress}
            onChange={(e) => this.handleInputOnChange(e)}
            placeholder='Search '
            value={searchValue}
            className={classes.input}
            id='js-searches-select-search-input'
          />
          <SearchSelectOptions
            optionLabelKey={optionLabelKey}
            options={filteredOptions}
            selectedItemIndex={selectedItemIndex}
            handleOptionOnClick={this.handleOptionOnClick}
            handleKeyPress={this.handleKeyPress}
            close={close}
            value={selectedValues}
          />
        </div>
      </div>
    );
  }
}

SelectSearchOptionsContainer.defaultProps = {
  optionLabelKey: 'label',
};

SelectSearchOptionsContainer.propTypes = {
  options: PropTypes.array,
  close: PropTypes.func,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withStyles(styles)(SelectSearchOptionsContainer);
