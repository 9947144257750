import React from 'react';

import MuiTablePagination from '@material-ui/core/TablePagination';

import { AutoResizer } from 'react-base-table';
import Table, { TableProps } from 'src/components/BaseTable';

const TablePagination = ({
  rowsPerPage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
  totalCount,
  page,
  isLoading,
  hasNextPage,
  hasPreviousPage,
  onChangePage,
  labelRowsPerPage,
}) => (
  <MuiTablePagination
    rowsPerPageOptions={rowsPerPageOptions}
    component='div'
    count={totalCount}
    rowsPerPage={rowsPerPage}
    page={page}
    onChangePage={(_e, newPage) => onChangePage({ index: newPage })}
    onChangeRowsPerPage={onChangeRowsPerPage}
    nextIconButtonProps={{
      disabled: isLoading || !hasNextPage,
    }}
    backIconButtonProps={{
      disabled: isLoading || !hasPreviousPage,
    }}
    labelRowsPerPage={labelRowsPerPage}
  />
);

export type PagedTableProps<T> = Omit<
  TableProps<T>,
  'isSelectAllChecked' | 'data' | 'columns' | 'width' | 'height' | 'isLoading'
> & {
  isLoading?: boolean;
  columns:
    | ((opts: { height: number; width: number }) => TableProps['columns'])
    | TableProps['columns'];
  isAllRowsSelected?: boolean;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalCount?: number;
  page?: number;
  onChangePage?: (opts: { index: number }) => void;
  onSelectAllChange?: (checked: boolean) => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  items?: TableProps['data'];
  emptyText?: string;
  onChangeOrder?: (orderBy: {
    field: React.Key;
    direction: 'asc' | 'desc';
  }) => void;
  orderBy?: React.Key;
  orderByDirection?: 'asc' | 'desc';
  showPagination?: boolean;
  labelRowsPerPage?: string;
};

function PagedTable<T = unknown>({
  rowKey = 'bbl',
  selectedRowKeys = [],
  isAllRowsSelected,
  rowsPerPage = 25,
  rowsPerPageOptions = [25, 50, 100],
  onChangeRowsPerPage,
  totalCount = -1,
  page = 0,
  onChangePage,
  onSelect,
  onSelectAllChange,
  hasNextPage = false,
  hasPreviousPage = false,
  onChangeOrder,
  columns = [],
  items = [],
  isLoading = false,
  emptyText,
  orderBy,
  orderByDirection,
  showPagination = true,
  selection = true,
  stickyLeft,
  fixed = true,
  rowRenderer,
  headerRenderer,
  headerHeight,
  overscanRowCount,
  labelRowsPerPage,
}: PagedTableProps<T>) {
  return (
    <>
      <div
        style={{
          opacity: isLoading ? 0.4 : 1,
          overflow: 'auto',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <AutoResizer>
          {({ width, height }) => (
            <Table
              isLoading={isLoading}
              stickyLeft={stickyLeft}
              isSelectAllChecked={isAllRowsSelected}
              selection={selection}
              width={width}
              height={height}
              columns={
                typeof columns === 'function'
                  ? columns({ width, height })
                  : columns
              }
              data={items}
              rowKey={rowKey}
              selectedRowKeys={selectedRowKeys}
              onSelect={onSelect}
              onSelectAll={onSelectAllChange}
              sortBy={
                orderBy && orderByDirection
                  ? {
                      key: orderBy,
                      order: orderByDirection,
                    }
                  : undefined
              }
              onColumnSort={({ key, order }) => {
                onChangeOrder &&
                  onChangeOrder({
                    field: key,
                    direction: order,
                  });
              }}
              fixed={fixed}
              rowRenderer={rowRenderer}
              headerRenderer={headerRenderer}
              headerHeight={headerHeight}
              overscanRowCount={overscanRowCount}
            />
          )}
        </AutoResizer>
        {items.length === 0 && isLoading === false && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 300,
            }}
          >
            {emptyText || 'No rows found'}
          </div>
        )}
      </div>
      {showPagination ? (
        <div
          style={{
            borderTop: '1px solid rgb(238, 238, 238)',
          }}
        >
          <TablePagination
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangeRowsPerPage={onChangeRowsPerPage}
            totalCount={totalCount}
            page={page}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            onChangePage={onChangePage || function () {}}
            labelRowsPerPage={labelRowsPerPage}
          />
        </div>
      ) : null}
    </>
  );
}

export default PagedTable;
