import React from 'react';
import Toolbar from './components/Toolbar';
import Paper from '@material-ui/core/Paper';
import grey from '@material-ui/core/colors/grey';
import { Typography } from '@material-ui/core';

export const PaperPage: React.FC<{
  backButtonClick?: () => void;
  pageTitle?: string;
  pageDescription?: string;
}> = ({ backButtonClick, pageTitle, pageDescription, children }) => {
  return (
    <div
      style={{
        flex: 1,
        width: '100',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: grey['200'],
        color: grey['800'],
        overflow: pageTitle ? 'auto' : 'visible',
        padding: 5,
      }}
    >
      <div
        style={{
          maxWidth: 780,
          minWidth: 400,
          width: '100%',
        }}
      >
        {pageTitle && (
          <Toolbar backButtonClick={backButtonClick} title={pageTitle} />
        )}
        {pageDescription && (
          <Typography style={{ paddingBottom: 29 }}>
            {pageDescription}
          </Typography>
        )}
        <Paper
          style={{
            padding: '17px 17px',
          }}
        >
          {children}
        </Paper>
      </div>
    </div>
  );
};
