import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';
import Done from '@material-ui/core/SvgIcon';

const styles = {
  minHeight: '49px',
};

const dynamicText = function (props) {
  const {
    input,
    label,
    fullWidth,
    multiline,
    placeholder,
    inputClassName,
    formControlClassName,
    meta: { touched, error },
    showAdornment,
    maskFormat,
    maskPlaceholder,
    mask,
    autoFocus,
    disabled,
  } = props;

  return (
    <FormControl
      className={formControlClassName}
      fullWidth={fullWidth}
      style={{ ...props.attr.style, ...styles }}
      error={touched && !!error}
      required={props.required}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        {...input}
        disabled={disabled}
        autoFocus={autoFocus}
        className={inputClassName}
        multiline={multiline}
        placeholder={placeholder}
        // type={type}
        inputComponent={maskFormat && NumberFormat}
        name={input.name}
        inputProps={{
          format: maskFormat,
          placeholder: placeholder || maskPlaceholder,
          mask,
        }}
        endAdornment={
          showAdornment &&
          !error && (
            <InputAdornment position='end'>
              {
                <IconButton tabIndex='-1'>
                  <Done color='#6cc351' />
                </IconButton>
              }
            </InputAdornment>
          )
        }
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default dynamicText;
