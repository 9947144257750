import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

const TreppLoanDataModalTableHeader = ({ item }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Typography variant='body2' style={{ color: grey[700] }}>
          {`At Securitization (${item.date.format('MMM DD, YYYY')}) `}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='body2' style={{ color: grey[700] }}>
          Preceding Fiscal Year
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TreppLoanDataModalTableHeader;
