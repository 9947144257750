import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { CustomListItem, ListItemTextStyles } from '../TreeCustomComponents';
import grey from '@material-ui/core/colors/grey';
import ExpansionOrCheckbox from './ExpansionOrCheckbox';

const ExpansionIconLabelListItem = (props) => {
  const {
    idData,
    node,
    depth,
    disabled,
    bold,
    selectedNode,
    handleLabelClick,
  } = props;

  if (idData[node] === undefined) {
    return null;
  }

  return (
    <div
      id='js-expansion-icon-label-list-item'
      style={{
        backgroundColor: node === selectedNode ? grey[300] : '',
      }}
    >
      <CustomListItem key={node} component='div'>
        <ExpansionOrCheckbox {...props} />
        <ListItemText
          onClick={() => handleLabelClick(idData[node])}
          disableTypography
          primary={`${idData[node].label}`}
          style={Object.assign(
            { display: 'flex', alignItems: 'center' },
            ListItemTextStyles(
              depth,
              bold(idData[node]),
              disabled[node] === true
            ).normal
          )}
        />
      </CustomListItem>
    </div>
  );
};

export default ExpansionIconLabelListItem;
