import { CustomCollapse } from './TreeCustomComponents';
import React from 'react';
import TreeView from '..';

const CollapsibleContent = (props) => {
  const { parentChildren, node, expanded, depth } = props;

  if (parentChildren[node] && parentChildren[node].length > 0) {
    return (
      <CustomCollapse in={expanded[node] === true} timeout='auto' unmountOnExit>
        <TreeView {...props} parentNodeId={node} depth={depth + 1} />
      </CustomCollapse>
    );
  } else {
    return null;
  }
};

export default CollapsibleContent;
