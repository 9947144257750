import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'src/components/ButtonProgress';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

const useStyles = makeStyles({
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
});

interface ClearSessionsDialogProps {
  open: boolean;
  onRequestClose: () => void;
  data: {
    refresh: () => void;
    row: { migrationName: string };
    as: string;
  };
}

const ClearSessions: React.FC<ClearSessionsDialogProps> = (props) => {
  const {
    onRequestClose,
    open,
    data: { refresh },
  } = props;

  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const handleClick = React.useCallback(() => {
    setLoading(true);

    fetch(`/api/v1/migrations/flush-sessions`, { method: 'POST' })
      .then(async (response) => {
        if (response.status > 200) {
          const { errors } = await response.json();
          showErrorPopupNotification(errors?.[0]?.message || response.body, 0);
        } else {
          showSuccessPopupNotification(
            'User Sessions were successfully cleared'
          );
        }
      })
      .then(onRequestClose)
      .then(refresh)
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, onRequestClose]);

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle style={{ textTransform: 'capitalize' }}>
        Clear Sessions
      </DialogTitle>
      <DialogContent>
        Are you sure you clear <b>All User Sessions</b> ?
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color='primary'>
          close
        </Button>

        <Button
          onClick={handleClick}
          color='primary'
          variant='contained'
          loading={loading}
        >
          clear sessions
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClearSessions;
