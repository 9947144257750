import React from 'react';
import SecurityIcon from '@material-ui/icons/Security';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import grey from '@material-ui/core/colors/grey';
import { Typography } from '@material-ui/core';

import { toggleLayer } from './utils';
import { primaryColor } from 'src/const/theme';

const icons = {
  security: SecurityIcon,
  localAtm: LocalAtmIcon,
  supervisorAccount: SupervisorAccountIcon,
};

const RadioSelector = ({ group, layer, onChange, onLayerSelect }) => {
  const { label, name, icon } = layer;
  const selected = group.checkedLayerName === name;
  const Icon = icons[icon];

  return (
    <div
      id={`core-prospect-heatmap-selector-${name}`}
      data-testid={`core-prospect-heatmap-selector-${name}`}
      key={name}
      onClick={() => {
        onLayerSelect && onLayerSelect(layer.name);
        toggleLayer(group, onChange, layer.name, true, true);
      }}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        border: selected
          ? `3px solid ${primaryColor.main}`
          : `1px solid ${grey[400]}`,
        marginBottom: 20,
        padding: selected ? 6 : 8,
        borderRadius: 5,
        cursor: selected ? undefined : 'pointer',
      }}
    >
      {icon && <Icon style={{ marginRight: 10 }} />}
      <Typography variant='body1'>{label}</Typography>
    </div>
  );
};

export default RadioSelector;
