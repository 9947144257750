import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

import { toggleLayer } from './utils';

const CheckboxSelector = ({ group, layer, onChange, onLayerSelect }) => {
  const { label, name, checked, checkedLayerName, layers } = layer;

  const control = (
    <Checkbox
      style={{ padding: '5px 10px' }}
      disableRipple
      color='primary'
      value={name}
      checked={checked ? true : false}
      onChange={(e) => {
        const checked = e.target.checked;

        if (checked) {
          onLayerSelect && onLayerSelect(layer.name);
        }

        if (layers && layers[0]) {
          toggleLayer(
            group,
            onChange,
            layers[0].name,
            checked,
            false,
            layer.name
          );
        } else {
          toggleLayer(group, onChange, layer.name, checked);
        }
      }}
    />
  );

  return (
    <>
      <FormControlLabel control={control} value={name} label={label} />
      {checked && layers && (
        <Select
          native
          value={checkedLayerName || ''}
          onChange={(e) => {
            toggleLayer(group, onChange, e.target.value, true, false, name);
          }}
          name={name}
          input={<Input />}
          style={{ marginLeft: 35 }}
        >
          {layers.map((layer, index) => {
            return (
              <option key={index} value={layer.name}>
                {layer.label}
              </option>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default CheckboxSelector;
