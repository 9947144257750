import { gql, GQL } from 'src/utils/recoil/gql';
import axios from 'axios';
import {
  selectorFamilyWithDefaultValue,
  selectorWithDefaultValue,
} from 'src/utils/recoil/withDefaultValue';
import * as T from '../types';
import { TaxAssessorsQuery, TaxAssessorsQueryVariables } from './__generated__';
import { atomFamily, selectorFamily } from 'recoil';
import { remoteToLocalDeal } from '../formatters';

export const dealStagesSelector = selectorWithDefaultValue({
  defaultValue: [],
  key: 'CORE-EXPLORE/DEAL/STAGES',
  get: async () => {
    const response = await axios.get<T.DealStagesResponse>(
      `/api/v1/deals/stages/`
    );
    return response.data;
  },
});

export const dealBrokersSelector = selectorWithDefaultValue<T.BrokersResponse>({
  defaultValue: [],
  key: 'CORE-EXPLORE/DEAL/BROKERS',
  get: async () => {
    const response = await axios.get<T.BrokersResponse>(
      `/api/v1/deals/brokers`
    );
    return response.data;
  },
});

export const dealSellersSelector = selectorWithDefaultValue<T.SellersResponse>({
  defaultValue: [],
  key: 'CORE-EXPLORE/DEAL/SELLERS',
  get: async () => {
    const response = await axios.get<T.SellersResponse>(
      `/api/v1/deals/sellers`
    );
    return response.data;
  },
});

export const dealILOsSelector = selectorWithDefaultValue<T.ILOsResponse>({
  defaultValue: [],
  key: 'CORE-EXPLORE/DEAL/ILOS',
  get: async () => {
    const response = await axios.get<T.ILOsResponse>(`/api/v1/deals/ilos`);
    return response.data;
  },
});

export const dealLeadersSelector =
  selectorWithDefaultValue<T.DealLeadersResponse>({
    defaultValue: [],
    key: 'CORE-EXPLORE/DEAL/LEADERS',
    get: async () => {
      const response = await axios.get<T.DealLeadersResponse>(
        `/api/v1/deals/leaders`
      );
      return response.data;
    },
  });

export const dealPortfolioSelectorFamily = selectorFamily<
  T.PortfolioDeal | undefined,
  number
>({
  key: 'CORE-EXPLORE/PORTFOLIO/INFO',
  get: (dealId: number) => async () => {
    const response = await axios.get(`/api/v1/deals/${dealId}`);
    return response.data;
  },
});

export const dealPortfolioAllSelector = selectorFamilyWithDefaultValue<
  T.PortfolioDeal[],
  Readonly<T.DealGetAllOptions>
>({
  defaultValue: [],
  key: 'CORE-EXPLORE/DEAL-PORTFOLIO/ALL',
  get: (options) => async () => {
    const response = await axios.post<T.PortfolioDeal[]>(
      `/api/v1/deals/search`,
      options
    );
    return response.data;
  },
});

export const dealPortfolioByTaxIdSelector = selectorFamilyWithDefaultValue({
  defaultValue: null,
  key: 'CORE-EXPLORE/DEAL-PORTFOLIO/TAX-ID',
  get: (id: number) => async () => {
    try {
      const response = await axios.get<T.PortfolioDeal>(
        `/api/v1/deals/tax/${id}`
      );
      return response.data;
    } catch {
      return null;
    }
  },
});

export const dealTeamsSelector = selectorWithDefaultValue<T.DealTeam[]>({
  defaultValue: [],
  key: 'CORE-EXPLORE/DEAL-PORTFOLIO/TEAMS',
  get: () => axios.get<T.DealTeam[]>('/api/v1/deals/teams').then((r) => r.data),
});

export const dealInvestmentTypesSelector = selectorWithDefaultValue<
  T.InvestmentType[]
>({
  defaultValue: [],
  key: 'CORE-EXPLORE/DEAL-PORTFOLIO/INVESTMENT-TYPES',
  get: () =>
    axios
      .get<T.InvestmentType[]>('/api/v1/deals/investmentTypes')
      .then((r) => r.data),
});

export const localDeal = atomFamily<T.LocalDeal | undefined, number | 'new'>({
  key: 'LOCAL-DEAL-ATOM',
  default: selectorFamily<T.LocalDeal | undefined, number | 'new'>({
    key: 'LOCAL-DEAL-SELECTOR',
    get:
      (id) =>
      ({ get }) => {
        if (id === 'new') {
          return undefined;
        }

        const deal = get(dealPortfolioSelectorFamily(id));
        if (!deal) {
          return undefined;
        }

        return remoteToLocalDeal(deal);
      },
  }),
});

export const taxAssessorsQuery = gql`
  query TaxAssessors($ids: [numeric!]!) {
    tax_assessor_v2(where: { tax_assessor_id: { _in: $ids } }) {
      cherre_parcel_id
      tax_assessor_id
      address
      city
      state
      zip
      latitude
      longitude
      zone_code
      units_count
      year_built
      building_sq_ft
      lot_size_acre
      property_use_code_mapped
      recorder_v2_summary_v2__tax_assessor_id {
        cherre_latest_deed_date
        cherre_latest_deed_amount
      }
      cherre_address__property_address {
        reis_property_attributes__address {
          sector
          propertytype
          sizesqft
          sizeunit
          yearbuilt
        }
      }
    }
  }
` as GQL<TaxAssessorsQuery, TaxAssessorsQueryVariables>;
