import { TextField } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Search from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import React, { Dispatch, SetStateAction } from 'react';
import { useTeamMembersAutocomplete } from 'src/products/core-prospect/hooks';
import { ListItemMemberOption } from './ListItemMemberOption';

export type MemberOption = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
};

export type MembersAutocompleteProps = {
  selectedValues: MemberOption[];
  setSelectedValues: Dispatch<SetStateAction<MemberOption[]>>;
  currentMembers?: number[];
};

export const MembersAutocomplete: React.VFC<MembersAutocompleteProps> = ({
  selectedValues,
  setSelectedValues,
  currentMembers,
}) => {
  const { searchText, setSearchText, options } = useTeamMembersAutocomplete({
    currentMembers,
    limit: 8,
  });

  return (
    <Autocomplete
      data-testid='group_members_autocomplete'
      value={selectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          size='small'
          placeholder='Enter name'
          style={{ backgroundColor: 'white' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Search htmlColor={grey[400]} />,
          }}
        />
      )}
      onInputChange={(e, value) => {
        setSearchText(value);
      }}
      inputValue={searchText}
      getOptionLabel={(option) =>
        [option.firstName, option.lastName].filter(Boolean).join(' ')
      }
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={(option) => <ListItemMemberOption {...option} />}
      filterSelectedOptions
      onChange={(_, option) => {
        setSelectedValues(
          option.filter((o): o is MemberOption => typeof o === 'object')
        );
      }}
      disableClearable
      options={options}
      multiple
      freeSolo
      autoHighlight
      autoComplete
      ListboxProps={{ style: { padding: 0 } }}
    />
  );
};
