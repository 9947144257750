import React from 'react';
import New from './New';

const ErrorTemplate = (props) => {
  const { notification, isOpen, isSidebar } = props;

  if (!notification.text) {
    return null;
  }

  return (
    <div>
      <div style={{ color: '#000' }} id='js-notification-template-error-text'>
        {notification.text} {!notification.isViewed && isSidebar && <New />}
      </div>
      <div style={{ color: '#757575' }}>{notification.data.fileName}</div>
      {isOpen && (
        <div id='js-notification-template-error-data'>
          <br />
          {notification.data}
        </div>
      )}
    </div>
  );
};

export default ErrorTemplate;
