import React from 'react';
import { Typography } from '@material-ui/core';

const CenteredMessageToUser = ({ height = 100, topMessage, messageDetail }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: `1px solid #d8d8d8`,
        borderRadius: 10,
        width: 500,
        height,
        margin: 'auto',
      }}
    >
      <Typography variant='h6'>{topMessage}</Typography>
      <Typography variant='body1'>{messageDetail}</Typography>
    </div>
  );
};

export default CenteredMessageToUser;
