import React from 'react';
import grey from '@material-ui/core/colors/grey';

const TreppLoanNameIdAndLogo = ({ item }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <div>
        <span style={{ color: grey[700] }}>Deal name: </span>
        <span style={{ color: grey[900] }}>{item.trepp_deal_name}</span>
      </div>
      <div style={{ marginLeft: 16 }}>
        <span style={{ color: grey[700] }}>Loan id: </span>
        <span style={{ color: grey[900] }}>{item.loan_id}</span>
      </div>
      <img
        style={{ height: 32, marginLeft: 16 }}
        src='/assets/trepp-blue-box-logo.svg'
      />
    </div>
  );
};

export default TreppLoanNameIdAndLogo;
