import { useMemo } from 'react';
import { PortfolioDeal, DealGetAllOptions } from '../types';
import { useAllDealsPortfolio } from './useAllDealsPortfolio';

export const useAllDealsPortfolioByTaxId = (opts: DealGetAllOptions) => {
  const deals = useAllDealsPortfolio(opts)?.valueMaybe();

  const dealsByTaxId = useMemo(() => {
    const ret = new Map<number, PortfolioDeal>();

    for (const deal of deals || []) {
      for (const asset of deal.assets) {
        if (asset.cherreAsset && asset.cherreAsset.taxAssessorId) {
          ret.set(asset.cherreAsset.taxAssessorId, deal);
        }
      }
    }

    return ret;
  }, [deals]);

  return dealsByTaxId;
};
