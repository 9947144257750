import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropertyListsIcon from './PropertyListsIcon';
import AdminSettingsMenu from './AdminSettingsMenu';
import ProfileIcon from './ProfileIcon';
import { isAllowed } from 'src/utils/routing/isAllowed';
import { SelectReport } from 'src/products/core-bi-v2/SelectReport';
import Reminders from './Reminders';
import { ErrorBoundary } from '@sentry/react';
import { FeatureFlagGate } from 'src/hooks/useFeatureFlag';

const useStyles = makeStyles({
  groupMenuContainer: {
    display: 'flex',
  },
});

const RegularNavBarRight = ({
  user,
  isCherreAdmin,
  isPowerBiReports,
  toggleRemindersDrawer,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.groupMenuContainer}>
      {isPowerBiReports && <SelectReport />}
      {user?.featureFlags?.appBarPropertyListsEnabled &&
        isAllowed(user.permissions, ['CoreProspect']) && <PropertyListsIcon />}
      {isCherreAdmin && <AdminSettingsMenu />}
      <FeatureFlagGate flag='DSPReminders'>
        <ErrorBoundary fallback={<span />}>
          <Reminders onClick={toggleRemindersDrawer} />
        </ErrorBoundary>
      </FeatureFlagGate>
      <ProfileIcon imageUrl={user.image} />
    </div>
  );
};

export default RegularNavBarRight;
