import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import match from 'autosuggest-highlight/match';
import CircularProgress from '@material-ui/core/CircularProgress';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash/debounce';
import { withStyles } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import Suggestion from './components/Suggestion';
import SuggestionHeader from './components/SuggestionHeader';
import SuggestionFooter from './components/SuggestionFooter';
import { Scrollbars } from 'react-custom-scrollbars';

const styles = (theme) => ({
  adornment: {
    outline: 'none',
    color: '#aaa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
  input: {
    alignItems: 'center',
  },
});

class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: props.value || '',
      suggestions: [],
    };

    this.handleSuggestionsFetchRequested = debounce(
      this._handleSuggestionsFetchRequested,
      200
    );
  }

  componentWillReceiveProps({ value }) {
    if (value || value === '') {
      this.setState({ value });
    }
  }

  renderInput(inputProps) {
    const {
      autoFocus,
      placeholder,
      label,
      classes,
      value,
      onBlur,
      ref,
      ...other
    } = inputProps;

    const { onFocus } = this.props;

    const adornmentIcon = this.state.isFetching ? (
      <CircularProgress
        style={{
          position: 'relative',
          top: 0,
          width: 20,
          height: 20,
        }}
        size={24}
      />
    ) : (
      <div
        className={classes.adornment}
        tabIndex='-1'
        style={{
          borderRadius: 5,
        }}
      >
        {value ? (
          <Clear
            style={{
              cursor: 'pointer',
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={(e) => {
              e.preventDefault();

              this.setState({
                value: '',
                suggestions: [],
              });
            }}
          />
        ) : (
          <Search />
        )}
      </div>
    );

    return (
      <TextField
        label={label}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={classes.textField}
        value={value}
        inputRef={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{
          style: { height: 17 },
        }}
        InputProps={{
          style: { padding: 10 },
          className: cx(classes.input),
          endAdornment: (
            <InputAdornment style={{}}>{adornmentIcon}</InputAdornment>
          ),
          ...other,
        }}
      />
    );
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    const { getSuggestionRenderValue } = this.props;

    const value = getSuggestionRenderValue
      ? getSuggestionRenderValue(suggestion)
      : suggestion.name || suggestion;

    const matches = match(value, query);
    const parts = parse(value, matches);

    const SuggestionElement = this.props.Suggestion || Suggestion;

    return (
      <SuggestionElement
        parts={parts}
        isHighlighted={isHighlighted}
        suggestion={suggestion}
      />
    );
  }

  getSuggestionValue(suggestion) {
    return suggestion;
  }

  renderSuggestionsContainer(options) {
    const {
      suggestionsMaxHeight,
      suggestionHeader,
      suggestionFooter,
      suggestionsStyle,
    } = this.props;

    const { containerProps, children } = options;

    // if (!children) {
    //   return null;
    // }

    return (
      <Paper
        style={{
          zIndex: 2,
          marginTop: -1,
          marginLeft: -10,
          marginRight: -10,
          boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2)',
          ...suggestionsStyle,
        }}
        {...containerProps}
        square
      >
        {children && (
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMax={suggestionsMaxHeight || 290}
          >
            {suggestionHeader && (
              <SuggestionHeader content={suggestionHeader} />
            )}
            {children}
            {suggestionFooter && (
              <SuggestionFooter content={suggestionFooter} />
            )}
          </Scrollbars>
        )}
      </Paper>
    );
  }

  shouldRenderSuggestions(value) {
    if (typeof value === 'string') {
      return value.trim().length > 2;
    } else {
      return true;
    }
  }

  _handleSuggestionsFetchRequested = async ({ value }) => {
    try {
      this.setState({ isFetching: true });

      const suggestions = (await this.props.onSearch(value)) || [];

      this.setState({
        suggestions,
        isFetching: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  handleChange(event, data) {
    event.preventDefault();
    const { newValue, method } = data;
    const { onType, getSuggestionRenderValue } = this.props;

    if (method === 'type') {
      this.setState({ value: newValue });

      onType && onType(newValue, event);
    } else if (['enter', 'click'].includes(method)) {
      this.setState({
        value: getSuggestionRenderValue
          ? getSuggestionRenderValue(newValue)
          : newValue.name || newValue,
      });
    }
  }

  render() {
    const {
      classes,
      placeholder,
      disableUnderline,
      label,
      autoFocus,
      style,
      name,
      onBlur,
      shouldRenderSuggestions,
      className,
      id,
    } = this.props;

    return (
      <div
        id={id || 'cherre-autocomplete-input'}
        style={{ ...style }}
        className={className}
      >
        <Autosuggest
          highlightFirstSuggestion
          focusInputOnSuggestionClick={false}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          shouldRenderSuggestions={
            shouldRenderSuggestions || this.shouldRenderSuggestions
          }
          renderInputComponent={this.renderInput.bind(this)}
          suggestions={[...this.state.suggestions].filter(Boolean)}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested.bind(
            this
          )}
          renderSuggestionsContainer={this.renderSuggestionsContainer.bind(
            this
          )}
          onSuggestionSelected={(e, { suggestion }) =>
            this.props.onChange(suggestion)
          }
          getSuggestionValue={this.getSuggestionValue.bind(this)}
          renderSuggestion={this.renderSuggestion.bind(this)}
          inputProps={{
            name,
            disableUnderline,
            autoFocus,
            classes,
            label,
            placeholder,
            onBlur,
            value: this.state.value,
            onChange: this.handleChange.bind(this),
            onKeyDown: this.props.onKeyDown,
          }}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  adornment: PropTypes.element,
  Suggestion: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  classes: PropTypes.object,
  disableUnderline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  suggestionsMaxHeight: PropTypes.number,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  onType: PropTypes.func,
  getSuggestionRenderValue: PropTypes.func,
  shouldRenderSuggestions: PropTypes.func,
  style: PropTypes.object,
  name: PropTypes.string,
};

export default withStyles(styles)(IntegrationAutosuggest);
