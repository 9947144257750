import { useSelector } from 'react-redux';

export enum ConnectorEnvironments {
  DEMO = 'demo',
  MVP = 'mvp',
}

export const useConnectorEnvironment = (): ConnectorEnvironments => {
  const organizationId = useSelector(
    (state: any) => state?.user?.profile?.value?.organizationId
  );
  return organizationId === 1
    ? ConnectorEnvironments.DEMO
    : ConnectorEnvironments.MVP;
};
