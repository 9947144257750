import {
  useCherrePartialState,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import {
  Checkbox,
  FilterChip,
  Menu,
  MenuItem,
  styled,
} from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { reminderDrawerFilterState } from '../recoil';

const CheckboxStyled = styled(Checkbox)`
  padding: 0px;
`;

type FilterProps = {
  filterType: string;
  filterOptions: Record<string, string>;
};

const Filter: React.FC<FilterProps> = ({ filterType, filterOptions }) => {
  const isSuspended = useIsSuspended();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [filterSelections, setFilterSelections] = useCherrePartialState(
    reminderDrawerFilterState
  );

  if (!filterOptions) {
    return <></>;
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const onOptionClear = () => {
    setFilterSelections({
      [filterType]: [],
    });
  };

  const label = useMemo(() => {
    const selections = filterSelections?.[filterType] || [];
    if (selections.length === 0) {
      return filterType;
    }

    const selectedCount = selections.length;

    if (selectedCount === 1) {
      return selections[0];
    }

    return `${selections[0]} +${selectedCount - 1}`;
  }, [filterSelections]);

  const onOptionChange = (value: string) => {
    const selections = filterSelections?.[filterType] || [];
    if (selections.includes(value)) {
      setFilterSelections({
        [filterType]: selections.filter((v) => v !== value),
      });
    } else {
      setFilterSelections({
        [filterType]: [...selections, value],
      });
    }
  };

  return (
    <>
      <FilterChip
        label={label}
        onClick={handleFilterClick}
        active={filterSelections?.[filterType].length > 0}
        onDelete={onOptionClear}
        disabled={isSuspended}
      />
      <Menu
        disableEnforceFocus
        id={`submission-filter-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          style={{ width: '200px', gap: '6px' }}
          onClick={onOptionClear}
        >
          Any {filterType}
        </MenuItem>
        {Object.keys(filterOptions || {}).map((key: string) => {
          const isSelected = filterSelections?.[filterType]?.includes(key);
          return (
            <MenuItem
              key={key}
              style={{ width: '250px', gap: '6px' }}
              onClick={() => onOptionChange(key)}
            >
              <CheckboxStyled checked={isSelected} />
              {filterOptions[key]}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default Filter;
