import numberWithCommas from 'src/utils/numberWithCommas';

export function getMaskProps(mask) {
  let prefix, suffix;
  let thousandSeparator = true;
  const decimalSeparator = '.';

  switch (mask) {
    case 'dollars':
      prefix = '$';
      break;
    case 'sqft':
      suffix = ' sqft';
      break;
    case 'percent':
      suffix = '%';
      break;
    case 'ft':
      suffix = ' ft';
      break;
    case 'digitsNoCommas':
      thousandSeparator = '';
      break;
    default:
  }

  return {
    prefix,
    suffix,
    thousandSeparator,
    decimalSeparator,
  };
}

export function getFormatPropsFromDatabase(mask = '') {
  const maskParts = mask.split('|');

  return {
    format: maskParts[1],
  };
}

export function applyMask(text, mask) {
  if (mask.thousandSeparator) {
    text = numberWithCommas(text);
  }

  if (mask.prefix) {
    text = mask.prefix + text;
  }

  if (mask.suffix) {
    text = text + mask.suffix;
  }

  return text;
}
