import React from 'react';
import SearchSelectOption from './SearchSelectOption';

const SearchSelectOptions = (props) => {
  const {
    options,
    selectedItemIndex,
    handleOptionOnClick,
    value,
    close,
    handleKeyPress,
    optionLabelKey,
  } = props;

  return (
    <div>
      {options.map((option, index) => {
        return (
          <SearchSelectOption
            option={option}
            key={index}
            index={index}
            selectedItemIndex={selectedItemIndex}
            handleOptionOnClick={handleOptionOnClick}
            handleKeyPress={handleKeyPress}
            close={close}
            value={value}
            optionLabelKey={optionLabelKey}
          />
        );
      })}
    </div>
  );
};

export default SearchSelectOptions;
