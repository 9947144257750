export default function validate(value) {
  if (!value) {
    return {};
  }
  const errors = {};

  if (Number.isFinite(+value)) {
    if (+value < 0) {
      errors.value = `${value} should be positive`;
    }
  }
  return errors;
}
