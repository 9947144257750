import React from 'react';

type Action = () => Promise<void>;

const printActionContext = React.createContext(new Set<Action>());

export const useBeforePrintAction = (a: Action) => {
  const set = React.useContext(printActionContext);
  const ref = React.useRef(a);
  ref.current = a;
  React.useEffect(() => {
    const handler = () => ref.current();
    set.add(handler);
    return () => {
      set.delete(handler);
    };
  }, []);
};

export const usePrint = () => {
  const set = React.useContext(printActionContext);
  return React.useCallback(() => {
    const actions = Array.from(set);
    Promise.all(actions.map((a) => a())).then(() => {
      set.clear();
      window.print();
    });
  }, []);
};
