import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  label: {
    fontSize: 14,
    height: 18,
    color: '#212121',
    marginBottom: 8,
  },
}));

const SelectWithItems = ({
  label,
  menuItems = [],
  changeHandler,
  index,
  fieldName,
  value,
  disabled = false,
  helperText = null,
  inputLabel = null,
  size = 'medium',
  variant = 'outlined',
  disableUnderline = false,
}) => {
  const classes = useStyles();

  const onChange = (event) =>
    changeHandler(event.target.value, index, fieldName);

  return (
    <div>
      {label ? (
        <Typography component={'div'} className={classes.label}>
          {label}
        </Typography>
      ) : null}
      <FormControl
        variant={variant}
        className={classes.formControl}
        disabled={disabled}
        size={size}
      >
        {inputLabel ? <InputLabel>{inputLabel}</InputLabel> : null}
        <Select
          value={value}
          onChange={onChange}
          disableUnderline={disableUnderline}
        >
          {(menuItems || []).map((menuItem, index) => (
            <MenuItem key={index} value={menuItem.value}>
              {menuItem.label}
            </MenuItem>
          ))}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </div>
  );
};

export default SelectWithItems;
