import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { Flex } from 'src/components/Flex';

const SPACING_PIXELS = 15;

const CollapsiblePanel = ({ title, children, allowToCollapse }) => {
  const [isOpen, setOpen] = useState(true);

  const handleOnClick = () => {
    if (allowToCollapse) {
      setOpen(!isOpen);
    }
  };

  return (
    <Flex
      direction='column'
      style={{
        padding: SPACING_PIXELS,
        borderRadius: 5,
        backgroundColor: '#fafafa',
      }}
    >
      <div
        onClick={handleOnClick}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: allowToCollapse ? 'pointer' : 'default',
          marginBottom: isOpen ? SPACING_PIXELS : 0,
        }}
      >
        <Typography variant='h5'>{title}</Typography>
        {allowToCollapse && <>{isOpen ? <ExpandLess /> : <ExpandMore />}</>}
      </div>
      {isOpen && children}
    </Flex>
  );
};

export default CollapsiblePanel;
