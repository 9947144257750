import constants from 'src/const';

export const ORDER_BY_DIRECTION_CHERRE = {
  [constants.ORDER_BY_DIRECTION.ASC]: 'asc',
  [constants.ORDER_BY_DIRECTION.DESC]: 'desc',
};

export function transformOrderBy(orderBy) {
  const arrOrderBy = Array.isArray(orderBy) ? orderBy : [orderBy];

  return arrOrderBy.map((entry) => ({
    [entry.field]: ORDER_BY_DIRECTION_CHERRE[entry.direction],
  }));
}
