import React from 'react';
import PropTypes from 'prop-types';
import DynamicForm from './components/formControls/dynamicForm';
import { PaperPage } from '../PaperPage';

const ItemEditFormPage = ({
  backButtonClick,
  submitButtonText,
  formName,
  submit,
  fields,
  pageTitle,
  pageDescription,
  operation,
  store: { data },
}) => {
  return (
    <PaperPage
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      backButtonClick={backButtonClick}
    >
      <DynamicForm
        operation={operation}
        formName={formName}
        onSubmit={submit}
        RequestForm={{ title: pageTitle, fields: fields }}
        initialValues={data}
        submitButtonText={submitButtonText}
      />
    </PaperPage>
  );
};

ItemEditFormPage.propTypes = {
  formName: PropTypes.string,
  fields: PropTypes.array,
  submit: PropTypes.func,
  pageTitle: PropTypes.string,
  backButtonClick: PropTypes.func,
  submitButtonText: PropTypes.string,
  store: PropTypes.shape({
    data: PropTypes.object,
  }),
};

export default ItemEditFormPage;
