const uppercaseDictionary = ['ny', 'ny,', 'p.s.', 'llc', 'llp'];

const lowercaseDictionary = ['for', 'and', 'of'];

const capitalize = (string) => {
  return (string || '')
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (uppercaseDictionary.includes(word)) {
        return word.toUpperCase();
      } else if (lowercaseDictionary.includes(word)) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
};

export default capitalize;
