import { graphql } from 'relay-runtime';

export const PreConfiguredModelsQuery = graphql`
  query preconfiguredModelsQuery {
    preconfiguredModels {
      name
      provider
      displayName
      compatiblePreconfiguredSources
      stableVersion
      revisions {
        tables
      }
    }
  }
`.default;
