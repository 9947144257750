import { atomFamily, noWait, selectorFamily } from 'recoil';
import { graphqlClient } from 'src/products/shell/Root';
import { throttle } from 'lodash';
import * as T from './types';
import React from 'react';

export * from './types';

// CONSTANTS
const DEFAULT_PAGINATION_LIMIT = 50;
const ON_SCROLL_THRESHOLD = 5;

/**
 * this function can be used to perform a graphql query inside recoil environment, declare the query using template strings.
 * after that run `npm run generate-graphql` with backend running to generate TS types for the query
 * a folder __generated__ should be created in the same directory as the query, then you can use
 * GQL type exported by this file to type the query:
 * ```ts
 * gql`
 * query cities($city: String!, $offset: Int!, $limit: Int!) {
 *   usa_zip_code_boundary_v2(
 *     distinct_on: reference_1
 *     order_by: { reference_1: asc }
 *     where: { reference_1: { _is_null: false, _ilike: $city } }
 *     offset: $offset
 *     limit: $limit
 *   ) {
 *     city: reference_1
 *   }
 * }
 *` as GQL<AllAssigned<T.CitiesQuery>, T.CitiesQueryVariables>;
 * ```
 * @param TemplateString query
 * @returns the selector family for this query
 */
export function gql<
  R extends T.QueryResponse = any,
  V extends T.MaybeObj = void
>([query]: TemplateStringsArray) {
  const selector = selectorFamily({
    key: query,
    get:
      (variables: any = {}) =>
      () =>
        graphqlClient.request<R>({ query, variables }).then((res) => {
          if (!res.data) {
            throw new Error('No Data Returned');
          }
          return res.data;
        }),
  });
  return (variables: V) => selector(variables);
}

gql.withDefaultValue = function withDefaultValue(defaultValue: any) {
  return function gql<
    R extends T.QueryResponse = any,
    V extends T.MaybeObj = void
  >([query]: TemplateStringsArray) {
    const selector = selectorFamily({
      key: query,
      get:
        (variables: any = {}) =>
        () =>
          graphqlClient.request<R>({ query, variables }).then((res) => {
            if (!res.data) {
              return defaultValue;
            }
            return res.data;
          }),
    });
    return (variables: V) => selector(variables);
  };
};

/**
 * this function can be used to add pagination to a already created graphql query,
 * for that the query should include the $offset and $limit variables, it provides a convenient `onScrollFetchMore` callback that can be used
 * on the onScroll prop of any container.
 * @param T.PaginationParams pagination params
 * @returns the selector family for the paginated query
 */
export function paginate<
  R extends T.PaginatableResponse<R>,
  K extends T.RemoveTypename<keyof R>,
  V extends T.PaginatableVariables
>(params: T.PaginationParams<R, K, V>) {
  const pageNumberAtomFamily = atomFamily<number, any>({
    key: params.key + '/PAGE-NUMBER-ATOM',
    default: () => (params.lazy ? -1 : 0),
  });
  const selector = selectorFamily({
    key: params.key,
    get:
      (variables: any = {}) =>
      ({ get, getCallback }) => {
        const numOfResults = params.resultsPerPage || DEFAULT_PAGINATION_LIMIT;
        const pageNumberAtom = pageNumberAtomFamily(variables);
        const pageNumber = get(pageNumberAtom);

        const { data, loading, endOfList } = Array.from(
          Array(pageNumber + 1).keys()
        ).reduce(
          (acc, page) => {
            const result = get(
              noWait(
                params.query({
                  ...variables,
                  offset: page * numOfResults,
                  limit: numOfResults,
                })
              )
            );
            switch (result.state) {
              case 'hasValue': {
                const newData = result.contents[params.fieldName];
                return {
                  data: [...acc.data, ...newData],
                  loading: acc.loading,
                  endOfList: acc.endOfList || newData.length < numOfResults,
                };
              }
              case 'hasError': {
                throw result.contents;
              }
              case 'loading': {
                return { data: acc.data, loading: true, endOfList: false };
              }
            }
          },
          { data: [], loading: false, endOfList: false } as {
            data: any[];
            loading: boolean;
            endOfList: boolean;
          }
        );
        const fetchMore = getCallback(({ set }) => {
          const throttleFetchMore = throttle(
            () => set(pageNumberAtom, (n) => n + 1),
            500
          );
          return () => {
            if (!loading && !endOfList) {
              throttleFetchMore();
            }
          };
        });

        const onScrollFetchMore = (e: React.SyntheticEvent) => {
          const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
          if (
            Math.abs(scrollTop + clientHeight - scrollHeight) <
            ON_SCROLL_THRESHOLD
          ) {
            fetchMore();
          }
        };
        return {
          data,
          loading,
          fetchMore,
          onScrollFetchMore,
          endOfList,
        } as T.PaginationReturn<R, K>;
      },
  });
  return (variables: Omit<V, 'offset' | 'limit'>) => selector(variables);
}
