import { Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';

const StreetViewPlaceholder = ({ typography = true }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        fontSize: 22,
        height: '100%',
        borderRadius: 5,
        backgroundImage: 'url("/assets/cp-streetside-preview.svg")',
        backgroundColor: '#eee',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: -15,
      }}
    >
      {typography && (
        <Typography style={{ margin: 20, color: grey[500] }}>
          No streetview for this property.
        </Typography>
      )}
    </div>
  );
};

export default StreetViewPlaceholder;
