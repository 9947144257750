import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modal: {
    minWidth: 520,
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    padding: '15px 15px 0px',
  },
  content: {
    padding: '0px 15px',
  },
  key: {
    fontWeight: 500,
    fontSize: 14,
    paddingTop: 24,
    paddingBottom: 9,
  },
  value: {
    fontWeight: 700,
    fontSize: 14,
  },
  action: {
    padding: 12,
  },
  add: {
    padding: '10px 20px 12px',
  },
  cancel: {
    padding: '10px 20px 12px',
  },
}));
