import { graphql } from 'relay-runtime';

export const CreateModelMutation = graphql`
  mutation createModelMutation(
    $preconfiguredModelName: String!
    $schedule: String!
    $envs: [VariableInput]
    $displayName: String
    $connectorName: String
    $egressedTables: [String]
  ) {
    createModel(
      preconfiguredModelName: $preconfiguredModelName
      schedule: $schedule
      envs: $envs
      displayName: $displayName
      connectorName: $connectorName
      egressedTables: $egressedTables
    ) {
      name
    }
  }
`.default;
