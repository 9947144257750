export const getColumnNameFromEntityLink = (entityLink: string) => {
  if (entityLink.includes('::columns::')) {
    const splitLinkParts = entityLink.split('::');
    let columnName = splitLinkParts[splitLinkParts.length - 1];
    if (columnName.endsWith('>')) {
      columnName = columnName.slice(0, -1);
    }
    return columnName;
  }
  return '';
};
