import React from 'react';
import { CircularProgress } from '@material-ui/core';

import useStreetViewStatus from 'src/hooks/useStreetViewStatus';
import usePanoramaHeading from 'src/hooks/usePanoramaHeading';
import StreetViewPlaceholder from './StreetViewPlaceholder';
import { getMapParams, getStreetViewParams } from 'src/utils/streetview';

const GoogleStreetView = ({ point, style, allowFullScreen = true }) => {
  const { latitude, longitude } = point;

  if (!point.latitude || !point.longitude) {
    return <StreetViewPlaceholder />;
  }

  const { heading, loading: loadingHeading } = usePanoramaHeading(point);
  const { loading: loadingStatus, hasImage } = useStreetViewStatus(
    latitude,
    longitude
  );

  const loading = loadingHeading || loadingStatus;

  if (loadingHeading === null || loadingStatus === null || loading) {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasImage) {
    return (
      <iframe
        frameBorder='0'
        style={style}
        src={`https://www.google.com/maps/embed/v1/place?${getMapParams({
          latitude,
          longitude,
        })}`}
      />
    );
  }

  return (
    <iframe
      frameBorder='0'
      style={style}
      src={`https://www.google.com/maps/embed/v1/streetview?${getStreetViewParams(
        { latitude, longitude, heading: heading || 0, pitch: -0.76 }
      )}`}
      allowFullScreen={allowFullScreen}
    />
  );
};

export default GoogleStreetView;
