// Robert Penner's easeInOutQuad - http://robertpenner.com/easing/

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

const jumper = () => {
  // private

  let element; // element to scroll to                   (node)

  let start; // where scroll starts                    (px)
  let stop; // where scroll stops                     (px)

  let easing; // easing function                        (function)
  let a11y; // accessibility support flag             (boolean)

  let duration; // scroll duration                        (ms)
  let distance; // distance of scroll                     (px)

  let timeStart; // time scroll started                    (ms)
  let timeElapsed; // time scrolling thus far                (ms)

  let next; // next scroll position                   (px)

  let callback; // fire when done scrolling               (function)

  // scroll position helper

  function location(container) {
    return container.scrollTop;
  }

  // scroll finished helper

  function done(container) {
    // account for rAF time rounding inaccuracies
    container.scrollTop = Math.round(start + distance);

    // if scrolling to an element, and accessibility is enabled
    if (element && a11y) {
      // add tabindex
      element.setAttribute('tabindex', '-1');

      // focus the element
      element.focus();
    }

    // if it exists, fire the callback
    if (typeof callback === 'function') {
      callback();
    }

    // reset time for next jump
    timeStart = false;
  }

  // rAF loop helper

  function loop(container, timeCurrent) {
    // store time scroll started, if not started already
    if (!timeStart) {
      timeStart = timeCurrent;
    }

    // determine time spent scrolling so far
    timeElapsed = timeCurrent - timeStart;

    // calculate next scroll position
    next = easing(timeElapsed, start, distance, duration);

    // scroll to it
    container.scrollTop = Math.round(next);

    // check progress
    timeElapsed < duration
      ? requestAnimationFrame(loop.bind(null, container)) // continue looping
      : done(container); // scrolling is done
  }

  // API

  function jump(target, options = {}) {
    const zoomLevel = options.zoomLevel || 1;
    const offset = options.offset || 0;
    // cache starting position
    const container = options.container
      ? document.querySelector(options.container)
      : window;
    start = location(container);

    // resolve target
    switch (typeof target) {
      // scroll from current position
      case 'number':
        element = undefined; // no element to scroll to
        a11y = false; // make sure accessibility is off
        stop = start + target;
        break;

      // scroll to element (node)
      // bounding rect is relative to the viewport
      case 'object':
        element = target;

        if (!element) {
          return;
        }

        stop =
          zoomLevel * element.getBoundingClientRect().top + location(container);
        break;

      // scroll to element (selector)
      // bounding rect is relative to the viewport
      case 'string':
        element = document.querySelector(target);

        if (!element) {
          return;
        }

        stop =
          zoomLevel * element.getBoundingClientRect().top +
          location(container) -
          offset;

        break;
    }
    // resolve scroll distance
    distance = stop - start;

    // resolve duration
    switch (typeof options.duration) {
      // number in ms
      case 'number':
        duration = options.duration;
        break;

      // function that can utilize the distance of the scroll
      case 'function':
        duration = options.duration(distance);
        break;

      // default to 1000ms
      default:
        duration = 500;
        break;
    }

    // resolve options
    callback = options.callback;
    easing = options.easing || easeInOutQuad;
    a11y = options.a11y || false;

    // start the loop
    requestAnimationFrame(loop.bind(null, container));
  }

  // expose only the jump method
  return jump;
};

// export singleton

const singleton = jumper();

export default singleton;
