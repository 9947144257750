import React, { CSSProperties } from 'react';
import { CircularProgress } from '@material-ui/core';

import useStreetViewStatus from 'src/hooks/useStreetViewStatus';
import StreetViewPlaceholder from './StreetViewPlaceholder';
import { getMapParams, getStreetViewParams } from 'src/utils/streetview';

type Props = {
  width?: number;
  height?: number;
  style?: CSSProperties;
  latitude: number;
  longitude: number;
};

export const GoogleStreetViewStatic: React.FC<Props> = ({
  width = 600,
  height = 300,
  style,
  latitude,
  longitude,
}) => {
  if (!latitude || !longitude) {
    return <StreetViewPlaceholder typography={false} />;
  }

  const { loading: loadingStatus, hasImage } = useStreetViewStatus(
    latitude,
    longitude
  );

  if (loadingStatus === null || loadingStatus) {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!hasImage) {
    return (
      <iframe
        frameBorder='0'
        style={style}
        src={`https://www.google.com/maps/embed/v1/place?${getMapParams({
          latitude,
          longitude,
          height: null,
          width: null,
        })}`}
      />
    );
  }

  return (
    <img
      style={
        style || {
          width: '100%',
          height: 'auto',
        }
      }
      src={`https://maps.googleapis.com/maps/api/streetview?${getStreetViewParams(
        { latitude, longitude, width, height, pitch: 5, heading: null }
      )}`}
    />
  );
};

export default GoogleStreetViewStatic;
