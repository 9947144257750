import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { useRecoilCallback } from 'recoil';

import Actions from './Actions';

import grey from '@material-ui/core/colors/grey';

import { renderCell } from './utils';

function Body({
  data,
  page,
  rowsPerPage,
  columns,
  onDelete,
  classes,
  params,
  actions,
  refresh,
  local = true,
}) {
  const skip = local ? page * rowsPerPage : 0;
  const end = skip + rowsPerPage;
  const filteredData = local ? data.slice(skip, end) : data;
  const recoilCallback = useRecoilCallback((opts) => () => opts, []);

  return (
    <TableBody>
      {filteredData.map((row, i) => {
        return (
          <TableRow className={classes.row} hover tabIndex={-1} key={i}>
            {columns.map((column, index) => {
              return (
                <TableCell
                  className={classes.cell}
                  key={index}
                  numeric={column.numeric}
                >
                  {renderCell(row, column, params)}
                </TableCell>
              );
            })}
            {actions && (
              <TableCell style={{ padding: 4 }}>
                <Actions
                  refresh={refresh}
                  onDelete={onDelete}
                  row={row}
                  params={params}
                  recoilCallback={recoilCallback}
                />
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
}

const styles = {
  cell: {
    fontSize: 14,
    padding: '12px 24px',
  },
  row: {
    '&:hover': {
      backgroundColor: grey[50],
    },
  },
};

Body.propTypes = {
  classes: PropTypes.object,
  params: PropTypes.object,
  actions: PropTypes.bool,
  data: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  columns: PropTypes.array,
  onDelete: PropTypes.func,
  refresh: PropTypes.func,
  local: PropTypes.bool,
};

export default withStyles(styles)(Body);
