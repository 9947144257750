/**
 * @generated SignedSource<<b286672767060870a36fbcb502df5c6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type connectorsListQuery$variables = {};
export type connectorsListQuery$data = {
  readonly connectors: ReadonlyArray<{
    readonly displayName: string | null;
    readonly models: ReadonlyArray<{
      readonly createdAt: any;
      readonly destinationStatuses: ReadonlyArray<{
        readonly destinationName: string | null;
        readonly statusObject: {
          readonly isRunning: boolean;
          readonly status: string | null;
        } | null;
      } | null> | null;
      readonly destinations: ReadonlyArray<{
        readonly displayName: string | null;
        readonly name: string;
        readonly provider: string;
      }>;
      readonly displayName: string | null;
      readonly name: string;
      readonly preconfiguredModelRef: string | null;
      readonly provider: string | null;
      readonly publishedRevisions: ReadonlyArray<{
        readonly revisionNumber: number;
      }>;
      readonly publishedTables: ReadonlyArray<string>;
      readonly publishedTablesObject: ReadonlyArray<{
        readonly name: string;
        readonly schema: string | null;
      }>;
      readonly statusObject: {
        readonly endDatetime: any | null;
        readonly isRunning: boolean;
        readonly runningDatetime: any | null;
        readonly startDatetime: any | null;
        readonly status: string | null;
      } | null;
    }>;
    readonly name: string;
    readonly sources: ReadonlyArray<{
      readonly createdAt: any;
      readonly displayName: string | null;
      readonly ingestRevisions: ReadonlyArray<{
        readonly semanticVersion: string;
      }>;
      readonly name: string;
      readonly provider: string | null;
      readonly schedule: string;
      readonly statusObject: {
        readonly endDatetime: any | null;
        readonly isRunning: boolean;
        readonly runningDatetime: any | null;
        readonly startDatetime: any | null;
        readonly status: string | null;
      } | null;
    }>;
    readonly statusObject: {
      readonly endDatetime: any | null;
      readonly isRunning: boolean;
      readonly runningDatetime: any | null;
      readonly startDatetime: any | null;
      readonly status: string | null;
    } | null;
  }>;
};
export type connectorsListQuery = {
  response: connectorsListQuery$data;
  variables: connectorsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRunning",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "StatusObject",
  "kind": "LinkedField",
  "name": "statusObject",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "runningDatetime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDatetime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDatetime",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Connector",
    "kind": "LinkedField",
    "name": "connectors",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "sources",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/),
          (v0/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedule",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "IngestRevision",
            "kind": "LinkedField",
            "name": "ingestRevisions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "semanticVersion",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Model",
        "kind": "LinkedField",
        "name": "models",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/),
          (v0/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedTables",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "preconfiguredModelRef",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DestinationStatusObject",
            "kind": "LinkedField",
            "name": "destinationStatuses",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "destinationName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StatusObject",
                "kind": "LinkedField",
                "name": "statusObject",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishedRevisionObject",
            "kind": "LinkedField",
            "name": "publishedRevisions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revisionNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PublishedTablesObject",
            "kind": "LinkedField",
            "name": "publishedTablesObject",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schema",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Destination",
            "kind": "LinkedField",
            "name": "destinations",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "connectorsListQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "connectorsListQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "410dfcd37f2b2b4ad0a49b23ae4a3c84",
    "id": null,
    "metadata": {},
    "name": "connectorsListQuery",
    "operationKind": "query",
    "text": "query connectorsListQuery {\n  connectors {\n    displayName\n    name\n    statusObject {\n      status\n      isRunning\n      runningDatetime\n      endDatetime\n      startDatetime\n    }\n    sources {\n      provider\n      name\n      displayName\n      createdAt\n      schedule\n      ingestRevisions {\n        semanticVersion\n      }\n      statusObject {\n        status\n        isRunning\n        runningDatetime\n        endDatetime\n        startDatetime\n      }\n    }\n    models {\n      provider\n      name\n      displayName\n      createdAt\n      publishedTables\n      preconfiguredModelRef\n      destinationStatuses {\n        destinationName\n        statusObject {\n          status\n          isRunning\n        }\n      }\n      publishedRevisions {\n        revisionNumber\n      }\n      publishedTablesObject {\n        name\n        schema\n      }\n      destinations {\n        provider\n        name\n        displayName\n      }\n      statusObject {\n        status\n        isRunning\n        runningDatetime\n        endDatetime\n        startDatetime\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "96e899169757316730e4332b87d27019";

export default node;
