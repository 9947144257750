import List from '@material-ui/core/List';
import { ListStyles } from './components/TreeCustomComponents';
import React from 'react';
import CollapsibleContent from './components/CollapsibleContent';
import PropTypes from 'prop-types';
import ListItem from './components/ListItems';

const TreeView = (props) => {
  const { parentNodeId, parentChildren, depth = 1 } = props;

  return (
    <div>
      {parentChildren &&
        parentChildren[parentNodeId] &&
        parentChildren[parentNodeId].map((node, index) => {
          return (
            <List
              style={ListStyles(depth)}
              component='div'
              dense
              disablePadding
              key={index}
            >
              <ListItem {...props} node={node} />
              <CollapsibleContent {...props} node={node} />
            </List>
          );
        })}
    </div>
  );
};

TreeView.defaultProps = {
  expanded: {},
  checked: {},
  indeterminate: {},
};

TreeView.propTypes = {
  expanded: PropTypes.object,
  checked: PropTypes.object,
  indeterminate: PropTypes.object,
  parentChildren: PropTypes.object,
  idData: PropTypes.object,
  changePanelExpansion: PropTypes.func,
  handleCheckboxClick: PropTypes.func,
  highlightedNeighborhoodId: PropTypes.number,
};

export default TreeView;
