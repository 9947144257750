import React from 'react';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { MemberOption } from './MembersAutocomplete';
import { getNameInitials } from 'src/utils/getNameInitials';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.light,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.13,
  },
}));

export const ListItemMemberOption: React.VFC<MemberOption> = ({
  firstName = '',
  lastName = '',
  email,
}) => {
  const classes = useStyles();

  return (
    <ListItem dense style={{ padding: 0 }} component='div'>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          {getNameInitials(`${firstName} ${lastName}`)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={firstName} secondary={email} />
    </ListItem>
  );
};
