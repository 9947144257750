import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function SelectFilter(props) {
  const {
    filterValue,
    setFilterValue,
    filter: { label, placeholder },
    data: { options },
    classes,
  } = props;

  return (
    <div
      className={classes.root}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <FormControl
        size='small'
        variant='outlined'
        className={classes.textField}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={filterValue || ''}
          onChange={(e) => setFilterValue(e.target.value)}
          label={label}
          fullWidth
          placeholder={placeholder || label}
          inputProps={{
            placeholder: label,
          }}
        >
          {options?.length > 0 ? (
            options.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value={null}>No Options</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

SelectFilter.propTypes = {
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  filter: PropTypes.object,
  classes: PropTypes.object,
};

SelectFilter.defaultProps = {
  setFilterValue: (values) => {
    values;
  },
};

export default withStyles(styles)(SelectFilter);
