import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { useOrganizationPropsValue } from 'src/hooks';
import { dealPortfolioAllSelector } from '../recoil';
import { DealGetAllOptions } from '../types';

export const useAllDealsPortfolioAssets = (opts: DealGetAllOptions) => {
  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  if (!isDealsIntegrationAvailable) {
    return;
  }

  const deals =
    useRecoilValueLoadable(dealPortfolioAllSelector(opts)).valueMaybe() || [];

  const assets = useMemo(
    () =>
      deals.flatMap(({ assets, ...dealInfo }) =>
        assets.map((a) => ({
          ...a,
          deal: { ...dealInfo, numberOfAssets: String(assets.length) },
        }))
      ),
    [deals]
  );

  return assets;
};
