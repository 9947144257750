import useWidth from './useWidth';

/**
 * @deprecated use MUI standard patterns
 */
export default function useIsCompact() {
  const width = useWidth();

  return ['xs', 'sm'].includes(width);
}
