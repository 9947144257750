import FileTemplate from './FileTemplate';
import ErrorTemplate from './ErrorTemplate';
import SearchCSVGenerated from './SearchCSVGenerated';
import ViewWasShared from './ViewWasShared';

export default {
  system: FileTemplate,
  error: ErrorTemplate,
  'file-was-generated': FileTemplate,
  'search-csv-generated': SearchCSVGenerated,
  'view-was-shared-with-user': ViewWasShared,
};
