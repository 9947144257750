import React from 'react';
import PopoverSelect from './PopoverSelect';
import SelectSearchOptionsContainer from './SelectSearchOptionsContainer';

const SelectWithSearch = (props) => {
  const {
    optionLabelKey,
    onChange,
    options,
    value,
    multiple,
    title,
    label,
    autoFocus,
  } = props;

  let displayValue;

  const selectedOptions = options.filter((option) => {
    const valuesArray = Array.isArray(value) ? value : [value];

    return valuesArray.includes(option.value);
  });

  if (!title) {
    displayValue =
      selectedOptions && selectedOptions.length > 0
        ? selectedOptions.map((option) => option[optionLabelKey]).join(', ')
        : '';
  } else {
    displayValue = title;
  }

  return (
    <PopoverSelect
      autoFocus={autoFocus}
      title={displayValue}
      label={label}
      onChange={onChange}
      value={value}
    >
      <SelectSearchOptionsContainer
        onChange={onChange}
        options={options}
        multiple={multiple}
        optionLabelKey={optionLabelKey}
      />
    </PopoverSelect>
  );
};

export default SelectWithSearch;
