import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';

const ListItemStyled = withStyles({
  root: {
    cursor: 'pointer',
    outline: 'none',
    color: blueGrey['600'],
    '&:hover': {
      backgroundColor: '#f5f5f5',
      color: grey['900'],
      '& i': {
        color: grey['600'],
      },
    },
  },
})(ListItem);

const ListItemIconStyled = withStyles({
  root: {
    color: blueGrey['300'],
    marginRight: 0,
  },
})(ListItemIcon);

export default class ContextMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: undefined,
    };
  }

  static propTypes = {
    options: PropTypes.array.isRequired,
    id: PropTypes.string,
  };

  open(anchorEl) {
    this.setState({ anchorEl });
  }

  onClose = () => {
    this.setState({ anchorEl: undefined });
  };

  onItemClick = (handler) => {
    if (typeof handler === 'function') {
      handler();
    }
    this.onClose();
  };

  render() {
    const { anchorEl } = this.state;
    const { options } = this.props;

    const items = options.map((option) => (
      <ListItemStyled
        id={option.id}
        key={option.name}
        onClick={() => this.onItemClick(option.onClick)}
      >
        <ListItemIconStyled>{option.icon}</ListItemIconStyled>
        <ListItemText disableTypography primary={option.name} />
      </ListItemStyled>
    ));

    return (
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.onClose}>
        {items}
      </Menu>
    );
  }
}
