import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilCallback } from 'recoil';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { getCommonData } from './getCommonData';
import axios from 'axios';
import {
  dealPortfolioAllSelector,
  dealPortfolioByTaxIdSelector,
  dealPortfolioSelectorFamily,
  localDeal,
  taxAssessorsQuery,
} from './recoil';
import { usaDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaDataFormatter';
import { useSnackbarCherre } from 'src/products/core-prospect/hooks';
import * as F from './formatters';
import * as T from './types';
import * as U from './utils';
import { push } from 'connected-react-router';
import { useDisableNextCoreExploreReset } from 'src/products/core-prospect/search-pages/hooks/useResetCoreExplore';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';

const useCreateDealModal =
  ({ dealType, assets, raw_assets, modalProps }: T.CreateDealModalData) =>
  (onRequestClose: () => void) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbarCherre();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [stage, setStage] = useState<T.DealStageType>('Underwriting');
    const [lead, setLead] = useState<string | null>(null);
    const [broker, setBroker] = useState<string | null>(null);
    const [seller, setSeller] = useState<string | null>(null);
    const [ilo, setILO] = useState<string | null>(null);
    const [dealTeam, setDealTeam] = useState<number>(() => {
      const initialTeam = modalProps.teams.find(
        ({ name }) => name === 'Industrial'
      );

      if (!initialTeam) {
        throw new Error('Industrial team not found');
      }

      return initialTeam.id;
    });
    const [investmentType, setInvestmentType] = useState<number | undefined>(
      undefined
    );

    const [lastActivityNote, setLastActivityNote] = useState('');
    const [loading, setIsLoading] = useState(false);
    const { disableNextReset } = useDisableNextCoreExploreReset();

    const user = useSelector((state) => state.user.profile.value);

    const send = useRecoilCallback(
      ({ refresh, set }) =>
        async () => {
          if (loading) {
            return;
          }
          setIsLoading(true);
          try {
            if (!lead) {
              throw new Error('Deal must have an Acquisitions Associate');
            }
            const isMultiFamily =
              modalProps.teams.find(({ id }) => id === dealTeam)?.name ===
              'Multi-Family';
            if (isMultiFamily && !investmentType) {
              throw new Error('Multi-Family deal must have an investment type');
            }
            if (dealType === 'portfolio') {
              if (!name) {
                throw new Error('Deal must have a name');
              }
              set(localDeal('new'), {
                assets: [],
                dealName: name,
                description,
                lastActivityNote,
                lastActivityDate: new Date().toISOString(),
                lead,
                seller,
                broker,
                ilo,
                dealTeam,
                investmentType: isMultiFamily ? investmentType : undefined,
                stage,
                status: 'active',
              });
              disableNextReset();
              dispatch(push('/portfoliodeal/new'));
            } else {
              const body = F.modalToCreateData({
                name: raw_assets[0]?.address || '',
                description,
                stage,
                lead,
                seller,
                broker,
                ilo,
                lastActivityNote,
                user,
                raw_assets,
                dealTeam,
                investmentType,
              });
              const response = await axios.post<T.PortfolioDeal>(
                '/api/v1/deals/create',
                body
              );
              refresh(dealPortfolioSelectorFamily(response.data.dealId));
              refresh(dealPortfolioAllSelector({}));

              if (raw_assets[0]?.tax_assessor_id) {
                refresh(
                  dealPortfolioByTaxIdSelector(
                    Number(raw_assets[0]?.tax_assessor_id)
                  )
                );
              }

              dispatch(
                execQuery({
                  id: 'CreateDeal',
                  cards: true,
                  count: true,
                  rebound: false,
                  results: true,
                  viewportOnly: true,
                })
              );
              enqueueSnackbar(
                'Deal was created.',
                U.getSnackbarProps('success')
              );
            }
            onRequestClose();
          } catch (e) {
            const msg = U.getErrorMsg(e, 'Failed to create the deal');
            enqueueSnackbar(msg, U.getSnackbarProps('error'));
          } finally {
            setIsLoading(false);
          }
        },
      [
        name,
        description,
        stage,
        lead,
        seller,
        broker,
        ilo,
        lastActivityNote,
        loading,
        dealTeam,
        investmentType,
      ]
    );
    return {
      ...modalProps,
      selectedStage: stage,
      setSelectedStage: setStage,
      selectedLead: lead,
      setSelectedLead: setLead,
      selectedBroker: broker,
      setSelectedBroker: setBroker,
      selectedSeller: seller,
      setSelectedSeller: setSeller,
      selectedILO: ilo,
      setSelectedILO: setILO,
      note: lastActivityNote,
      setNote: setLastActivityNote,
      send,
      name,
      setName,
      description,
      setDescription,
      loading,
      assets,
      dealId: undefined,
      showAssets: false,
      dealTeam,
      setDealTeam,
      investmentType,
      setInvestmentType,
    };
  };

export type CreateDealModalHook = ReturnType<typeof useCreateDealModal>;

export const useCreateDeal = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbarCherre();

  const [loading, setLoading] = useState(false);

  const showCreateDialog = useRecoilCallback(
    ({ snapshot }) =>
      async (taxId?: number) => {
        if (loading) {
          return;
        }

        setLoading(true);

        //retain snapshot for async operations
        const release = snapshot.retain();

        try {
          // get data
          const modalProps = await getCommonData(snapshot);

          modalProps.stages = modalProps.stages.filter((s) => s !== 'Inactive');

          const taxAssessorsResult = taxId
            ? await snapshot.getPromise(taxAssessorsQuery({ ids: [taxId] }))
            : { tax_assessor_v2: [] };

          const taxAssessors = taxAssessorsResult.tax_assessor_v2
            .map<T.DataFormatter | null>((tax) => {
              return tax ? usaDataFormatter(tax) : null;
            })
            .filter((t): t is T.DataFormatter => Boolean(t));

          const assetsCount = taxAssessors.length;

          if (assetsCount === 1) {
            const hook = useCreateDealModal({
              dealType: 'single',
              modalProps: {
                ...modalProps,
                ...F.taxAssessorToDealData(taxAssessorsResult),
              },
              assets: taxAssessors,
              raw_assets: taxAssessorsResult.tax_assessor_v2,
            });

            dispatch(showModal('DEALCLOUD', { hook, mode: 'create' }));
          } else {
            const hook = useCreateDealModal({
              dealType: 'portfolio',
              modalProps,
              assets: taxAssessors,
              raw_assets: taxAssessorsResult.tax_assessor_v2,
            });

            dispatch(
              showModal('DEALCLOUD_PORTFOLIO', { hook, mode: 'create' })
            );
          }
        } catch (e) {
          const msg = U.getErrorMsg(e);
          enqueueSnackbar(msg, U.getSnackbarProps('error'));
          throw e;
        } finally {
          setLoading(false);
          release();
        }
      },
    [loading]
  );

  return [showCreateDialog, loading] as const;
};
