import React from 'react';
import PropTypes from 'prop-types';
import { FormSelect } from './dynamicSelect';
import DynamicText from './dynamicText';
import DynamicDate from './dynamicDate';
import UploadCsv from './uploadCsv';
import { FormCheckbox } from './dynamicCheckbox';
import DynamicLabel from './dynamicLabel';
import FooterButtons from '../footerbuttons';
import { Form, Field } from 'react-final-form';
import * as validators from 'src/products/shell/forms/validators';
import DynamicHidden from './dynamicHidden';
import UploadImage from './uploadImage';
import { mapValues } from 'lodash';

const typeComponentMap = {
  label: DynamicLabel,
  textbox: DynamicText,
  image: UploadImage,
  date: DynamicDate,
  select: FormSelect,
  checkbox: FormCheckbox,
  hidden: DynamicHidden,
  csv: UploadCsv,
};

const composeValidators = (validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const getField = (field, form) => {
  const validatorFunctions = [];

  if (field.required) {
    validatorFunctions.push(validators['required']);
  }

  if (field.validation && Array.isArray(field.validation)) {
    field.validation.forEach((value) => {
      validatorFunctions.push(validators[value]);
    });
  }

  const additionalProps = {};

  switch (field.type) {
    case 'integer':
    case 'number':
      additionalProps.type = 'number';
      break;
    case 'longString':
      additionalProps.multiline = true;
      break;
  }

  if (typeof typeComponentMap[field.interface] === 'undefined') {
    return null;
  }

  const formFieldComponent = typeComponentMap[field.interface];

  if (typeof formFieldComponent === 'undefined') {
    const err = `invalid field interface specified in dynamic form, Field: ${field.name}, Interface:${field.interface}.`;
    console.error(err);
    return null;
  }

  const renderField = (
    <Field
      validate={composeValidators(validatorFunctions)}
      component={formFieldComponent}
      key={field.name}
      name={field.name}
      parse={(value) => value}
      {...additionalProps}
      {...field}
      form={form}
    />
  );

  if (field.condition) {
    return (
      <Condition when={field.condition.when} is={field.condition.equalTo}>
        {renderField}
      </Condition>
    );
  }

  return renderField;
};

const DynamicForm = (props) => {
  const requestForm = props.RequestForm;
  const { submitButtonText, onSubmit, initialValues } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, submitting, valid, form }) => {
        const formFields = React.useMemo(
          () =>
            requestForm.fields.map((field) => {
              let _field = { ...field };

              _field.attr = _field.attr ? _field.attr : {};
              _field.attr.handleValueChange = (valueObj) => {
                this.setState(valueObj);
              };

              _field.attr.style = {
                width:
                  _field.attr.style && _field.attr.style.width
                    ? _field.attr.style.width
                    : 466,
                fontSize: '14px',
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '15px',
                marginTop: '15px',
              };

              const dynamicProps = mapValues(_field.dynamicProps, (fn) => {
                return fn({ operation: props.operation });
              });

              _field = {
                ..._field,
                ...dynamicProps,
              };

              return getField(_field, form);
            }),
          [requestForm.fields]
        );

        return (
          <form
            onSubmit={(...args) => {
              handleSubmit(...args);
            }}
            noValidate
            autoComplete='off'
          >
            {formFields}
            <FooterButtons
              valid={valid}
              pristine={pristine}
              submitting={submitting}
              buttonText={submitButtonText}
            />
          </form>
        );
      }}
    />
  );
};

DynamicForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  RequestForm: PropTypes.object,
  submitButtonText: PropTypes.string,
};

export default DynamicForm;
