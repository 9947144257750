import { useRecoilValueLoadable, selector } from 'recoil';

export const LookupsByTableNamesSelector = selector({
  key: 'fetchDataSelector',
  get: async () => {
    const response = await fetch('/api/v1/lookups/by-table-name', {
      headers: { 'Content-Type': 'application/json' },
    });
    return await response.json();
  },
});

export const useLookupsByTableNames = () => {
  return useRecoilValueLoadable(LookupsByTableNamesSelector);
};
