import React from 'react';

import TextField from '@material-ui/core/TextField';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';

const PopoverSelection = (props) => {
  const { title, handleClick, autoFocus, label } = props;
  return (
    <div>
      <TextField
        value={title}
        label={label}
        onClick={handleClick}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleClick(e);
          }
        }}
        style={{ width: '100%' }}
        readOnly
        autoFocus={autoFocus}
        variant='outlined'
        size='small'
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <ExpandMore />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default PopoverSelection;
