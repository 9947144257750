export default {
  label: {
    fontSize: 12,
    width: '100%',
  },
  labelReversed: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0,
  },
};
