import { ConnectorStatus } from 'src/products/connector/recoil';
import {
  Activity,
  ConnectorStatuses,
  ScheduleItem,
} from 'src/services/ConnectorDemo/data/ConnectorLanding';
import {
  aggregatedQualyticsValidationRules,
  landingPageValidationRuleSummary,
} from 'src/services/Qualytics/recoil';
import {
  graphQLSelector,
  selector,
  GraphQLReturn,
} from '@cherre-frontend/data-fetching';
import { getActivityOverviewCheckFailures } from 'src/products/connector/pages/connectors-landing/utils/getActivityOverviewCheckFailures';
import { getComponentRunningMessage } from 'src/products/connector/pages/connectors-landing/utils/getComponentRunningMessage';
import { deduplicateDestinationsAcrossModels } from 'src/products/connector/utils/deduplicateDestinationsAcrossModels';
import { createConnectorStatusMessage } from 'src/products/connector/utils/createConnectorStatusMessage';
import { getEarliestSourceRun } from 'src/products/connector/pages/connectors-landing/utils/getEarliestSourceRun';
import { graphql } from 'react-relay';
import { recoilGetConnectorQuery } from './__generated__/recoilGetConnectorQuery.graphql';
import { printLocalDatetimeWithTimezone } from 'src/products/connector/utils/printLocalDatetimeWithTimezone';
import { buildConnectorAllStatuses } from 'src/products/connector/utils/buildConnectorAllStatuses';

export const connectorDataSelector = graphQLSelector({
  environment: 'dom',
  resetCache: false,
  query: graphql`
    query recoilGetConnectorQuery {
      connectors {
        displayName
        name
        statusObject {
          status
          isRunning
          runningDatetime
          endDatetime
          startDatetime
        }
        sources {
          provider
          name
          displayName
          createdAt
          schedule
          ingestRevisions {
            semanticVersion
          }
          statusObject {
            status
            isRunning
            runningDatetime
            endDatetime
            startDatetime
          }
        }
        models {
          provider
          name
          displayName
          createdAt
          publishedTables
          preconfiguredModelRef
          destinationStatuses {
            destinationName
            statusObject {
              status
              isRunning
            }
          }
          publishedRevisions {
            revisionNumber
          }
          publishedTablesObject {
            name
            schema
          }
          destinations {
            provider
            name
            displayName
          }
          statusObject {
            status
            isRunning
            runningDatetime
            endDatetime
            startDatetime
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetConnectorQuery>,
  mapVariables: () => () => ({}),
  mapResponse: ({ connectors }) => ({
    data: deduplicateDestinationsAcrossModels(connectors),
  }),
});

export const connectorLandingStatuses = selector<{
  data: ConnectorStatuses;
}>({
  key: 'DOM-CONNECTOR-LANDING-STATUSES',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<{ data: ConnectorStatuses }> => {
      const failedChecks = get(landingPageValidationRuleSummary);
      const connectors = get(connectorDataSelector());
      const numRunningConnectors = connectors.data.filter(
        (connector) => connector.statusObject?.isRunning
      ).length;
      const statuses = {
        activePipelines: connectors.data.length,
        runningConnectors: numRunningConnectors,
        failedChecks: failedChecks ? failedChecks.data.failed : 0,
      };

      return { data: statuses };
    },
});

export const connectorLandingActivityOverview = selector<{
  data: Activity[];
}>({
  key: 'DOM-CONNECTOR-LANDING-ACTIVITY-OVERVIEW',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<{ data: Activity[] }> => {
      const connectors = get(connectorDataSelector());
      const validationRules = get(aggregatedQualyticsValidationRules);

      const activities: Activity[] = [];

      if (validationRules) {
        connectors.data.forEach((connector) => {
          const { sources, models, statusObject } = connector;
          const { isRunning } = statusObject || ({} as any);

          const runningComponentMessage = getComponentRunningMessage(connector);

          const numFailedValidationChecks = getActivityOverviewCheckFailures(
            connector,
            validationRules.data
          );
          const { failedConnectorComponent, connectorStatus } =
            buildConnectorAllStatuses({
              sources,
              models,
              status: statusObject?.status,
            });
          const connectorStatusMessage = createConnectorStatusMessage({
            sources,
            failedConnectorComponent,
            connectorEndDateTime: new Date().toISOString(),
            connectorStatus,
          });

          const lastRun = printLocalDatetimeWithTimezone(
            'MMM D YYYY h:mm A',
            connector.statusObject?.endDatetime
          );

          activities.push({
            connectorId: connector.name,
            activityName: connector.displayName ?? connector.name,
            connectorStatus,
            connectorStatusMessage,
            lastRun,
            numFailedValidationChecks,
            connectorRunningObject: {
              isRunning: Boolean(isRunning),
              runningMessage: runningComponentMessage ?? '',
            },
          });
        });
        return { data: activities };
      }
      return { data: [] };
    },
});
export const connectorLandingSources = selector<{
  data: [];
}>({
  key: 'DOM-CONNECTOR-LANDING-SOURCES',
  get: async (): Promise<{ data: [] }> => {
    return { data: [] };
  },
});
export const connectorLandingSchedule = selector<{
  data: ScheduleItem[];
}>({
  key: 'DOM-CONNECTOR-LANDING-SCHEDULE',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<{ data: ScheduleItem[] }> => {
      const connectors = get(connectorDataSelector());

      const schedule: ScheduleItem[] = connectors.data
        .filter((connector) => connector.sources.length > 0)
        .map((connector) => {
          const sourcesWithSchedule = connector.sources.filter(
            (source) => source.schedule !== '@once'
          );

          if (sourcesWithSchedule.length === 0) {
            return null;
          }

          const earliestRunSource = getEarliestSourceRun(sourcesWithSchedule);

          return {
            connectorId: connector.name,
            name: connector.name,
            displayName: connector.displayName ?? connector.name,
            runStatus: ConnectorStatus.SCHEDULED,
            cronExpression:
              earliestRunSource?.schedule ?? sourcesWithSchedule[0].schedule,
          };
        })
        .filter((connector): connector is ScheduleItem => Boolean(connector));
      return { data: schedule };
    },
});
