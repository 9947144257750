import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showModal } from 'src/products/shell/redux/modals/actions';
import fillTemplate from 'src/utils/fillTemplate';
import constants from 'src/const';

import {
  SupervisorAccount,
  Lock,
  Unlock,
  Edit,
  List,
  Sync,
  Send,
  SettingsApplications,
  DashboardIcon,
  ToggleOn,
} from './icons';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import {
  allowCustomerAdminAndCherreAdmin,
  customerUsersIsVisibleByPermissions,
} from './utils';
import { isAllowed } from 'src/utils/routing/isAllowed';
import { SwitchAccountIcon } from '@cherre-frontend/ui';

import { reassignUserIdSearchParam } from 'src/products/admin/pages/getTableComponent.js';

const styles = {
  iconButton: {
    '&:hover': {
      background: 'rgba(0,0,0,0.08)',
    },
  },
  cell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const restrictedEmulateNonIDAdminPermissions = [
  'IDAdmin',
  'UserManagement',
  'CherreAdmin',
  'CherreAdminReadOnly',
];

export class Actions extends React.Component {
  state = { anchorEl: null };

  setAnchorEl(anchorEl) {
    this.setState({ anchorEl });
  }

  handleClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  render() {
    const {
      row,
      showModal,
      onDelete,
      classes,
      params,
      refresh,
      user,
      recoilCallback,
    } = this.props;

    const { schema } = this.context;
    const isCherreAdmin = isAllowed(user.permissions, ['CherreAdmin']);

    const open = Boolean(this.state.anchorEl);

    const options = [
      {
        name: 'edit-user-dashboards',
        label: <span>Dashboards</span>,
        icon: <DashboardIcon />,
        onClick: () =>
          this.props.push(`${window.location.pathname}/${row.id}/dashboards`),
        isVisible: (user, row, schema) => {
          if (row?.role === 'id_admin') {
            return false;
          }

          return customerUsersIsVisibleByPermissions(user, row, schema, [
            'CherreAdmin',
            'UserManagement',
          ]);
        },
      },
      {
        name: 'deploy-migrations',
        label: (
          <span>
            Deploy <b>All</b> Migrations
          </span>
        ),
        onClick: () =>
          showModal('DEPLOY_MIGRATIONS', {
            refresh,
            schema,
            row,
          }),
      },
      {
        name: 'flush-sessions',
        label: (
          <span>
            Clear <b>All</b> User Sessions
          </span>
        ),
        onClick: () =>
          showModal('CLEAR_SESSIONS', {
            refresh,
            schema,
            row,
          }),
      },
      {
        name: 'resolve-migration-as-applied',
        label: (
          <span>
            Resolve as <b>Applied</b>: {row.migrationName}
          </span>
        ),
        onClick: () =>
          showModal('RESOLVE_MIGRATION', {
            refresh,
            schema,
            row,
            as: 'applied',
          }),
      },
      {
        name: 'resolve-migration-as-applied',
        label: (
          <span>
            Resolve as <b>Rolled Back</b>: {row.migrationName}
          </span>
        ),
        onClick: () =>
          showModal('RESOLVE_MIGRATION', {
            refresh,
            schema,
            row,
            as: 'rolled-back',
          }),
      },
      {
        name: 'resolve-migration-as-reseted',
        label: (
          <span>
            <b>Reset</b>: {row.migrationName}
          </span>
        ),
        onClick: () =>
          showModal('RESOLVE_MIGRATION', {
            refresh,
            schema,
            row,
            as: 'reseted',
          }),
      },
      {
        name: 'edit',
        label: 'Edit',
        onClick: () => this.props.push(`${window.location.pathname}/${row.id}`),
        icon: <Edit />,
        isVisible: (user, row, schema) => {
          return (
            allowCustomerAdminAndCherreAdmin(user, row, schema) &&
            customerUsersIsVisibleByPermissions(user, row, schema, [
              'CherreAdmin',
              'UserManagement',
              'IDAdmin',
              'RoleAdmin',
            ])
          );
        },
      },
      {
        name: 'emulate',
        label: 'Emulate User',
        onClick: () => {
          const emulateUrl = fillTemplate(schema.emulateEndpoint, {
            row,
            params,
          });

          sessionStorage.setItem(constants.IS_EMULATED_SESSION_KEY, true);

          window.location.href = emulateUrl;
        },
        icon: <SupervisorAccount />,
        isVisible: (user, row) => {
          const canEmulateUser = isAllowed(user.permissions, ['EmulateUser']);
          if (canEmulateUser) {
            return true;
          }

          const canEmulateNonIdAdmin = isAllowed(user.permissions, [
            'EmulateNonIDAdmin',
          ]);
          if (!canEmulateNonIdAdmin) {
            return false;
          }

          const hasRestrictedPermission = (row.permissions ?? []).some(
            (permission) =>
              restrictedEmulateNonIDAdminPermissions.includes(permission)
          );

          return !hasRestrictedPermission;
        },
      },
      {
        name: 'permissions',
        label: 'Permissions',
        isVisible: (user, row, schema) => {
          const authzVersionName = row.authzVersionName;

          return (
            allowCustomerAdminAndCherreAdmin(user, row, schema) &&
            customerUsersIsVisibleByPermissions(user, row, schema, [
              'CherreAdmin',
              'UserManagement',
              'IDAdmin',
              'ProductAdmin',
            ]) &&
            (!authzVersionName || authzVersionName === 'legacy')
          );
        },
        onClick: () =>
          this.props.push(`${window.location.pathname}/${row.id}/permissions`),
        icon: <List />,
      },
      {
        name: 'productAccess',
        label: 'Product Access',
        isVisible: (user, row, schema) => {
          const authzVersionName = row.authzVersionName;

          if (row?.role === 'id_admin') {
            return false;
          }

          return (
            allowCustomerAdminAndCherreAdmin(user, row, schema) &&
            customerUsersIsVisibleByPermissions(user, row, schema, [
              'CherreAdmin',
              'UserManagement',
              'ProductAdmin',
            ]) &&
            authzVersionName === 'cherre_product_roles'
          );
        },
        onClick: () =>
          this.props.push(
            `${window.location.pathname}/${row.id}/product-access`
          ),
        icon: <ToggleOn />,
      },
      {
        name: 'app-configuration',
        label: 'Configuration',
        onClick: () =>
          this.props.push(
            `${window.location.pathname}/${row.id}/configuration`
          ),
        isVisible: (user) => isAllowed(user.permissions, ['CherreAdmin']),
        icon: <SettingsApplications />,
      },
      {
        name: 'core-bi-configuration',
        label: 'Configuration',
        onClick: () =>
          this.props.push(
            `${window.location.pathname}/${row.id}/configuration`
          ),
        icon: <SettingsApplications />,
        isVisible: (user) => isAllowed(user.permissions, ['CherreAdmin']),
      },
      {
        name: 'organization-settings',
        label: 'Settings',
        onClick: () =>
          this.props.push(`${window.location.pathname}/${row.id}/settings`),
        icon: <SettingsApplications />,
        isVisible: (user) => isAllowed(user.permissions, ['CherreAdmin']),
      },
      {
        name: 'core-bi-dashboards',
        label: 'Dashboards',
        onClick: () =>
          this.props.push(`${window.location.pathname}/${row.id}/dashboards`),
        icon: <DashboardIcon />,
        isVisible: (user, row) => {
          if (row?.role === 'id_admin') {
            return false;
          }

          return isAllowed(user.permissions, ['CherreAdmin']);
        },
      },
      {
        name: 'core-bi-workspaces',
        label: 'Power BI Workspaces',
        onClick: () =>
          this.props.push(`${window.location.pathname}/${row.id}/workspaces`),
        icon: <DashboardIcon />,
        isVisible: (user, row, _) => {
          return (
            row?.role !== 'id_admin' &&
            isAllowed(user.permissions, ['CherreAdmin', 'PowerBIAdmin'])
          );
        },
      },
      {
        name: 'disable',
        label: 'Disable',
        onClick: () =>
          showModal('DELETE_TABLE_ITEM', {
            onDelete: onDelete.bind(null, row.id),
            schema,
            row,
          }),
        icon: <Lock />,
        isVisible: (user, row, schema) => {
          return (
            !row.disabled &&
            allowCustomerAdminAndCherreAdmin(user, row, schema) &&
            customerUsersIsVisibleByPermissions(user, row, schema)
          );
        },
      },
      {
        name: 'changeUserOrg',
        label: 'Change Org',
        onClick: () =>
          showModal('CHANGE_USER_ORG', {
            user: row,
          }),
        icon: <Sync />,
        isVisible: (user) => isAllowed(user.permissions, ['CherreAdmin']),
      },
      {
        name: 're-enable-organization',
        label: 'Re-enable',
        onClick: () =>
          showModal('REENABLE_ORGANIZATION', {
            refresh,
            schema,
            row,
          }),
        icon: <Unlock />,
        isVisible: (user, row) => {
          if (!isAllowed(user.permissions, ['CherreAdmin'])) {
            return false;
          }
          return row.disabled;
        },
      },
      {
        name: 're-enable',
        label: 'Re-enable',
        onClick: () =>
          showModal('REENABLE_USER', {
            schema,
            row,
          }),
        icon: <Unlock />,
        isVisible: (user, row, schema) => {
          return (
            row.disabled &&
            allowCustomerAdminAndCherreAdmin(user, row, schema) &&
            customerUsersIsVisibleByPermissions(user, row, schema)
          );
        },
      },
      {
        name: 'welcome-email',
        label: 'Welcome Email',
        onClick: () =>
          showModal('RESEND_EMAIL', {
            refresh,
            schema,
            row,
          }),
        icon: <Send />,
        isVisible: (user, row) =>
          !row.disabled && isAllowed(user.permissions, ['CherreAdmin']),
      },
      {
        name: 'edit-api-key',
        label: 'Edit',
        onClick: () =>
          showModal('EDIT_API_KEY', {
            refresh,
            schema,
            row,
            isCherreAdmin,
          }),
        icon: <Lock />,
        isVisible: (user, row, schema) => {
          if (!allowCustomerAdminAndCherreAdmin(user, row, schema)) {
            return false;
          }
          return row.status === 'active';
        },
      },
      {
        name: 'disable-api-key',
        label: 'Disable',
        onClick: () =>
          showModal('DISABLE_API_KEY', {
            refresh,
            schema,
            row,
          }),
        icon: <Lock />,
        isVisible: (user, row, schema) => {
          if (!allowCustomerAdminAndCherreAdmin(user, row, schema)) {
            return false;
          }
          return row.status === 'active';
        },
      },
      {
        name: 'dsp-reassign-user',
        label: 'Reassign User',
        onClick: () => {
          recoilCallback().set(reassignUserIdSearchParam, row.id);
        },
        icon: <SwitchAccountIcon />,
        isVisible: (user, row) => {
          if (!isAllowed(user.permissions, ['DSPAdmin'])) {
            return false;
          }
          if (!['dsp_regular', 'dsp_admin'].includes(row.role)) {
            return false;
          }
          return true;
        },
      },
    ].filter(
      (permission) =>
        schema.actions.includes(permission.name) &&
        (!permission.isVisible || permission.isVisible(user, row, schema))
    );

    if (!options.length) {
      return null;
    }

    return (
      <div className={classes.cell}>
        <IconButton
          className={classes.iconButton}
          onClick={this.handleClick.bind(this)}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          id='table-page-actions-menu'
          anchorEl={this.state.anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {options.map((option) => (
            <MenuItem
              key={option.name}
              onClick={() => {
                option.onClick();
                this.handleClose();
              }}
            >
              {option.icon} {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

Actions.contextTypes = {
  schema: PropTypes.object,
};

Actions.propTypes = {
  classes: PropTypes.object,
  row: PropTypes.object,
  showModal: PropTypes.func,
  onDelete: PropTypes.func,
  schemaName: PropTypes.string,
  recoilCallback: PropTypes.any,
};

export default connect(
  (state) => ({
    user: state.user.profile.value,
  }),
  { showModal, push }
)(withStyles(styles)(withRouter(Actions)));
