import React from 'react';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import moment from 'moment';
import { Box } from '@material-ui/core';

const propertyStatus = {
  1: 'In Foreclosure',
  2: 'REO',
  3: 'Defeased',
  4: 'Partial Release/Release',
  5: 'Substituted',
  6: 'Same as at Contribution',
};

const TreppLoanDataModalHeader = ({ item }) => {
  return (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
      }}
      spacing={3}
    >
      <Typography variant='body2' style={{ color: grey[700] }}>
        Property Address
      </Typography>
      <Typography variant='body2'>
        <span style={{ color: grey[700] }}>Status: </span>
        <span style={{ color: grey[900] }}>
          {propertyStatus[item.property_status]}
        </span>
      </Typography>

      <Typography style={{ fontWeight: 'bold' }}>
        {`${item.address}, ${item.city}, ${item.state} ${item.zip_code}`}
      </Typography>
      <Typography variant='body2'>
        <span style={{ color: grey[700] }}>Since: </span>
        <span style={{ color: grey[900] }}>
          {moment(item.property_status_change_date).format('MMM DD, YYYY')}
        </span>
      </Typography>

      <Typography>Newly on Watchlist</Typography>
      <Typography variant='body2'>
        <span style={{ color: grey[700] }}>Since: </span>
        <span style={{ color: grey[900] }}>
          {moment(new Date()).format('MMM DD, YYYY')}
        </span>
      </Typography>

      {/* <Typography>Watchlist Status</Typography>
      <Typography variant='body2'>
        <span style={{ color: grey[900] }}>Performing Loan</span>
      </Typography> */}
    </Box>
  );
};

export default TreppLoanDataModalHeader;
