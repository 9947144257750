import React from 'react';
import { Voyager } from 'graphql-voyager';
import './styles.less';

const introspectionProvider =
  (api: 'CoreAPI' | 'AnalyticsAPI') =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (query: string): Promise<any> => {
    const url =
      api === 'AnalyticsAPI'
        ? `/api/v1/voyager/introspection-analytics`
        : '/api/v1/voyager/introspection';
    const resp = await fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    });

    if (api === 'AnalyticsAPI') {
      const respQuery = await resp.json();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const faultyTypes = [] as any[];
      let types = respQuery.data.__schema.types.filter((t) => {
        if (
          t.kind === 'INPUT_OBJECT' &&
          t.fields.length === 0 &&
          t.inputFields.length === 0
        ) {
          faultyTypes.push(t.name);
          return false;
        }
        return true;
      });

      types = types.map((t) => {
        if (t.fields) {
          const fields = t.fields.map((f) => {
            if (f.args) {
              const args = f.args
                .map((a) => {
                  if (faultyTypes.includes(a?.type?.name)) {
                    return null;
                  }
                  return a;
                })
                .filter(Boolean);
              return { ...f, args };
            }
            return f;
          });
          return { ...t, fields };
        }
        return t;
      });

      return {
        data: {
          ...respQuery.data,
          __schema: { ...respQuery.data.__schema, types },
        },
      };
    }

    return resp.json();
  };

type VoyagerProps = {
  api: 'CoreAPI' | 'AnalyticsAPI';
};

const VoyagerComponent: React.FC<VoyagerProps> = ({ api }) => {
  return (
    <div
      style={{
        height: 'calc(100vh - 60px)',
        overflow: 'hidden',
      }}
    >
      <Voyager
        introspection={introspectionProvider(api)}
        displayOptions={{ showLeafFields: true }}
      />
    </div>
  );
};

export default VoyagerComponent;
