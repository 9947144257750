import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { CustomListItem, ListItemTextStyles } from '../TreeCustomComponents';
import ExpansionIconWithoutClick from '../ExpansionIconWithoutClick';

const LabelListItem = (props) => {
  const {
    idData,
    changePanelExpansion,
    node,
    depth,
    disabled,
    showExpansionIcon,
    bold,
  } = props;

  if (idData[node] === undefined) {
    return null;
  }

  return (
    <CustomListItem
      key={node}
      component='div'
      onClick={() => {
        changePanelExpansion(idData[node]);
      }}
      id='js-label-list-item'
    >
      {showExpansionIcon(idData[node]) ? (
        <ExpansionIconWithoutClick {...props} node={node} />
      ) : null}
      <div
        style={{ height: 34, display: 'flex', alignItems: 'center' }}
        title={idData[node].tooltip}
      >
        <ListItemText
          disableTypography
          primary={`${idData[node].label}`}
          style={
            ListItemTextStyles(
              depth,
              bold(idData[node]),
              disabled ? disabled[node] === true : false
            ).normal
          }
        />
      </div>
    </CustomListItem>
  );
};

export default LabelListItem;
