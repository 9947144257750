import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';

import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import NavigationGroup, { getItemProps } from './NavigationGroup';
import NavigationItem from './NavigationItem';

import grey from '@material-ui/core/colors/grey';
import { routesListFlat } from 'src/products/shell/apps/cherre/Routes';
import { push } from 'connected-react-router';
import { groupBy } from 'lodash';
import { isAllowed } from 'src/utils/routing/isAllowed';
import { usePage } from 'src/utils/routing/CherreRoute';

const styles = (theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    minHeight: 66,
    padding: '18px 16px 18px 0',
    color: grey[900],
  },
  listItemActive: {
    background: '#eee',
  },
});

const MENU_GROUP_ORDER = [
  'Libraries',
  'CoreExplore',
  'Data Submission Portal',
  'CoreConnect',
  'Management',
];

const NavigationDrawer = (props) => {
  const [, active] = usePage();
  const { isOpen, classes, onClose, hasNoPermissions } = props;

  const router = {
    push: (params) => props.dispatch(push(params)),
  };

  const { user } = useSelector((state) => ({
    user: state.user.profile.value,
  }));

  const menuRoutes = routesListFlat
    .filter((r) => r.menu)
    .filter(
      (r) =>
        isAllowed(r.permissions, user.permissions + ['Global']) &&
        (!r.menu.isVisible || r.menu.isVisible(user))
    );
  const menuRoutesWithoutGroups = menuRoutes.filter((r) => !r.menu.group);
  const menuRoutesGroups = groupBy(
    menuRoutes.filter((r) => r.menu.group),
    'menu.group'
  );

  return (
    <Drawer
      id='js-navigation-drawer'
      anchor='left'
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.header}>
        <IconButton onClick={onClose}>
          <ArrowBack style={{ fill: grey[900] }} />
        </IconButton>
        <span
          style={{
            position: 'relative',
            top: 1,
            paddingLeft: 10,
            color: grey[900],
            fontSize: 20,
            fontFamily: 'Montserrat',
          }}
        >
          Menu
        </span>
      </div>

      {hasNoPermissions ? (
        <div style={{ padding: '80px' }}>No apps available</div>
      ) : (
        <React.Fragment>
          {menuRoutesWithoutGroups.map((r) => (
            <NavigationItem
              key={r.path}
              {...getItemProps({
                ...props,
                router,
                route: r,
                activeRoute: active,
              })}
            />
          ))}
          {Object.entries(menuRoutesGroups)
            .sort((a, b) => {
              return (
                MENU_GROUP_ORDER.indexOf(a[0]) - MENU_GROUP_ORDER.indexOf(b[0])
              );
            })
            .map(([groupName, routes]) => (
              <NavigationGroup
                key={groupName}
                user={user}
                onClose={onClose}
                groupName={groupName}
                activeRoute={active}
                routes={routes}
              />
            ))}
        </React.Fragment>
      )}
    </Drawer>
  );
};

NavigationDrawer.propTypes = {
  isOpen: PropTypes.bool,
  classes: PropTypes.object,
  userRole: PropTypes.string,
  onClose: PropTypes.func,
  hasNoPermissions: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    user: {
      profile: {
        value: { permissions },
      },
    },
  } = state;

  return {
    hasNoPermissions: (permissions || []).length === 0,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(NavigationDrawer));
