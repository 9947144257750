import { useState, useEffect } from 'react';

import { useManualQuery } from 'graphql-hooks';
import { transformOrderBy } from 'src/utils/query';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import {
  queryEnd,
  queryStart,
} from 'src/products/core-prospect/redux/graphqlRequests/actions';

function transformSearch(search) {
  return `%${search}%`;
}
/**
 * @todo Refactor with relay + recoil
 * @deprecated
 */
export default function useCherreQuery({
  query,
  getVariables,
  handleMore,
  offset: initialOffset = 0,
  limit: initialLimit = 100,
  orderBy: initialOrderBy,
  shouldExecute = true,
  /*
    provide queryName in case you want to dispatch
    QUERY_START, QUERY_END actions to monitor requests at redux
  */
  queryName = '',
}) {
  const [search, setSearch] = useState();
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const variables = getVariables({
    offset: initialOffset,
    limit: initialLimit,
    search: search ? transformSearch(search) : undefined,
    orderBy: orderBy ? transformOrderBy(orderBy) : undefined,
  });

  const [fetchData, { loading, data, error }] = useManualQuery(query, {
    variables,
  });

  const dispatch = useDispatch();

  const _fetchData = async (...args) => {
    const id = uniqueId();

    if (queryName) {
      dispatch(queryStart(queryName, id));
    }

    try {
      return await fetchData(...args);
    } finally {
      if (queryName) {
        dispatch(queryEnd(id));
      }
    }
  };

  useEffect(() => {
    if (shouldExecute) {
      _fetchData();
    }
  }, [shouldExecute, query, JSON.stringify(variables)]);

  const getMore = ({ offset, limit }) => {
    _fetchData({
      variables: Object.assign({}, variables, {
        offset,
        limit,
      }),
      updateData: handleMore,
    });
  };

  return {
    fetchData: _fetchData,
    setSearch,
    setOrderBy,
    getMore,
    data,
    isLoading: loading,
    error,
    limit: initialLimit,
    orderBy,
    search,
  };
}
