import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import { Tooltip, Button, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import Title from './Title';
import Search from './Search';

export function Toolbar({
  classes = {},
  buttonText,
  title,
  onAddButtonClick,
  onBackButtonClick,
  onSearch,
  disableSearch,
  isAddButtonDisabled,
  searchValue,
  stores,
}) {
  const dispatch = useDispatch();

  const params = useParams();

  const [addButtonDisable, setAddButtonDisable] = React.useState(
    !!isAddButtonDisabled
  );
  const [addButtonTooltipMessage, setAddButtonTooltipMessage] =
    React.useState('');

  React.useEffect(() => {
    if (isAddButtonDisabled) {
      isAddButtonDisabled({
        stores,
        dispatch,
        callback: setAddButtonDisable,
        messageCallback: setAddButtonTooltipMessage,
        params,
      });
    }
  }, [stores]);

  return (
    <div id='table-page-toolbar' className={classes.root}>
      <div className={classes.title}>
        {onBackButtonClick && (
          <IconButton
            id='table-page-toolbar-back-button'
            onClick={onBackButtonClick}
            style={{ color: '#000' }}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Title text={title} />
        {onAddButtonClick && (
          <Tooltip title={addButtonTooltipMessage}>
            <Box>
              <Button
                id='table-page-toolbar-add-button'
                onClick={onAddButtonClick}
                color='primary'
                variant='contained'
                disabled={addButtonDisable}
              >
                {buttonText}
              </Button>
            </Box>
          </Tooltip>
        )}
      </div>
      {!disableSearch && (
        <Search searchValue={searchValue} onSearch={onSearch} />
      )}
    </div>
  );
}

Toolbar.propTypes = {
  classes: PropTypes.object,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  onAddButtonClick: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  onSearch: PropTypes.func,
  disableSearch: PropTypes.bool,
  isAddButtonDisabled: PropTypes.func,
  stores: PropTypes.object,
  searchValue: PropTypes.string,
};

Toolbar.defaultProps = {
  buttonText: 'add',
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
};

export default withRouter(withStyles(styles)(Toolbar));
