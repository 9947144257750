import {
  OverviewCardType,
  OverviewType,
} from 'src/products/connector/pages/connector-details/components/Overview/OverviewCard';
import { StatusBoxState } from 'src/products/connector/pages/connector-details/components/StatusBox';
import { ConnectorStatus } from 'src/products/connector/recoil';

export type ConnectorDetailOverview = {
  name: string;
  displayName: string;
  status: ConnectorStatus;
  statusMessage: string;
  failedComponentName?: string;
  failedConnectorComponent?: string;
  connectorStatusMessage?: string;
  connectorRunningObject: {
    isRunning: boolean;
    runningMessage: string;
  };
  created: string;
  lastRun?: string | null;
  updateSchedule: string;
  connectorStatus: {
    sources: StatusBoxState;
    models: StatusBoxState;
    destinations: StatusBoxState;
  };
  sources: OverviewCardType<OverviewType.SOURCE>[];
  models: (OverviewCardType<OverviewType.MODEL> & {
    preconfiguredModelRef: string | null;
  })[];
  destinations: OverviewCardType<OverviewType.DESTINATION>[];
};

export const overviewData: ConnectorDetailOverview[] = [
  {
    name: 'chatham-warehouse',
    displayName: 'Chatham to Warehouse',
    status: ConnectorStatus.SUCCESS,
    statusMessage: 'Success Apr 26 9:00 PM ET',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
    created: 'Feb 1, 2024 11:03 AM ET',
    lastRun: 'Feb 1, 2024 1:12 PM ET',
    updateSchedule: 'Nightly',
    connectorStatus: {
      sources: StatusBoxState.SUCCESS,
      models: StatusBoxState.LOADING,
      destinations: StatusBoxState.SCHEDULED,
    },
    sources: [
      {
        type: OverviewType.SOURCE,
        name: 'chatham-api',
        displayName: 'Chatham API',
        revision: '1',
        created: 'Feb 1, 2024',
        creator: 'Cherre',
        sourceAvailable: '11:00 PM ET',
        statusObject: {
          isRunning: false,
          status: ConnectorStatus.SUCCESS,
        },
      },
    ],
    models: [
      {
        type: OverviewType.MODEL,
        name: 'chatham-source-model',
        displayName: 'Chatham Source Model',
        revision: '1',
        created: 'Feb 1, 2024',
        creator: 'Cherre',
        provider: 'cherre',
        preconfiguredModelRef: null,
        statusObject: {
          isRunning: false,
          status: ConnectorStatus.SUCCESS,
        },
      },
    ],
    destinations: [
      {
        type: OverviewType.DESTINATION,
        name: 'snowflake',
        displayName: 'Snowflake',
        revision: '1',
        created: 'Feb 1, 2024',
        creator: 'Cherre',
        frequency: 'Monthly Every 10th',
        runTime: '11:00 PM ET',
        provider: 'snowflake',
      },
    ],
  },
  {
    name: 'vts-leasing-warehouse',
    displayName: 'VTS Leasing to Warehouse',
    status: ConnectorStatus.SUCCESS,
    statusMessage: 'Success Apr 10 9:30 PM',
    created: 'Feb 15, 2024 11:03 AM ET',
    connectorRunningObject: {
      isRunning: true,
      runningMessage: 'Source Running',
    },
    lastRun: 'Feb 15, 2024 1:12 PM ET',
    updateSchedule: 'Nightly',
    connectorStatus: {
      sources: StatusBoxState.SUCCESS,
      models: StatusBoxState.LOADING,
      destinations: StatusBoxState.SCHEDULED,
    },
    sources: [
      {
        type: OverviewType.SOURCE,
        name: 'vts-nextgen-api',
        displayName: 'VTS NextGen API',
        revision: '1',
        created: 'Feb 15, 2024',
        creator: 'Cherre',
        sourceAvailable: '11:00 PM ET',
        provider: 'vts',
        statusObject: {
          isRunning: true,
          status: ConnectorStatus.SUCCESS,
        },
      },
    ],
    models: [
      {
        type: OverviewType.MODEL,
        name: 'vts-source-model',
        displayName: 'VTS Source Model',
        revision: '1',
        created: 'Feb 15, 2024',
        creator: 'Cherre',
        provider: 'cherre',
        preconfiguredModelRef: null,
        statusObject: {
          isRunning: false,
          status: ConnectorStatus.SUCCESS,
        },
      },
    ],
    destinations: [
      {
        name: 'snowflake',
        displayName: 'Snowflake',
        revision: '1',
        created: 'Feb 15, 2024',
        creator: 'Cherre',
        frequency: 'Monthly Every 10th',
        runTime: '11:00 PM ET',
        provider: 'snowflake',
        type: OverviewType.DESTINATION,
      },
    ],
  },
  {
    name: 'dwellsy-test-feed',
    displayName: 'Dwellsy Test Feed',
    status: ConnectorStatus.FAILED,
    statusMessage: 'Source Failed Apr 10 9:30 PM ET',
    created: 'Feb 1, 2024 11:03 AM ET',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
    lastRun: 'Feb 1, 2024 1:12 PM ET',
    updateSchedule: 'Nightly',
    connectorStatus: {
      sources: StatusBoxState.FAILED,
      models: StatusBoxState.SCHEDULED,
      destinations: StatusBoxState.SCHEDULED,
    },
    sources: [
      {
        type: OverviewType.SOURCE,
        name: 'cherre-sftp-dwellsy',
        displayName: 'Cherre SFTP (Dwellsy)',
        revision: '1',
        created: 'March 1, 2024',
        creator: 'Cherre',
        sourceAvailable: '11:00 PM ET',
        provider: 'dwellsy',
        statusObject: {
          isRunning: false,
          status: ConnectorStatus.SUCCESS,
        },
      },
    ],
    models: [
      {
        type: OverviewType.MODEL,
        name: 'dwellsy-listings-source-model',
        displayName: 'Dwellsy Listings Source Model',
        revision: '1',
        created: 'March 1, 2024',
        creator: 'Cherre',
        provider: 'cherre',
        preconfiguredModelRef: null,
        statusObject: {
          isRunning: false,
          status: ConnectorStatus.SUCCESS,
        },
      },
    ],
    destinations: [
      {
        type: OverviewType.DESTINATION,
        name: 'snowflake',
        displayName: 'Snowflake',
        revision: '1',
        created: 'March 1, 2024',
        creator: 'Cherre',
        frequency: 'Monthly Every 10th',
        runTime: '11:00 PM ET',
        provider: 'snowflake',
      },
    ],
  },
  {
    name: 'dev-model-test-feed',
    displayName: 'Dev Model Test Feed',
    status: ConnectorStatus.FAILED,
    statusMessage: 'Source Failed Apr 10 9:30 PM ET',
    created: 'Feb 1, 2024 11:03 AM ET',
    lastRun: 'Feb 1, 2024 1:12 PM ET',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
    updateSchedule: 'Nightly',
    connectorStatus: {
      sources: StatusBoxState.FAILED,
      models: StatusBoxState.SCHEDULED,
      destinations: StatusBoxState.SCHEDULED,
    },
    sources: [
      {
        type: OverviewType.SOURCE,
        name: 'cherre-sftp-dwellsy',
        displayName: 'Cherre SFTP (Dwellsy)',
        revision: '1',
        created: 'March 1, 2024',
        creator: 'Cherre',
        sourceAvailable: '11:00 PM ET',
        provider: 'dwellsy',
        statusObject: {
          isRunning: false,
          status: ConnectorStatus.FAILED,
        },
      },
    ],
    models: [
      {
        type: OverviewType.MODEL,
        name: 'dwellsy-listings-source-model',
        displayName: 'Dwellsy Listings Source Model',
        revision: '1',
        created: 'March 1, 2024',
        creator: 'Cherre',
        provider: 'cherre',
        preconfiguredModelRef: null,
        statusObject: {
          isRunning: false,
          status: ConnectorStatus.SUCCESS,
        },
      },
    ],
    destinations: [
      {
        name: 'snowflake',
        displayName: 'Snowflake',
        revision: '1',
        created: 'March 1, 2024',
        creator: 'Cherre',
        frequency: 'Monthly Every 10th',
        runTime: '11:00 PM ET',
        provider: 'snowflake',
        type: OverviewType.DESTINATION,
      },
    ],
  },
];
