import { ConnectorDetailsRoute } from 'src/products/connector/routes';
import { ConnectorDetailOverview } from 'src/services/ConnectorDemo/data/ConnectorOverview';
import { connectorDataSelector } from '../../connectorLanding/recoil';
import {
  getTranslatedCronFrequency,
  getTranslatedCronFrequencyWithoutTime,
} from 'src/products/connector/pages/connector-inventory/utils/translatedCronFrequency';
import { getComponentRunningMessage } from 'src/products/connector/pages/connectors-landing/utils/getComponentRunningMessage';
import { createConnectorStatusMessage } from 'src/products/connector/utils/createConnectorStatusMessage';
import { OverviewType } from 'src/products/connector/pages/connector-details/components/Overview/OverviewCard';
import { selector, selectorFamily } from '@cherre-frontend/data-fetching';
import { printLocalDatetimeWithTimezone } from 'src/products/connector/utils/printLocalDatetimeWithTimezone';
import { ColumnItem } from 'src/services/OpenMetadata/types';
import { sortSemVer } from './utils';
import { buildConnectorAllStatuses } from 'src/products/connector/utils/buildConnectorAllStatuses';

export const connectorOverview = selector<{ data: ConnectorDetailOverview }>({
  key: 'DOM-CONNECTOR-OVERVIEW',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<{ data: ConnectorDetailOverview }> => {
      const connectors = get(connectorDataSelector());
      const connectorName = get(
        ConnectorDetailsRoute.routeParamSelector
      )?.connector_name;

      const connector = connectors.data.find(
        (connector) => connector.name === connectorName
      );

      if (!connector) {
        throw new Error('Connector not found');
      }
      const { sources, statusObject, models } = connector;

      const {
        failedConnectorComponent,
        connectorStatusObject,
        connectorStatus,
        failedComponentName,
      } = buildConnectorAllStatuses({
        sources,
        models,
        status: statusObject?.status,
      });

      const statusMessage = createConnectorStatusMessage({
        sources,
        failedConnectorComponent,
        connectorEndDateTime: statusObject?.endDatetime,
        connectorStatus,
        simplifiedErrorMessage: true,
      });

      const runningComponentMessage = getComponentRunningMessage(connector);

      const lastRun = printLocalDatetimeWithTimezone(
        'MMM D YYYY h:mm A',
        connector.statusObject?.endDatetime
      );

      const overviewContent: ConnectorDetailOverview = {
        name: connector.name ?? '',
        displayName: connector.displayName ?? connector.name,
        status: connectorStatus,
        statusMessage,
        connectorRunningObject: {
          isRunning: Boolean(connector.statusObject?.isRunning),
          runningMessage: runningComponentMessage ?? '',
        },
        created:
          connector.sources.length > 0
            ? printLocalDatetimeWithTimezone(
                'MMM D YYYY h:mm A',
                connector.sources[0].createdAt
              )
            : 'N/A',
        lastRun,
        updateSchedule:
          connector.sources.length > 0
            ? getTranslatedCronFrequencyWithoutTime(
                connector.sources[0].schedule
              )
            : 'N/A',
        connectorStatus: connectorStatusObject,
        failedComponentName,
        failedConnectorComponent,
        sources:
          connector.sources.map((source) => {
            return {
              type: OverviewType.SOURCE,
              name: source.name,
              displayName: source.displayName,
              provider: source.provider,
              revision:
                source.ingestRevisions
                  .map((r) => r.semanticVersion)
                  .sort(sortSemVer)
                  .pop() || 'Latest',
              created: printLocalDatetimeWithTimezone(
                'MMM D YYYY h:mm A',
                source.createdAt
              ),
              creator: 'Cherre',
              frequency: getTranslatedCronFrequency(source.schedule),
              statusObject: source.statusObject,
            };
          }) ?? [],
        models:
          connector.models.map((model) => {
            return {
              type: OverviewType.MODEL,
              name: model.name,
              displayName: model.displayName,
              revision: Math.max(
                ...model.publishedRevisions.map((r) => r.revisionNumber),
                0
              ).toString(),
              preconfiguredModelRef: model.preconfiguredModelRef,
              provider: model.provider,
              created: printLocalDatetimeWithTimezone(
                'MMM D YYYY h:mm A',
                model.createdAt
              ),
              creator: 'Cherre',
              statusObject: model.statusObject,
            };
          }) ?? [],
        destinations:
          connector.models.flatMap((model) =>
            model.destinations.map((destination) => {
              return {
                type: OverviewType.DESTINATION,
                name: destination.name,
                displayName: destination.displayName,
                revision: 'Latest',
                provider: destination.provider,
                created: printLocalDatetimeWithTimezone(
                  'MMM D YYYY h:mm A',
                  model.createdAt
                ),
                creator: 'Cherre',
              };
            })
          ) ?? [],
      };

      return { data: overviewContent };
    },
});

export const tableColumns = selectorFamily({
  key: 'CONNECTOR/TABLE-COLUMNS',
  scoped: true,
  get:
    (tableName: string) =>
    async ({ get }): Promise<ColumnItem[]> => {
      const connectors = get(connectorDataSelector());

      const models = connectors.data.flatMap((c) => c.models);
      const tables = models.flatMap((m) => m.publishedTablesObject);
      const table = tables.find((t) => t.name.endsWith(tableName));

      if (!table?.schema) {
        return [];
      }

      try {
        return JSON.parse(table.schema)?.map((s) => ({
          name: s.name,
          dataType: s.type,
          description: s.description,
        }));
      } catch (e) {
        return [];
      }
    },
});
