/**
 * @generated SignedSource<<67421bad520b7f6dc88b56b6c01e8782>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EgressedTablesInput = {
  revisionNumber: number;
  tables?: ReadonlyArray<string> | null;
};
export type updateModelMutation$variables = {
  displayName?: string | null;
  egressedTables?: ReadonlyArray<EgressedTablesInput | null> | null;
  modelName: string;
  schedule?: string | null;
};
export type updateModelMutation$data = {
  readonly updateModel: {
    readonly name: string;
  };
};
export type updateModelMutation = {
  response: updateModelMutation$data;
  variables: updateModelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "egressedTables"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "modelName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schedule"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "displayName",
        "variableName": "displayName"
      },
      {
        "kind": "Variable",
        "name": "egressedTables",
        "variableName": "egressedTables"
      },
      {
        "kind": "Variable",
        "name": "modelName",
        "variableName": "modelName"
      },
      {
        "kind": "Variable",
        "name": "schedule",
        "variableName": "schedule"
      }
    ],
    "concreteType": "Model",
    "kind": "LinkedField",
    "name": "updateModel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateModelMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateModelMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "e0414946583e37541f8c451e487f19f5",
    "id": null,
    "metadata": {},
    "name": "updateModelMutation",
    "operationKind": "mutation",
    "text": "mutation updateModelMutation(\n  $modelName: String!\n  $schedule: String\n  $displayName: String\n  $egressedTables: [EgressedTablesInput]\n) {\n  updateModel(modelName: $modelName, schedule: $schedule, displayName: $displayName, egressedTables: $egressedTables) {\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "acfb7aea4771462aefe074140cd7487f";

export default node;
