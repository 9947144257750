import { Component } from 'react';
import { findDOMNode } from 'react-dom';

const listeners = [];

window.addEventListener(
  'click',
  (e) => {
    listeners.forEach((listener) => {
      const node = findDOMNode(listener.element);

      if (!node) {
        return;
      }

      const ignoredElement = document.querySelector(listener.ignoreSelector);

      if (e.target === ignoredElement) {
        return;
      }

      if (!node.contains(e.target)) {
        listener.element.close(e);
      }
    });
  },
  true
);

export default class Closable extends Component {
  state = {
    active: false,
  };

  close() {
    if (this.state.active) {
      this.setState({ active: false });
    }
  }

  open() {
    if (!this.state.active) {
      this.setState({ active: true });
    }
  }

  componentWillMount() {
    listeners.push({
      element: this,
      ignoreSelector: this.getIgnoredSelector && this.getIgnoredSelector(),
    });
  }

  componentWillUnmount() {
    listeners.splice(listeners.indexOf(this), 1);
  }
}
