export const getErrorMsg = (e: any, defaultMsg = 'Unknown Error') => {
  const errors = e.response?.data?.errors;

  if (Array.isArray(errors) && errors.length > 0) {
    return errors[0].message;
  }

  let msg = defaultMsg;

  if (e instanceof Error) {
    msg = e.message;
  }
  if (typeof e === 'string') {
    msg = e;
  }
  return msg;
};

export const getSnackbarProps = (variant: 'success' | 'error') => ({
  variant,
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'top',
  } as const,
});
