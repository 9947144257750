import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const LayerGroupTitle = ({ classes, group }) => {
  return (
    <div className={classes.title}>
      <Typography component={'span'} variant={'h6'}>
        {group.groupName}
      </Typography>
    </div>
  );
};

const styles = {
  title: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default withStyles(styles)(LayerGroupTitle);
