import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import NavigationItem from './NavigationItem';

import { push } from 'connected-react-router';

import grey from '@material-ui/core/colors/grey';
import { routeWithoutParams } from 'src/utils/routing';

const styles = () => ({
  titleText: {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontColor: grey[900],
  },
  title: {
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  list: {
    width: 280,
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemActive: {
    background: '#eee',
  },
});

export const getClickHandler =
  (route, onClose, organizationId, router) => () => {
    router.push(
      route.getUrl
        ? route.getUrl({ organizationId })
        : routeWithoutParams(route.path)
    );

    if (typeof onClose === 'function') {
      onClose();
    }
  };

export const getItemProps = ({
  classes,
  activeRoute,
  onClose,
  organizationId,
  route,
  router,
}) => ({
  clickHandler: getClickHandler(route, onClose, organizationId, router),
  className: cx('', {
    [classes.listItemActive]: activeRoute?.path === route.path,
  }),
  pageName: route.menu.title || route.customHeader,
  listItemText: classes.listItemText,
  pageIcon: route.menu.icon,
  permissionHook: route.permissionHook,
});

export class NavigationGroup extends React.Component {
  state = {
    open: true,
  };

  open() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { groupName, routes, classes } = this.props;

    const router = {
      push: (params) => this.props.push(params),
    };

    if (routes.length === 0) {
      return null;
    }

    return (
      <List className={classes.list}>
        <ListItem
          className={classes.title}
          button
          onClick={this.open.bind(this)}
        >
          <ListItemText
            classes={{
              primary: classes.titleText,
            }}
            primary={groupName}
          />
          {this.state.open ? (
            <ExpandLess style={{ fill: '#000' }} />
          ) : (
            <ExpandMore style={{ fill: '#000' }} />
          )}
        </ListItem>
        <Collapse in={this.state.open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {routes.map((route, index) => (
              <Suspense fallback={null} key={`${index}-${route.path}`}>
                <NavigationItem
                  key={`${index}-${route.path}`}
                  {...getItemProps({ ...this.props, router, route })}
                />
              </Suspense>
            ))}
          </List>
        </Collapse>
      </List>
    );
  }
}

function mapStateToProps(state) {
  const {
    user: {
      profile: {
        value: { permissions, organizationId },
      },
    },
  } = state;

  return {
    permissions,
    organizationId,
  };
}

NavigationGroup.propTypes = {
  groupName: PropTypes.string,
  classes: PropTypes.object,
  activeRoute: PropTypes.object,
  onClose: PropTypes.func,
  permissions: PropTypes.array,
  organizationId: PropTypes.number,
  routes: PropTypes.array,
};

export default connect(mapStateToProps, { push })(
  withStyles(styles)(NavigationGroup)
);
