import React from 'react';
import Input from '@material-ui/core/Input';
const dynamicText = function (props) {
  const { input, autoFocus, disabled } = props;

  return (
    <Input
      {...input}
      type='hidden'
      disabled={disabled}
      autoFocus={autoFocus}
      name={input.name}
    />
  );
};

export default dynamicText;
