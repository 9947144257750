import { selectorWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import axios from 'axios';
import {
  connectorDetailsSelectedConnectorName,
  dataValidationSelectedTableFilter,
} from '../../../products/connector/recoil/index';
import {
  ColumnItem,
  TableProfileItem,
  TablesResponse,
  TestCaseResults,
  ValidationTestCaseResult,
  ValidationTestDetails,
} from '../types';
import moment from 'moment';
import { getActiveConnectorSchema } from '../utils/getActiveConnectorSchema';
import { countRules } from '../utils/getValidationTestSummary';
import {
  selectedValidationRule,
  validationDetailsRuleId,
} from 'src/products/connector/pages/validation-details/recoil';
import { dataValidationTestsTrigger } from 'src/products/connector/pages/connector-details/recoil';
import { getUser } from 'src/products/data-submission-portal/recoil/getUser';
import { getColumnNameFromEntityLink } from '../utils/getColumnNameFromEntityLink';
import { ValidationRuleResult } from 'src/products/connector/pages/connector-details/components/DataValidation/types';
import { getSelector } from '@cherre-frontend/data-fetching';
import { RuleDetails } from 'src/products/connector/types/ruleTypes';
import { RuleStatusChipType } from 'src/products/connector/components/RuleStatusChip';
import { getFailingTestDataById } from 'src/products/connector/pages/validation-details/utils/getFailingTestDataById';
import { getTableAndColumnName } from 'src/products/connector/pages/validation-details/utils/const';

export const openMetadataTables = selectorWithDefaultValue({
  defaultValue: [],
  key: 'OPEN-METADATA/TABLES',
  get: async ({ get }): Promise<TablesResponse[]> => {
    const activeConnectorName = get(connectorDetailsSelectedConnectorName);
    const activeSchemaName = getActiveConnectorSchema(activeConnectorName);

    let tables: TablesResponse[];
    if (activeSchemaName) {
      const encodedSchemaName = encodeURIComponent(activeSchemaName);
      try {
        const response = await axios.get(
          `/api/v1/openmetadata/tables/${encodedSchemaName}`
        );

        switch (activeConnectorName) {
          case 'chatham-warehouse':
            tables = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('chatham')
            );
            break;
          case 'vts-leasing-warehouse':
            tables = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('vts')
            );
            break;
          case 'dwellsy-test-feed':
            tables = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('dwellsy')
            );
            break;
          case 'dev-model-test-feed':
            tables = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('cardinal')
            );
            break;
          default:
            tables = response.data.data;
            break;
        }
        return tables;
      } catch (error) {
        return [];
      }
    }
    return [];
  },
});

export const openMetadataTableLatestData = selectorWithDefaultValue({
  defaultValue: null,
  key: 'OPEN-METADATA/TABLE-LATEST-DATA',
  get: async ({
    get,
  }): Promise<{
    profile: TableProfileItem;
    columns: ColumnItem[];
  }> => {
    const selectedRule = get(selectedValidationRule);
    const { table } = getTableAndColumnName(selectedRule?.entityLink);

    const response: {
      profile: TableProfileItem;
      columns: ColumnItem[];
    } = await get(
      getSelector({
        url: `/api/v1/openmetadata/tables/latest/${table}`,
      })
    );

    //Add table profile if it is missing
    if (!response.profile) {
      response.profile = {
        columnCount: response.columns.length,
        timestamp: 0,
        profileSample: 0,
        profileSampleType: 'PERCENTAGE',
        rowCount: 0,
        sizeInByte: 0,
        createDateTime: '',
      };
    }
    //Add column profile if it is missing. This is currently only a bug in OpenMetadata for geography data types
    response.columns.map((column) => {
      if (!column.profile) {
        column.profile = {
          name: column.name,
          valuesCount: 0,
          nullCount: 0,
          nullProportion: 0.0,
          uniqueCount: 0,
          uniqueProportion: 0,
          distinctCount: 0,
          distinctProportion: 0,
          min: 0,
          max: 0,
          mean: 0,
          sum: 0,
          stddev: 0,
          median: 0,
          firstQuartile: 0,
          thirdQuartile: 0,
          interQuartileRange: 0,
          timestamp: 0,
        };
      } else {
        column.profile.uniqueProportion =
          column.profile.uniqueProportion ?? 0.0;
        column.profile.nullProportion = column.profile.nullProportion ?? 0.0;
        column.profile.distinctProportion =
          column.profile.distinctProportion ?? 0.0;
        column.profile.valuesCount = column.profile.valuesCount ?? 0;
      }
    });
    return response;
  },
});

export const openMetadataDataValidationTests = selectorWithDefaultValue({
  defaultValue: null,
  key: 'OPEN-METADATA/DATA-VALIDATION-TESTS',
  get: async ({
    get,
  }): Promise<{
    data: ValidationRuleResult[];
  }> => {
    get(dataValidationTestsTrigger);
    const activeConnectorName = get(connectorDetailsSelectedConnectorName);
    const activeSchemaName = getActiveConnectorSchema(activeConnectorName);

    let validationTests: TestCaseResults[];
    if (activeSchemaName) {
      try {
        const response = await axios.get(
          '/api/v1/openmetadata/dataValidationTests'
        );

        switch (activeConnectorName) {
          case 'chatham-warehouse':
            validationTests = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('chatham')
            );
            break;
          case 'vts-leasing-warehouse':
            validationTests = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('vts')
            );
            break;
          case 'dwellsy-test-feed':
            validationTests = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('dwellsy')
            );
            break;
          case 'dev-model-test-feed':
            validationTests = response.data.data.filter((table) =>
              table.fullyQualifiedName.includes('cardinal')
            );
            break;
          default:
            validationTests = response.data.data;
            break;
        }
        return {
          data: validationTests.map((test) => ({
            id: test.fullyQualifiedName,
            enrichmentDatastoreId: 0,
            enrichmentDatastoreName: '',
            datastoreId: 0,
            tableName: test.entityFQN,
            ruleName: test.name,
            fullyQualifiedName: test.fullyQualifiedName,
            columnName: getColumnNameFromEntityLink(test.entityLink),
            entityLink: test.entityLink,
            lastRun:
              test.testCaseResult !== undefined
                ? test.testCaseResult.timestamp
                : undefined,
            watchers: ['kevin@cherre.com'],
            ruleStatus:
              test.testCaseResult !== undefined
                ? test.testCaseResult.testCaseStatus
                : 'Scheduled',
            connectorName: '',
          })),
        };
      } catch (error) {
        return { data: [] };
      }
    }
    return { data: [] };
  },
});

export const openMetadataDataValidationTestCaseResults =
  selectorWithDefaultValue({
    defaultValue: null,
    key: 'OPEN-METADATA/DATA-VALIDATION-TEST-CASE-RESULTS',
    get: async ({ get }): Promise<ValidationTestCaseResult[]> => {
      const test_case_id = get(validationDetailsRuleId);
      const encodedTestCase = encodeURIComponent(test_case_id);
      const startDate = moment().subtract(1, 'months').startOf('day');
      const endDate = new Date();
      const response: { data: ValidationTestCaseResult[] } = await get(
        getSelector({
          url: `/api/v1/openmetadata/dataQuality/testCases/${encodedTestCase}/testCaseResult?startTs=${moment(
            startDate
          ).valueOf()}&endTs=${moment(endDate).milliseconds(0).valueOf()}`,
        })
      );

      return response.data;
    },
  });

export const openMetadataDataValidationTestSummary = selectorWithDefaultValue({
  defaultValue: null,
  key: 'OPEN-METADATA/DATA-VALIDATION-TEST-SUMMARY',
  get: async ({
    get,
  }): Promise<{
    data: { success: number; failed: number; aborted: number; total: number };
  }> => {
    const tests = get(openMetadataDataValidationTests);
    const summary = countRules(tests);
    return { data: summary };
  },
});

//Used in the data validation content screen when filtering by table name
export const selectedOpenMetadataDataValidationTests = selectorWithDefaultValue(
  {
    defaultValue: null,
    key: 'OPEN-METADATA/SELECTED-DATA-VALIDATION-TESTS',
    get: async ({
      get,
    }): Promise<{
      data: ValidationRuleResult[];
    }> => {
      const fullyQualifiedName = get(dataValidationSelectedTableFilter);
      if (!fullyQualifiedName) {
        return { data: [] };
      }

      const entityLink = `<#E::table::${fullyQualifiedName}>`;
      const encodedLink = encodeURIComponent(entityLink);

      const response: { data: TestCaseResults[] } = await get(
        getSelector({
          url: `/api/v1/openmetadata/tables/dataValidationTests/${encodedLink}`,
        })
      );

      return {
        data: response.data.map((test) => ({
          id: test.fullyQualifiedName,
          enrichmentDatastoreId: 0,
          enrichmentDatastoreName: '',
          datastoreId: 0,
          tableName: test.entityFQN,
          ruleName: test.name,
          fullyQualifiedName: test.fullyQualifiedName,
          columnName: getColumnNameFromEntityLink(test.entityLink),
          entityLink: test.entityLink,
          lastRun:
            test.testCaseResult !== undefined
              ? test.testCaseResult.timestamp
              : undefined,
          watchers: ['kevin@cherre.com'],
          ruleStatus:
            test.testCaseResult !== undefined
              ? test.testCaseResult.testCaseStatus
              : 'Scheduled',
          connectorName: '',
        })),
      };
    },
  }
);

export const openMetadataDataValidationTestDetails = selectorWithDefaultValue({
  defaultValue: null,
  key: 'OPEN-METADATA/DATA-VALIDATION-TEST-DETAILS',
  get: async ({ get }): Promise<RuleDetails | null> => {
    const testId = get(validationDetailsRuleId);
    const encodedTestCase = encodeURIComponent(testId);
    const userId = get(getUser).id;

    const response: ValidationTestDetails = await get(
      getSelector({
        url: `/api/v1/openmetadata/dataQuality/testCases/name/${encodedTestCase}?userId=${userId}`,
      })
    );

    const results = get(openMetadataDataValidationTestCaseResults);

    const latestTableData = get(openMetadataTableLatestData);
    const sourceRecords = getFailingTestDataById(response.id);

    const ruleDetails: RuleDetails = {
      id: response.id,
      anomalyID: -1,
      lastRun:
        results && results.length > 0
          ? moment(results[0].timestamp).format('MMM DD, YYYY h:mm A')
          : '--',
      failedRecordCount:
        results && results.length > 0
          ? Number(results[0].testResultValue[0].value)
          : null,
      failedSourceRecords: {
        records: sourceRecords?.records ?? [],
        columns: sourceRecords?.columnNames ?? [],
        failedColumns: sourceRecords?.failingColumns ?? [],
      },
      status:
        results && results.length > 0
          ? (results[0].testCaseStatus as RuleStatusChipType)
          : 'Scheduled',
      ruleName: response.displayName ?? '',
      tableRecordCount: latestTableData?.profile.rowCount ?? null,
      canEdit: response.canEdit,
      failedRowCountHistory:
        results?.map((result) => {
          return {
            id: result.incidentId,
            date: moment(result.timestamp).format('MMM DD, YYYY h:mm A'),
            failedRowCount: Number(results[0].testResultValue[0].value),
          };
        }) ?? [],
      testDefinition: response.testDefinition,
      entityLink: response.entityLink,
      parameterValues: response.parameterValues,
    };
    return ruleDetails;
  },
});
