import { useRecoilValue } from 'recoil';
import { useOrganizationPropsValue } from 'src/hooks';
import { dealPortfolioByTaxIdSelector } from '../recoil';

export const useDealPortfolioByTaxId = (taxAssessorId: number) => {
  const isDealsIntegrationAvailable =
    useOrganizationPropsValue('deals_enabled');

  if (!isDealsIntegrationAvailable) {
    return;
  }

  return useRecoilValue(dealPortfolioByTaxIdSelector(taxAssessorId));
};
