import React, { Component } from 'react';
import TreeView from 'src/components/TreeView';
import CondoUnitsSwitch from 'src/products/core-prospect/search-pages/components/Sidebar/Views/FiltersView/CondoUnitsSwitch';
import {
  createParentChildrenTable,
  createChildrenParentTable,
  createIdDataTable,
  createCheckedObject,
  createExpansionObject,
  createIndeterminateObject,
} from './componentHelperFunctions';

class HierarchicalFilter extends Component {
  constructor(props) {
    super(props);

    const {
      data: { nodes, parentNodeId },
    } = props;

    this.parentChildren = createParentChildrenTable(nodes);
    this.childrenParent = createChildrenParentTable(nodes);
    this.idData = createIdDataTable(nodes);
    this.parentNodeId = parentNodeId;
    this.disabled = {};
  }

  handleCheckboxClick = (node) => {
    const { filterValue = {}, setFilterValue } = this.props;

    const checked = createCheckedObject(
      filterValue.checked,
      this.parentChildren,
      this.childrenParent,
      node
    );

    const indeterminate = createIndeterminateObject(
      filterValue.indeterminate,
      this.childrenParent,
      this.parentChildren,
      checked,
      node
    );

    if (typeof setFilterValue === 'function') {
      setFilterValue({
        ...filterValue,
        activeNodeId: node.id,
        checked,
        indeterminate,
      });
    }
  };

  handlePanelExpansion = (node) => {
    const { filterValue = {}, setFilterValue } = this.props;

    const expanded = createExpansionObject(filterValue.expanded, node.id);

    if (typeof setFilterValue === 'function') {
      setFilterValue({
        ...filterValue,
        expanded,
      });
    }
  };

  render() {
    const { filterValue = {} } = this.props;

    const { checked, expanded, indeterminate } = filterValue;

    return (
      <div style={{ padding: '5px 0' }}>
        <CondoUnitsSwitch />
        <TreeView
          parentChildren={this.parentChildren}
          parentNodeId={this.parentNodeId}
          idData={this.idData}
          disabled={this.disabled}
          expanded={expanded}
          indeterminate={indeterminate}
          checked={checked}
          changePanelExpansion={this.handlePanelExpansion}
          handleCheckboxClick={this.handleCheckboxClick}
          handleExpansionIconClick={this.handlePanelExpansion}
          showExpansionIcon={(node) => {
            if (
              this.parentChildren[node.id] &&
              this.parentChildren[node.id].length > 0
            ) {
              return true;
            } else {
              return false;
            }
          }}
          hideArrow
          {...this.props}
        />
      </div>
    );
  }
}

HierarchicalFilter.defaultProps = {
  data: {
    nodes: [],
  },
};

export default HierarchicalFilter;
