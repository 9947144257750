import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import capitalize from 'src/utils/capitalize';
import Button from 'src/components/ButtonProgress';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

const DeleteTableItem = (props) => {
  const {
    open,
    onRequestClose,
    adminPanel,
    data: { schema, onDelete, row },
    classes,
  } = props;

  const entityName = pluralize(schema.entityName, 1);

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle>Disable {entityName}</DialogTitle>
      <DialogContent className={classes.modalContent}>
        Are you sure you want to disable <b>{row[schema.nameKey]}</b>{' '}
        {entityName} ?
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color='primary'>
          close
        </Button>
        <Button
          onClick={async () => {
            onDelete()
              .then(() => {
                showSuccessPopupNotification(
                  `${capitalize(entityName)} has been disabled.`
                );
              })
              .then(onRequestClose)
              .catch((e) => {
                showErrorPopupNotification(`Failed to remove. ${e.message}`);
              });
          }}
          color='primary'
          variant='contained'
          loading={adminPanel[schema.id].deleteStatus.value === 'LOADING'}
        >
          disable
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
};

DeleteTableItem.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  data: PropTypes.object,
  classes: PropTypes.object,
  adminPanel: PropTypes.object,
};

function mapStateToProps({ adminPanel }) {
  return {
    adminPanel,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DeleteTableItem));
