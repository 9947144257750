import React from 'react';
import 'react-base-table/styles.css';

import BaseTable, { BaseTableProps, ColumnShape } from 'react-base-table';
import { addSelection, formatColumns } from './utils';
import EmptyTable from './EmptyTable';
import { useTheme } from '@material-ui/core';

const getIdFromRowKeys = (item, rowKeys, index) => {
  if (typeof rowKeys === 'string') {
    return item[rowKeys] ? item[rowKeys] : index;
  }

  if (Array.isArray(rowKeys)) {
    return rowKeys.map((rowKey) => item[rowKey]).join('-');
  }

  return index;
};

export interface TableProps<T = unknown> {
  rowKey: string | string[];
  emptyText1?: string;
  emptyText2?: string;
  onSelect?: (key: string, checked: boolean) => void;
  onSelectAll?: (checked: boolean) => void;
  isSelectAllChecked?: boolean;
  selectedRowKeys?: any[];
  selection?: boolean;
  stickyLeft?: number;
  stickyRight?: number;
  isLoading: boolean;
  columns?: Omit<ColumnShape, 'key'>[];
  data?: Readonly<BaseTableProps<T>['data']>;
  width: BaseTableProps<T>['width'];
  height?: BaseTableProps<T>['height'];
  maxHeight?: BaseTableProps<T>['maxHeight'];
  sortBy?: BaseTableProps<T>['sortBy'];
  onColumnSort?: BaseTableProps<T>['onColumnSort'];
  fixed?: BaseTableProps<T>['fixed'];
  rowRenderer?: BaseTableProps<T>['rowRenderer'];
  headerRenderer?: BaseTableProps<T>['headerRenderer'];
  headerHeight?: BaseTableProps<T>['headerHeight'];
  overscanRowCount?: BaseTableProps<T>['overscanRowCount'];
}

const Table: React.FC<TableProps> = <T,>({
  stickyLeft = 0,
  stickyRight = 0,
  onSelect,
  onSelectAll,
  isSelectAllChecked,
  selectedRowKeys,
  selection,
  columns,
  data,
  width,
  height,
  maxHeight,
  rowKey = 'id',
  sortBy,
  onColumnSort,
  isLoading,
  emptyText1,
  emptyText2,
  fixed = true,
  rowRenderer,
  headerRenderer,
  headerHeight,
  overscanRowCount,
}: TableProps) => {
  const theme = useTheme();

  let _columns: ColumnShape[] = formatColumns(
    columns,
    stickyLeft,
    stickyRight,
    theme
  );

  if (selection && (data || []).length !== 0) {
    _columns = addSelection({
      columns: _columns,
      onSelect,
      onSelectAll,
      isSelectAllChecked,
      selectedRowKeys,
      rowKey,
    });
  }

  return (
    <BaseTable
      emptyRenderer={
        isLoading ? null : (
          <EmptyTable title1={emptyText1} title2={emptyText2} />
        )
      }
      getScrollbarSize={() => 10}
      style={{
        fontFamily: theme.typography.body1.fontFamily,
      }}
      sortBy={sortBy}
      onColumnSort={onColumnSort}
      estimatedRowHeight={100}
      data={data?.map((item, index) => {
        const id = getIdFromRowKeys(item, rowKey, index);

        return {
          ...(item as T),
          key: id,
          id,
        } as T & { key: React.Key; id: string };
      })}
      fixed={fixed}
      columns={_columns}
      width={width}
      height={height}
      maxHeight={maxHeight}
      rowRenderer={rowRenderer}
      headerRenderer={headerRenderer}
      headerHeight={headerHeight}
      overscanRowCount={overscanRowCount}
    />
  );
};

Table.displayName = 'Table';

export default Table;
