import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

const TreppLoanDataModalMasterServicer = ({ item }) => {
  return (
    <Grid item xs={4}>
      <Typography variant='body2' style={{ color: grey[700] }}>
        Master Servicer
      </Typography>
      <Typography style={{ fontWeight: 'bold' }}>
        {item.master_servicer}
      </Typography>
    </Grid>
  );
};

export default TreppLoanDataModalMasterServicer;
