import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  fontSize: 14,
  width: 680,
  margin: '15px',
  color: '#000000',
};

const DynamicLabel = (props) => {
  const [text, setText] = React.useState('');

  React.useEffect(() => {
    (async () => {
      if (typeof props.label === 'function') {
        setText(await props.label());
      } else {
        setText(props.label || '');
      }
    })();
  }, []);

  return (
    <div
      style={{ ...styles, ...props.attr.style }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

DynamicLabel.propTypes = {
  label: PropTypes.string,
  attr: PropTypes.object,
};

export default DynamicLabel;
