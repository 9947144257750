import { useMutation } from '@cherre-frontend/data-fetching';
import {
  Box,
  CheckCircleIcon,
  Grid,
  GridProps,
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  Tooltip,
  Typography,
  styled,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { markReminderResolvedMutation } from 'src/products/data-submission-portal/components/PackageReportsTable/PackageReportSidebar/recoil';
import { useClaims } from 'src/products/data-submission-portal/packages/dsp-role-based-rendering';
import { Reminder as ReminderType } from './recoil';

const Container = styled(Grid, {
  shouldForwardProp: (prop) =>
    !['closed', 'height', 'showBottomBorder'].includes(prop.toString()),
})<
  GridProps & { closed?: boolean; height: string; showBottomBorder?: boolean }
>`
  position: relative;
  transition: all 0.4s ease-in-out;
  flex-shrink: 0;
  overflow: hidden;
  height: ${({ height, closed }) => (closed ? '0px' : height)};
  margin-bottom: ${({ theme, closed }) =>
    closed ? `-${theme.spacing(2)}` : '0px'};
  border-bottom: ${({ theme, showBottomBorder }) =>
    showBottomBorder ? `1px solid ${theme.palette.grey[200]}` : 'none'};
  opacity: ${({ closed }) => (closed ? 0 : 1)};
`;

const Body = styled(Grid, {
  shouldForwardProp: (prop) => !['closed', 'height'].includes(prop.toString()),
})<GridProps & { closed?: boolean; height: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  height: ${({ height, closed }) => (closed ? '0px' : height)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const ResolveButton = styled(MuiLink, {
  shouldForwardProp: (prop) => prop !== 'resolved',
})<MuiLinkProps & { resolved?: boolean }>`
  color: ${({ theme, resolved }) =>
    resolved ? theme.accents.info.main : theme.palette.grey[400]};
  cursor: pointer;
  font-size: 12px;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(1)};

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

const UnreadIndicator = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  top: 4px;
  right: 2px;
`;

const ReminderBody = styled(Typography)`
  white-space: pre-line;
  word-break: break-word;
`;

const Subtext = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[600]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 270px;
`;

const PackageReportLink = styled(MuiLink)`
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 500;
`;

interface ReminderProps {
  reminder?: ReminderType;
  closeReminder?: () => void;
  showBottomBorder?: boolean;
}

const Reminder: React.FC<ReminderProps> = ({
  reminder,
  closeReminder,
  showBottomBorder,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = React.useState<string>('auto');
  const [closed, setClosed] = React.useState<boolean>(false);
  const userIsAdmin = useClaims('view_admin');

  const userPropertyRole =
    reminder?.property.properties_roles_users[0]?.property_role
      .property_role_set;

  const reminderIsUnread =
    !reminder?.is_resolved &&
    reminder?.user_viewed_count?.aggregate?.count === 0;

  const markReminderResolved = useMutation(markReminderResolvedMutation, {
    mapVariables: () => async () => {
      if (!reminder) {
        throw new Error('reminder not set');
      }
      return {
        params: {
          reminder_id: reminder.reminder_id,
          is_resolved: !reminder.is_resolved,
        },
      };
    },
    onCompleted: (_, ctx) => {
      setClosed(true);
      if (closeReminder) {
        closeReminder();
      }
      ctx.showSnackbar({
        message: 'Reminder marked resolved.',
        type: 'success',
      });
    },
    onError: (_, ctx) => {
      setClosed(false);
      ctx.showSnackbar({
        message: 'Reminder failed to update. Please try again.',
        type: 'error',
      });
    },
  });

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setContainerHeight(`${containerRef.current.scrollHeight}px`);
  }, [containerRef.current]);

  const packageReportLink = useMemo(() => {
    const roleParam = userIsAdmin ? 'view_admin' : userPropertyRole;
    const params = encodeURIComponent(
      btoa(
        JSON.stringify({
          expandedCards: [reminder?.property_batch_id],
          'submission-batch-details/package-report-modal-state': {
            property_batch_id: reminder?.property_batch_id,
            report_slug: `${reminder?.package_report.package_report_slug}_package_report`,
            property_id: reminder?.property_id,
          },
        })
      )
    );
    return `/dsp/${roleParam}/submissions/${reminder?.property_batch.submission_id}/?${params}`;
  }, [reminder]);

  const authorText = `${reminder?.user.sakura_user?.first_name} ${
    reminder?.user.sakura_user?.last_name
  } • ${moment(reminder?.created_at_datetime).format(
    'MMM DD, YYYY [at] h:mm A'
  )}`;

  return (
    <Container
      height={containerHeight}
      closed={closed}
      showBottomBorder={showBottomBorder}
    >
      {reminderIsUnread && <UnreadIndicator />}
      <Body ref={containerRef} height={containerHeight} closed={closed}>
        <Grid>
          <ReminderBody>{reminder?.text}</ReminderBody>
          <Tooltip title={authorText}>
            <Subtext variant='body2'>{authorText}</Subtext>
          </Tooltip>

          <Grid
            container
            display='flex'
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
          >
            <Subtext variant='body2' mr={0.5}>
              ID {reminder?.property.entity_id} •
            </Subtext>
            <Tooltip title='View Report'>
              <PackageReportLink href={packageReportLink} target='_blank'>
                {reminder?.package_report?.package_report_label}
              </PackageReportLink>
            </Tooltip>
          </Grid>

          <Grid
            container
            display='flex'
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
          >
            <Subtext variant='body2' mr={0.5}>
              {reminder?.property_batch.submission.submission_name} •
            </Subtext>
            <Subtext variant='body2'>
              {reminder?.property_batch.submission.reporting_period ===
              'monthly'
                ? ` ${moment(
                    reminder?.property_batch.submission
                      .reporting_period_start_date
                  ).format('MMM YYYY')}`
                : ` ${moment(
                    reminder?.property_batch.submission
                      .reporting_period_start_date
                  ).format('[Q]Q YYYY')}`}{' '}
              Period
            </Subtext>
          </Grid>
        </Grid>

        <Tooltip
          title={reminder?.is_resolved ? 'Mark Unresolved' : 'Mark Resolved'}
        >
          <ResolveButton
            onClick={markReminderResolved}
            resolved={reminder?.is_resolved || closed}
          >
            <CheckCircleIcon />
          </ResolveButton>
        </Tooltip>
      </Body>
    </Container>
  );
};

export default Reminder;
