import { StandardizedConnector } from 'src/products/connector/types/connectorTypes';
import { connectors } from '../data/ConnectorInventory';
import { selector } from 'recoil';
import {
  Activity,
  activityOverview,
  CherreConnectorSource,
  connectorStatuses,
  ConnectorStatuses,
  schedule,
  ScheduleItem,
  sources,
} from '../data/ConnectorLanding';
import { FailedSourceRecord } from '../data/failedSourceRecords';
import { getFailingTestDataById } from 'src/products/connector/pages/validation-details/utils/getFailingTestDataById';
import { openMetadataDataValidationTestDetails } from 'src/services/OpenMetadata/recoil';
import {
  ConnectorDetailOverview,
  overviewData,
} from '../data/ConnectorOverview';
import { ConnectorDetailsRoute } from 'src/products/connector/routes';
import { selectorWithDefaultValue } from 'src/utils/recoil/withDefaultValue';
import {
  connectorDetailsCustomDateRange,
  connectorDetailsSelectedConnectorName,
  connectorDetailsSelectedTable,
} from 'src/products/connector/recoil';
import { getActiveConnectorSchema } from 'src/services/OpenMetadata/utils/getActiveConnectorSchema';
import {
  ColumnItem,
  TableProfileItem,
  TablesResponse,
} from 'src/services/OpenMetadata/types';
import moment from 'moment';
import { getSelector } from '@cherre-frontend/data-fetching';

//Landing page
export const connectorLandingStatusesDemo = selector<{
  data: ConnectorStatuses;
}>({
  key: 'DEMO-CONNECTOR-LANDING-STATUSES',
  get: async (): Promise<{ data: ConnectorStatuses }> => {
    return { data: connectorStatuses };
  },
});
export const connectorLandingActivityOverviewDemo = selector<{
  data: Activity[];
}>({
  key: 'DEMO-CONNECTOR-LANDING-ACTIVITY-OVERVIEW',
  get: async (): Promise<{ data: Activity[] }> => {
    return { data: activityOverview };
  },
});
export const connectorLandingSourcesDemo = selector<{
  data: CherreConnectorSource[];
}>({
  key: 'DEMO-CONNECTOR-LANDING-SOURCES',
  get: async (): Promise<{ data: CherreConnectorSource[] }> => {
    return { data: sources };
  },
});
export const connectorLandingScheduleDemo = selector<{
  data: ScheduleItem[];
}>({
  key: 'DEMO-CONNECTOR-LANDING-SCHEDULE',
  get: async (): Promise<{ data: ScheduleItem[] }> => {
    return { data: schedule };
  },
});

//Connector inventory
export const connectorInventoryDemo = selector<{
  data: StandardizedConnector[];
}>({
  key: 'DEMO-CONNECTOR-INVENTORY',
  get: async (): Promise<{
    data: StandardizedConnector[];
  }> => {
    return { data: connectors };
  },
});

//Validation rule details
export const connectorOverviewDemo = selector<{
  data: ConnectorDetailOverview;
}>({
  key: 'DEMO-CONNECTOR-OVERVIEW',
  get: async ({ get }): Promise<{ data: ConnectorDetailOverview }> => {
    const connector_name = get(
      ConnectorDetailsRoute.routeParamSelector
    )?.connector_name;
    const demoData = overviewData.find(
      (connector) => connector.name === connector_name
    );
    if (demoData) {
      return { data: demoData };
    }

    return { data: overviewData[0] as ConnectorDetailOverview };
  },
});

//Validation rule details
export const connectorValidationRuleFailedSourceRecordsDemo = selector<{
  data: FailedSourceRecord;
}>({
  key: 'DEMO-CONNECTOR-VALIDATION-RULE-FAILED-SOURCE-RECORDS',
  get: async ({ get }): Promise<{ data: FailedSourceRecord }> => {
    const ruleDetails = get(openMetadataDataValidationTestDetails);
    const defaultRecord = {
      connector: '',
      testName: '',
      testId: '',
      columnNames: [],
      failingColumns: [],
      records: [],
    };
    if (ruleDetails) {
      const sourceRecords = getFailingTestDataById(ruleDetails.id);
      return { data: sourceRecords ?? defaultRecord };
    }
    return { data: defaultRecord };
  },
});

//Observability-Table Profile
export const openMetadataTablesDemo = selectorWithDefaultValue({
  defaultValue: [],
  key: 'OPEN-METADATA/TABLES-DEMO',
  get: async ({ get }): Promise<string[]> => {
    const activeConnectorName = get(connectorDetailsSelectedConnectorName);
    const activeSchemaName = getActiveConnectorSchema(activeConnectorName);

    if (activeSchemaName) {
      const encodedSchemaName = encodeURIComponent(activeSchemaName);

      const response: { data: TablesResponse[] } = await get(
        getSelector({
          url: `/api/v1/openmetadata/tables/${encodedSchemaName}`,
        })
      );

      console.log('response for tables', response);
      //For demo purposes, Chatham and VTS share the same DB so we have to filter off hard coded id
      if (activeConnectorName === 'chatham-warehouse') {
        return response.data
          .filter((table) => table.fullyQualifiedName.includes('chatham'))
          .map((table) => table.fullyQualifiedName);
      }
      if (activeConnectorName === 'vts-leasing-warehouse') {
        return response.data
          .filter((table) => table.fullyQualifiedName.includes('vts'))
          .map((table) => table.fullyQualifiedName);
      }
      //ID for DEV table tests
      if (activeConnectorName === 'dev-model-test-feed') {
        return response.data
          .filter((table) => table.fullyQualifiedName.includes('cardinal'))
          .map((table) => table.fullyQualifiedName);
      }
      return response.data.map((table) => table.fullyQualifiedName);
    }
    return [];
  },
});
export const openMetadataTableDataVolumeDemo = selectorWithDefaultValue({
  defaultValue: [],
  key: 'OPEN-METADATA/TABLE-DETAILS-DEMO',
  get: async ({ get }): Promise<TableProfileItem[]> => {
    const tableNames = get(openMetadataTablesDemo);
    const defaultTable = get(connectorDetailsSelectedTable);
    const dateRange = get(connectorDetailsCustomDateRange);

    const { startDate, endDate } = dateRange[0];

    const tableName = defaultTable || tableNames[0];

    const response: { data: TableProfileItem[] } = await get(
      getSelector({
        url: `/api/v1/openmetadata/tables/dataVolume/${tableName}?startTs=${moment(
          startDate
        ).valueOf()}&endTs=${moment(endDate).valueOf()}`,
      })
    );
    const dataVolume = response.data.map((record) => {
      return {
        rowCount: record.rowCount,
        timestamp: record.timestamp,
      };
    });
    return dataVolume;
  },
});
export const openMetadataThirtyDayAverageDemo = selectorWithDefaultValue({
  defaultValue: [],
  key: 'OPEN-METADATA/THIRTY-DAY-AVERAGE-DEMO',
  get: async ({ get }): Promise<TableProfileItem[]> => {
    const tableNames = get(openMetadataTablesDemo);
    const defaultTable = get(connectorDetailsSelectedTable);

    const currentDate = moment().startOf('minute').valueOf();
    const thirtyDaysAgo = moment()
      .subtract(30, 'days')
      .startOf('minute')
      .valueOf();
    const tableName = defaultTable || tableNames[0];

    const response: { data: TableProfileItem[] } = await get(
      getSelector({
        url: `/api/v1/openmetadata/tables/dataVolume/${tableName}?startTs=${thirtyDaysAgo}&endTs=${currentDate}`,
      })
    );
    return response.data;
  },
});
export const openMetadataTableLatestDataDemo = selectorWithDefaultValue({
  defaultValue: null,
  key: 'OPEN-METADATA/TABLE-LATEST-DATA-DEMO',
  get: async ({
    get,
  }): Promise<{ profile: TableProfileItem; columns: ColumnItem[] }> => {
    const tableNames = get(openMetadataTablesDemo);
    const defaultTable = get(connectorDetailsSelectedTable);
    const tableName = defaultTable || tableNames[0];

    const response: {
      profile: TableProfileItem;
      columns: ColumnItem[];
    } = await get(
      getSelector({
        url: `/api/v1/openmetadata/tables/latest/${tableName}`,
      })
    );
    const dataCopy = {
      ...response,
      columns: response.columns.map((column) => ({ ...column })),
    };
    //Add table profile if it is missing
    if (!dataCopy.profile) {
      dataCopy.profile = {
        columnCount: response.columns.length,
        timestamp: 0,
        profileSample: 0,
        profileSampleType: 'PERCENTAGE',
        rowCount: 0,
        sizeInByte: 0,
        createDateTime: '',
      };
    }
    //Add column profile if it is missing. This is currently only a bug in OpenMetadata for geography data types
    dataCopy.columns.map((column) => {
      if (!column.profile) {
        column.profile = {
          name: column.name,
          valuesCount: 0,
          nullCount: 0,
          nullProportion: 0.0,
          uniqueCount: 0,
          uniqueProportion: 0,
          distinctCount: 0,
          distinctProportion: 0,
          min: 0,
          max: 0,
          mean: 0,
          sum: 0,
          stddev: 0,
          median: 0,
          firstQuartile: 0,
          thirdQuartile: 0,
          interQuartileRange: 0,
          timestamp: 0,
        };
      } else {
        column.profile.uniqueProportion =
          column.profile.uniqueProportion ?? 0.0;
        column.profile.nullProportion = column.profile.nullProportion ?? 0.0;
        column.profile.distinctProportion =
          column.profile.distinctProportion ?? 0.0;
        column.profile.valuesCount = column.profile.valuesCount ?? 0;
      }
    });
    return dataCopy;
  },
});

export const validationRuleTableList = selectorWithDefaultValue({
  defaultValue: [],
  key: 'OPEN-METADATA/TABLE-LIST-DEMO',
  get: async ({ get }): Promise<string[]> => {
    const tableData = get(openMetadataTablesDemo);
    const tables = tableData.map((table) => {
      return table;
    });
    return tables;
  },
});
