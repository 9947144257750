// example - { year_built: { gte: 1990, lte: 2010 } }

export function getQuery(filter, value) {
  if (!value) {
    return {};
  }

  const where = { _eq: value };

  return where;
}

export function getFilterDisplayValue(filter, value) {
  return value;
}

export function getFilterSetDisplayValue(filter, value) {
  const displayValue = getFilterDisplayValue(filter, value);

  if (value) {
    return `${filter.label} (${displayValue})`;
  }
}

export default {
  getQuery,
  getFilterDisplayValue,
  getFilterSetDisplayValue,
};
