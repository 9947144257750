import React from 'react';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    overflow: 'visible',
    width: 340,
  },
};

const PopoverCustom = (props) => {
  const { close, open, anchorEl, children, classes } = props;

  return (
    <Popover
      onClose={close}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        classes: {
          root: classes.root,
        },
      }}
      style={{ overflow: 'hidden' }}
    >
      <div
        style={{
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
          borderRadius: 5,
          overflow: 'hidden',
        }}
      >
        {React.cloneElement(children, { ...props })}
      </div>
    </Popover>
  );
};

export default withStyles(styles)(PopoverCustom);
