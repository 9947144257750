import React from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ExpansionIconStyles } from './TreeCustomComponents';

const ExpansionIconWithoutClick = (props) => {
  const { expanded, node, showExpansionIcon, idData, disabled } = props;

  if (showExpansionIcon(idData[node])) {
    return expanded[node] ? (
      <ExpandLess
        style={ExpansionIconStyles(disabled ? disabled[node] : false)}
      />
    ) : (
      <ExpandMore
        style={ExpansionIconStyles(disabled ? disabled[node] : false)}
      />
    );
  } else {
    return null;
  }
};

export default ExpansionIconWithoutClick;
