import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import Bookmark from '@material-ui/icons/Bookmark';
import { useStyles } from '../../styles';

const PropertyListsIcon = ({
  onClick,
}: {
  onClick: (MouseEventHandler) => void;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.menuItem} onClick={onClick}>
      <Tooltip title='Saved Searches and Property Lists'>
        <Bookmark className={`icon icon-settings ${classes.icon}`} />
      </Tooltip>
    </Box>
  );
};

export default PropertyListsIcon;
