import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import NumberFormat from 'react-number-format';
import validate from './validation';
import { getNumberFormatProps } from '../utils/getNumberFormatProps';

function TextInput(props) {
  const {
    filterValue,
    setFilterValue,
    filter: { number_format_props, label, filter_type_props = {} },
    classes,
    onSubmit,
    autoFocus,
    onBlur = () => {},
  } = props;

  const { placeholder } = filter_type_props;

  const errors = validate(filterValue);

  const customInput = React.useCallback((props) => {
    return <TextField {...props} variant='outlined' size='small' />;
  }, []);

  const numberFormatProps = getNumberFormatProps(number_format_props);

  return (
    <div
      className={classes.root}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <FormControl className={classes.textField}>
        {!numberFormatProps ? (
          <TextField
            autoFocus={autoFocus}
            label={label}
            fullWidth
            placeholder={placeholder}
            value={filterValue || ''}
            onChange={(e) => setFilterValue(e.target.value)}
            variant='outlined'
            size='small'
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onSubmit();
              }
            }}
            onBlur={onBlur}
          />
        ) : (
          <NumberFormat
            {...numberFormatProps}
            onKeyDown={() => {
              if (event.key === 'Enter') {
                onSubmit();
              }
            }}
            // format='#-#####-####'
            error={!!errors.value}
            helperText={errors.value}
            label={label}
            fullWidth
            placeholder={placeholder}
            value={filterValue || ''}
            onValueChange={(values) => setFilterValue(values.value)}
            customInput={customInput}
            onBlur={onBlur}
          />
        )}
      </FormControl>
    </div>
  );
}

TextInput.propTypes = {
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  filter: PropTypes.object,
  classes: PropTypes.object,
  onBlur: PropTypes.func,
};

TextInput.defaultProps = {
  setFilterValue: (values) => {
    values;
  },
};

export default withStyles(styles)(TextInput);
