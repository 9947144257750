import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    height: 90,
    width: '100%',
  },
  alignRight: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  alignCenter: {
    justifyContent: 'center',
    display: 'flex',
  },
  pageName: {
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  groupMenuContainer: {
    display: 'flex',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 16,
  },
  icon: {
    width: 20,
    height: 20,
    fontSize: 20,
    color: theme.palette.primary.light,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  menuIcon: {
    fontSize: 23,
  },
  userIcon: {
    backgroundColor: '#fff',
    width: 30,
    height: 30,
    backgroundSize: 'cover',
    borderRadius: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  entityTab: {
    height: 60,
  },
  entityTabLabel: {
    fontSize: 16,
  },
  indicator: {
    height: 4,
    borderRadius: '3px 3px 0 0',
  },
}));

export default {};
