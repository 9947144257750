import React from 'react';
import { Link } from 'react-router-dom';
import New from './New';

const ViewWasShared = (props) => {
  const { notification, isSidebar } = props;

  const {
    data: { sender },
  } = notification;

  let userName;

  if (sender) {
    userName = `${sender.firstName} ${sender.lastName}`;
  } else {
    userName = 'User';
  }

  return (
    <div>
      {userName} shared{' '}
      <Link to={`/core-query/${notification.data.id}`}>
        {notification.data.name}
      </Link>{' '}
      search with you. {!notification.isViewed && isSidebar && <New />}
    </div>
  );
};

export default ViewWasShared;
