export const getFeatureStyle = () => {
  return {
    stroke: '#df2467',
    strokeWidth: 2,
    fillOpacity: 0,
  };
};

export const getEditHandleStyle = () => {
  return {
    strokeDasharray: 'none',
    stroke: '#df2467',
    fill: '#fff',
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    strokeWidth: 1,
    rx: '100%',
  };
};
