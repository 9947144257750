import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilCallback } from 'recoil';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { getCommonData } from './getCommonData';
import axios from 'axios';
import {
  dealPortfolioAllSelector,
  dealPortfolioByTaxIdSelector,
  dealPortfolioSelectorFamily,
  taxAssessorsQuery,
} from './recoil';
import { usaDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaDataFormatter';
import { useSnackbarCherre } from 'src/products/core-prospect/hooks';
import * as F from './formatters';
import * as T from './types';
import * as U from './utils';
import { execQuery } from 'src/products/core-prospect/redux/query/actions/execQuery';

const useEditDealModal =
  ({ dealType, deal, assets, raw_assets, modalProps }: T.EditDealModalData) =>
  (onRequestClose: () => void) => {
    const { enqueueSnackbar } = useSnackbarCherre();
    const [name, setName] = useState(deal.dealName);
    const [description, setDescription] = useState(deal.description);
    const [stage, setStage] = useState<T.DealStageType>(deal.stage);
    const [lead, setLead] = useState<string | null>(deal.lead);
    const [broker, setBroker] = useState<string | null>(deal.broker);
    const [seller, setSeller] = useState<string | null>(deal.seller);
    const [ilo, setILO] = useState<string | null>(deal.ilo);
    const [lastActivityNote, setLastActivityNote] = useState(
      deal.lastActivityNote
    );
    const [loading, setIsLoading] = useState(false);
    const [dealTeam, setDealTeam] = useState(deal.dealTeam);
    const [investmentType, setInvestmentType] = useState(deal.investmentType);

    const user = useSelector((state) => state.user.profile.value);
    const dispatch = useDispatch();

    const send = useRecoilCallback(
      ({ refresh }) =>
        async () => {
          if (loading) {
            return;
          }
          setIsLoading(true);
          try {
            if (!name && dealType === 'portfolio') {
              throw new Error('Deal must have a name');
            }
            const isMultiFamily =
              modalProps.teams.find(({ id }) => id === dealTeam)?.name ===
              'Multi-Family';
            if (isMultiFamily && !investmentType) {
              throw new Error('Multi-Family deal must have an investment type');
            }
            if (!lead) {
              throw new Error('Deal must have an Acquisitions Associate');
            }
            const body = F.modalToEditData({
              deal,
              name,
              description,
              stage,
              lead,
              broker,
              seller,
              ilo,
              lastActivityNote,
              user,
              raw_assets,
              dealTeam,
              investmentType: isMultiFamily ? investmentType : undefined,
            });
            await axios.post('/api/v1/deals/edit', body);
            refresh(dealPortfolioSelectorFamily(deal.dealId));
            raw_assets.forEach(
              ({ tax_assessor_id }) =>
                tax_assessor_id &&
                refresh(dealPortfolioByTaxIdSelector(tax_assessor_id))
            );
            if (stage === 'Inactive') {
              refresh(dealPortfolioAllSelector({}));
              dispatch(
                execQuery({
                  id: 'EditDeal',
                  cards: true,
                  count: true,
                  rebound: false,
                  results: true,
                  viewportOnly: true,
                })
              );
              enqueueSnackbar(
                'Deal marked as “Inactive”.',
                U.getSnackbarProps('success')
              );
            } else {
              enqueueSnackbar(
                'Deal was updated.',
                U.getSnackbarProps('success')
              );
            }
            onRequestClose();
          } catch (e) {
            const msg = U.getErrorMsg(e, 'Failed to update the deal');
            enqueueSnackbar(msg, U.getSnackbarProps('error'));
          } finally {
            setIsLoading(false);
          }
        },
      [
        name,
        description,
        stage,
        lead,
        broker,
        seller,
        ilo,
        lastActivityNote,
        loading,
        dealTeam,
        investmentType,
      ]
    );
    return {
      ...modalProps,
      selectedStage: stage,
      setSelectedStage: setStage,
      selectedLead: lead,
      selectedBroker: broker,
      selectedSeller: seller,
      selectedILO: ilo,
      setSelectedLead: setLead,
      setSelectedBroker: setBroker,
      setSelectedSeller: setSeller,
      setSelectedILO: setILO,
      note: lastActivityNote,
      setNote: setLastActivityNote,
      send,
      name,
      setName,
      description,
      setDescription,
      loading,
      assets,
      dealId: deal.dealId,
      showAssets: true,
      dealTeam,
      setDealTeam,
      investmentType,
      setInvestmentType,
    };
  };

export type EdtiDealModalHook = ReturnType<typeof useEditDealModal>;

export const useEditDeal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbarCherre();

  const showEditDialog = useRecoilCallback(
    ({ snapshot }) =>
      async (dealId: number) => {
        if (loading) {
          return;
        }

        setLoading(true);

        //retain snapshot for async operations
        const release = snapshot.retain();

        try {
          // get data
          const modalProps = await getCommonData(snapshot);
          //get deal data
          const dealData = await snapshot
            .getLoadable(dealPortfolioSelectorFamily(dealId))
            .toPromise();

          if (!dealData) {
            throw new Error('Failed to find deal to update');
          }

          const ids = dealData.assets.map((a) => a.cherreAsset.taxAssessorId);

          const taxAssessorsResult = await snapshot.getPromise(
            taxAssessorsQuery({ ids })
          );

          const taxAssessors = dealData.assets
            .map<T.DataFormatter | null>((a) => {
              const tax = taxAssessorsResult.tax_assessor_v2.find(
                (t) => t.tax_assessor_id === a.cherreAsset.taxAssessorId
              );
              return tax ? usaDataFormatter(tax) : null;
            })
            .filter((t): t is T.DataFormatter => Boolean(t));

          const assetsCount = dealData.assets.length;

          if (assetsCount === 1) {
            const hook = useEditDealModal({
              dealType: 'single',
              modalProps: {
                ...modalProps,
                ...F.taxAssessorToDealData(taxAssessorsResult),
              },
              deal: dealData,
              assets: taxAssessors,
              raw_assets: taxAssessorsResult.tax_assessor_v2,
            });

            dispatch(showModal('DEALCLOUD', { hook, mode: 'edit' }));
          } else {
            const hook = useEditDealModal({
              dealType: 'portfolio',
              modalProps,
              deal: dealData,
              assets: taxAssessors,
              raw_assets: taxAssessorsResult.tax_assessor_v2,
            });

            dispatch(showModal('DEALCLOUD_PORTFOLIO', { hook, mode: 'edit' }));
          }
        } catch (e) {
          const msg = U.getErrorMsg(e);
          enqueueSnackbar(msg, U.getSnackbarProps('error'));
          throw e;
        } finally {
          setLoading(false);
          release();
        }
      },
    [loading]
  );

  return [showEditDialog, loading] as const;
};
