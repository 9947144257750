import { Box, CssBaseline, makeStyles } from '@material-ui/core';
import { classnames } from '@material-ui/data-grid';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    width: '95%',
    paddingTop: '34px',
    margin: '0 auto',
  },
}));

export const Page: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Box className={classnames(classes.root, className)}>{children}</Box>
    </>
  );
};
