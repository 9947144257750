import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { ReactElement, useCallback, useState } from 'react';

const useStyles = makeStyles({
  modal: {
    minWidth: 526,
    overflow: 'visible',
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    padding: '20px 20px 0px',
    lineHeight: 'normal',
  },
  content: {
    padding: '17px 20px 0px',
    color: grey[900],
    '& p': {
      margin: 0,
    },
  },
  action: {
    padding: '43px 20px 20px',
  },
  done: {
    padding: '10px 20px 12px',
  },
  cancel: {
    padding: '10px 20px 12px',
  },
});

export type ConfirmProps = {
  open: boolean;
  onRequestClose: () => void;
  data: {
    title: string;
    message: string | ReactElement;
    cancelText?: string;
    okText?: string;
    onClickOk: () => Promise<void>;
    onClickCancel?: () => Promise<void>;
  };
};

const Confirm: React.VFC<ConfirmProps> = ({ open, data, onRequestClose }) => {
  const classes = useStyles();
  const {
    title,
    message,
    cancelText = 'Cancel',
    okText = 'Ok',
    onClickOk,
    onClickCancel,
  } = data;

  const [loading, setLoading] = useState(false);

  const onClickDone = useCallback(async () => {
    setLoading(true);
    try {
      await onClickOk();
      onRequestClose();
    } catch {
      // no-op
    }
    setLoading(false);
  }, [onRequestClose, setLoading]);

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{ paper: classes.modal }}
      data-testid='confirm_modal'
    >
      <Typography className={classes.title}>{title}</Typography>
      <DialogContent className={classes.content}>
        {React.isValidElement(message) ? (
          message
        ) : (
          <Typography>{message}</Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={() => {
            onClickCancel?.();
            onRequestClose();
          }}
          color='primary'
          className={classes.cancel}
        >
          {cancelText}
        </Button>
        <Button
          disabled={loading}
          onClick={onClickDone}
          variant='contained'
          color='primary'
          className={classes.done}
          data-testid='confirm_btn_done'
        >
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
