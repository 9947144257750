import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import TreppLoanDataModalFooterTenant from './TreppLoanDataModalFooterTenant';
import { getMaskedValue } from 'src/utils/mask';
import PieChart from 'src/products/core-prospect/components/Panel/Regular/Element/CherrePlotlyGraphWrapper/PieChart';

const getPieChartDataObject = (values, labels) => {
  const colors = {
    0: '#ff6294',
    1: '#d3b41b',
    2: '#4897f0',
    3: '#ce75b3',
    4: '#bdbdbd',
  };

  return {
    values: values || [],
    labels: labels || [],
    type: 'pie',
    textinfo: 'none',
    automargin: true,
    marker: {
      colors: values.map((value, index) => colors[index]),
    },
  };
};

const TreppLoanDataModalFooterTenants = ({ item }) => {
  const {
    largest_tenant_percent,
    second_largest_tenant_percent,
    third_largest_tenant_percent,
  } = item;
  const { largest_tenant, second_largest_tenant, third_largest_tenant } = item;

  const getPercentage = (rawPercentage) => {
    const rounded = Math.round(rawPercentage * 100) / 100;
    return rounded / 100;
  };
  const labels = [
    largest_tenant,
    second_largest_tenant,
    third_largest_tenant,
  ].filter(Boolean);

  const rawPercentages = [
    largest_tenant_percent,
    second_largest_tenant_percent,
    third_largest_tenant_percent,
  ];
  const formattedPercentages = rawPercentages
    .map((rawPercentage) =>
      rawPercentage ? getPercentage(rawPercentage) : false
    )
    .filter(Boolean);

  const data = getPieChartDataObject(formattedPercentages, labels);
  return (
    <Grid item xs={8}>
      <Typography variant='body2' style={{ color: grey[700] }}>
        Tenants
      </Typography>
      <div style={{ display: 'flex' }}>
        <div>
          <PieChart
            pad={1}
            marginLeft={1}
            marginRight={18}
            marginBottom={1}
            marginTop={8}
            removeLegend
            height={86}
            width={86}
            data={[data]}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          {largest_tenant ? (
            <TreppLoanDataModalFooterTenant
              tenantName={largest_tenant}
              tenantSquareFootage={getMaskedValue(
                item.largest_tenant_square_footage,
                'number'
              )}
              tenantPercent={Math.round(largest_tenant_percent * 100) / 100}
              dotColor={'#ff6294'}
            />
          ) : null}
          {second_largest_tenant ? (
            <TreppLoanDataModalFooterTenant
              tenantName={second_largest_tenant}
              tenantSquareFootage={getMaskedValue(
                item.second_largest_tenant_square_footage,
                'number'
              )}
              tenantPercent={
                Math.round(second_largest_tenant_percent * 100) / 100
              }
              dotColor={'#d3b41b'}
            />
          ) : null}
          {third_largest_tenant ? (
            <TreppLoanDataModalFooterTenant
              tenantName={third_largest_tenant}
              tenantSquareFootage={getMaskedValue(
                item.third_largest_tenant_square_footage,
                'number'
              )}
              tenantPercent={
                Math.round(third_largest_tenant_percent * 100) / 100
              }
              dotColor={'#4897f0'}
            />
          ) : null}
        </div>
      </div>
    </Grid>
  );
};

export default TreppLoanDataModalFooterTenants;
