import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';

export const ViewPropertiesIcon = createSvgIcon(
  <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.73898 0.833496C13.1081 0.833496 16.644 4.36933 16.644 8.73766C16.644 10.2818 16.2006 11.756 15.3798 13.0227L18.679 16.3218C19.3281 16.971 19.3281 18.0277 18.679 18.6777C18.0281 19.3302 16.974 19.331 16.3206 18.6768L13.0223 15.3785C11.7565 16.1985 10.2823 16.6427 8.73898 16.6427C4.36982 16.6427 0.833984 13.1052 0.833984 8.73766C0.833984 4.3685 4.37065 0.833496 8.73898 0.833496ZM8.73898 2.50016C5.29065 2.50016 2.50065 5.29016 2.50065 8.73766C2.50065 12.186 5.29315 14.976 8.73898 14.976C10.4048 14.976 11.9715 14.3277 13.149 13.1485C14.3281 11.9702 14.9773 10.4043 14.9773 8.73766C14.9773 5.28933 12.1848 2.50016 8.73898 2.50016ZM8.75023 4.99983L13.3336 8.33316H11.6669V11.6665H10.0002V9.99983H7.50023V11.6665H5.83357V8.33316H4.1669L8.75023 4.99983Z'
      fill='#DF2467'
    />
  </svg>,
  'ViewProperties'
);
