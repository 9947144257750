import {
  SimplePaletteColorOptions,
  ThemeOptions,
} from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { cherre } from '../clients';

const theme = (window.cherre?.theme || cherre.expose.theme) as ThemeOptions & {
  pageTitleColor: string;
  mapClusterColor: string;
  logo: string;
  appBar: {
    colorPrimary?: {
      backgroundColor: string;
    };
  };
};

export const pageTitleColor = theme.pageTitleColor;
export const mapClusterColor = theme.mapClusterColor;
export const logoUrl = theme.logo;
export const primaryColor = theme.palette?.primary as SimplePaletteColorOptions;
export const secondaryColor = theme.palette
  ?.secondary as SimplePaletteColorOptions;

const typography = {
  fontFamily: 'Montserrat',
  h1: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 48,
  },
  h2: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 40,
  },
  h3: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 27,
  },
  h4: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 24,
  },
  h5: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 20,
  },
  h6: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 16,
  },
  subtitle1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 18,
  },
  subtitle2: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
  },
  body1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 14,
    '@media (maxWidth:600px)': {
      fontSize: 12,
    },
  },
  body2: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 12,
  },
  button: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 14,
    textTransform: 'capitalize',
  },
  caption: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 10,
  },
  overline: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 10,
    textTransform: 'uppercase',
  },
} satisfies TypographyOptions;

const muiThemeSettings = {
  typography: {
    ...typography,
    fontSize: 16,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        lineHeight: '1.3rem',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1rem',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: theme.appBar?.colorPrimary?.backgroundColor,
      },
    },
    MuiTooltip: {
      tooltip: {
        color: '#fff !important',
        backgroundColor: 'rgba(0, 0, 0, 0.8) !important',
        borderRadius: '2px !important',
        fontSize: '12px !important',
        fontWeight: 400,
        padding: '8px !important',
      },
    },
    MuiTable: {
      root: {
        fontFamily: 'Montserrat',
        fontSize: 12,
        fontWeight: 500,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontFamily: 'Montserrat',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#9e9e9e',
        '&$selected': {
          color: primaryColor?.main,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: primaryColor?.main,
        height: 5,
        borderRadius: '5px 5px 0 0',
      },
    },
  },
  palette: {
    background: {
      default: '#eeeeee',
    },
    primary: primaryColor,
    secondary: secondaryColor,
    error: {
      main: '#e23b49',
      light: '#FFD9D8',
    },
    warning: {
      main: '#bfa901',
      dark: '#FF9B04',
      light: '#FFFBED',
    },
    info: {
      main: '#00b9f7',
    },
    success: {
      light: '#D4F6D5',
      main: '#5de043',
      dark: '#43A047',
    },
  },
} as const;

export default muiThemeSettings;
