// example - { is_pre_war: { _eq: true } }

export function getQuery(filter, value) {
  if (!value) {
    return;
  }

  const where = { _eq: value };

  return where;
}

export function getFilterSetDisplayValue(filter, value) {
  if (value) {
    return filter.label;
  }
}

export function getFilterDisplayValue(filter, value) {
  if (value) {
    return filter.label;
  }
}

export default {
  getQuery,
  getFilterDisplayValue,
  getFilterSetDisplayValue,
};
