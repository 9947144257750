import styled from 'styled-components';

export const Flex = styled.div`
  flex: ${(props) => props.flex || 1};
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.direction || 'row'};
  flex-wrap: ${(props) => props.flexWrap};
`;

export const FlexSpan = styled.span`
  flex: ${(props) => props.flex || 1};
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.direction || 'row'};
  flex-wrap: ${(props) => props.flexWrap};
`;

export const FlexChangeOnMobile = styled.div`
  flex: ${(props) => props.flex || 1};
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.direction || 'row'};
  flex-wrap: ${(props) => props.flexWrap};
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex: ${(props) => props.flex || 1};
  flex-wrap: ${(props) => props.flexWrap || 'no-wrap'};
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

export const FlexColumn = styled.div`
  position: relative;
  display: flex;
  flex: ${(props) => props.flex || 1};
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  align-self: ${(props) => props.alignSelf};
`;
