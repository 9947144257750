import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';

export const PopupIcon = createSvgIcon(
  <svg viewBox='0 0 12 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9.33329 7.66732C9.33329 8.03598 9.03529 8.33398 8.66663 8.33398H3.33329C2.96463 8.33398 2.66663 8.03598 2.66663 7.66732V4.33398C2.66663 3.96532 2.96463 3.66732 3.33329 3.66732H3.58796C3.84063 3.66732 4.07129 3.52465 4.18396 3.29865L4.48263 2.70265C4.59529 2.47665 4.82596 2.33398 5.07863 2.33398H6.92129C7.17396 2.33398 7.40463 2.47665 7.51729 2.70265L7.81596 3.29865C7.92863 3.52465 8.15929 3.66732 8.41196 3.66732H8.66663C9.03529 3.66732 9.33329 3.96532 9.33329 4.33398V7.66732ZM9.99996 0.333984H1.99996C1.26663 0.333984 0.666626 0.933984 0.666626 1.66732V9.00065C0.666626 9.73398 1.26663 10.334 1.99996 10.334H3.91663L5.99996 13.6673L8.08329 10.334H9.99996C10.7333 10.334 11.3333 9.73398 11.3333 9.00065V1.66732C11.3333 0.933984 10.7333 0.333984 9.99996 0.333984Z' />
    <path d='M5.99996 4.33398C5.26396 4.33398 4.66663 4.93132 4.66663 5.66732C4.66663 6.40332 5.26396 7.00065 5.99996 7.00065C6.73596 7.00065 7.33329 6.40332 7.33329 5.66732C7.33329 4.93132 6.73596 4.33398 5.99996 4.33398Z' />
  </svg>,
  'PopupIcon'
);
