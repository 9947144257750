import { useCherreValue } from '@cherre-frontend/data-fetching';
import { Box, styled } from '@cherre-frontend/ui';
import React, { useMemo } from 'react';
import { getResolvedReminders, getUnresolvedReminders } from '../recoil';
import Filter from './Filter';

const Container = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const RemindersDrawerFilters: React.FC = () => {
  const resolvedReminders = useCherreValue(getResolvedReminders());
  const unresolvedReminders = useCherreValue(getUnresolvedReminders());

  const filterOptions = useMemo(() => {
    const reminders = [
      ...(resolvedReminders ?? []),
      ...(unresolvedReminders ?? []),
    ];

    const options = reminders.reduce(
      (acc, reminder) => {
        if (reminder?.property_batch?.submission.submission_name) {
          acc.Batch[reminder.property_batch.submission.submission_name] =
            reminder.property_batch.submission.submission_name;
        }

        if (reminder?.property.entity_id) {
          acc['Entity ID'][reminder?.property.entity_id] =
            reminder?.property.entity_id;
        }

        if (
          reminder?.property_batch.submission.submission_type.submission_type_id
        ) {
          acc.Type[
            reminder.property_batch.submission.submission_type.submission_type_label
          ] =
            reminder.property_batch.submission.submission_type.submission_type_label;
        }

        return acc;
      },
      {
        Batch: {},
        'Entity ID': {},
        Type: {},
      }
    );

    Object.keys(options).forEach((key) => {
      options[key] = Object.fromEntries(Object.entries(options[key]).sort());
    });

    return options;
  }, []);

  return (
    <Container position='relative'>
      {Object.keys(filterOptions).map((filterType) => {
        return (
          <Filter
            key={`filter-${filterType}`}
            filterType={filterType}
            filterOptions={filterOptions[filterType]}
          />
        );
      })}
    </Container>
  );
};

export default RemindersDrawerFilters;
