import { Tabs, Tab, TabsProps } from '@material-ui/core';
import React from 'react';

const TabPanel: React.FC<{ selected: boolean }> = ({ selected, children }) => {
  if (!selected) {
    return null;
  }
  return <>{children}</>;
};

interface TabProps {
  label: string;
}

const TabComponent: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

interface ContainerProps extends TabsProps {
  children: (ReturnType<typeof TabComponent> | null | undefined)[];
  onTabChange?: (label: string) => void;
}

const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  const [selected, setSelected] = React.useState(0);
  const { onTabChange, ...tabsProps } = props;

  React.useEffect(() => {
    const label = (React.Children.toArray(children) as any[])[selected].props
      .label;
    onTabChange?.(label);
  }, [selected]);

  return (
    <>
      <Tabs
        value={selected}
        onChange={(_, value) => setSelected(value)}
        {...tabsProps}
      >
        {React.Children.map(
          children,
          (child: any) => child && <Tab label={child.props.label} />
        )}
      </Tabs>
      {React.Children.map(
        children,
        (child, index) =>
          child && <TabPanel selected={selected === index}>{child}</TabPanel>
      )}
    </>
  );
};

export default {
  Container,
  Tab: TabComponent,
};
