type Props = {
  format?: string;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
};

export const getNumberFormatProps = (props?: Props): Props | void => {
  if (!props) {
    return;
  }

  return {
    format: props.format,
    prefix: props.prefix,
    suffix: props.suffix,
    decimalSeparator:
      typeof props.decimalSeparator === 'undefined'
        ? '.'
        : props.decimalSeparator,
    thousandSeparator:
      typeof props.thousandSeparator === 'undefined'
        ? ','
        : props.thousandSeparator,
  };
};
