import {
  useCherrePartialState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  Box,
  Drawer,
  Grid,
  Panel,
  Typography,
  styled,
} from '@cherre-frontend/ui';
import React, { useEffect } from 'react';
import ReminderDrawerContent from './ReminderDrawerContent';
import RemindersDrawerFilters from './RemindersDrawerFilters';
import {
  reminderDrawerState,
  searchParamReminderDrawerState,
  useMarkRemindersReviewedMutation,
} from './recoil';

const Content = styled(Box)`
  width: 350px;
  padding: ${({ theme }) => theme.spacing(2)};
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  overflow: hidden;
`;

export type FilterOptions = {
  'Entity ID': Record<string, string>;
  Batch: Record<string, string>;
};

const RemindersDrawer: React.FC = () => {
  const searchParamReminderDrawer = useCherreValue(
    searchParamReminderDrawerState
  );

  const markRemindersReviewed = useMarkRemindersReviewedMutation();

  const [reminderDrawer, setReminderDrawer] =
    useCherrePartialState(reminderDrawerState);

  useEffect(() => {
    if (!searchParamReminderDrawer) {
      return;
    }
    setReminderDrawer({
      isOpen: searchParamReminderDrawer.isOpen,
      showCompleted: searchParamReminderDrawer.showCompleted,
      filterSelections: {
        Batch: searchParamReminderDrawer.filterSelections.Batch as string[],
        'Entity ID': searchParamReminderDrawer.filterSelections[
          'Entity ID'
        ] as string[],
        Type: searchParamReminderDrawer.filterSelections.Type as string[],
      },
    });
  }, [searchParamReminderDrawer]);

  const closeDrawer = () => {
    markRemindersReviewed();
    setReminderDrawer({ isOpen: false });
  };

  return (
    <Drawer anchor='right' open={reminderDrawer?.isOpen} onClose={closeDrawer}>
      <Panel id='reminder-drawer-panel'>
        <Content>
          <Typography variant='h4'>Reminders</Typography>

          <Grid display='flex' alignItems='center' justifyContent='flex-start'>
            <RemindersDrawerFilters />
          </Grid>
          <ReminderDrawerContent />
        </Content>
      </Panel>
    </Drawer>
  );
};

export default RemindersDrawer;
