import {
  GraphQLReturn,
  atom,
  graphQLSelector,
  searchParamsSelector,
  selector,
  useMutation,
} from '@cherre-frontend/data-fetching';
import { array, bool, object, string, withDefault } from '@recoiljs/refine';
import { graphql } from 'react-relay';
import { getDSPUser } from 'src/products/data-submission-portal/recoil/getUser';
import { recoilGetUnreadRemindersCountQuery } from './__generated__/recoilGetUnreadRemindersCountQuery.graphql';
import {
  recoilGetUnresolvedRemindersQuery,
  recoilGetUnresolvedRemindersQuery$data,
  sys_reminders_bool_exp,
} from './__generated__/recoilGetUnresolvedRemindersQuery.graphql';
import { recoilResolvedRemindersQuery } from './__generated__/recoilResolvedRemindersQuery.graphql';
import { recoilUseMarkRemindersReviewedMutation } from './__generated__/recoilUseMarkRemindersReviewedMutation.graphql';

export type Reminder =
  recoilGetUnresolvedRemindersQuery$data['sys_reminders'][0];

export const reminderDrawerState = atom<{
  isOpen: boolean;
  showCompleted: boolean;
  filterSelections: {
    Batch: string[];
    'Entity ID': string[];
    Type: string[];
  };
}>({
  key: 'reminder-drawer-state-internal',
  default: {
    isOpen: false,
    showCompleted: false,
    filterSelections: {
      Batch: [],
      'Entity ID': [],
      Type: [],
    },
  },
});

export const reminderDrawerShowCompletedState = selector({
  key: 'reminder-drawer-show-completed-state',
  get: ({ get }) => {
    const reminderDrawer = get(reminderDrawerState);
    return reminderDrawer.showCompleted;
  },
  set: ({ set }, newValue) => {
    set(reminderDrawerState, (oldValue) => ({
      ...oldValue,
      showCompleted: newValue,
    }));
  },
});

const reminderDrawerSearchParams = searchParamsSelector({
  key: 'reminder-drawer-state',
  refine: withDefault(
    object({
      isOpen: bool(),
      showCompleted: bool(),
      filterSelections: object({
        Batch: array(string()),
        'Entity ID': array(string()),
        Type: array(string()),
      }),
    }),
    {
      isOpen: false,
      showCompleted: false,
      filterSelections: {
        Batch: [],
        'Entity ID': [],
        Type: [],
      },
    }
  ),
  routes: ['/**'],
});

export const searchParamReminderDrawerState = selector({
  key: 'search-param-reminder-drawer-state',
  get: ({ get }) => {
    try {
      return get(reminderDrawerSearchParams);
    } catch {
      return undefined;
    }
  },
});

export const reminderDrawerFilterState = selector({
  key: 'reminder-drawer-filter-state',
  get: ({ get }) => {
    const reminderDrawer = get(reminderDrawerState);
    return reminderDrawer.filterSelections;
  },
  set: ({ set }, newValue) => {
    set(reminderDrawerState, (oldValue) => ({
      ...oldValue,
      filterSelections: newValue,
    }));
  },
});

export const getResolvedReminders = graphQLSelector({
  query: graphql`
    subscription recoilResolvedRemindersQuery(
      $where: sys_reminders_bool_exp
      $user_id: Int
    ) {
      sys_reminders(where: $where, order_by: { created_at_datetime: desc }) {
        reminder_id
        is_resolved
        created_at_datetime
        text
        property_batch_id
        property_id
        user {
          sakura_user {
            first_name
            last_name
          }
        }
        package_report {
          package_report_label
          package_report_slug
        }
        property_batch {
          submission_id
          submission {
            submission_name
            reporting_period
            reporting_period_start_date
            submission_type {
              submission_type_label
              submission_type_id
            }
          }
        }
        property {
          entity_id
          properties_roles_users(where: { user_id: { _eq: $user_id } }) {
            property_role {
              property_role_set
            }
          }
        }
        user_viewed_count: reminders_reviewed_aggregate(
          where: { user_id: { _eq: $user_id } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<recoilResolvedRemindersQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const where: sys_reminders_bool_exp = {
        is_resolved: { _eq: true },
      };
      const filterSelections = get(reminderDrawerFilterState);

      if (filterSelections.Batch.length > 0) {
        where.submission = {
          submission_name: { _in: filterSelections.Batch },
        };
      }

      if (filterSelections['Entity ID'].length > 0) {
        where.property = {
          entity_id: { _in: filterSelections['Entity ID'] },
        };
      }

      if (filterSelections.Type.length > 0) {
        where.property_batch = {
          submission: {
            submission_type: {
              submission_type_label: { _in: filterSelections.Type },
            },
          },
        };
      }

      return {
        user_id: get(getDSPUser()).user_id,
        where,
      };
    },
  mapResponse: (response) => response.sys_reminders,
});

export const getUnresolvedReminders = graphQLSelector({
  query: graphql`
    subscription recoilGetUnresolvedRemindersQuery(
      $where: sys_reminders_bool_exp
      $user_id: Int
    ) {
      sys_reminders(where: $where, order_by: { created_at_datetime: desc }) {
        reminder_id
        is_resolved
        created_at_datetime
        text
        property_batch_id
        property_id
        user {
          sakura_user {
            first_name
            last_name
          }
        }
        package_report {
          package_report_label
          package_report_slug
        }
        property_batch {
          submission_id
          submission {
            submission_name
            reporting_period
            reporting_period_start_date
            submission_type {
              submission_type_label
              submission_type_id
            }
          }
        }
        property {
          entity_id
          properties_roles_users(where: { user_id: { _eq: $user_id } }) {
            property_role {
              property_role_set
            }
          }
        }
        user_viewed_count: reminders_reviewed_aggregate(
          where: { user_id: { _eq: $user_id } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  ` as GraphQLReturn<recoilGetUnresolvedRemindersQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      const where: sys_reminders_bool_exp = {
        is_resolved: { _eq: false },
      };

      const filterSelections = get(reminderDrawerFilterState);

      if (filterSelections.Batch.length > 0) {
        where.submission = {
          submission_name: { _in: filterSelections.Batch },
        };
      }

      if (filterSelections['Entity ID'].length > 0) {
        where.property = {
          entity_id: { _in: filterSelections['Entity ID'] },
        };
      }

      if (filterSelections.Type.length > 0) {
        where.property_batch = {
          submission: {
            submission_type: {
              submission_type_label: { _in: filterSelections.Type },
            },
          },
        };
      }

      return {
        user_id: get(getDSPUser()).user_id,
        where,
      };
    },
  mapResponse: (response) => response.sys_reminders,
});

export const getUnreadRemindersCount = graphQLSelector({
  query: graphql`
    subscription recoilGetUnreadRemindersCountQuery($user_id: Int) {
      sys_reminders_aggregate(
        where: {
          is_resolved: { _eq: false }
          reminders_reviewed_aggregate: {
            count: {
              predicate: { _eq: 0 }
              filter: { user_id: { _eq: $user_id } }
            }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  ` as GraphQLReturn<recoilGetUnreadRemindersCountQuery>,
  mapVariables:
    () =>
    ({ get }) => {
      return {
        user_id: get(getDSPUser()).user_id,
      };
    },
  mapResponse: (response) =>
    response.sys_reminders_aggregate.aggregate?.count ?? 0,
});

export const useMarkRemindersReviewedMutation = () => {
  return useMutation(
    graphql`
      mutation recoilUseMarkRemindersReviewedMutation(
        $params: create_reminders_reviewed_params!
      ) {
        _sys_create_reminders_reviewed(params: $params) {
          reminders_reviewed_ids
        }
      }
    ` as GraphQLReturn<recoilUseMarkRemindersReviewedMutation>,
    {
      mapVariables:
        () =>
        async ({ getPromise }) => {
          const unresolvedReminders = await getPromise(
            getUnresolvedReminders()
          );
          const reminder_ids = unresolvedReminders.map(
            (reminder) => reminder.reminder_id
          );

          return {
            params: {
              reminder_ids,
            },
          };
        },
    }
  );
};
