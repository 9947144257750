import { styled, Box } from '@cherre-frontend/ui';

export const ReminderListContainer = styled(Box)`
  overflow-y: auto;
  height: 100%;
  padding-right: ${({ theme }) => theme.spacing(1)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;
