import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

class Suggestion extends Component {
  static propTypes = {
    parts: PropTypes.array,
    isHighlighted: PropTypes.bool,
    classes: PropTypes.object,
  };

  render() {
    const { parts, isHighlighted, classes } = this.props;

    return (
      <MenuItem
        className={cx(classes.root)}
        selected={isHighlighted}
        component='div'
      >
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span
                key={index}
                className={classes.text}
                style={{ fontWeight: 500 }}
              >
                {part.text}
              </span>
            ) : (
              <span key={index} style={{ fontWeight: 300 }}>
                {part.text}
              </span>
            );
          })}
        </div>
      </MenuItem>
    );
  }
}

export default withStyles(() => ({
  root: {},
}))(Suggestion);
