import React from 'react';
import Grid from '@material-ui/core/Grid';
import TreppLoanDataModalFooterTenants from './TreppLoanDataModalFooterTenants';
import TreppLoanDataModalMasterServicer from './TreppLoanDataModalMasterServicer';
import TreppLoanNameIdAndLogo from './TreppLoanNameIdAndLogo';

const TreppLoanDataModalFooter = ({ item }) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Grid container spacing={3}>
        <TreppLoanDataModalMasterServicer item={item} />
        <TreppLoanDataModalFooterTenants item={item} />
      </Grid>
      <TreppLoanNameIdAndLogo item={item} />
    </div>
  );
};

export default TreppLoanDataModalFooter;
