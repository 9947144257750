export const RUNTIMES = {
  PROD: 'app',
  STAGE: 'sandbox',
  LOCALHOST: 'dev',
};

export const IS_EMULATED_SESSION_KEY = 'isEmulatedSession';

export const EMPTY_VALUE_FOR_LOTS_PAGE = ' ';

export const IS_SEGMENT_TRACKER_ENABLED =
  window.analytics !== undefined &&
  !sessionStorage.getItem(IS_EMULATED_SESSION_KEY);

export const IS_SERVICE_WORKER_ENABLED = window.runtime !== RUNTIMES.LOCALHOST;

export const PRODUCTS = {
  EXPLORE: 'CoreExplore',
  QUERY: 'CoreQuery',
  CONNECT: 'CoreConnect',
  BI: 'Dashboards',
  SCHEMA: 'CoreSchema',
  API: 'CoreAPI',
  ANALYTICS_API: 'AnalyticsAPI',
  ANALYTICS_SCHEMA: 'AnalyticsSchema',
  USER_MANAGEMENT: 'UserManagement',
  API_KEY_MANAGEMENT: 'ApiKeyManagement',
  NORTH_STAR: 'NorthStar',
  CHERRE_ADMIN: 'CherreAdmin',
  DATA_SUBMISION_PORTAL: 'DataSubmissionPortal',
  CONNECTORS: 'Connectors',
} as const;

export const PROPERTY_MAPPINGS = {
  ONE_TO_ONE: '1:1',
  MANY_TO_ONE: 'M:1',
} as const;

export const PRODUCT_LINES = {
  DEVELOPMENT: 'Development',
  CORE_EXPLORE: 'CoreExplore',
  CORE_CONNECT: 'Core Connect',
  DATA_SUBMISSION_PORTAL: 'Data Submission Portal',
  ADMINISTRATION: 'Administration',
} as const;

export const OWNER_TYPE = {
  P: 'INDIVIDUAL',
  G: 'GOVERNMENT',
  E: 'EDUCATION',
  I: 'COMPANY',
  C: 'COMPANY',
  L: 'LENDER',
} as const;

const constants = {
  IS_EMULATED_SESSION_KEY,
  ORDER_BY_DIRECTION: {
    ASC: 'asc',
    DESC: 'desc',
  },
  NAV_BAR_HEIGHT: 60,
  CORE_PROSPECT_NAV_BAR_HEIGHT: 73,
  NAV_BAR_Z_INDEX: 1002,
  LAYOUT_SIZE: {
    xs: '100%',
    sm: '100%',
    md: '750px',
    lg: '1200px',
    xl: '1600px',
    xxl: '1600px',
  },
  MAX_IMAGE_SIZE: 20,
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED',
  STATIONS: [
    {
      name: 'A',
      color: 'rgb(50, 90, 170)',
    },
    {
      name: 'C',
      color: 'rgb(50, 90, 170)',
    },
    {
      name: 'E',
      color: 'rgb(50, 90, 170)',
    },
    {
      name: 'B',
      color: 'rgb(250, 100, 50)',
    },
    {
      name: 'D',
      color: 'rgb(250, 100, 50)',
    },
    {
      name: 'F',
      color: 'rgb(250, 100, 50)',
    },
    {
      name: 'M',
      color: 'rgb(250, 100, 50)',
    },
    {
      name: 'G',
      color: 'rgb(114, 184, 79)',
    },
    {
      name: 'L',
      color: 'rgb(165, 165, 165)',
    },
    {
      name: 'J',
      color: 'rgb(149, 100, 60)',
    },
    {
      name: 'Z',
      color: 'rgb(149, 100, 60)',
    },
    {
      name: 'N',
      color: '#fcc71f',
    },
    {
      name: 'Q',
      color: '#fcc71f',
    },
    {
      name: 'R',
      color: '#fcc71f',
    },
    {
      name: 'W',
      color: '#fcc71f',
    },
    {
      name: '1',
      color: '#f62932',
    },
    {
      name: '2',
      color: '#f62932',
    },
    {
      name: '3',
      color: '#f62932',
    },
    {
      name: '4',
      color: 'rgb(29, 143, 65)',
    },
    {
      name: '5',
      color: 'rgb(29, 143, 65)',
    },
    {
      name: '6',
      color: 'rgb(29, 143, 65)',
    },
    {
      name: '6E',
      color: 'rgb(29, 143, 65)',
    },
    {
      name: '7',
      color: 'rgb(179, 62, 168)',
    },
    {
      name: '7E',
      color: 'rgb(179, 62, 168)',
    },
    {
      name: 'S',
      color: 'rgb(127, 127, 127)',
    },
  ],
  BOROUGHS: {
    1: {
      key: 'MN',
      name: 'Manhattan',
    },
    2: {
      key: 'BX',
      name: 'Bronx',
    },
    3: {
      key: 'BK',
      name: 'Brooklyn',
    },
    4: {
      key: 'QN',
      name: 'Queens',
    },
    5: {
      key: 'SI',
      name: 'Staten Island',
    },
  },
  CITY_PART_TO_CBSA_CODE: {
    'NEW Y': '35620',
    DALLA: '19100',
    'LOS A': '31100',
    'SAN F': '41860',
  },
} as const;

export const CHERRE_BASE_MAP_STYLE =
  'mapbox://styles/sbcherre/cl5vaos2c002714mmxp6h5yg1';
export const CHERRE_SATELLITE_MAP_STYLE =
  'mapbox://styles/sbcherre/cl708yrwd000c14myu1ipa309';

export const CHERRE_POSTHOG_KEY =
  'phc_TcNa1pRfoqqXUkKT3TgvVx5yqyKM681BZRzvG79jkhw';

export const DbSysPropertyTypesIds = {
  ONE_TO_ONE: 1,
  INVESTMENT: 2,
  PROVIDER: 3,
} as const;

export default constants;
