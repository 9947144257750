import { cherreCreateTheme } from '@cherre-frontend/ui';
import muiThemeSettings from './theme';

export const themeV5 = cherreCreateTheme({
  ...muiThemeSettings,
  accents: {
    deals: {
      main: '#E88819',
      light: '#F6FFFA',
    },
    positive: {
      main: '#43A047',
      light: '#F6FFFA',
    },
    error: {
      main: '#E53935',
      light: '#FFD9D8',
    },
    info: {
      main: '#039BE5',
      light: '#E4F6FF',
    },
    warning: {
      main: '#FF9B04',
      light: '#FFFBED',
    },
    brown: {
      main: '#AD5D00',
      light: '#FFFBED',
    },
  },
  palette: {
    ...muiThemeSettings.palette,
    positive: { main: '#43A047' },
  },
});
