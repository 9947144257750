import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTeamAddMembersModal } from 'src/products/core-prospect/hooks/useTeamAddMembers';
import {
  MemberOption,
  MembersAutocomplete,
} from './components/MembersAutocomplete';
import { useStyles } from './style';

export type TeamAddMembersProps = {
  open: boolean;
  data: {
    teamId: number;
    currentMembers: number[];
  };
  onRequestClose: () => void;
};

const TeamAddMembers: React.VFC<TeamAddMembersProps> = ({
  open,
  data,
  onRequestClose,
}) => {
  const classes = useStyles();
  const { teamId, currentMembers } = data;

  const { isSaving, onClickAdd } = useTeamAddMembersModal(
    teamId,
    onRequestClose
  );

  const [selectedMembers, setSelectedMembers] = useState<MemberOption[]>([]);

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{ paper: classes.modal }}
      data-testid='group_add_members_modal'
    >
      <Typography className={classes.title}>Add group members</Typography>
      <DialogContent className={classes.content}>
        <Typography className={classes.key}>Add new group members</Typography>
        <div className={classes.value}>
          <MembersAutocomplete
            selectedValues={selectedMembers}
            setSelectedValues={setSelectedMembers}
            currentMembers={currentMembers}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={onRequestClose}
          color='primary'
          className={classes.cancel}
        >
          Cancel
        </Button>
        <Button
          disabled={isSaving}
          onClick={() =>
            onClickAdd([...currentMembers, ...selectedMembers.map((s) => s.id)])
          }
          variant='contained'
          color='primary'
          className={classes.add}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamAddMembers;
