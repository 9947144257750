import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import RadioSelector from './RadioSelector';
import CheckboxSelector from './CheckboxSelector';
import GroupHeader from './GroupHeader';

const LayerGroup = ({ classes, group, onChange, onLayerSelect }) => {
  return (
    <div className={classes.root}>
      <GroupHeader group={group} onChange={onChange} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {group.layers.map((layer, i) => {
          if (group.radio) {
            return (
              <RadioSelector
                key={i}
                group={group}
                layer={layer}
                onChange={onChange}
                onLayerSelect={onLayerSelect}
              />
            );
          } else {
            return (
              <CheckboxSelector
                key={i}
                group={group}
                layer={layer}
                onChange={onChange}
                onLayerSelect={onLayerSelect}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

const styles = {
  root: {
    marginBottom: 15,
  },
};

export default withStyles(styles)(LayerGroup);
