import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRecoilCallback } from 'recoil';
import { usaDataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/usaDataFormatter';
import { showErrorPopupNotification } from 'src/products/shell/Notifications';
import { showModal } from 'src/products/shell/redux/modals/actions';
import { dealPortfolioSelectorFamily, taxAssessorsQuery } from './recoil';

export const useViewProperties = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const cbk = useRecoilCallback(
    ({ snapshot }) =>
      async (dealCloudId: number) => {
        if (loading) {
          return;
        }
        setLoading(true);
        const release = snapshot.retain();

        const deal = await snapshot.getPromise(
          dealPortfolioSelectorFamily(dealCloudId)
        );

        if (!deal) {
          release();
          showErrorPopupNotification('Failed to find deal');
          throw new Error('could not find deal');
        }

        const ids = deal.assets.map((a) => a.cherreAsset.taxAssessorId);

        const taxAssessorsResult = await snapshot.getPromise(
          taxAssessorsQuery({ ids })
        );

        const taxAssessors = deal.assets
          .map((a) => {
            const tax = taxAssessorsResult.tax_assessor_v2.find(
              (t) => t.tax_assessor_id === a.cherreAsset.taxAssessorId
            );
            return tax ? usaDataFormatter(tax) : null;
          })
          .filter(Boolean);

        release();
        setLoading(false);
        dispatch(showModal('DEALCLOUD_PROPERTIES', { deal, taxAssessors }));
      },
    [loading]
  );
  return [cbk, loading] as const;
};
