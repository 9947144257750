import React from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ExpansionIconStyles } from './TreeCustomComponents';

const ExpansionIcon = (props) => {
  const {
    expanded,
    node,
    handleExpansionIconClick,
    showExpansionIcon,
    idData,
    disabled,
  } = props;

  if (showExpansionIcon(idData[node])) {
    return expanded[node] ? (
      <ExpandLess
        style={ExpansionIconStyles(disabled[node])}
        onClick={() => handleExpansionIconClick(node)}
      />
    ) : (
      <ExpandMore
        style={ExpansionIconStyles(disabled[node])}
        onClick={() => handleExpansionIconClick(node)}
      />
    );
  } else {
    return null;
  }
};

export default ExpansionIcon;
