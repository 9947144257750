import {
  routeParamsSelector as routeParams,
  searchParamsSelector,
  SearchParamsSelectorParams,
} from '@cherre-frontend/data-fetching';
import { PathType, Route } from './types';
import { PersonaScope } from '../../products/data-submission-portal/packages/dsp-role-based-rendering';
import { usePermission } from 'src/hooks';
import { constSelector, selector, useRecoilValue, waitForAny } from 'recoil';
import { DSPHasuraJWT } from 'src/products/shell/DSPRelayEnvironment/jwt';
import React from 'react';
import {
  personasList,
  PersonasType,
} from 'src/products/data-submission-portal/packages/dsp-role-based-rendering/context';

export const createRoute = <T extends PathType>(input: Route<T>) => {
  const routeDescriptor = input;
  const routeParamSelector = routeParams(input.path);
  const searchParamsSelectorFamily = <R,>(
    params: Omit<SearchParamsSelectorParams<R>, 'routes'>
  ) => searchParamsSelector({ ...params, routes: [input.path] });
  return { routeDescriptor, routeParamSelector, searchParamsSelectorFamily };
};

export const PersonaWrapper =
  (Component: Route<any>['component'], role: PersonasType) => () => {
    return (
      <PersonaScope persona={role}>
        <Component />
      </PersonaScope>
    );
  };

export const isDspRole = (role: string) => () => {
  const isRegularUser = usePermission('DSPRegular');
  const isAdminUser = usePermission('DSPAdmin');
  const dspRoles = useRecoilValue(
    isRegularUser || isAdminUser ? DSPHasuraJWT.rolesAtom : constSelector([])
  );
  return dspRoles.includes(role);
};

export const groupRoutes = <A extends PathType, B extends PathType>(
  routeA: ReturnType<typeof createRoute<A>>,
  routeB: ReturnType<typeof createRoute<B>>
) => {
  const routes = [routeA, routeB];
  const routeDescriptors = routes?.map((r) => r.routeDescriptor) ?? [];

  const paths =
    routes?.flatMap((r) => r.routeDescriptor.path as PathType) ?? [];

  const routeParamSelectors = routes?.map((r) => r.routeParamSelector) ?? [];

  const routeParamSelector = selector({
    key: `route-group-params-selector::${paths.join(',')}`,
    get: ({ get }) => {
      const values = get(waitForAny(routeParamSelectors));
      const resolved = values.find((v) => v.state === 'hasValue');
      return resolved!.getValue();
    },
  });

  const searchParamsSelectorFamily = <R,>(
    params: Omit<SearchParamsSelectorParams<R>, 'routes'>
  ) =>
    searchParamsSelector({
      ...params,
      routes: routes?.flatMap((r) => r.routeDescriptor.path as PathType) ?? [],
    });

  return { routeDescriptors, routeParamSelector, searchParamsSelectorFamily };
};

export const createRouteGroup = <T extends PathType>(
  input: Omit<Route<T>, 'menu'> & {
    menu?: (persona: PersonasType) => Route<T>['menu'] | undefined;
  }
) => {
  const routeDescriptors: Route<T>[] = personasList.map((persona) => ({
    ...input,
    component: PersonaWrapper(input.component, persona),
    permissionHook: isDspRole(persona),
    path: input.path.replace('{{persona}}', persona) as T,
    menu: input.menu?.(persona),
  }));

  const routeParamSelectors = personasList.map((persona) =>
    routeParams(input.path.replace('{{persona}}', persona) as T)
  );

  const routeParamSelector = selector({
    key: `route-group-params-selector::${input.path}`,
    get: ({ get }) => {
      const values = get(waitForAny(routeParamSelectors));
      const resolved = values.find((v) => v.state === 'hasValue');
      return resolved!.getValue();
    },
  });

  const searchParamsSelectorFamily = <R,>(
    params: Omit<SearchParamsSelectorParams<R>, 'routes'>
  ) =>
    searchParamsSelector({
      ...params,
      routes: personasList.map(
        (persona) => input.path.replace('{{persona}}', persona) as T
      ),
    });

  return { routeDescriptors, routeParamSelector, searchParamsSelectorFamily };
};
