import React from 'react';
import FileTemplate from './FileTemplate';

const SearchCSVGenerated = (props) => {
  const { notification, isSidebar } = props;

  if (!notification.data) {
    return null;
  }

  if (!isSidebar) {
    return (
      <div>
        <h4>Your CSV is ready.</h4>
        <a
          href={notification.data.fileURL}
          download={notification.data.fileName}
        >
          {notification.data.fileName}
        </a>
      </div>
    );
  } else {
    return <FileTemplate {...props} />;
  }
};

export default SearchCSVGenerated;
