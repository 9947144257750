import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { Alert, Autocomplete } from '@material-ui/lab';
import React from 'react';
import { dealStageColorMap } from 'src/products/core-prospect/constants';
import { DealStage } from 'src/products/core-prospect/pages/lot-page/components';
import { CreateDealModalHook } from 'src/services/Dealcloud/createDeal';
import ButtonProgress from 'src/components/ButtonProgress';
import { EdtiDealModalHook } from 'src/services/Dealcloud/editDeal';
import Edit from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PropertyItem } from './DealcloudProperties';
import * as K from './consts/DealcloudPortfolio.consts';
import Warning from '@material-ui/icons/Warning';

interface DealModalProps {
  open: boolean;
  onRequestClose: () => void;
  data: {
    hook: CreateDealModalHook | EdtiDealModalHook;
    mode: 'create' | 'edit';
  };
  shareStatus: string;
}

interface StylesProps {
  mode: 'create' | 'edit';
  showAssets: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  modal: {
    minWidth: ({ mode, showAssets }) =>
      mode === 'create' || !showAssets ? 500 : 820,
    overflow: 'auto',
    borderRadius: 10,
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: 20,
    padding: '20px 20px 0px 20px',
  },
  dialogSubtitle: {
    fontWeight: 500,
    fontSize: 14,
    padding: '0px 20px 20px 20px',
  },
  modalContent: {
    overflow: 'visible',
    display: 'grid',
    gridTemplateAreas: ({ mode, showAssets }) =>
      mode === 'create' || !showAssets
        ? `
    "team team"
    "name name"
    "description description"
    "stage lead"
    "ilo broker"
    "seller seller"
    "note note"
  `
        : `
    "team team properties"
    "name name properties"
    "description description properties"
    "stage lead properties"
    "ilo broker properties"
    "seller seller properties"
    "note note properties"
  `,
    gridTemplateColumns: ({ mode, showAssets }) =>
      mode === 'create' || !showAssets ? '1fr 1fr' : '3fr 3fr 4fr',
    gap: 30,
    maxHeight: '750px',
    gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr 4fr',
  },
  propertyList: {
    border: `1px solid ${theme.palette.grey[400]}`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  propertyCell: {
    padding: 10,
  },
  key: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: '10px',
  },
  value: {
    fontWeight: 700,
    fontSize: 14,
  },
  textArea: {
    ...theme.typography.body1,
    padding: '7px 8px 8px',
    border: '1px solid #c4c4c4',
    borderRadius: 4,
    fontSize: 14,
    width: '100%',
    '&:focus': {
      outline: `${theme.palette.primary.main} auto 1px`,
    },
  },
  inactiveAlert: {
    width: '452px',
    paddingTop: '15px',
    '& 	.MuiAlert-root': {
      padding: '0px 8px',
      color: '#AD5D00',
      '& .MuiAlert-icon': {
        color: '#AD5D00',
      },
    },
  },
}));

const DealModal: React.FC<DealModalProps> = (props) => {
  const {
    send,
    stages,
    selectedStage,
    setSelectedStage,
    leads,
    selectedLead,
    setSelectedLead,
    brokers,
    selectedBroker,
    setSelectedBroker,
    sellers,
    selectedSeller,
    setSelectedSeller,
    ilos,
    selectedILO,
    setSelectedILO,
    note,
    setNote,
    name,
    setName,
    description,
    setDescription,
    assets,
    loading,
    dealId,
    showAssets,
    teams,
    dealTeam,
    setDealTeam,
    investmentTypes,
    investmentType,
    setInvestmentType,
  } = props.data.hook(props.onRequestClose);
  const { mode } = props.data;
  const classes = useStyles({ mode, showAssets });
  const dispatch = useDispatch();
  const onEditClick = () => {
    dispatch(push(`/portfoliodeal/${dealId}`));
    props.onRequestClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      classes={{ paper: classes.modal }}
      className={`js-${mode}-portfolio-modal`}
    >
      <Typography className={classes.dialogTitle}>
        {mode === 'create' ? 'Create Portfolio Deal' : 'Edit Portfolio Deal'}
      </Typography>
      {mode === 'edit' && showAssets && (
        <Typography className={classes.dialogSubtitle}>
          {assets.length} Property Portfolio
        </Typography>
      )}
      <DialogContent className={classes.modalContent}>
        <div style={{ gridArea: 'name' }}>
          <Typography className={classes.key}>Portfolio Name</Typography>
          <TextField
            variant='outlined'
            size='small'
            placeholder='Enter portfolio name'
            style={{ backgroundColor: 'white', width: '100%' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{
              'data-testid': K.DEAL_NAME_INPUT_TEST_ID,
              className: 'js-portfolio-name-input',
            }}
          />
        </div>
        <div style={{ gridArea: 'description' }}>
          <Typography className={classes.key}>Deal Description</Typography>
          <TextField
            variant='outlined'
            size='small'
            placeholder='Enter deal description'
            style={{ backgroundColor: 'white', width: '100%' }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{ 'data-testid': K.DEAL_DESCRIPTION_INPUT_TEST_ID }}
          />
        </div>
        <div style={{ gridArea: 'stage', width: '210px' }}>
          <Typography className={classes.key}>Stage</Typography>
          <Autocomplete
            value={selectedStage}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_STAGE_INPUT_TEST_ID,
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Box
                      width={11}
                      height={11}
                      bgcolor={dealStageColorMap[selectedStage]}
                      borderRadius='50%'
                    />
                  ),
                }}
              />
            )}
            renderOption={(stage) => <DealStage name={stage} />}
            onChange={(_, stage) => setSelectedStage(stage)}
            options={stages}
            disableClearable
          />
          {selectedStage === 'Inactive' ? (
            <div className={classes.inactiveAlert}>
              <Alert icon={<Warning fontSize='small' />} severity='warning'>
                Inactive deals will no longer display in the platform.
              </Alert>
            </div>
          ) : null}
        </div>
        <div style={{ gridArea: 'lead' }}>
          <Typography className={classes.key}>
            Acquisitions Associate
          </Typography>
          <Autocomplete
            value={selectedLead}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_LEAD_INPUT_TEST_ID,
                  id: 'js-portfolio-lead-input',
                }}
              />
            )}
            onChange={(_, lead) => setSelectedLead(lead || '')}
            options={leads.map((lead) => lead.name)}
            getOptionLabel={(opt) => opt}
          />
        </div>
        <div style={{ gridArea: 'ilo' }}>
          <Typography className={classes.key}>ILO</Typography>
          <Autocomplete
            value={selectedILO}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_ILO_INPUT_TEST_ID,
                }}
              />
            )}
            onChange={(_, ilo) => setSelectedILO(ilo || '')}
            options={ilos.map((ilo) => ilo.name)}
            getOptionLabel={(opt) => opt}
          />
        </div>
        <div style={{ gridArea: 'broker' }}>
          <Typography className={classes.key}>Broker</Typography>
          <Autocomplete
            value={selectedBroker}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_BROKER_INPUT_TEST_ID,
                }}
              />
            )}
            onChange={(_, broker) => setSelectedBroker(broker || '')}
            options={brokers.map((broker) => broker.name)}
            getOptionLabel={(opt) => opt}
          />
        </div>
        <div style={{ gridArea: 'seller' }}>
          <Typography className={classes.key}>Seller</Typography>
          <Autocomplete
            value={selectedSeller}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_SELLER_INPUT_TEST_ID,
                }}
              />
            )}
            onChange={(_, seller) => setSelectedSeller(seller || '')}
            options={sellers.map((seller) => seller.name)}
            getOptionLabel={(opt) => opt}
          />
        </div>
        <div style={{ gridArea: 'note' }}>
          <Typography className={classes.key}>Latest note</Typography>
          <TextareaAutosize
            className={classes.textArea}
            value={note}
            placeholder='Add note'
            onChange={(e) => setNote(e.target.value)}
            data-testid={K.DEAL_NOTE_INPUT_TEST_ID}
          />
        </div>
        <div style={{ gridArea: 'team' }}>
          <Typography className={classes.key}>Deal Team</Typography>
          <RadioGroup
            row
            value={dealTeam}
            onChange={(_, value) => setDealTeam(Number(value))}
          >
            {teams.map((t) => (
              <FormControlLabel
                key={t.id}
                value={t.id}
                control={<Radio color='primary' />}
                label={t.name}
              />
            ))}
          </RadioGroup>
          {teams.find((t) => t.id === dealTeam)?.name === 'Multi-Family' && (
            <div style={{ gridArea: 'investment-type' }}>
              <Autocomplete
                value={
                  investmentTypes.find((i) => i.id === investmentType) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    size='small'
                    label='Investment Type'
                    style={{ backgroundColor: 'white' }}
                  />
                )}
                onChange={(_, invType) => setInvestmentType(invType?.id)}
                options={investmentTypes}
                getOptionLabel={(o) => o.name}
              />
            </div>
          )}
        </div>
        {mode === 'edit' && showAssets && (
          <div
            style={{ gridArea: 'properties' }}
            className={classes.propertyList}
          >
            {assets.map((data) => (
              <div key={data.taxAssessorId} className={classes.propertyCell}>
                <PropertyItem data={data} />
              </div>
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ padding: 12 }}>
          <Button
            style={{ marginRight: 10 }}
            onClick={props.onRequestClose}
            color='primary'
          >
            Cancel
          </Button>
          {props.data.mode === 'edit' && showAssets && (
            <Button
              style={{ marginRight: 10 }}
              onClick={onEditClick}
              startIcon={<Edit />}
              variant='outlined'
              color='primary'
            >
              Add/Remove Properties
            </Button>
          )}
          <ButtonProgress
            loading={loading}
            onClick={send}
            variant='contained'
            color='primary'
            className='js-submit-portfolio-button'
          >
            {props.data.mode === 'create' ? 'Next' : 'Done'}
          </ButtonProgress>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DealModal;
