import { TestStatusList } from 'src/products/connector/pages/connector-details/components/DataValidation/constants';
import { TestStatus } from 'src/products/connector/pages/connector-details/components/DataValidation/types';

export const buildRuleStatus = (hasPassed: boolean | null): TestStatus => {
  if (hasPassed === null) {
    return TestStatusList.SCHEDULED;
  }

  return hasPassed ? TestStatusList.SUCCESS : TestStatusList.FAILED;
};
