export const countRules = (rules) => {
  const initialStatusCounts = {
    success: 0,
    failed: 0,
    aborted: 0,
    total: 0,
  };
  return rules.data.reduce((summary, testCase) => {
    const status = testCase.ruleStatus.toLowerCase();
    if (summary[status]) {
      summary[status] += 1;
    } else {
      summary[status] = 1;
    }

    summary['total'] += 1;
    return summary;
  }, initialStatusCounts);
};
