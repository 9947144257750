import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';

import withStyles from '@material-ui/core/styles/withStyles';

export class Search extends React.Component {
  state = {
    isInputFocused: false,
  };

  handleChange(e) {
    const value = e.target.value;

    this.props.onSearch(value);
  }

  handleClose() {
    this.props.onSearch('');
  }

  render() {
    const { searchValue, classes = {} } = this.props;
    const { isInputFocused } = this.state;

    return (
      <Paper className={classes.root}>
        <TextField
          onFocus={() => this.setState({ isInputFocused: true })}
          onBlur={() => this.setState({ isInputFocused: false })}
          onChange={this.handleChange.bind(this)}
          value={searchValue}
          style={{ width: isInputFocused ? 250 : 'auto' }}
          placeholder='Search'
          InputProps={{
            id: 'table-page-toolbar-search',
            disableUnderline: true,
            endAdornment: (
              <InputAdornment>
                {!searchValue ? (
                  <SearchIcon className={classes.searchIcon} />
                ) : (
                  <CloseIcon
                    onClick={this.handleClose.bind(this)}
                    className={classes.searchIcon}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Paper>
    );
  }
}

const styles = () => ({
  root: {
    padding: '2px 10px 0px',
  },
  searchIcon: {
    width: '20px',
    height: '20px',
    opacity: '0.4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

Search.propTypes = {
  searchValue: PropTypes.string,
  onSearch: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(Search);
