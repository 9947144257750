import { graphql } from 'relay-runtime';

export const CreateConnectorMutation = graphql`
  mutation createConnectorMutation(
    $displayName: String!
    $sourceNames: [String!]
    $modelNames: [String!]
  ) {
    createConnector(
      displayName: $displayName
      sourceNames: $sourceNames
      modelNames: $modelNames
    ) {
      displayName
      name
    }
  }
`.default;
