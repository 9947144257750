import { useRecoilValueLoadable } from 'recoil';
import { openMetadataTableLatestData } from '../recoil';
import {
  ConnectorEnvironments,
  useConnectorEnvironment,
} from 'src/hooks/useConnectorEnvironment';
import { openMetadataTableLatestDataDemo } from 'src/services/ConnectorDemo/recoil';

export const useGetValidationRuleColumns = () => {
  const environment = useConnectorEnvironment();
  if (environment === ConnectorEnvironments.MVP) {
    const latestTableData = useRecoilValueLoadable(openMetadataTableLatestData);
    return { latestTableData };
  }
  const latestTableData = useRecoilValueLoadable(
    openMetadataTableLatestDataDemo
  );
  return { latestTableData };
};
