import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'src/components/ButtonProgress';

const NoTaxRecordFound: React.FC<any> = (props) => {
  const { open, onRequestClose, classes } = props;

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.modal,
      }}
    >
      <DialogTitle>No Tax Record Found</DialogTitle>
      <DialogContent className={classes.modalContent}>
        Unfortunatelly no tax records found for this address
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color='primary' variant='contained'>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
};

export default withStyles(styles)(NoTaxRecordFound);
