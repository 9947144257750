import React from 'react';
import New from './New';

const FileTemplate = (props) => {
  const { notification, isOpen, isSidebar } = props;

  if (!notification.data) {
    return null;
  }

  return (
    <div>
      <div style={{ color: '#000' }}>
        {notification.data.fileType || 'File'} is generated.{' '}
        {!notification.isViewed && isSidebar && <New />}
      </div>
      <div style={{ color: '#757575' }}>{notification.data.fileName}</div>
      {isOpen && (
        <a
          href={notification.data.fileURL}
          download={notification.data.fileName}
          onClick={(e) => e.stopPropagation()}
        >
          <br />
          DOWNLOAD
        </a>
      )}
    </div>
  );
};

export default FileTemplate;
