import { ConnectorStatus } from 'src/products/connector/recoil';

export type Activity = {
  connectorId: string;
  activityName: string;
  connectorStatus: ConnectorStatus;
  connectorStatusMessage: string;
  lastRun?: string | null;
  frequency?: string;
  numFailedValidationChecks?: number;
  connectorRunningObject: {
    isRunning: boolean;
    runningMessage: string;
  };
};

export type ConnectorStatuses = {
  activePipelines: number;
  runningConnectors: number;
  failedChecks: number;
};

export type CherreConnectorSource = {
  sourceName: string;
  category: string;
  numActiveConnectors: number;
  logoPath: string;
};

export type ScheduleItem = {
  connectorId: string;
  name: string;
  displayName: string;
  runStatus: ConnectorStatus;
  cronExpression: string;
};

export type LandingPageDataTypes = {
  connectorStatuses: ConnectorStatuses;
  activityOverview: Activity[];
  sources: CherreConnectorSource[];
  schedule: ScheduleItem[];
};

export const connectorStatuses: ConnectorStatuses = {
  activePipelines: 14,
  runningConnectors: 3,
  failedChecks: 3,
};

export const activityOverview: Activity[] = [
  {
    connectorId: 'dwellsy-test-feed',
    activityName: 'Dwellsy Test Feed',
    connectorStatus: ConnectorStatus.FAILED,
    connectorStatusMessage: 'Source Failed Apr 10 9:30 PM',
    numFailedValidationChecks: 1,
    lastRun: 'Feb 1, 2024 1:12 PM ET',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
  {
    connectorId: 'vts-leasing-warehouse',
    activityName: 'VTS Leasing to Warehouse',
    connectorStatus: ConnectorStatus.SUCCESS,
    connectorStatusMessage: 'Success Apr 10 9:30 PM',
    numFailedValidationChecks: 1,
    connectorRunningObject: {
      isRunning: true,
      runningMessage: 'Source Running',
    },
  },
  {
    connectorId: 'chatham-warehouse',
    activityName: 'Chatham to Warehouse',
    connectorStatus: ConnectorStatus.SUCCESS,
    connectorStatusMessage: 'Success Apr 26 9:00 PM',
    numFailedValidationChecks: 1,
    lastRun: 'Feb 1, 2024 1:12 PM ET',
    connectorRunningObject: {
      isRunning: false,
      runningMessage: '',
    },
  },
];

export const sources: CherreConnectorSource[] = [
  {
    sourceName: 'Trepp',
    category: 'Risk',
    numActiveConnectors: 3,
    logoPath: '/assets/connector-icons/rounded-logos/trepp-rounded.svg',
  },
  {
    sourceName: 'VTS',
    category: 'Management',
    numActiveConnectors: 6,
    logoPath: '/assets/connector-icons/rounded-logos/vts-rounded.svg',
  },
  {
    sourceName: 'Argus',
    category: 'Valuation',
    numActiveConnectors: 1,
    logoPath: '/assets/connector-icons/rounded-logos/argus-rounded.svg',
  },
  {
    sourceName: 'Chatham',
    category: 'Risk',
    numActiveConnectors: 2,
    logoPath: '/assets/connector-icons/rounded-logos/chatham-rounded.svg',
  },
];

export const schedule: ScheduleItem[] = [
  {
    connectorId: 'vts-snowflake',
    name: 'vts-snowflake',
    displayName: 'VTS to Snowflake',
    runStatus: ConnectorStatus.SCHEDULED,
    cronExpression: '0 11 * * 1-5',
  },
  {
    connectorId: 'dwellsy-snowflake',
    name: 'dwellsy-snowflake',
    displayName: 'Dwellsy to Snowflake',
    runStatus: ConnectorStatus.SCHEDULED,
    cronExpression: '45 8 * * 1-5',
  },
  {
    connectorId: 'chatham-snowflake',
    name: 'chatham-snowflake',
    displayName: 'Chatham to Snowflake',
    runStatus: ConnectorStatus.SCHEDULED,
    cronExpression: '30 4 * * 1-5',
  },
];

export const landingPageData: LandingPageDataTypes = {
  connectorStatuses: {
    activePipelines: 14,
    runningConnectors: 3,
    failedChecks: 3,
  },
  activityOverview: [
    {
      connectorId: 'dwellsy-test-feed',
      activityName: 'Dwellsy Test Feed',
      connectorStatus: ConnectorStatus.FAILED,
      connectorStatusMessage: 'Failed Apr 10 9:30 PM',
      numFailedValidationChecks: 1,
      connectorRunningObject: {
        isRunning: false,
        runningMessage: '',
      },
    },
    {
      connectorId: 'vts-leasing-warehouse',
      activityName: 'VTS Leasing to Warehouse',
      connectorStatus: ConnectorStatus.FAILED,
      connectorStatusMessage: 'Failed',
      numFailedValidationChecks: 1,
      connectorRunningObject: {
        isRunning: true,
        runningMessage: 'Model Running',
      },
    },
    {
      connectorId: 'chatham-warehouse',
      activityName: 'Chatham to Warehouse',
      connectorStatus: ConnectorStatus.SUCCESS,
      connectorStatusMessage: 'Success Apr 26 9:00 PM',
      numFailedValidationChecks: 1,
      connectorRunningObject: {
        isRunning: false,
        runningMessage: '',
      },
    },
  ],
  sources: [
    {
      sourceName: 'Trepp',
      category: 'Risk',
      numActiveConnectors: 3,
      logoPath: '/assets/connector-icons/rounded-logos/trepp-rounded.svg',
    },
    {
      sourceName: 'VTS',
      category: 'Management',
      numActiveConnectors: 6,
      logoPath: '/assets/connector-icons/rounded-logos/vts-rounded.svg',
    },
    {
      sourceName: 'Argus',
      category: 'Valuation',
      numActiveConnectors: 1,
      logoPath: '/assets/connector-icons/rounded-logos/argus-rounded.svg',
    },
    {
      sourceName: 'Chatham',
      category: 'Risk',
      numActiveConnectors: 2,
      logoPath: '/assets/connector-icons/rounded-logos/chatham-rounded.svg',
    },
  ],

  schedule: [
    {
      connectorId: 'vts-snowflake',
      name: 'vts-snowflake',
      displayName: 'VTS to Snowflake',
      runStatus: ConnectorStatus.SCHEDULED,
      cronExpression: '0 11 * * 1-5',
    },
    {
      connectorId: 'dwellsy-snowflake',
      name: 'dwellsy-snowflake',
      displayName: 'Dwellsy to Snowflake',
      runStatus: ConnectorStatus.SCHEDULED,
      cronExpression: '45 8 * * 1-5',
    },
    {
      connectorId: 'chatham-snowflake',
      name: 'chatham-snowflake',
      displayName: 'Chatham to Snowflake',
      runStatus: ConnectorStatus.SCHEDULED,
      cronExpression: '30 4 * * 1-5',
    },
  ],
};
