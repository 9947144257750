import React from 'react';
import { pick } from 'lodash';
import { Layer, Source } from 'react-map-gl';
import { Sources as SourcesType } from '../../types';

type Props = {
  sources: SourcesType;
};

export const Sources: React.FC<Props> = ({ sources }) => {
  return (
    <>
      {sources.map((s) => {
        const { layers, source } = s;

        const sourceId = source.id + '-' + layers.map((l) => l.id).join('-');

        return (
          <Source key={sourceId} {...source} id={sourceId}>
            {layers.map((layer) => {
              return (
                <Layer
                  key={layer.id}
                  {...pick(layer, [
                    'id',
                    'source',
                    'source-layer',
                    'type',
                    'layout',
                    'minzoom',
                    'maxzoom',
                    'paint',
                    'filter',
                    'beforeId',
                  ])}
                />
              );
            })}
          </Source>
        );
      })}
    </>
  );
};
