import * as TYPES from './types';

const initialState = {};

export default function (state = initialState, action) {
  const { type, meta } = action;

  const name = meta && meta.name;

  if (!name) {
    return state;
  }

  switch (type) {
    case TYPES.INIT_QUERY_SETTINGS: {
      return {
        ...state,
        [name]: {
          ...state[name],
          querySettings: action.payload,
        },
      };
    }

    case TYPES.SET_SEARCH_PARAMETER_VALUE: {
      const { field_name, value } = action;

      state[name].querySettings.search_parameters.forEach(
        (search_parameter) => {
          if (search_parameter.field_name === field_name) {
            search_parameter.attributes.value = value;
          }
        }
      );

      return {
        ...state,
        [name]: {
          ...state[name],
          querySettings: {
            ...state[name].querySettings,
            search_parameters: [...state[name].querySettings.search_parameters],
          },
        },
      };
    }

    case TYPES.CHANGE_LIMIT: {
      return {
        ...state,
        [name]: {
          ...state[name],
          querySettings: {
            ...state[name].querySettings,
            view: {
              ...state[name].querySettings.view,
              limit: action.limit,
            },
          },
        },
      };
    }

    case TYPES.CHANGE_OFFSET: {
      return {
        ...state,
        [name]: {
          ...state[name],
          querySettings: {
            ...state[name].querySettings,
            view: {
              ...state[name].querySettings.view,
              offset: action.offset,
            },
          },
        },
      };
    }

    case TYPES.EXEC_QUERY_REQUEST: {
      return {
        ...state,
        [name]: {
          ...state[name],
          execQueryFetchStatus: { value: 'LOADING' },
        },
      };
    }

    case TYPES.EXEC_QUERY_SUCCESS: {
      const { isLoadMore } = action;

      const entity_name = state[name].querySettings.view.entity_name;
      const oldResults = state[name].results || [];
      const results = action.data[entity_name];

      return {
        ...state,
        [name]: {
          ...state[name],
          execQueryFetchStatus: { value: 'LOADED' },
          results: isLoadMore ? [...oldResults, ...results] : results,
        },
      };
    }

    default:
      return state;
  }
}
