import React from 'react';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

import LayerGroupTitle from './LayerGroupTitle';
import { toggleLayer } from './utils';

const GroupHeader = ({ group, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
      }}
    >
      <LayerGroupTitle group={group} />
      <Link
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (group.radio) {
            toggleLayer(group, onChange, undefined, false, true);
          } else {
            const layers = group.layers;
            let currentGroup = group;

            layers.forEach((layer) => {
              if (layer.checked) {
                currentGroup = toggleLayer(
                  currentGroup,
                  onChange,
                  layer.name,
                  false
                );
              }
            });
          }
        }}
      >
        <Typography variant='body1'>clear</Typography>
      </Link>
    </div>
  );
};

export default GroupHeader;
