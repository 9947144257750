/**
 * @generated SignedSource<<2f9fd3d229ef1ecb06e28deeebc2c985>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type recoilGetUnreadRemindersCountQuery$variables = {
  user_id?: number | null;
};
export type recoilGetUnreadRemindersCountQuery$data = {
  readonly sys_reminders_aggregate: {
    readonly aggregate: {
      readonly count: number;
    } | null;
  };
};
export type recoilGetUnreadRemindersCountQuery = {
  response: recoilGetUnreadRemindersCountQuery$data;
  variables: recoilGetUnreadRemindersCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "is_resolved",
            "value": {
              "_eq": false
            }
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "user_id"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "user_id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "filter"
                  },
                  {
                    "kind": "Literal",
                    "name": "predicate",
                    "value": {
                      "_eq": 0
                    }
                  }
                ],
                "kind": "ObjectValue",
                "name": "count"
              }
            ],
            "kind": "ObjectValue",
            "name": "reminders_reviewed_aggregate"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_reminders_aggregate",
    "kind": "LinkedField",
    "name": "sys_reminders_aggregate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sys_reminders_aggregate_fields",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilGetUnreadRemindersCountQuery",
    "selections": (v1/*: any*/),
    "type": "subscription_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilGetUnreadRemindersCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "04f7aa3b1dc58f7b89feb7a664936fd8",
    "id": null,
    "metadata": {},
    "name": "recoilGetUnreadRemindersCountQuery",
    "operationKind": "subscription",
    "text": "subscription recoilGetUnreadRemindersCountQuery(\n  $user_id: Int\n) {\n  sys_reminders_aggregate(where: {is_resolved: {_eq: false}, reminders_reviewed_aggregate: {count: {predicate: {_eq: 0}, filter: {user_id: {_eq: $user_id}}}}}) {\n    aggregate {\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6b474051f844cc7c77178485957ffc3";

export default node;
