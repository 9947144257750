import React, { ReactElement } from 'react';

import escapeRegExp from 'lodash/escapeRegExp';
import Add from '@material-ui/icons/Add';
import Done from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core';
import cx from 'classnames';

import Truncate from 'src/components/Truncate';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    width: 350,
    maxWidth: '100%',
  },
  addNew: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  itemContainer: {
    '& .actionHover': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& .actionHover': {
        visibility: 'visible',
      },
    },
  },
}));

export type InteractiveListItem = {
  value: string;
  label: string;
  secondary?: string | ReactElement;
  shareIcon?: ReactElement;
  shareIconTooltip?: string;
};

export interface InteractiveList extends React.HTMLAttributes<HTMLDivElement> {
  items?: InteractiveListItem[];
  selected?: InteractiveListItem[];
  onItemCreate?: (inputValue: string) => void;
  onItemSelect?: (item: InteractiveListItem) => void;
  onItemDeselect?: (item: InteractiveListItem) => void;
  onItemShare?: (item: InteractiveListItem) => void;
  placeholder?: string;
}

const InteractiveList: React.VFC<InteractiveList> = ({
  items = [],
  selected = [],
  onItemCreate = (_) => {},
  onItemSelect = (_) => {},
  onItemDeselect = (_) => {},
  onItemShare,
  placeholder = 'Search or add new',
  ...otherProps
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');

  const filterItems = (item) => {
    return (item.label || '').match(new RegExp(escapeRegExp(inputValue), 'gi'));
  };

  const filteredItems = items.filter(filterItems);

  const isCreateItemVisible =
    filteredItems.length === 0 && inputValue && onItemCreate;

  return (
    <div {...otherProps} className={classes.root}>
      <TextField
        autoFocus
        fullWidth
        inputProps={{
          className: 'js-core-prospect-property-list__search-input',
        }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        variant='outlined'
        size='small'
        placeholder={placeholder}
        type='search'
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            if (isCreateItemVisible) {
              onItemCreate(inputValue);
              setInputValue('');
            }
          }
        }}
      />

      <List
        style={{
          padding:
            !isCreateItemVisible && filteredItems.length === 0 ? 0 : undefined,
        }}
      >
        {isCreateItemVisible && (
          <ListItem
            className='js-core-prospect-property-list__create-list-item'
            dense
            button
            onClick={() => {
              onItemCreate(inputValue);
              setInputValue('');
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: 'body1',
              }}
              primary={
                <Truncate style={{ paddingRight: 50 }}>{inputValue}</Truncate>
              }
            />
            <ListItemSecondaryAction>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onItemCreate(inputValue);
                  setInputValue('');
                }}
                className={`js-core-prospect-property-list__create-list-text ${classes.addNew}`}
              >
                <Add />
                <Typography>New List</Typography>
              </div>
            </ListItemSecondaryAction>
          </ListItem>
        )}
        <div style={{ maxHeight: 300, overflow: 'auto' }}>
          {items.filter(filterItems).map((item, index) => {
            const isSelected = selected.some(
              (selectedItem) => selectedItem.value === item.value
            );

            return (
              <div className={classes.itemContainer} key={index}>
                <ListItem
                  className={cx({
                    'js-core-prospect-property-list__list-item': true,
                    [`js-core-prospect-property-list__list-item-selected-${index}`]:
                      isSelected,
                    [`js-core-prospect-property-list__list-item-${index}`]:
                      true,
                  })}
                  key={index}
                  dense
                  button
                  onClick={() =>
                    isSelected ? onItemDeselect(item) : onItemSelect(item)
                  }
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'body1',
                    }}
                    primary={
                      <Truncate>
                        <span title={item.label}>
                          {isSelected ? <b>{item.label}</b> : item.label}
                        </span>
                      </Truncate>
                    }
                    secondary={item.secondary ? item.secondary : null}
                  />
                  {isSelected && (
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => onItemDeselect(item)}
                        size='small'
                        edge='end'
                        aria-label='comments'
                      >
                        <Done />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                  {onItemShare && (
                    <ListItemSecondaryAction className='actionHover'>
                      <Tooltip title={item.shareIconTooltip || 'Share'}>
                        <IconButton
                          onClick={() => onItemShare(item)}
                          size='small'
                          edge='end'
                          aria-label='share'
                          color='primary'
                        >
                          {item.shareIcon || (
                            <img src='/assets/share.svg' width={25} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              </div>
            );
          })}
        </div>
      </List>
    </div>
  );
};

export default InteractiveList;
