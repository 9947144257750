export const PROFILE = 'PROFILE';
export const ADMIN_ORGANIZATIONS = 'ADMIN_ORGANIZATIONS';
export const SIGNUP_REQUESTS = 'SIGNUP_REQUESTS';
export const CORE_PROSPECT = 'CORE_PROSPECT';
export const WELCOME = 'WELCOME';
export const FILTERS = 'FILTERS';
export const MAVERICK_SEARCH = 'MAVERICK_SEARCH';
export const ORGANIZATION = 'ORGANIZATION';
export const MAVERICK_SEARCH_QUERY = 'MAVERICK_SEARCH_QUERY';
export const GROUPS = 'GROUPS';
export const CORE_SCHEMA = 'CORE_SCHEMA';
export const ANALYTICS_SCHEMA = 'ANALYTICS_SCHEMA';
export const USERS = 'USERS';
export const API_KEYS = 'API_KEYS';
export const HOME = 'HOME';
export const CORE_BI = 'CORE_BI';
export const CORE_BI_V2 = 'CORE_BI_V2';
export const DASHBOARD = 'DASHBOARD';
export const CORE_API = 'CORE_API';
export const ANALYTICS_API = 'ANALYTICS_API';
export const COMPANY_DETAILS = 'COMPANY_DETAILS';
export const MIGRATIONS = 'MIGRATIONS';
export const DATASET_UPLOAD = 'DATASET_UPLOAD';
export const GLOBAL_SETTINGS = 'GLOBAL_SETTINGS';
export const DATASET_DETAILS = 'DATASET_DETAILS';
export const DATASET_LIST = 'DATASET_LIST';
export const LISTS_AND_SEARCHES = 'LISTS_AND_SEARCHES';
export const DSP_SUBMISSIONS = 'DSP_SUBMISSIONS';
export const DSP_SUBMISSIONS_FILE_UPLOAD = 'DSP_SUBMISSIONS_FILE_UPLOAD';
export const DSP_SUBMISSION_DETAILS = 'DSP_SUBMISSION_DETAILS';
export const DSP_FILE_VALIDATION_REPORT_SUMMARY =
  'DSP_FILE_VALIDATION_REPORT_SUMMARY';
export const DSP_SECURE_FILE_TRANSFER = 'DSP_SECURE_FILE_TRANSFER';
export const DSP_SUBMISSION_VALIDATION_REPORT_SUMMARY =
  'DSP_SUBMISSION_VALIDATION_REPORT_SUMMARY';
