import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'inline-block',
    width: 30,
    height: 15,
    borderRadius: 3,
    backgroundColor: '#424242',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    color: '#fff',
    lineHeight: '15px',
    padding: '0px 3px',
    position: 'relative',
    top: -3,
  },
};

const New = (props) => {
  const { classes } = props;
  return <div className={classes.root}>new</div>;
};

export default withStyles(styles)(New);
