const removeUndefined = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const merge = <A>(...objs: any): A => {
  return objs.reduce(
    (acc, cur) => Object.assign({}, removeUndefined(acc), removeUndefined(cur)),
    {}
  ) as any;
};

export type Mapper<A, B> = (a: A) => B;
