import { graphql } from 'relay-runtime';

export const CreateSourceMutation = graphql`
  mutation createSourceMutation(
    $preconfiguredSourceName: String!
    $version: String
    $schedule: String!
    $envs: [VariableInput]
    $displayName: String
    $connectorName: String
  ) {
    createSource(
      preconfiguredSourceName: $preconfiguredSourceName
      version: $version
      schedule: $schedule
      envs: $envs
      displayName: $displayName
      connectorName: $connectorName
    ) {
      name
    }
  }
`.default;
