import React from 'react';
import PropTypes from 'prop-types';
import PopoverCustom from './PopoverCustom';
import PopoverSelection from './PopoverSelection';

class PopoverSelect extends React.Component {
  state = {};

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({
      anchorEl: null,
    });

    if (onClose) {
      onClose();
    }
  };

  render() {
    const { title, label, children, value, autoFocus } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <PopoverSelection
          label={label}
          autoFocus={autoFocus}
          handleClick={this.handleClick}
          title={title}
        />
        <PopoverCustom
          close={this.handleClose}
          open={open}
          anchorEl={this.state.anchorEl}
          children={children}
          value={value}
        />
      </div>
    );
  }
}

PopoverSelect.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default PopoverSelect;
