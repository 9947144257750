import { RootState } from 'react-redux';
import { cherrePropertyUseHierarchical } from 'src/const/lookups';
import { TaxAssessorsQuery } from './recoil/__generated__';
import numberWithCommas from 'src/utils/numberWithCommas';
import * as T from './types';
import { getString } from 'src/utils/getString';

const reisVariation1 = [
  'Flex/R&D',
  'Industrial',
  'Office',
  'Retail',
  'Warehouse/Distribution',
];

const reisVariation2 = [
  'Affordable Housing',
  'Apartment',
  'Hotel',
  'Self Storage',
  'Seniors Housing',
  'Student Housing',
];

/**
 * Those are the lookup codes according to the variation of the Deal modal
 * to see the variations check the spreadsheet: https://docs.google.com/spreadsheets/d/1TdxdWwh9q7WAmJaHvuy2NIKTBiKS7jXasI7RDyfiAgs/edit
 * to see the ticket related to this AC check: https://cherre.atlassian.net/browse/YST-2734?atlOrigin=eyJpIjoiNDJmMmVmMWJhZWJiNDRhZmJjYjA0NWVkOWZkZjUzNmIiLCJwIjoiaiJ9
 * to check the property type related to each lookup code check the file in frontend/src/const/lookups.ts
 */

const variation1 = [
  3, 6, 7, 8, 9, 14, 15, 16, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
  37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
];

const variation2 = [2, 5, 12, 13, 22, 23];

const variation3 = [1, 4, 10, 11, 17, 18, 19, 20, 21];

const getVariation = (
  data: TaxAssessorsQuery['tax_assessor_v2'][0]
): 1 | 2 | 3 => {
  const reisAttributes =
    data.cherre_address__property_address?.[0]
      ?.reis_property_attributes__address?.[0];
  if (reisVariation1.includes(reisAttributes?.sector || '')) {
    return 1;
  }
  if (reisVariation2.includes(reisAttributes?.sector || '')) {
    return 2;
  }
  const code = data.property_use_code_mapped;
  if (typeof code !== 'number') {
    return 1;
  }
  if (variation1.includes(code)) {
    return 1;
  }
  if (variation2.includes(code)) {
    return 2;
  }
  if (variation3.includes(code)) {
    return 3;
  }
  return 1;
};

export const taxAssessorToDealData = (
  data: TaxAssessorsQuery
): Omit<T.SingleDealModalProps, 'stages' | 'leads'> => {
  const taxAssessor = data.tax_assessor_v2[0];

  const mappedCode = taxAssessor.property_use_code_mapped;

  const reisAttributes =
    taxAssessor.cherre_address__property_address?.[0]
      ?.reis_property_attributes__address?.[0];

  const address = taxAssessor.address || '';
  const city = taxAssessor.city || '';
  const state = taxAssessor.state || '';
  const country = 'US';
  const zip = taxAssessor.zip || '';

  const propertyUseCodeMapped = cherrePropertyUseHierarchical.find(
    (a) => a.lookup_code === mappedCode
  )?.lookup_value;

  const propertyAddress = `${address}, ${city}, ${state} ${zip}`;
  const propertyType = reisAttributes?.sector || propertyUseCodeMapped || '--';
  const buildingArea = `${
    numberWithCommas(reisAttributes?.sizesqft || taxAssessor.building_sq_ft) ||
    '--'
  }`;
  const landArea = taxAssessor.lot_size_acre?.toString() || '--';
  const zoning = taxAssessor.zone_code || '--';
  const yearBuilt =
    reisAttributes?.yearbuilt?.toString() ||
    taxAssessor.year_built?.toString() ||
    '--';

  const numberOfUnits =
    reisAttributes?.sizeunit?.toString() ||
    taxAssessor.units_count?.toString() ||
    '--';

  return {
    variation: getVariation(taxAssessor),
    propertyAddress,
    propertyType,
    buildingArea,
    landArea,
    zoning,
    longitude: String(taxAssessor?.longitude || ''),
    latitude: String(taxAssessor?.latitude || ''),
    zipCode: zip,
    city,
    state,
    country,
    taxAssessorId: taxAssessor.tax_assessor_id || 0,
    parcelId: taxAssessor.cherre_parcel_id || '',
    yearBuilt,
    numberOfUnits,
    unitType: 'sqft',
    isMultiFam: '',
    numberOfAppartments: numberOfUnits,
  };
};

/**
 * Convert tax_assessor to asset
 */

type TaxResult = TaxAssessorsQuery['tax_assessor_v2'][number];

const taxToAsset = (property: TaxResult) => ({
  address: property.address || '',
  buildingArea: getString(property.building_sq_ft),
  city: property.city || '',
  country: 'USA',
  isMultiFam: '',
  latitude: property.latitude || 0,
  longitude: property.longitude || 0,
  state: property.state || '',
  taxAssessorId: property.tax_assessor_id,
  unitType: '',
  yearBuilt: getString(property.year_built),
  zipCode: property.zip || '',
  parcelId: property.cherre_parcel_id || '',
  numberOfAppartments: getString(property.units_count),
});

/**
 * Create Deal
 */

interface ModalToCreateDataProps {
  name: string;
  description: string | null;
  stage: T.DealStageType;
  lead: string;
  broker: string | null;
  seller: string | null;
  ilo: string | null;
  lastActivityNote: string;
  user: RootState['user']['profile']['value'];
  raw_assets: TaxResult[];
  dealTeam: number;
  investmentType?: number;
}

export const modalToCreateData = ({
  name,
  description,
  stage,
  lead,
  broker,
  seller,
  ilo,
  lastActivityNote,
  user,
  raw_assets,
  dealTeam,
  investmentType,
}: ModalToCreateDataProps) => ({
  dealName: name,
  description,
  stage,
  lead,
  broker,
  seller,
  ilo,
  dealTeam,
  investmentType,
  userName: `${user.firstName} ${user.lastName}` || '',
  userEmail: user.email || '',
  lastActivityNote: lastActivityNote,
  lastActivityDate: new Date().toISOString(),
  status: 'active',
  assets: raw_assets.map((a) => taxToAsset(a)),
});

/**
 * Edit Deal
 */

interface ModalToEditDataProps extends Omit<ModalToCreateDataProps, 'assets'> {
  deal: T.PortfolioDeal;
}

export const modalToEditData = ({
  deal,
  name,
  description,
  stage,
  lead,
  broker,
  seller,
  ilo,
  lastActivityNote,
  user,
  raw_assets,
  dealTeam,
  investmentType,
}: ModalToEditDataProps) => ({
  ...modalToCreateData({
    name,
    description,
    stage,
    lead,
    broker,
    seller,
    ilo,
    lastActivityNote,
    user,
    raw_assets,
    dealTeam,
    investmentType,
  }),
  dealId: deal.dealId,
  lastActivityDate:
    lastActivityNote === deal.lastActivityNote
      ? deal.lastActivityDate
      : new Date().toISOString(),
});

/**
 * Remote deal to local deal
 */

export const remoteToLocalDeal = (deal: T.PortfolioDeal) => ({
  dealId: deal.dealId,
  dealName: deal.dealName,
  description: deal.description,
  stage: deal.stage,
  lead: deal.lead,
  broker: deal.broker,
  seller: deal.seller,
  dealTeam: deal.dealTeam,
  investmentType: deal.investmentType,
  ilo: deal.ilo,
  lastActivityNote: deal.lastActivityNote,
  lastActivityDate: deal.lastActivityDate,
  status: deal.status,
  assets: deal.assets.map((asset) => ({
    assetId: asset.dealCloudAsset.id,
    longitude: Number(asset.dealCloudAsset.longitude),
    latitude: Number(asset.dealCloudAsset.latitude),
    address: asset.dealCloudAsset.address,
    zipCode: asset.dealCloudAsset.zipCode,
    country: asset.dealCloudAsset.country,
    city: asset.dealCloudAsset.city,
    state: asset.dealCloudAsset.state,
    taxAssessorId: asset.cherreAsset.taxAssessorId,
    parcelId: asset.cherreAsset.parcelId,
    yearBuilt: asset.dealCloudAsset.yearBuilt,
    buildingArea: asset.dealCloudAsset.buildingArea,
    unitType: asset.dealCloudAsset.unitType,
    isMultiFam: asset.dealCloudAsset.isMultiFam,
    numberOfAppartments: asset.dealCloudAsset.numberOfAppartments,
  })),
});
