import React from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/ButtonProgress';
import withStyles from '@material-ui/core/styles/withStyles';

export function FooterButtons({
  classes = {},
  buttonText,
  pristine,
  valid,
  submitting,
  onClick,
}) {
  return (
    <div className={classes.root}>
      <div>
        <Button
          loading={submitting}
          disabled={pristine || !valid}
          color='primary'
          className={classes.button}
          type='submit'
          variant='contained'
          onClick={onClick ? onClick : () => {}}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

FooterButtons.propTypes = {
  classes: PropTypes.object,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
};

FooterButtons.defaultProps = {
  buttonText: 'save',
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    margin: '15px 0',
    minWidth: 115,
  },
};

export default withStyles(styles)(FooterButtons);
