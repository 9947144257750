export function decurrencyize(num) {
  return Number(
    String(num)
      .replace(/\$|,/g, '')
      .replace(/\/PSF|,/g, '')
  );
}

export function round(num, places = 2) {
  return Math.round((num + Number.EPSILON) * 10 ** places) / 10 ** places;
}

export function formatNumber(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '';
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}
