import useFetch from 'use-http';

import { getStreetViewParams } from 'src/utils/streetview';

/**
 * @todo move to recoil and move to right scope (streetview component)
 */
export default function useStreetViewStatus(latitude, longitude) {
  const { loading, data } = useFetch(
    `https://maps.googleapis.com/maps/api/streetview/metadata?${getStreetViewParams(
      { latitude, longitude }
    )}`,
    {
      responseType: 'json',
    },
    [latitude, longitude]
  );

  return {
    loading,
    hasImage: data && data.status === 'OK',
  };
}
