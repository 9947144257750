import { useSelector } from 'react-redux';

/**
 * possibly dead code
 * @todo remove dead code, refactor what remains
 * @deprecated
 */
const useLookups = () => {
  return useSelector(
    (state) => {
      const {
        lookupsDictionary,
        lookupsByListName,
        lookupsByTableName,
        fetchStatus,
        fetchStatusByTableName,
      } = state.common.lookups;

      function getListNameLookups(listName, code) {
        if ([undefined, null, ''].includes(code)) {
          return lookupsByListName[listName];
        }
        const lookup = lookupsDictionary[`${listName}|${code}`];

        return lookup ? lookup.lookup_value : code;
      }

      function getFieldLookups(entity_name, field_name, code) {
        if (!['string', 'number'].includes(typeof code)) {
          return code;
        }

        const listName = lookupsByTableName[`${entity_name}.${field_name}`];

        return getListNameLookups(listName, code);
      }

      return {
        fetchStatus: fetchStatus.value,
        fetchStatusByTableName: fetchStatusByTableName.value,
        lookupsByListName,
        lookupsByTableName,
        getFieldLookups,
        getListNameLookups,
      };
    },
    (newState, prevState) => {
      return (
        newState.fetchStatus === prevState.fetchStatus &&
        newState.fetchStatusByTableName === prevState.fetchStatusByTableName
      );
    }
  );
};

export default useLookups;
