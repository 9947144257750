import isEmpty from 'lodash/isEmpty';
import { getMaskProps, applyMask } from 'src/components/filters/utils';

function cleanValue(value) {
  return value.replace(/\$|,|ft|sqft|%/g, '');
}

export function getQuery(filter, value) {
  if (!value) {
    return;
  }

  let where;

  if (value.min) {
    where = { _gte: cleanValue(value.min) };
  }

  if (value.max) {
    where = where || {};
    where._lte = cleanValue(value.max);
  }

  return where;
}

export function getFilterDisplayValue(filter, value) {
  if (value) {
    const mask = getMaskProps(filter.mask);
    if (value.min && value.max) {
      return `${applyMask(value.min, mask)} - ${applyMask(value.max, mask)}`;
    }

    if (value.min) {
      return `Above ${applyMask(value.min, mask)}`;
    }

    if (value.max) {
      return `Below ${applyMask(value.max, mask)}`;
    }
  }
}

export function getFilterSetDisplayValue(filter, value) {
  const displayValue = getFilterDisplayValue(filter, value);
  if (!isEmpty(value)) {
    return `${filter.label} (${displayValue})`;
  }
}

export default {
  getQuery,
  getFilterDisplayValue,
  getFilterSetDisplayValue,
};
