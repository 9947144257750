import React from 'react';

import MUILock from '@material-ui/icons/Lock';
import MUILockOpen from '@material-ui/icons/LockOpen';
import MUIEdit from '@material-ui/icons/Edit';
import MUIList from '@material-ui/icons/List';
import MUISend from '@material-ui/icons/Send';
import MUIToggleOn from '@material-ui/icons/ToggleOn';
import MUISettingsApplications from '@material-ui/icons/SettingsApplications';
import MUIDashboardIcon from '@material-ui/icons/Dashboard';
import { withStyles } from '@material-ui/core/styles';

const style = {
  width: 20,
  height: 20,
  color: '#5F5F5F',
  cursor: 'pointer',
  marginRight: 10,
  display: 'flex',
  alignItems: 'center',
  fontSize: 17,
};

export const SupervisorAccount = () => (
  <i style={style} className='icon icon-ico-useremulate' />
);

export const Sync = () => <i style={style} className='icon icon-ico-sync' />;

export const Lock = withStyles(() => ({
  root: style,
}))(MUILock);

export const Unlock = withStyles(() => ({
  root: style,
}))(MUILockOpen);

export const Send = withStyles(() => ({
  root: style,
}))(MUISend);

export const ToggleOn = withStyles(() => ({
  root: style,
}))(MUIToggleOn);

export const Edit = withStyles(() => ({
  root: style,
}))(MUIEdit);

export const List = withStyles(() => ({
  root: style,
}))(MUIList);

export const SettingsApplications = withStyles(() => ({
  root: style,
}))(MUISettingsApplications);

export const DashboardIcon = withStyles(() => ({
  root: style,
}))(MUIDashboardIcon);
