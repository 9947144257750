import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import Info from '@material-ui/icons/Info';

const useStyles = makeStyles({
  alert: {
    background: '#00B9F7',
    color: '#FFEFFB',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const HeaderAlert: React.FC<{ message: string }> = ({ message }) => {
  const classes = useStyles();

  if (!message) {
    return null;
  }

  return (
    <Alert
      icon={false}
      classes={{
        root: classes.alert,
        message: classes.message,
      }}
    >
      <Info
        style={{
          cursor: 'pointer',
          marginRight: '8px',
        }}
      />
      {message}
    </Alert>
  );
};

export default HeaderAlert;
