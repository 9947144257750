import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'src/components/ButtonProgress';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  reEnableUser,
  getUsers,
} from 'src/products/admin/redux/organization/actions';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

class ReEnableUserDialog extends React.Component {
  state = { notifyUser: false };

  render() {
    const {
      open,
      onRequestClose,

      classes,
      enableStatus,
      reEnableUser,
      getUsers,
    } = this.props;

    const user = this.props.data.row;
    const removeHandler = async () => {
      reEnableUser(user, this.state.notifyUser)
        .then(getUsers)
        .then(() => {
          showSuccessPopupNotification(
            `${user.firstName || user.email} ${
              user.lastName || ''
            } was reactivated.`
          );
        })
        .then(onRequestClose)
        .catch((e) => {
          showErrorPopupNotification(
            `${e.message} ${user.firstName || user.email} ${
              user.lastName || ''
            }`
          );
        });
    };

    return (
      <Dialog
        open={open}
        onClose={onRequestClose}
        classes={{
          paper: classes.modal,
        }}
      >
        <DialogTitle>Re-Enable User</DialogTitle>
        <DialogContent className={classes.modalContent}>
          Are you sure you want to Reactivate{' '}
          <b>
            {user.firstName} {user.lastName}
          </b>{' '}
          <br />
          <br />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            control={
              <Checkbox
                color='primary'
                checked={this.state.notifyUser}
                onChange={(e) =>
                  this.setState({ notifyUser: e.target.checked })
                }
              />
            }
            label='Notify user about this.'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose} color='primary'>
            close
          </Button>
          <Button
            onClick={removeHandler}
            color='primary'
            variant='contained'
            loading={enableStatus === 'LOADING'}
          >
            Reactivate
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = {
  modal: {
    minWidth: 500,
  },
  label: {
    color: '#58595b',
  },
};

ReEnableUserDialog.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  getUsers: PropTypes.func,
  data: PropTypes.object,
  classes: PropTypes.object,
  enableStatus: PropTypes.string,
  reEnableUser: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    enableStatus: state.adminPanel.organizationReqs.enableUserFetchStatus.value,
  };
}

export default connect(mapStateToProps, { reEnableUser, getUsers })(
  withStyles(styles)(ReEnableUserDialog)
);
