import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { Alert, Autocomplete } from '@material-ui/lab';
import React from 'react';
import { dealStageColorMap } from 'src/products/core-prospect/constants';
import { DealStage } from 'src/products/core-prospect/pages/lot-page/components';
import { CreateDealModalHook } from 'src/services/Dealcloud/createDeal';
import { EdtiDealModalHook } from 'src/services/Dealcloud/editDeal';
import ButtonProgress from 'src/components/ButtonProgress';
import * as K from './consts/DealcloudPortfolio.consts';
import { makeStyles } from '@material-ui/styles';
import Warning from '@material-ui/icons/Warning';

interface DealModalProps {
  open: boolean;
  onRequestClose: () => void;
  data: {
    hook: CreateDealModalHook | EdtiDealModalHook;
    mode: 'create' | 'edit';
  };
  shareStatus: string;
}

const gridTemplateAreasVariantios = [
  `
    "address address"
    "type building-area"
    "year-built land-area"
    "team team"
    "description description"
    "stage lead"
    "ilo broker"
    "seller seller"
    "note note"
  `,
  `
    "address address"
    "type number-of-units"
    "year-built land-area"
    "team team"
    "description description"
    "stage lead"
    "ilo broker"
    "seller seller"
    "note note"
  `,
  `
    "address address"
    "type land-area"
    "zoning ."
    "team team"
    "description description"
    "stage lead"
    "ilo broker"
    "seller seller"
    "note note"
  `,
];

const useStyles = makeStyles<Theme, { variation: 1 | 2 | 3 }>((theme) => ({
  modal: {
    minWidth: 515,
    overflow: 'visible',
    borderRadius: 10,
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: 20,
    padding: 20,
  },
  modalContent: {
    maxWidth: 515,
    overflow: 'auto',
    display: 'grid',
    gridTemplateAreas: ({ variation }) =>
      gridTemplateAreasVariantios[variation - 1],
    gridTemplateColumns: '1fr 1fr',
    gap: 15,
  },
  key: {
    fontWeight: 500,
    fontSize: 14,
  },
  value: {
    fontWeight: 700,
    fontSize: 14,
  },
  textArea: {
    ...theme.typography.body1,
    padding: '7px 8px 8px',
    border: '1px solid #9E9E9E',
    borderRadius: 4,
    fontSize: 14,
    width: '100%',
    '&:focus': {
      outline: `${theme.palette.primary.main} auto 1px`,
    },
  },
  inactiveAlert: {
    width: '467px',
    paddingTop: '15px',
    '& 	.MuiAlert-root': {
      padding: '0px 8px',
      color: '#AD5D00',
      '& .MuiAlert-icon': {
        color: '#AD5D00',
      },
    },
  },
}));

const DealModal: React.FC<DealModalProps> = (props) => {
  const {
    propertyAddress,
    propertyType,
    buildingArea,
    landArea,
    zoning,
    send,
    description,
    setDescription,
    stages,
    selectedStage,
    setSelectedStage,
    selectedBroker,
    setSelectedBroker,
    selectedSeller,
    setSelectedSeller,
    selectedILO,
    setSelectedILO,
    leads,
    brokers,
    sellers,
    ilos,
    selectedLead,
    setSelectedLead,
    note,
    setNote,
    loading,
    variation,
    yearBuilt,
    numberOfUnits,
    teams,
    dealTeam,
    setDealTeam,
    investmentTypes,
    investmentType,
    setInvestmentType,
  } = props.data.hook(props.onRequestClose);
  const classes = useStyles({ variation: variation || 1 });
  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      className={`js-${props.data.mode}-deal-modal`}
      classes={{
        paper: classes.modal,
      }}
    >
      <Typography className={classes.dialogTitle}>
        {props.data.mode === 'create' ? 'Create New Deal' : 'Edit Deal'}
      </Typography>
      <DialogContent className={classes.modalContent}>
        <div style={{ gridArea: 'address' }}>
          <Typography className={classes.key}>Property address</Typography>
          <Typography className={classes.value}>{propertyAddress}</Typography>
        </div>
        <div style={{ gridArea: 'type' }}>
          <Typography className={classes.key}>Property type</Typography>
          <Typography className={classes.value}>{propertyType}</Typography>
        </div>
        {variation === 2 ? (
          <div style={{ gridArea: 'number-of-units' }}>
            <Typography className={classes.key}># of Units</Typography>
            <Typography className={classes.value}>{numberOfUnits}</Typography>
          </div>
        ) : null}
        {variation === 1 ? (
          <div style={{ gridArea: 'building-area' }}>
            <Typography className={classes.key}>Building Area (SF)</Typography>
            <Typography className={classes.value}>{buildingArea}</Typography>
          </div>
        ) : null}
        {variation !== 3 ? (
          <div style={{ gridArea: 'year-built' }}>
            <Typography className={classes.key}>Year built</Typography>
            <Typography className={classes.value}>{yearBuilt}</Typography>
          </div>
        ) : null}
        <div style={{ gridArea: 'land-area' }}>
          <Typography className={classes.key}>Land Area (AC)</Typography>
          <Typography className={classes.value}>{landArea}</Typography>
        </div>
        {variation === 3 ? (
          <div style={{ gridArea: 'zoning' }}>
            <Typography className={classes.key}>Zoning</Typography>
            <Typography className={classes.value}>{zoning}</Typography>
          </div>
        ) : null}
        <div style={{ gridArea: 'stage', width: '226px' }}>
          <Typography className={classes.key}>Stage</Typography>
          <Autocomplete
            className='js-deal-stage-input'
            value={selectedStage}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_STAGE_INPUT_TEST_ID,
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Box
                      width={11}
                      height={11}
                      bgcolor={dealStageColorMap[selectedStage]}
                      borderRadius='50%'
                    />
                  ),
                }}
              />
            )}
            renderOption={(stage) => <DealStage name={stage} />}
            onChange={(_, stage) => setSelectedStage(stage)}
            options={stages}
            disableClearable
          />
          {selectedStage === 'Inactive' ? (
            <div className={classes.inactiveAlert}>
              <Alert icon={<Warning fontSize='small' />} severity='warning'>
                Inactive deals will no longer display in the platform.
              </Alert>
            </div>
          ) : null}
        </div>
        <div style={{ gridArea: 'broker' }}>
          <Typography className={classes.key}>Broker</Typography>
          <Autocomplete
            value={selectedBroker || undefined}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_BROKER_INPUT_TEST_ID,
                }}
              />
            )}
            onChange={(_, broker) => setSelectedBroker(broker)}
            options={brokers.map((broker) => broker.name)}
          />
        </div>
        <div style={{ gridArea: 'seller' }}>
          <Typography className={classes.key}>Seller</Typography>
          <Autocomplete
            value={selectedSeller || undefined}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_SELLER_INPUT_TEST_ID,
                }}
              />
            )}
            onChange={(_, seller) => setSelectedSeller(seller)}
            options={sellers.map((seller) => seller.name)}
          />
        </div>
        <div style={{ gridArea: 'ilo' }}>
          <Typography className={classes.key}>ILO</Typography>
          <Autocomplete
            value={selectedILO || undefined}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': K.DEAL_ILO_INPUT_TEST_ID,
                }}
              />
            )}
            onChange={(_, ilo) => setSelectedILO(ilo)}
            options={ilos.map((ilo) => ilo.name)}
          />
        </div>
        <div style={{ gridArea: 'lead' }}>
          <Typography className={classes.key}>
            Acquisitions Associate
          </Typography>
          <Autocomplete
            value={selectedLead || undefined}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                placeholder='Select'
                style={{ backgroundColor: 'white' }}
                inputProps={{
                  ...params.inputProps,
                  id: 'js-deal-lead-input',
                  'data-testid': K.DEAL_LEAD_INPUT_TEST_ID,
                }}
              />
            )}
            onChange={(_, lead) => setSelectedLead(lead)}
            options={leads.map((l) => l.name)}
          />
        </div>
        <div style={{ gridArea: 'note' }}>
          <Typography className={classes.key}>Latest note</Typography>
          <TextareaAutosize
            className={classes.textArea}
            value={note}
            placeholder='Add note'
            onChange={(e) => setNote(e.target.value)}
            data-testid={K.DEAL_NOTE_INPUT_TEST_ID}
          />
        </div>
        <div style={{ gridArea: 'description' }}>
          <Typography className={classes.key}>Description</Typography>
          <TextareaAutosize
            className={classes.textArea}
            value={description}
            placeholder='Description'
            onChange={(e) => setDescription(e.target.value)}
            data-testid={K.DEAL_DESCRIPTION_INPUT_TEST_ID}
          />
        </div>
        <div style={{ gridArea: 'team' }}>
          <Typography className={classes.key}>Deal Team</Typography>
          <RadioGroup
            row
            value={dealTeam}
            onChange={(_, value) => setDealTeam(Number(value))}
          >
            {teams.map((t) => (
              <FormControlLabel
                key={t.id}
                value={t.id}
                control={<Radio color='primary' />}
                label={t.name}
              />
            ))}
          </RadioGroup>
          {teams.find((t) => t.id === dealTeam)?.name === 'Multi-Family' ? (
            <div style={{ gridArea: 'investment-type' }}>
              <Autocomplete
                value={
                  investmentTypes.find((i) => i.id === investmentType) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    size='small'
                    label='Investment Type'
                    style={{ backgroundColor: 'white' }}
                  />
                )}
                onChange={(_, invType) => setInvestmentType(invType?.id)}
                options={investmentTypes}
                getOptionLabel={(o) => o.name}
              />
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: 12 }}>
          <Button onClick={props.onRequestClose} color='primary'>
            Cancel
          </Button>
          <ButtonProgress
            className='js-deal-submit-button'
            loading={loading}
            onClick={send}
            variant='contained'
            color='primary'
          >
            {props.data.mode === 'create' ? 'Create' : 'Done'}
          </ButtonProgress>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DealModal;
