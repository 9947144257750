import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    fontSize: 16,
    color: '#000',
  },
};

const LayersListButton = (props) => {
  const { toggle, expanded } = props;

  if (!expanded) {
    return null;
  }

  return (
    <IconButton
      id='core-prospect-close-layers-list-button'
      onClick={toggle}
      style={{
        cursor: 'pointer',
      }}
    >
      <Close />
    </IconButton>
  );
};

LayersListButton.propTypes = {
  toggle: PropTypes.func,
};

export default withStyles(styles)(LayersListButton);
