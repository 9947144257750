import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import RegularNavBarRight from './RegularNavBarRight';
import RegularNavBarLeft from './RegualarNavBarLeft';
import RegularNavBarCenter from './RegularNavBarCenter';

import constants from 'src/const';
import { useStyles } from './styles';
import { usePage } from 'src/utils/routing/CherreRoute';
const { NAV_BAR_HEIGHT } = constants;

const RegularNavBar = ({
  user,
  toggleNavigationDrawer,
  toggleRemindersDrawer,
  isCherreAdmin,
}) => {
  const classes = useStyles();
  const [, active] = usePage();

  const isCoreApi =
    active?.path === '/core-api' || active?.path === '/analytics-api';
  const isPowerBiReports = active?.path?.includes('/reports');

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid
        style={{ paddingTop: 0, paddingBottom: 0 }}
        item
        xs={12}
        sm={6}
        lg={4}
      >
        <RegularNavBarLeft
          user={user}
          toggleNavigationDrawer={toggleNavigationDrawer}
        />
      </Grid>
      <Hidden xsDown>
        <Grid
          style={{ padding: !isCoreApi ? 0 : '', maxHeight: NAV_BAR_HEIGHT }}
          item
          xs={false}
          sm={3}
          lg={5}
          className={!isCoreApi ? classes.alignCenter : ''}
        >
          <RegularNavBarCenter isCoreApi={isCoreApi} />
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid item xs={false} sm={3} lg={3} className={classes.alignRight}>
          <RegularNavBarRight
            user={user}
            isPowerBiReports={isPowerBiReports}
            isCherreAdmin={isCherreAdmin}
            toggleRemindersDrawer={toggleRemindersDrawer}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};

RegularNavBar.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  searchText: PropTypes.string,
  toggleNotificationsDrawer: PropTypes.func,
  toggleNavigationDrawer: PropTypes.func,
  unreadNotificationsCount: PropTypes.number,
  isCherreAdmin: PropTypes.bool,
  canEmulateUser: PropTypes.bool,
};

function mapStateToProps(state) {
  const { user } = state;

  return {
    isCherreAdmin: (user.profile.value.permissions || []).includes(
      'CherreAdminReadOnly'
    ),
    user: user.profile.value,
  };
}

export default connect(mapStateToProps)(RegularNavBar);
