function createHighlightedMapArray(parentChildren, checked) {
  const highlightedMapArray = [];

  const ids = Object.keys(checked).map((id) => +id);

  ids.forEach((id) => {
    if (checked[id] && !parentChildren[id]) {
      highlightedMapArray.push(id);
    }
  });

  return highlightedMapArray;
}

// Each node in nodesArray should have a parent, id, and label property
// returns an object with the nodes parent id as the key and an array of all of it's children values
function createParentChildrenTable(nodesArray) {
  const parentChildren = {};

  if (!nodesArray) {
    return parentChildren;
  }

  if (nodesArray.length) {
    nodesArray.forEach((node) => {
      parentChildren[node.parent]
        ? parentChildren[node.parent].push(node.id)
        : (parentChildren[node.parent] = [node.id]);
    });
  }

  return parentChildren;
}

// Each node in nodesArray should have a parent, id, and label property
// returns an object with the nodes id as the key and an array of all of it's parent values
function createChildrenParentTable(nodesArray) {
  const childrenParent = {};

  if (!nodesArray) {
    return childrenParent;
  }

  if (nodesArray.length) {
    nodesArray.forEach((node) => {
      childrenParent[node.id] = node.parent;
    });
  }

  return childrenParent;
}

// Each node in nodesArray should have a parent, id, and label property
// returns an object with the nodes id as the key and the all of the data about the node as the value
function createIdDataTable(nodesArray) {
  const idData = {};

  if (!nodesArray) {
    return idData;
  }

  if (nodesArray.length) {
    nodesArray.forEach((node) => {
      idData[node.id] = node;
    });
  }

  return idData;
}

// checks or uncheck the parent node, if all or none of it's children are checked
function checkParent(
  childrenParent = {},
  parentChildren = {},
  nodeId,
  newCheckedObject
) {
  const parentNode = childrenParent[nodeId];
  const nodeSiblings = parentChildren[parentNode];
  let everySiblingChecked;

  if (nodeSiblings) {
    everySiblingChecked = nodeSiblings.every(
      (sibling) => newCheckedObject[sibling] === true
    );
  }

  newCheckedObject[parentNode] = everySiblingChecked;
}

// checks or unchecks all of a parent nodes children, and all of their children
function recursivelyCheckChildren(
  parentChildren = {},
  nodeId,
  newCheckedObject,
  newCheckedValue
) {
  if (parentChildren[nodeId]) {
    parentChildren[nodeId].forEach((childNode) => {
      if (parentChildren[childNode]) {
        recursivelyCheckChildren(
          parentChildren,
          childNode,
          newCheckedObject,
          newCheckedValue
        );
      }
      newCheckedObject[childNode] = newCheckedValue;
    });
  }
}

function createParentExpansionObject(
  childrenParent,
  parentChildren,
  expanded,
  checked,
  nodeId
) {
  // create copy of expanded object
  const newExpanded = Object.assign({}, expanded);
  // get the parent node of the node that was checked
  let parent = childrenParent[nodeId];
  // if it's checked then all of it's parents need to be open
  if (checked[nodeId] === true) {
    while (parent !== null) {
      newExpanded[parent] = true;
      parent = childrenParent[parent];
    }
  } else if (checked[nodeId] === false) {
    // if not checked, check to see if it has any siblings by getting the parents children
    const siblings = parentChildren[parent];
    if (siblings) {
      // if any of the siblings are checked
      const anyChecked = siblings.some((sibling) => checked[sibling] === true);

      if (anyChecked) {
        // then it needs to be open
        newExpanded[parent] = true;
      } else {
        // if not then close it
        newExpanded[parent] = false;
      }
    }
  }

  return newExpanded;
}

// takes the previous state of the expansion object, which is used to track, what nodes are collapsed or expanded
// in the filter and returns a new object with updated node collapsed or expanded state
function createExpansionObject(expanded = {}, panelId) {
  const newExpandedValue = !expanded[panelId];
  const newExpanded = { ...expanded };

  newExpanded[panelId] = newExpandedValue;

  return newExpanded;
}

function createIndeterminateObject(
  indeterminate = {},
  childrenParent = {},
  parentChildren = {},
  newCheckedObject,
  node
) {
  const newIndeterminateObject = { ...indeterminate };
  const id = node.id;
  const parentNode = childrenParent[node.id];
  const nodeSiblings = parentChildren[parentNode];
  let everySiblingChecked;
  let someSiblingChecked;

  if (nodeSiblings) {
    everySiblingChecked = nodeSiblings.every(
      (sibling) => newCheckedObject[sibling] === true
    );
    someSiblingChecked = nodeSiblings.some(
      (sibling) => newCheckedObject[sibling] === true
    );
  }

  const parentIndeterminate = someSiblingChecked && !everySiblingChecked;
  newIndeterminateObject[parentNode] = parentIndeterminate;

  if (newCheckedObject[id] === true) {
    newIndeterminateObject[id] = false;
  }

  return newIndeterminateObject;
}

// take in the previous state of the object tracking what items are checked in the hierachical selection
// and returns a new object with what should be checked.  It will also check the parent if all of it's children
// are checked and check all of the children if a parent node
function createCheckedObject(
  checked = {},
  parentChildren = {},
  childrenParent = {},
  node
) {
  const newCheckedValue = !checked[node.id];
  const newCheckedObject = { ...checked };

  newCheckedObject[node.id] = newCheckedValue;

  recursivelyCheckChildren(
    parentChildren,
    node.id,
    newCheckedObject,
    newCheckedValue
  );

  checkParent(childrenParent, parentChildren, node.id, newCheckedObject);

  return newCheckedObject;
}

// Select/deselect node and return changed filter value;
function selectNode(node, filterValue, parentChildren, childrenParent) {
  const checked = createCheckedObject(
    filterValue.checked,
    parentChildren,
    childrenParent,
    node
  );

  const indeterminate = createIndeterminateObject(
    filterValue.indeterminate,
    childrenParent,
    parentChildren,
    checked,
    node
  );

  return {
    ...filterValue,
    checked,
    indeterminate,
  };
}

export {
  createParentChildrenTable,
  createChildrenParentTable,
  createIdDataTable,
  createCheckedObject,
  createExpansionObject,
  createParentExpansionObject,
  createIndeterminateObject,
  selectNode,
  createHighlightedMapArray,
};
