import {
  getSelector,
  selector,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import axios from 'axios';
import moment from 'moment';
import { ValidationRuleResult } from 'src/products/connector/pages/connector-details/components/DataValidation/types';
import { selectedConnectorName } from 'src/products/connector/pages/connector-details/recoil';
import {
  selectedValidationRule,
  validationDetailsRuleId,
} from 'src/products/connector/pages/validation-details/recoil';
import { determineFailedRecordCountText } from 'src/products/connector/pages/validation-details/utils/determineFailedRecordCountText';
import {
  connectorDetailsSelectedTable,
  qualyticsDataVolumeReportDate,
  qualyticsDataVolumeTimeframe,
} from 'src/products/connector/recoil';
import { RuleDetails } from 'src/products/connector/types/ruleTypes';
import { buildRuleDetailStatus } from 'src/products/connector/utils/buildRuleDetailStatus';
import { connectorDataSelector } from 'src/services/DOM/connectorLanding/recoil';
import { tableColumns } from 'src/services/DOM/connectorOverview/recoil';
import {
  aggregatedConnectorPublishedTables,
  selectedConnectorPublishedTables,
} from 'src/services/DOM/connectorValidationRules/recoil';
import { ColumnItem, TableProfileItem } from 'src/services/OpenMetadata/types';
import { countRules } from 'src/services/OpenMetadata/utils/getValidationTestSummary';
import { buildRuleStatus } from '../utils/buildRuleStatus';

export type FailedRule = {
  records: {
    [key: string]: number | string | null;
  }[];
  failingColumn: string;
  columnNames: string[];
};

type RuleDetailsResponse = {
  anomalyID: number;
  lastRun: string;
  anomalousRecords: number;
  tableRecordCount: number;
  failedRowCountData: {
    id: string;
    date: string;
    failedRowCount: number;
  }[];
  failedColumns: string[];
  failedSourceRecords: any[];
};

type ValidationRulesParams = {
  tables: string[];
  connectorNames: string[];
};

type DataVolumeResponse = {
  profile: {
    columnCount: number;
    tableName: string;
    dataVolume: { date: string; count: number | null }[];
    profileSummary: TableProfileItem;
  };
  columns: ColumnItem[];
};

const validationRulesSelector = selectorFamily<
  { data: ValidationRuleResult[] },
  ValidationRulesParams
>({
  key: 'VALIDATION-RULES-SELECTOR',
  get: (params) => async () => {
    const { tables, connectorNames } = params;

    //Need to grab all connector names as a list
    try {
      const response = await axios.post(`/api/v1/qualytics/validationRules`, {
        tables,
        connectorNames,
      });

      const { data: validationRules } = response;

      if (validationRules.length === 0) {
        return { data: [] };
      }

      return {
        data: validationRules.map((rule) => ({
          id: rule.id,
          datastoreId: rule.datastore.id,
          enrichmentDatastoreId: rule.enrichmentDatastoreId,
          enrichmentDatastoreName: rule.enrichmentDatastoreName,
          tableName: rule.container.name,
          ruleName: rule.description ?? '',
          columnName: rule.properties?.field_name ?? '',
          lastRun: rule.last_asserted
            ? moment(rule.last_asserted).valueOf()
            : '',
          tableId: rule.container.id,
          watchers: [],
          ruleStatus: buildRuleStatus(rule.has_passed),
          connectorName: rule.connectorName,
        })),
      };
    } catch (error) {
      console.error('Error fetching validation rules:', error);
      return { data: [] };
    }
  },
});
export const aggregatedQualyticsValidationRules = selector({
  key: 'QUALYTICS/AGGREGATED-VALIDATION-RULES',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const tables = get(aggregatedConnectorPublishedTables);
      const connectorNames = get(connectorDataSelector()).data.map(
        (connector) => connector.name
      );

      if (!tables || !connectorNames) {
        return { data: [] };
      }

      return get(validationRulesSelector({ tables, connectorNames }));
    },
});

export const selectedConnectorQualyticsValidationRules = selector({
  key: 'QUALYTICS/SELECTED-VALIDATION-RULES',
  scoped: true,
  get:
    () =>
    ({ get }) => {
      const tables = get(selectedConnectorPublishedTables);
      const connector = get(selectedConnectorName);
      return get(
        validationRulesSelector({
          tables,
          connectorNames: connector ? [connector] : [],
        })
      );
    },
});

export const landingPageValidationRuleSummary = selector({
  key: 'QUALYTICS/LANDING-PAGE-DATA-VALIDATION-RULE-SUMMARY',
  scoped: true,
  get:
    () =>
    async ({
      get,
    }): Promise<{
      data: { success: number; failed: number; aborted: number; total: number };
    }> => {
      const rules = get(aggregatedQualyticsValidationRules);
      const summary = countRules(rules);
      return { data: summary };
    },
});
export const qualyticsDataValidationTestSummary = selector({
  key: 'QUALYTICS/DATA-VALIDATION-RULE-SUMMARY',
  scoped: true,
  get:
    () =>
    async ({
      get,
    }): Promise<{
      data: { success: number; failed: number; aborted: number; total: number };
    }> => {
      const rules = get(selectedConnectorQualyticsValidationRules);
      const summary = countRules(rules);
      return { data: summary };
    },
});
export const qualyticsValidationRuleDetails = selector({
  key: 'QUALYTICS/DATA-VALIDATION-RULE-DETAILS',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<RuleDetails | null> => {
      let selectedRule: ValidationRuleResult | null = get(
        selectedValidationRule
      );

      if (!selectedRule) {
        const ruleId = get(validationDetailsRuleId);
        const rules: { data: ValidationRuleResult[] } = get(
          selectedConnectorQualyticsValidationRules
        );
        selectedRule =
          rules?.data.find(
            (rule) => rule.id.toString() === ruleId.toString()
          ) ?? null;
      }

      if (!selectedRule) {
        return null;
      }

      const urlParams = new URLSearchParams({
        tableID: selectedRule?.tableId ? String(selectedRule.tableId) : '',
        lastRun: moment(selectedRule?.lastRun).toISOString(),
      });

      try {
        const response: { data: RuleDetailsResponse } = await get(
          getSelector({
            url: `/api/v1/qualytics/validationRule/${
              selectedRule?.id
            }?${urlParams.toString()}`,
          })
        );

        const failedRecordCount = determineFailedRecordCountText(
          selectedRule,
          response.data.tableRecordCount,
          response.data.anomalousRecords
        );

        let keys: string[] = [];
        const failedRecords = response.data.failedSourceRecords.map(
          (record, idx) => ({ ...record, _frontend_id: idx.toString() })
        );

        if (Array.isArray(failedRecords) && failedRecords.length > 0) {
          keys = Object.keys(failedRecords[0]).filter(
            (key) =>
              ![
                'anomaly_uuid',
                '_qualytics_source_partition',
                '_frontend_id',
              ].includes(key)
          );
        }

        const ruleDetails: RuleDetails = {
          id: selectedRule?.id ?? '',
          anomalyID: response.data.anomalyID,
          lastRun: selectedRule?.lastRun
            ? moment(selectedRule?.lastRun).format('MMM DD, YYYY h:mm A')
            : '--',
          failedRecordCount: failedRecordCount,
          failedSourceRecords: {
            records: failedRecords ? failedRecords : [],
            columns: keys,
            failedColumns: response.data.failedColumns,
          },
          status: buildRuleDetailStatus(
            response.data.anomalousRecords,
            selectedRule?.ruleStatus
          ),
          ruleName: selectedRule?.ruleName ?? '',
          tableRecordCount: response.data.tableRecordCount,
          canEdit: false,
          failedRowCountHistory: response.data.failedRowCountData,
          parameterValues: [],
          testDefinition: {
            description: '',
            type: '',
            name: '',
          },
          entityLink: '',
        };
        return ruleDetails;
      } catch (error) {
        if (error instanceof Promise) {
          throw error;
        }
        console.log(
          'An error occurred when getting qualyticsValidationRuleDetails',
          error
        );
        return null;
      }
    },
});

export const qualyticsTableDataVolume = selector({
  key: 'QUALYTICS/TABLE-DATA-VOLUME',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<TableProfileItem[]> => {
      const connectorPublishedTables = get(selectedConnectorPublishedTables);
      const selectedTable = get(connectorDetailsSelectedTable);
      const tableName = selectedTable || connectorPublishedTables[0];

      if (!tableName) {
        return [];
      }

      const reportDate = get(qualyticsDataVolumeReportDate);
      const timeframe = get(qualyticsDataVolumeTimeframe);

      try {
        const response: DataVolumeResponse = await get(
          getSelector({
            url: `/api/v1/qualytics/tableProfile/${tableName}?reportDate=${reportDate}&timeframe=${timeframe}`,
          })
        );

        const dataVolume: TableProfileItem[] = response.profile.dataVolume.map(
          (record) => {
            return {
              rowCount: record.count ?? 0,
              timestamp: moment(record.date).valueOf(),
              profileSample: response.profile.profileSummary.profileSample,
              columnCount: response.profile.profileSummary.columnCount,
              sizeInByte:
                response.profile.profileSummary.sizeInByte ?? undefined,
            };
          }
        );
        return dataVolume;
      } catch (error) {
        if (error instanceof Promise) {
          throw error;
        }
        return [];
      }
    },
});

export const qualyticsThirtyDayBaseData = selector({
  key: 'QUALYTICS/THIRTY-DAY-BASE-DATA',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<DataVolumeResponse | null> => {
      const connectorPublishedTables = get(selectedConnectorPublishedTables);
      const selectedTable = get(connectorDetailsSelectedTable);
      const tableName = selectedTable || connectorPublishedTables[0];

      if (!tableName) {
        return null;
      }

      try {
        const response: DataVolumeResponse = get(
          getSelector({
            url: `/api/v1/qualytics/tableProfile/${tableName}?reportDate=${moment().format(
              'YYYY-MM-DD'
            )}&timeframe=month`,
          })
        );
        return response;
      } catch (error) {
        if (error instanceof Promise) {
          throw error;
        }
        return null;
      }
    },
});

export const qualyticsThirtyDayAverageData = selector({
  key: 'QUALYTICS/THIRTY-DAY-AVERAGE-DATA',
  scoped: true,
  get:
    () =>
    async ({ get }): Promise<TableProfileItem[]> => {
      const baseData = get(qualyticsThirtyDayBaseData);

      if (!baseData) {
        return [];
      }
      const dataVolume: TableProfileItem[] = baseData.profile.dataVolume.map(
        (record) => {
          return {
            rowCount: record.count ?? 0,
            timestamp: moment(record.date).valueOf(),
          };
        }
      );
      return dataVolume;
    },
});

export const qualyticsObservabilityData = selector({
  key: 'QUALYTICS/PROFILE-SUMMARY',
  scoped: true,
  get:
    () =>
    async ({
      get,
    }): Promise<{
      profile: TableProfileItem;
      columns: ColumnItem[];
    } | null> => {
      const baseData = get(qualyticsThirtyDayBaseData);

      if (!baseData || !baseData.profile.profileSummary) {
        return null;
      }

      const profile: TableProfileItem = {
        rowCount: baseData.profile.profileSummary.rowCount,
        columnCount: baseData.profile.profileSummary.columnCount,
        sizeInByte: baseData.profile.profileSummary.sizeInByte ?? undefined,
        timestamp: baseData.profile.profileSummary.timestamp,
        profileSample:
          baseData.profile.profileSummary.profileSample ?? undefined,
      };

      return { profile, columns: baseData.columns };
    },
});

export const qualyticsObservabilityDataWithDOMColumns = selector({
  key: 'QUALYTICS/PROFILE-SUMMARY-WITH-DOM-COLUMNS',
  scoped: true,
  get:
    () =>
    async ({
      get,
    }): Promise<{
      profile: TableProfileItem;
      columns: ColumnItem[];
    } | null> => {
      const baseData = get(qualyticsThirtyDayBaseData);

      if (!baseData || !baseData.profile.profileSummary) {
        return null;
      }

      const profile: TableProfileItem = {
        rowCount: baseData.profile.profileSummary.rowCount,
        columnCount: baseData.profile.profileSummary.columnCount,
        sizeInByte: baseData.profile.profileSummary.sizeInByte ?? undefined,
        timestamp: baseData.profile.profileSummary.timestamp,
        profileSample:
          baseData.profile.profileSummary.profileSample ?? undefined,
      };

      const columns = get(tableColumns(baseData.profile.tableName));

      return { profile, columns };
    },
});
