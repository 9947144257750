import { grey } from '@material-ui/core/colors';
import { Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import axios from 'axios';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PagedTable from 'src/components/PagedTable';

const UploadFileText = styled('div')({
  color: grey[600],
  marginLeft: 13,
});

const Root = styled('div')({
  background: grey[100],
  padding: 17,
  borderRadius: 10,
  margin: '20px 0',
  display: 'flex',
  alignItems: 'center',
});

const UploadCsv = (props) => {
  const { input, label, endpoint } = props;

  const onDrop = useCallback(async (acceptedFiles) => {
    return axios
      .post(endpoint, acceptedFiles[0], {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
      .then((res) => {
        input.onChange(res.data);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'text/csv',
  });

  return (
    <>
      <Root
        {...getRootProps()}
        style={{
          border: isDragActive
            ? `2px dashed ${grey[600]}`
            : '2px solid transparent',
        }}
      >
        <Button variant='contained' color='primary' size='small'>
          {label}
          <input
            type='file'
            style={{ display: 'none' }}
            {...getInputProps()}
            accept='.csv'
          />
        </Button>
        <UploadFileText>or drag a file into this area.</UploadFileText>
      </Root>
      {input.value && (
        <div
          style={{
            width: '100%',
            height: 400,
          }}
        >
          <PagedTable
            rowKey='key'
            columns={input.value.headers.map((header) => {
              return {
                label: header,
                dataKey: header,
                width: 200,
              };
            })}
            items={input.value.rows.map((row, index) => ({
              ...row,
              key: index,
            }))}
            showPagination={false}
            selection={false}
          />
        </div>
      )}
    </>
  );
};

export default UploadCsv;
