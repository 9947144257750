import { graphql } from 'relay-runtime';

export const AddDestinationToModelMutation = graphql`
  mutation addDestinationToModelMutation(
    $destinationName: String!
    $modelName: String!
  ) {
    addDestinationToModel(
      destinationName: $destinationName
      modelName: $modelName
    ) {
      displayName
      name
    }
  }
`.default;
