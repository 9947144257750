import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalComponentsKeys, ModalPropsType } from 'src/components/modals';
import { showModal } from 'src/products/shell/redux/modals/actions';

/**
 * @todo refactor to move away from redux
 */
export const useShowModal = <T extends ModalComponentsKeys>(modalType: T) => {
  const dispatch = useDispatch();

  return useCallback(
    (modalProps: ModalPropsType<T>) => {
      dispatch(showModal(modalType, modalProps));
    },
    [modalType]
  );
};
