import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

export function Page({ children, classes = {} }) {
  return <div className={classes.root}>{children}</div>;
}

Page.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
};

const styles = {
  root: {
    paddingTop: 25,
    paddingBottom: 25,
    display: 'flex',
    justifyContent: 'center',
    height: '92vh',
  },
};

export default withStyles(styles)(Page);
