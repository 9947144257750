import { useSelector } from 'react-redux';

/**
 * @todo move user information retrieval to recoil
 */
export const useDashboardPropertyLink = (parcelId: string) => {
  const dashboardId = useSelector(
    (state) =>
      state.user.profile.value.organizationProps?.dashboard_property_link
  );
  if (!dashboardId) {
    return undefined;
  }
  const searchParams = new URLSearchParams();
  searchParams.append('addresses/parcel', parcelId);
  return `/reports/${dashboardId}?${searchParams.toString()}`;
};
