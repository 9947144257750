import React, { useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type FilterValue = { min?: string; max?: string };

export type RangeSelectionProps = {
  filterValue?: FilterValue;
  setFilterValue: React.Dispatch<React.SetStateAction<FilterValue>>;
  filter: { filter_type_props?: { minLabel?: string; maxLabel?: string } };
  classes: {
    root: string;
    inputs: string;
    textField: string;
  };
  onBlur: () => void;
};

const RangeSelection: React.FC<RangeSelectionProps> = ({
  filterValue,
  setFilterValue,
  filter: { filter_type_props = {} },
  onBlur = () => {},
  classes,
}) => {
  const min = (filterValue && filterValue.min) || null;
  const max = (filterValue && filterValue.max) || null;

  const { minLabel = 'From', maxLabel = 'To' } = filter_type_props;

  const onChangeInput = useCallback(
    (date: MaterialUiPickersDate, type: 'min' | 'max') => {
      setFilterValue &&
        setFilterValue({
          ...filterValue,
          [type]:
            date && date.isValid() ? date.format('MM/DD/YYYY') : undefined,
        });

      // trigger onBlur manually when user select the date via picker to trigger search query
      if (!date || date.isValid()) {
        onBlur();
      }
    },
    [onBlur, setFilterValue]
  );

  return (
    <div className={classes.root}>
      <div className={classes.inputs}>
        <FormControl className={classes.textField}>
          <KeyboardDatePicker
            data-testid='min-date-picker'
            inputVariant='outlined'
            InputProps={{
              style: { paddingRight: 0 },
            }}
            size='small'
            clearable
            maxDate={max ? new Date(max) : undefined}
            maxDateMessage='Date should not be after max date'
            format='MM/DD/YYYY'
            placeholder='__/__/____'
            label={minLabel}
            value={min}
            onChange={(date) => onChangeInput(date, 'min')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            onBlur={onBlur}
          />
        </FormControl>
        <FormControl className={classes.textField}>
          <KeyboardDatePicker
            data-testid='max-date-picker'
            inputVariant='outlined'
            InputProps={{
              style: { paddingRight: 0 },
            }}
            size='small'
            clearable
            minDate={min ? new Date(min) : undefined}
            minDateMessage='Date should not be before min date'
            format='MM/DD/YYYY'
            placeholder='__/__/____'
            label={maxLabel}
            value={max}
            onChange={(date) => onChangeInput(date, 'max')}
            onBlur={onBlur}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default withStyles(styles)(RangeSelection);
