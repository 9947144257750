import { RootState, useSelector } from 'react-redux';

/**
 * @todo move to revoil
 */
export const useOrganizationPropsValue = <
  T extends keyof NonNullable<
    RootState['user']['profile']['value']['organizationProps']
  >
>(
  field: T
) => {
  return useSelector(
    (state) => state.user?.profile.value.organizationProps?.[field]
  );
};
