import { useSelector } from 'react-redux';

/**
 * @deprecated use react-router useHistory
 */
const useHistory = () => {
  const { isHistoryEmpty } = useSelector((state) => {
    return { isHistoryEmpty: state.routerHistory.length <= 1 };
  });

  return { isHistoryEmpty };
};

export default useHistory;
