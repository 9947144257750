export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const INIT_QUERY_SETTINGS = 'INIT_QUERY_SETTINGS';

export const EXEC_QUERY_REQUEST = 'EXEC_QUERY_REQUEST';
export const EXEC_QUERY_SUCCESS = 'EXEC_QUERY_SUCCESS';
export const EXEC_QUERY_FAILED = 'EXEC_QUERY_FAILED';

export const CHANGE_LIMIT = 'CHANGE_LIMIT';
export const CHANGE_OFFSET = 'CHANGE_OFFSET';
export const SET_SEARCH_PARAMETER_VALUE = 'SET_SEARCH_PARAMETER_VALUE';
