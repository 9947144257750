import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataFormatter } from 'src/products/core-prospect/components/PropertyAddressSummary/dataFormatter/DataFormatter';
import { PortfolioDeal } from 'src/services/Dealcloud/types';
import numberWithCommas from 'src/utils/numberWithCommas';

interface DealPropertiesModalProps {
  open: boolean;
  onRequestClose: () => void;
  data: {
    deal: PortfolioDeal;
    taxAssessors: DataFormatter[];
  };
  shareStatus: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  modal: {
    minWidth: 500,
    overflow: 'visible',
    borderRadius: 10,
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: 20,
  },
  dialogSubtitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  modalContent: {
    overflow: 'visible',
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  list: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 20,
    maxHeight: 350,
    borderBottom: '1px solid #EEEEEE',
  },
  key: {
    fontWeight: 500,
    fontSize: 14,
  },
  value: {
    fontWeight: 700,
    fontSize: 14,
  },
  textArea: {
    ...theme.typography.body1,
    padding: '7px 8px 8px',
    border: '1px solid #9E9E9E',
    borderRadius: 4,
    fontSize: 14,
    width: '100%',
    '&:focus': {
      outline: `${theme.palette.primary.main} auto 1px`,
    },
  },
}));

const usePropertyStyles = makeStyles<Theme>((theme) => ({
  address: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  otherInfo: {
    color: theme.palette.grey[700],
    fontWeight: 500,
    fontSize: 12,
  },
}));

export const PropertyItem: React.FC<{ data: DataFormatter }> = ({ data }) => {
  const classes = usePropertyStyles();

  const { saleAmount, saleDate, parcelId, taxAssessorId } = data;

  const formattedAmount = saleAmount ? `$${numberWithCommas(saleAmount)}` : '';
  const fomattedDate = saleDate ? `on ${moment(saleDate).format('ll')}` : '';
  return (
    <div>
      <Typography
        component={Link}
        to={`/properties/${parcelId}/${taxAssessorId}`}
        href={`/properties/${parcelId}/${taxAssessorId}`}
        target='_blank'
        className={classes.address}
      >
        {data.address}
      </Typography>
      <Typography className={classes.otherInfo}>{data.subTitle}</Typography>
      {formattedAmount && (
        <Typography className={classes.otherInfo}>
          Last sale: {formattedAmount} - {fomattedDate}
        </Typography>
      )}
    </div>
  );
};

const DealPropertiesModal: React.FC<DealPropertiesModalProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const locationStr = useMemo(() => {
    const locations = props.data.deal.assets
      .map((a) => `${a.dealCloudAsset.city}, ${a.dealCloudAsset.state}`)
      .filter((v, i, a) => a.indexOf(v) === i);
    if (locations.length === 1) {
      return locations[0];
    }
    if (locations.length === 2) {
      return locations.join(' & ');
    }
    return 'multiple geographies';
  }, [props.data.deal]);

  const onEditClick = () => {
    props.onRequestClose();
    dispatch(push(`/portfoliodeal/${props.data.deal.dealId}`));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      classes={{ paper: classes.modal }}
    >
      <div style={{ padding: 20 }}>
        <Typography className={classes.dialogTitle}>
          {props.data.deal.dealName || 'Unnamed deal'}
        </Typography>
        <Typography className={classes.dialogSubtitle}>
          {props.data.taxAssessors.length} Property Portfolio in {locationStr}
        </Typography>
      </div>
      <DialogContent className={classes.modalContent}>
        <div className={classes.list}>
          {props.data.taxAssessors.map((data) => (
            <PropertyItem key={data.taxAssessorId} data={data} />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: 12 }}>
          <Button
            startIcon={<Edit />}
            style={{ marginRight: 10 }}
            onClick={onEditClick}
            variant='outlined'
            color='primary'
          >
            Edit
          </Button>
          <Button
            onClick={props.onRequestClose}
            variant='contained'
            color='primary'
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DealPropertiesModal;
