import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

const TreppLoanDataModalTableRow = ({ label, item1, item2 }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Typography variant='body2' style={{ color: grey[700] }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ color: grey[800] }}>{item1}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ color: grey[800] }}>{item2}</Typography>
      </Grid>
    </Grid>
  );
};

export default TreppLoanDataModalTableRow;
