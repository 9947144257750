import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/icons/Menu';
import NavBarPageTitle from './NavBarPageTilte';
import { makeStyles } from '@material-ui/core/styles';
import { logoUrl } from 'src/const/theme';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageName: {
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.light,
  },
}));

const RegularNavBarLeft = ({ user, toggleNavigationDrawer }) => {
  const classes = useStyles();

  return (
    <div className={classes.pageName}>
      <Tooltip title='Features'>
        <Menu
          id={user.email ? 'js-nav-menu-icon' : ''}
          onClick={toggleNavigationDrawer}
          style={{
            cursor: 'pointer',
            marginRight: 15,
          }}
        />
      </Tooltip>
      {logoUrl && (
        <Link to='/' style={{ display: 'flex' }}>
          <img style={{ height: 40 }} src={logoUrl} />
        </Link>
      )}
      <NavBarPageTitle />
    </div>
  );
};

export default RegularNavBarLeft;
