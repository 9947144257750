import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTeamCreateModal } from 'src/products/core-prospect/hooks/useTeamCreateOrEdit';
import {
  MemberOption,
  MembersAutocomplete,
} from './components/MembersAutocomplete';
import { useStyles } from './style';

export type TeamCreateProps = {
  open: boolean;
  data: {
    teamId: number | undefined;
    teamName: string;
  };
  onRequestClose: () => void;
};

const TeamCreate: React.VFC<TeamCreateProps> = ({
  open,
  data,
  onRequestClose,
}) => {
  const classes = useStyles();
  const { teamId, teamName } = data;

  const { isSaving, onClickAdd, onClickEditDone } = useTeamCreateModal(
    teamId,
    onRequestClose
  );

  const [selectedMembers, setSelectedMembers] = useState<MemberOption[]>([]);
  const [name, setName] = useState(teamName || '');

  const edit = !!teamId;

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{ paper: classes.modal }}
      data-testid='group_add_members_modal'
    >
      <Typography className={classes.title}>
        {edit ? 'Rename' : 'Create New'} Group
      </Typography>
      <DialogContent className={classes.content}>
        <Typography className={classes.key}>Group name</Typography>
        <div className={classes.value}>
          <TextField
            id='outlined-basic'
            variant='outlined'
            size='small'
            fullWidth
            onChange={(e) => setName(e.target.value)}
            value={name}
            data-testid='group_name'
          />
        </div>
        {!edit && (
          <>
            <Typography className={classes.key}>Add members</Typography>
            <div className={classes.value}>
              <MembersAutocomplete
                selectedValues={selectedMembers}
                setSelectedValues={setSelectedMembers}
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={onRequestClose}
          color='primary'
          className={classes.cancel}
        >
          Cancel
        </Button>
        {!edit ? (
          <Button
            disabled={isSaving}
            onClick={() =>
              onClickAdd({
                name,
                memberIds: selectedMembers.map((s) => s.id),
              })
            }
            variant='contained'
            color='primary'
            className={classes.add}
            data-testid='btn_create'
          >
            Create
          </Button>
        ) : (
          <Button
            disabled={isSaving}
            onClick={() =>
              onClickEditDone({
                name,
              })
            }
            variant='contained'
            color='primary'
            className={classes.add}
            data-testid='btn_done'
          >
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TeamCreate;
