import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

export function PageContent({ children, classes = {} }) {
  return <div className={classes.root}>{children}</div>;
}

PageContent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
};

const styles = {
  root: {
    width: '95%',
    padding: '0 30px',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default withStyles(styles)(PageContent);
