import React from 'react';
import cx from 'classnames';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  item: {
    background: 'rgba(0, 0, 0, 0)',
    height: 'auto',
    maxWidth: 345,
    whiteSpace: 'normal',
    '&:focus': {
      background: 'rgba(0, 0, 0, 0)',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  arrowFocused: {
    border: '1px solid #555',
  },
  selectedItem: {
    background: 'rgba(0, 0, 0, 0.12)',
    '&:focus': {
      background: 'rgba(0, 0, 0, 0.12)',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.12)',
    },
  },
};

const SearchSelectOption = (props) => {
  const {
    option,
    classes,
    index,
    value,
    handleOptionOnClick,
    selectedItemIndex,
    handleKeyPress,
    optionLabelKey,
  } = props;
  return (
    <MenuItem
      className={cx(classes.item, {
        [classes.selectedItem]:
          (Array.isArray(value) && value.includes(option.value)) ||
          value === option.value,
        [classes.arrowFocused]: index === selectedItemIndex,
      })}
      onClick={() => {
        handleOptionOnClick && handleOptionOnClick(option.value);
      }}
      onKeyPress={handleKeyPress}
      id={`option-${index}`}
    >
      {option[optionLabelKey]}
    </MenuItem>
  );
};

export default withStyles(styles)(SearchSelectOption);
