import React from 'react';

const Truncate: React.VFC<
  { wrap?: boolean } & React.HTMLAttributes<HTMLSpanElement>
> = ({ children, style = {}, wrap = false, ...otherProps }) => {
  return (
    <span
      title={typeof children === 'string' ? children : undefined}
      style={{
        display: 'block',
        whiteSpace: wrap ? 'normal' : 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </span>
  );
};

export default Truncate;
