import React from 'react';
import ContextMenu from 'src/components/NavBar/components/ContextMenu';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from '../styles';
import constants from 'src/const';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

let profileMenu;

const ProfileIcon = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getOptions = () => {
    return [
      {
        onClick: () => dispatch(push('/profile/personal')),
        icon: <i className={`icon icon-account ${classes.menuIcon}`} />,
        name: 'Account',
      },
      {
        onClick: () => {
          sessionStorage.removeItem(constants.IS_EMULATED_SESSION_KEY);

          window.location.href = '/api/v1/auth/logout';
        },
        icon: <i className={`icon icon-exit ${classes.menuIcon}`} />,
        name: 'Logout',
      },
    ].filter(Boolean);
  };

  return (
    <div className={classes.menuItem}>
      <Tooltip title='Account and Settings'>
        <div
          onClick={(e) => profileMenu.open(e.target)}
          className={classes.userIcon}
          style={{
            backgroundImage: "url('/assets/user-avatar.png')",
          }}
        />
      </Tooltip>
      <ContextMenu
        ref={(node) => (profileMenu = node)}
        options={getOptions()}
      />
    </div>
  );
};

export default ProfileIcon;
