import { graphql } from 'relay-runtime';

export const ConnectorsListQuery = graphql`
  query connectorsListQuery {
    connectors {
      displayName
      name
      statusObject {
        status
        isRunning
        runningDatetime
        endDatetime
        startDatetime
      }
      sources {
        provider
        name
        displayName
        createdAt
        schedule
        ingestRevisions {
          semanticVersion
        }
        statusObject {
          status
          isRunning
          runningDatetime
          endDatetime
          startDatetime
        }
      }
      models {
        provider
        name
        displayName
        createdAt
        publishedTables
        preconfiguredModelRef
        destinationStatuses {
          destinationName
          statusObject {
            status
            isRunning
          }
        }
        publishedRevisions {
          revisionNumber
        }
        publishedTablesObject {
          name
          schema
        }
        destinations {
          provider
          name
          displayName
        }
        statusObject {
          status
          isRunning
          runningDatetime
          endDatetime
          startDatetime
        }
      }
    }
  }
`.default;
