import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from 'src/components/ButtonProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { RootState, useDispatch, useSelector } from 'react-redux';
import { User } from 'src/products/admin/types/user';
import { Organization } from 'src/products/admin/types/organization';
import {
  changePage,
  getRows,
  search,
  setFilterValue,
} from 'src/products/admin/redux/tables/getActions';
import { getOrganization } from 'src/products/admin/redux/organization/actions';
import {
  showErrorPopupNotification,
  showSuccessPopupNotification,
} from 'src/products/shell/Notifications';
import Autocomplete from '../Autocomplete';

// TYPES

interface ChangeUserOrgParams {
  userId: number;
  orgId: number;
}

interface ChangeUserOrgModalProps {
  open: boolean;
  onRequestClose: () => void;
  data: {
    user: User;
  };
  shareStatus: string;
}

// STYLING

const useStyles = makeStyles({
  modal: {
    minWidth: 500,
    overflow: 'visible',
  },
  modalContent: {
    overflow: 'visible',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridColumnGap: '20px',
    gridRowGap: '10px',
  },
  selectedUsers: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: 5,
    marginRight: 5,
  },
  title: { color: '#808080' },
  value: { color: '#000000' },
  name: { color: '#000', fontWeight: 600 },
});

// UTILS

const exluceOrg = (id: number) => (org: Organization) => {
  return id !== org.id;
};

const sortByName = (a: Organization, b: Organization) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

// SELECTORS

const currentOrganizationSelector = (state: RootState) =>
  state.adminPanel.organizationReqs.organization;

const organizationsStateSelector = (state: RootState) =>
  state.adminPanel.organizations;

// METHODS

const changeUserOrg = ({ userId, orgId }: ChangeUserOrgParams) => {
  const endpoint = `/api/v1/organizations/${orgId}/users/${userId}/change-organization`;
  return fetch(endpoint, { method: 'POST' });
};

const restoreInitialOrganizationsStateValue = (
  { page, searchValue, filters }: RootState['adminPanel']['organizations'],
  dispatch: ReturnType<typeof useDispatch>
) => {
  const { disabled } = filters;
  dispatch(search('organizations', true, {}, searchValue));
  dispatch(changePage('organizations', true, {}, undefined, page));
  dispatch(
    setFilterValue('organizations', true, {}, 'disabled', disabled.value)
  );
};

// MAIN COMPONENT

const ChangeUserOrgModal: React.FC<ChangeUserOrgModalProps> = (props) => {
  // PROPS
  const { user } = props.data;

  //STYLING
  const classes = useStyles();

  // REDUX RELATED
  const dispatch = useDispatch();
  const currentOrganization = useSelector(currentOrganizationSelector);
  const organizationsState = useSelector(organizationsStateSelector);
  const initialOrganizationsStateValue = React.useRef(organizationsState);

  React.useEffect(() => {
    dispatch(getOrganization(user.organizationId));
    dispatch(changePage('organizations', true, {}, undefined, 0));
    dispatch(setFilterValue('organizations', true, {}, 'disabled', false));

    return () => {
      restoreInitialOrganizationsStateValue(
        initialOrganizationsStateValue.current,
        dispatch
      );
    };
  }, []);

  // COMPONENT STATE
  const [selectedOrg, setSelectedOrg] = React.useState<Organization | null>(
    null
  );

  // DERIVED STATE
  const userFullName = [user.firstName, user.lastName].join(' ');

  // CALLBACKS
  const moveUser = async () => {
    try {
      if (selectedOrg === null) {
        return;
      }
      await changeUserOrg({ userId: user.id, orgId: selectedOrg.id });
      dispatch(getRows('users', { id: currentOrganization?.id }));
      props.onRequestClose();
      showSuccessPopupNotification(
        `User ${userFullName} was moved to ${selectedOrg.name}`
      );
    } catch (e) {
      showErrorPopupNotification(
        'There was a problem when moving the user, please try again.'
      );
    }
  };

  const onSearch = async (value: string) => {
    if (value === organizationsState.searchValue) {
      return organizationsState.data;
    }

    await dispatch(search('organizations', true, {}, value));
    const organizations: Organization[] = await dispatch(
      getRows('organizations', {})
    );
    return organizations
      .filter(exluceOrg(user.organizationId))
      .sort(sortByName);
  };

  if (!currentOrganization) {
    return null;
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      classes={{ paper: classes.modal }}
    >
      <DialogTitle>Change User Organization</DialogTitle>
      <DialogContent className={classes.modalContent}>
        <div className={classes.value}>
          Choose organization to move user <b>{userFullName}</b> of{' '}
          <b>{currentOrganization.name}</b> to:
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Autocomplete
            onChange={(org) => setSelectedOrg(org)}
            onSearch={onSearch}
            getSuggestionRenderValue={(suggestion) => suggestion.name}
            shouldRenderSuggestions={() => true}
            suggestionsMaxHeight={360}
          />
        </div>
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose} color='primary'>
          close
        </Button>
        <Button
          onClick={moveUser}
          color='primary'
          variant='contained'
          loading={props.shareStatus === 'LOADING'}
          disabled={!selectedOrg}
        >
          Move User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeUserOrgModal.displayName = 'ChangeUserOrgModal';

export default ChangeUserOrgModal;
